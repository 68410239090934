import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container } from "react-bootstrap";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import "../DailyQuotes/dailyQuotes.css";
import Footer from "../../Footer";
import ContentTable from "../ContentTable/contentTable";
import AddStory from "./addStory";
import DeleteModal from "../deleteModal";
import { rename } from "../../../utils/rename";
import FilterDropdown from "../../commonComponent/FilterDropdown";
import { getStoryService} from "../../../modules/services";
import { toast } from "react-toastify";

const Story = props => {
  const [scheduledPageNumber, setScheduledPage] = useState(1);
  const [publishedPageNumber, setPublishedPage] = useState(1);
  const [show, setShow] = useState(false);
  const [scheduledDataList, setScheduledList] = useState();
  const [publishedDataList, setPublishedList] = useState();
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [sort, setSort] = useState();
  const [scheduledSort, setScheduledSort] = useState();
  const [publishedCsvLoader, setPublishedCsvLoader] = useState(false);
  const [scheduledCsvLoader, setScheduledCsvLoader] = useState(false);

  useEffect(() => {
    props.reset();
    props.toggleMainLoader(true);
    props.getScheduledStory("SCHEDULED", scheduledPageNumber, scheduledSort);
    props.getPublishedStory("PUBLISHED", publishedPageNumber, sort);
    props.toggleMainLoader(true);
    setScheduledPage(scheduledPageNumber);
    setPublishedPage(publishedPageNumber);
    if (props.responseSuccess) {
      setShow(false);
      setDeleteIndex(0);
      setDeleteModal(false);
    }
  }, [props.match.params.path, props.responseSuccess, sort,scheduledSort]);

  useEffect(() => {
    setScheduledList(
      rename(
        props.scheduledStory.results,
        "cellOne",
        "cellTwo",
        "title",
        "scheduled_date"
      )
    );
  }, [props.scheduledStory.results]);

  useEffect(() => {
    setPublishedList(
      rename(
        props.publishedStory.results,
        "cellOne",
        "cellTwo",
        "title",
        "updated_at"
      )
    );
  }, [props.publishedStory.results]);

  useEffect(() => {
    if(props.openModal){
      setShow(true);
      setEdit(true);
      props.openSearchModal(false)
    }
  }, [])

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };

  const changePageScheduled = selected => {
    setScheduledPage(selected);
    props.toggleMainLoader(true);
    props.getScheduledStory("SCHEDULED", selected, scheduledSort);
  };
  const changePagePublished = selected => {
    setPublishedPage(selected);
    props.toggleMainLoader(true);
    props.getPublishedStory("PUBLISHED", selected, sort);
  };

  const handleAddButton = () => {
    props.toggleMainLoader(false);
    setShow(true);
    setEdit(false);
  };

  const handleCancelClose = () => {
    setShow(false);
  };
  const headerFirst = {
    headerOne: "Position Title",
    headerTwo: "Scheduled Date",
  };
  const headerSecond = {
    headerOne: "Position Title",
    headerTwo: "Updated Date",
  };
  //Functions for Edit and delete
  const handleDelete = index => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteIndex(0);
    setDeleteModal(false);
  };
  const handleDeleteQuotes = () => {
    props.toggleMainLoader(true);
    props.deleteStory(deleteIndex);
  };

  const handleUpdate = data => {
    props.contentDetails(data);
    setEdit(true);
    setShow(true);
  };

  const handleUpdateClose = () => {
    setShow(false);
    setEdit(false);
  };

  const handlePublishedDownloadCsv = () => {
    setPublishedCsvLoader(true);
    getStoryService("PUBLISHED", publishedPageNumber, sort, true)
    .then(response =>{
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `published_comfort_positions.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setPublishedCsvLoader(false)
        toast.success("CSV file downloaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(err=> {
      console.error(err)
      setPublishedCsvLoader(false)
      toast.error("Download Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
}

const handelScheduledDownloadCsv = () => {
  setScheduledCsvLoader(true);
  getStoryService("SCHEDULED", scheduledPageNumber, scheduledSort, true)
.then(response =>{
  if (response.status === 200 || response.status === 201) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `scheduled_comfort_positions.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setScheduledCsvLoader(false)
    toast.success("CSV file downloaded successfully.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
})
.catch(err=> {
  console.error(err)
  setScheduledCsvLoader(false)
  toast.error("Download Failed", {
    position: toast.POSITION.TOP_RIGHT,
  });
})
}


  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />

        <UserManagementNavbar
          title={"Comfort Positions"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          buttonTitle={"Add Position"}
          handleAddButton={handleAddButton}
        />
        <ContentTable
            mainHeader={"Published"}
            secondHeader = {<FilterDropdown  sort = {setSort} value = {sort}/>}
            header={headerSecond}
            dataList={publishedDataList}
            rowPerPage={10}
            itemCountFromServer={props.publishedStory.count}
            changePage={changePagePublished}
            pageNumber={publishedPageNumber}
            minHeightClass={"procedures-minHeight"}
            contentHeaderOne={"content-header1"}
            contentHeaderTwo={"content-header2"}
            contentHeaderThree={"content-header3"}
            contentCellOne={"content-cell1"}
            contentCellTwo={"content-cell2"}
            contentCellThree={"content-cell3"}
            viewCellClass={"view-cell-class"}
            viewCell={true}
            onDelete={handleDelete}
            onEdit={handleUpdate}
            isCellTwoDate = {true}
            isCsvButton = {true}
            handelDownloadCsv = {handlePublishedDownloadCsv}
            csvLoader = {publishedCsvLoader}
          />

        
        {/* <div style={{ marginTop: "27px" }}>
        <ContentTable
          mainHeader={"Scheduled"}
          secondHeader = {<FilterDropdown  sort = {setScheduledSort}/>}
          header={headerFirst}
          dataList={scheduledDataList}
          rowPerPage={10}
          itemCountFromServer={props.scheduledStory.count}
          changePage={changePageScheduled}
          pageNumber={scheduledPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"content-header2"}
          contentHeaderThree={"content-header3"}
          contentCellOne={"content-cell1"}
          contentCellTwo={"content-cell2"}
          contentCellThree={"content-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelScheduledDownloadCsv}
          csvLoader = {scheduledCsvLoader}
        /> 
        </div> */}
        <Footer className={"content-management-footer"} />

        <AddStory
          show={show}
          contentClassName={"modal-content-quotes"}
          handleCancelClose={edit ? handleUpdateClose : handleCancelClose}
          title={
            edit ? "Update Comfort Positions" : "Add Comfort Positions"
          }
          isEdit={edit}
        />

        <DeleteModal
          modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteQuotes}
          loader={props.loader}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    details: state.userManagementDetails.details,
    scheduledStory: state.scheduledStory,
    publishedStory: state.publishedStory,
    responseSuccess: state.responseSuccess.responseSuccess,
    loader: state.loader.mainLoader,
    openModal : state.modalFromSearch.openModal,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getScheduledStory: actionCreator.getScheduledStory,
      getPublishedStory: actionCreator.getPublishedStory,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      deleteStory: actionCreator.deleteStory,
      contentDetails: actionCreator.getContentDetails,
      openSearchModal : actionCreator.isOpenModalFromSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Story);
