import React from "react";
import { Navbar } from "react-bootstrap";
import {
  BACK_ICON,
  EDIT_ICON,
  DELETE_ICON,
  BUTTON_SPINNER,
} from "../../constant";
import "./common.css";

const Back = props => {
  const {
    className,
    backIconClassName,
    backNavItemClassName,
    handleEdit,
    Back,
  } = props;

  return (
    <Navbar className={className}>
      <Navbar.Brand className={backNavItemClassName}>
        <div onClick={Back} style={{ cursor: "pointer" }}>
          <img src={BACK_ICON} alt="" className={backIconClassName} />
          Go back
        </div>
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        {props.isDownload ? (
          <button className="download-btn" onClick={props.onDownload}>
            Download
          </button>
        ) : null}

        {props.isEdit ? (
          <button className="edit-button" onClick={handleEdit}>
            <img src={EDIT_ICON} style={{ marginRight: "12px" }} alt="" /> Edit
            Details
          </button>
        ) : null}
        {props.isDelete ? (
          <button className="delete-button" onClick={props.onDelete}>
            <img src={DELETE_ICON} style={{ marginRight: "12px" }} alt="" />{" "}
            Delete
          </button>
        ) : null}
        {props.isSave ? (
          <button
            className="save-button"
            onClick={props.onSave}
            disabled={props.loader}
          >
            {!props.loader ? (
              <> Save Hospital</>
            ) : (
              <img src={BUTTON_SPINNER} alt="" height="30px" />
            )}
          </button>
        ) : null}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Back;
