import moment from "moment";
import { validateVideo, validatePicture } from "./validations";

//As the table is a common component but the attribute name came from api's are
//inconsistent so to make a common attribute this function is used and tested.
export const rename = (value, key1, key2, atr1, atr2) => {
  let array = JSON.parse(JSON.stringify(value));
  array = array.map(x => {
    x[key1] = x[atr1];
    x[key2] =
      atr2 === "scheduled_date" ? moment(x[atr2]).format("LL") : x[atr2];
    delete x[atr1];
    delete x[atr2];
    return x;
  });
  return array;
};

//This is used when we have three cells in use
export const newRename = (value, atr1, atr2, atr3) => {
  let array = JSON.parse(JSON.stringify(value));
  array = array.map(x => {
    x["cellOne"] = x[atr1];
    x["cellTwo"] = x[atr2];
    x["cellThree"] = moment(x[atr3]).format("LL");
    delete x[atr1];
    delete x[atr2];
    delete x[atr3];
    return x;
  });
  return array;
};

export const replace = str => {
  return str.replace(/\r?\n|\r/g, "");
};

export const status = selectedDate => {
  if (selectedDate > new Date()) {
    return "SCHEDULED";
  } else {
    return "PUBLISHED";
  }
};

//function to check the date and set the status accordingly
export const approveStatus = selectedDate => {
  if (selectedDate > new Date().toISOString().split("T")) {
    return "SCHEDULED";
  } else {
    return "PUBLISHED";
  }
};

export const separateVideo = arr => {
  let newArr = new Array();
  arr.map(item => {
    if (validateVideo(item)) {
      newArr.push(item);
    }
  });
  return newArr;
};

export const separatePictures = arr => {
  let newArr = new Array();
  arr.map(item => {
    if (validatePicture(item)) {
      newArr.push(item);
    }
  });
  return newArr;
};
