let apiVersion = process.env.REACT_APP_API_URL;

//Authorization URL
export const getUserLoggedInUrl = `${apiVersion}user/login`;
export const getUserForgotPasswordRequestUrl = `${apiVersion}user/forgotPasswordRequest`;
export const getUserOtpVerifiedUrl = `${apiVersion}user/verifyOTP`;
export const getUserResetPasswordUrl = `${apiVersion}user/resetPassword`;
export const getAccessTokenUrl = `${apiVersion}user/generateToken`;

//Hospital URL
export const getHospitalList = `${apiVersion}hospital/getHospitalDetailedAdmin`;
export const searchHospitalList = `${apiVersion}hospital/searchSubscriberManagement`;
export const addHospital = `${apiVersion}hospital/addHospital`;
export const addHealthHero = `${apiVersion}hospital/addHealthHero`;
export const editHealthHero = `${apiVersion}hospital/updateHealthHero`;
export const deleteHealthHero = `${apiVersion}hospital/deleteHealthHero`;
export const addCls = `${apiVersion}user/addCLSUser`;
export const addClinician = `${apiVersion}user/addClinicianUser`;
export const getHospitaldata = `${apiVersion}hospital/retrieveHospitalDetailsAdmin`;
export const updateHospital = `${apiVersion}hospital/updateHospitalDetails`;
export const deleteHospital = `${apiVersion}hospital/deleteHospitalDetails`;
export const filterHospital = `${apiVersion}hospital/filterSubscriberManagement`;
export const getHospitalFullUrl = `${apiVersion}hospital/getHospitalsList`;
export const renewPlan = `${apiVersion}hospital/renewHospitalPlanDetails`;

//User Management URL
export const parentChildListUrl = `${apiVersion}user/getParentChildList`;
export const clsListUrl = `${apiVersion}user/getAllCLSList`;
export const clinicianListUrl = `${apiVersion}user/getClinicianDetailsList`;
export const userManagementGlobalSearchUrl = `${apiVersion}user/searchManagementUser`;
export const userManagementGlobalFilterUrl = `${apiVersion}user/filterManagementUser`;
export const updateClinicianDetailsUrl = `${apiVersion}user/updateClinicianDetails/`;
export const updateClsDetailsUrl = `${apiVersion}user/updateCLSDetails/`;
export const updateParentDetailsUrl = `${apiVersion}user/updateParentDetails/`;
export const updateChildDetailsUrl = `${apiVersion}user/updateChildDetailsWithAvatar/`;
export const deleteClinicianDetailsUrl = `${apiVersion}user/deleteClinicianDetails/`;
export const deleteClsDetailsUrl = `${apiVersion}/user/deleteCLSDetails/`;
export const deleteParentDetailsUrl = `${apiVersion}user/deleteParentUser/`;
export const deleteChildDetailsUrl = `${apiVersion}user/deleteChildDetails/`;
export const updateChildUserUrl = `${apiVersion}user/updateChildUserDetails/`;
export const activationUrl = `${apiVersion}user/userDeactivation/`;
export const downloadUser = `${apiVersion}user/downloadAllUser/`;

//subscription Plan URL
export const subscriptioPlans = `${apiVersion}subscription_plans/getSubscriptionPlansList`;
export const addSubscriptionPlan = `${apiVersion}subscription_plans/addSubscriptionPlan`;
export const updateSubscriptionPlan = `${apiVersion}subscription_plans/updateSubscriptionPlan`;

//Content Management URL
export const getQuotesUrl = `${apiVersion}resources/dailyQuotesList`;
export const publishQuoteUrl = `${apiVersion}resources/addDailyQuotes`;
export const getProceduresUrl = `${apiVersion}resources/procedureListAdmin`;
export const approveProcedureUrl = `${apiVersion}resources/approveProcedure`;
export const addProcedureUrl = `${apiVersion}resources/addProcedure`;
export const getGlossaryUrl = `${apiVersion}resources/glossaryListAdmin`;
export const glossaryActionUrl = `${apiVersion}resources/approveGlossary`;
export const addGlossaryUrl = `${apiVersion}resources/addGlossary`;
export const getChildQuotesUrl = `${apiVersion}child_profile/ChildQuotesList`;
export const childQuotesActionUrl = `${apiVersion}child_profile/approveChildQuotes`;
export const getPodcastUrl = `${apiVersion}resources/podcastListAdmin`;
export const addPodcastUrl = `${apiVersion}resources/addPodcast`;
export const getHealthheroesUrl = `${apiVersion}hospital/healthHeroesAdminList`;
export const healthHeroActionUrl = `${apiVersion}hospital/approveHealthHero`;
export const getPicturesUrl = `${apiVersion}resources/picturesListAdmin`;
export const picturesAction = `${apiVersion}resources/approvePictures`;
export const addPicture = `${apiVersion}resources/addPictures`;
export const getWhatsGoingOnMe = `${apiVersion}child_profile/getWhatsGoingWithMeList`;
export const addWhatsGoingOnMe = `${apiVersion}child_profile/addWhatsGoingWithMeAdmin`;

//New content Management URL
export const getStoryUrl = `${apiVersion}resources/inspirationalStoryList`;
export const addStoryUrl = `${apiVersion}resources/addComfortPosition`;
export const getResourcesUrl = `${apiVersion}resources/getHospitalResources`;
export const getProfessionalResourcesUrl = `${apiVersion}resources/getProfessionalsResources`;
export const getParentResourcesUrl = `${apiVersion}resources/getParentResources`;
export const addResourcesUrl = `${apiVersion}resources/addHospitalResource/`;
export const addParentResourcesUrl = `${apiVersion}resources/addParentResource/`;
export const addProfessionalResourcesUrl = `${apiVersion}resources/addProfessionalsResource/`;
export const resourcesCategoryUrl = `${apiVersion}resources/getHospitalResourcesCategory`;
export const approveResourcesUrl = `${apiVersion}resources/approveOtherResource`;
export const deleteProcedure = `${apiVersion}resources/deleteProcedure`;
export const deleteDailyQuotes = `${apiVersion}resources/deleteDailyQuotes`;
export const deleteGlossary = `${apiVersion}resources/deleteGlossary`;
export const deletePodcast = `${apiVersion}resources/deletePodcast`;
export const deletePicture = `${apiVersion}resources/deletePicture`;
export const deleteResources = `${apiVersion}resources/deleteHospitalResource`;
export const deleteParentResources = `${apiVersion}resources/deleteParentResources`;
export const deleteProfessionalResources = `${apiVersion}resources/deleteProfessionalsResource`;
export const deleteStory = `${apiVersion}resources/deleteInspirationalStory`;
export const deleteWhatsGoingonMe = `${apiVersion}child_profile/deleteWhatsGoingWithMe`;
export const getTopResourcesUrl = `${apiVersion}resources/getAdminTopResources`;
export const addTopResourcesUrl = `${apiVersion}resources/addAdminTopResources`;
export const updateTopResourcesUrl = `${apiVersion}resources/updateAdminTopResources`;
export const deleteTopResourcesUrl = `${apiVersion}resources/deleteAdminTopResources`;
export const getOtherResourcesUrl = `${apiVersion}resources/getOtherResourceListAdmin`;
export const searchGlobalList = `${apiVersion}resources/adminHomeSearch`;

//UPDATE CONTENTS
export const updateDailQuotes = `${apiVersion}resources/updateDailyQuotes`;
export const updateGlossary = `${apiVersion}resources/updateGlossary`;
export const updateProcedure = `${apiVersion}resources/updateProcedure`;
export const updatePodcast = `${apiVersion}resources/updatePodcast`;
export const updatePicture = `${apiVersion}resources/updatePicture`;
export const updateResource = `${apiVersion}resources/updateHospitalResource`;
export const updateParentResource = `${apiVersion}resources/updateParentResource`;
export const updateProfessionalResource = `${apiVersion}resources/updateProfessionalsResource`;
export const updateStory = `${apiVersion}resources/updateInspirationalStory`;
export const updateWhatsGoingOnMe = `${apiVersion}child_profile/updateWhatsGoingWithMe`;
export const getPostsURL = `${apiVersion}community/getAllPostsAdmin`;
export const postAction = `${apiVersion}community/approvePosts`;

//Resources Categories
export const addHospitalCategoriesUrl = `${apiVersion}resources/addHospitalResourceCategroy/`;
export const deleteHospitalCategoriesUrl = `${apiVersion}resources/deleteHospitalResourceCategory`;
export const getHospitalCategoriesUrl = `${apiVersion}resources/getHospitalResourcesCategory`;
export const addParentCategoriesUrl = `${apiVersion}resources/addParentResourceCategroy/`;
export const deleteParentCategoriesUrl = `${apiVersion}resources/deleteParentResourceCategory`;
export const getParentCategoriesUrl = `${apiVersion}resources/getParentResourcesCategory`;
export const addProfessionalCategoriesUrl = `${apiVersion}resources/addProfessionalResourceCategroy/`;
export const deleteProfessionalCategoriesUrl = `${apiVersion}resources/deleteProfessionalResourceCategory`;
export const getProfessionalCategoriesUrl = `${apiVersion}resources/getProfessionalResourcesCategory`;
export const getAllProfessionalCategoriesUrl = `${apiVersion}resources/getProfessionalResourcesCategories`;
export const getAllParentCategoriesUrl = `${apiVersion}resources/getParentResourcesCategories`;
export const getAllHospitalCategoriesUrl = `${apiVersion}resources/getHospitalResourcesCategories`;
export const updateHospitalCategory = `${apiVersion}resources/updateAdminHospitalResourcesCategory`;
export const updateParentCategory = `${apiVersion}resources/updateAdminParentResourcesCategory`;
export const updateProfessionalCategory = `${apiVersion}resources/updateAdminProfessionalResourcesCategory`;
export const getHospitalSubscriptionList = `${apiVersion}user/hospitalSubscriptionList/v1`;
export const getHospitalPhotoSpansihTitle = `${apiVersion}hospital/hospitalPhotoSpanishTitle`;