import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container } from "react-bootstrap";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import Footer from "../../Footer";
import ContentTable from "../ContentTable/contentTable";
import "../Procedures/procedures.css";
import { newRename, approveStatus } from "../../../utils/rename";
import AddGlossary from "./addGlossary";
import DeleteModal from "../deleteModal";
import FilterDropdown from "../../commonComponent/FilterDropdown";
import { getGlossaryList} from "../../../modules/services";
import { toast } from "react-toastify";

const Glossary = props => {
  const [awaitingPageNumber, setAwaitingPage] = useState(1);
  const [publishedPageNumber, setPublishedPage] = useState(1);
  const [scheduledPageNumber, setScheduledPage] = useState(1);
  const [awaitingDataList, setAwaitingList] = useState();
  const [scheduledDataList, setScheduledList] = useState();
  const [publishedDataList, setPublishedList] = useState();
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [sort, setSort] = useState();
  const [scheduledSort, setScheduledSort] = useState();
  const [awaitingSort, setAwaitingSort] = useState();
  const [publishedCsvLoader, setPublishedCsvLoader] = useState(false);
  const [awaitingCsvLoader, setAwaitingCsvLoader] = useState(false);
  const [scheduledCsvLoader, setScheduledCsvLoader] = useState(false);

  useEffect(() => {
    props.reset();
    props.toggleMainLoader(true);
    props.getGlossaryList("AWAITING", awaitingPageNumber, awaitingSort);
    props.getPublishedGlossary("PUBLISHED", publishedPageNumber,sort);
    props.getScheduledGlossary("SCHEDULED", scheduledPageNumber,scheduledSort);
    setAwaitingPage(awaitingPageNumber);
    setScheduledPage(scheduledPageNumber);
    setPublishedPage(publishedPageNumber);
    if (props.responseSuccess) {
      setDeleteIndex(0);
      setDeleteModal(false);
      setShow(false);
    }
  }, [props.match.params.path, props.responseSuccess,awaitingSort,sort,scheduledSort]);

  useEffect(() => {
    setAwaitingList(
      newRename(
        props.glossaryList.results,
        "term",
        "uploaded_by",
        "scheduled_date"
      )
    );
  }, [props.glossaryList.results]);

  useEffect(() => {
    setScheduledList(
      newRename(
        props.scheduledGlossary.results,
        "term",
        "uploaded_by",
        "scheduled_date"
      )
    );
  }, [props.scheduledGlossary.results]);

  useEffect(() => {
    setPublishedList(
      newRename(
        props.publishedGlossary.results,
        "term",
        "uploaded_by",
        "updated_at"
      )
    );
  }, [props.publishedGlossary.results]);

  useEffect(() => {
    if(props.openModal){
      setShow(true);
      setEdit(true);
      props.openSearchModal(false)
    }
  }, [])

  const headerAwaiting = {
    headerOne: "Glossary Name",
    headerTwo: "Uploaded By",
    headerThree: "Actions",
  };
  const headerScheduled = {
    headerOne: "Glossary Name",
    headerTwo: "Uploaded By",
    headerThree: "Scheduled Date",
  };
  const headerPublished = {
    headerOne: "Glossary Name",
    headerTwo: "Uploaded By",
    headerThree: "Updated Date",
  };

  const changePageAwaiting = selected => {
    setAwaitingPage(selected);
    props.toggleMainLoader(true);
    props.getGlossaryList("AWAITING", selected, awaitingSort);
  };

  const changePageScheduled = selected => {
    setScheduledPage(selected);
    props.toggleMainLoader(true);
    props.getScheduledGlossary("SCHEDULED", selected, scheduledSort);
  };

  const changePagePublished = selected => {
    setPublishedPage(selected);
    props.toggleMainLoader(true);
    props.getPublishedGlossary("PUBLISHED", selected, sort);
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };
  const handleAddButton = () => {
    props.toggleMainLoader(false);
    setShow(true);
    setEdit(false);
  };

  const handleCancelClose = () => {
    setShow(false);
  };

  const handleApprove = index => {
    props.approveGlossary(index, {
      status: approveStatus(
        props.glossaryList.results.filter(item => item.id == index)[0]
          .scheduled_date
      ),
    });
  };

  const handleReject = index => {
    props.approveGlossary(index, { status: "REJECT" });
  };

  //Functions for update and delete
  const handleDelete = index => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteIndex(0);
    setDeleteModal(false);
  };
  const handleDeleteGlossary = () => {
    props.deleteGlossary(deleteIndex);
  };

  const handleUpdate = data => {
    props.contentDetails(data);
    setEdit(true);
    setShow(true);
  };

  const handleUpdateClose = () => {
    setShow(false);
    setEdit(false);
  };

  const handlePublishedDownloadCsv = () => {
    setPublishedCsvLoader(true);
    getGlossaryList("PUBLISHED", publishedPageNumber,sort, true)
    .then(response =>{
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `published_glossary.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setPublishedCsvLoader(false)
        toast.success("CSV file downloaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(err=> {
      console.error(err)
      setPublishedCsvLoader(false)
      toast.error("Download Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
}

const handelAwaitingDownloadCsv = () => {
  setAwaitingCsvLoader(true);
  getGlossaryList("AWAITING", awaitingPageNumber, awaitingSort, true)
  .then(response =>{
    if (response.status === 200 || response.status === 201) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `awaiting_glossary.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setAwaitingCsvLoader(false)
      toast.success("CSV file downloaded successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  })
  .catch(err=> {
    console.error(err)
    setAwaitingCsvLoader(false)
    toast.error("Download Failed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  })
}

const handelScheduledDownloadCsv = () => {
  setScheduledCsvLoader(true);
  getGlossaryList("SCHEDULED", scheduledPageNumber,scheduledSort, true)
.then(response =>{
  console.log("here")
  if (response.status === 200 || response.status === 201) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `scheduled_glossary.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setScheduledCsvLoader(false)
    toast.success("CSV file downloaded successfully.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
})
.catch(err=> {
  console.error(err)
  setScheduledCsvLoader(false)
  toast.error("Download Failed", {
    position: toast.POSITION.TOP_RIGHT,
  });
})
}


  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />
        <UserManagementNavbar
          title={"Glossary(Parent)"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          buttonTitle={"Add Glossary"}
          handleAddButton={handleAddButton}
        />
        <ContentTable
          mainHeader={"Published"}
          secondHeader = {<FilterDropdown  sort = {setSort} value = {sort}/>}
          header={headerPublished}
          dataList={publishedDataList}
          rowPerPage={10}
          itemCountFromServer={props.publishedGlossary.count}
          changePage={changePagePublished}
          pageNumber={publishedPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handlePublishedDownloadCsv}
          csvLoader = {publishedCsvLoader}
        />
        <div style={{ marginTop: "39px" }}></div>
        <ContentTable
          mainHeader={"Awaiting"}
          secondHeader = {<FilterDropdown  sort = {setAwaitingSort} value = {awaitingSort}/>}
          header={headerAwaiting}
          dataList={awaitingDataList}
          rowPerPage={10}
          itemCountFromServer={props.glossaryList.count}
          changePage={changePageAwaiting}
          pageNumber={awaitingPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          onApprove={handleApprove}
          onReject={handleReject}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelAwaitingDownloadCsv}
          csvLoader = {awaitingCsvLoader}
        />
        <div style={{ marginTop: "39px" }}></div>
        <ContentTable
          mainHeader={"Scheduled"}
          secondHeader = {<FilterDropdown  sort = {setScheduledSort} value = {scheduledSort}/>}
          header={headerScheduled}
          dataList={scheduledDataList}
          rowPerPage={10}
          itemCountFromServer={props.scheduledGlossary.count}
          changePage={changePageScheduled}
          pageNumber={scheduledPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelScheduledDownloadCsv}
          csvLoader = {scheduledCsvLoader}
        />
        
        
        <Footer className={"content-management-footer"} />
        <AddGlossary
          show={show}
          contentClassName={"modal-content-quotes"}
          handleCancelClose={edit ? handleUpdateClose : handleCancelClose}
          title={edit ? "Update Glossary" : "Add Glossary (Parent)"}
          isEdit={edit}
        />

        <DeleteModal
          modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteGlossary}
          loader={props.loader}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    details: state.userManagementDetails.details,
    responseSuccess: state.responseSuccess.responseSuccess,
    glossaryList: state.glossaryList,
    scheduledGlossary: state.scheduledGlossary,
    publishedGlossary: state.publishedGlossary,
    loader: state.loader.mainLoader,
    openModal : state.modalFromSearch.openModal,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getGlossaryList: actionCreator.getGlossaryList,
      getScheduledGlossary: actionCreator.getScheduledGlossary,
      getPublishedGlossary: actionCreator.getPublishedGlossary,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      approveGlossary: actionCreator.glossaryAction,
      deleteGlossary: actionCreator.deleteGlossary,
      contentDetails: actionCreator.getContentDetails,
      openSearchModal : actionCreator.isOpenModalFromSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Glossary);
