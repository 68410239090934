import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { toast } from "react-toastify";

export function* getAllHospitalSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getAllHospitalListService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.allHospitalListResponse(response.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (e) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* searchHospitalSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getSearchedHospitals,
      action.data,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.allHospitalListResponse(response.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (e) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addHospitalSagaCall(action) {
  try {
    const response = yield call(serviceCreator.addHospital, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.addHospitalSuccess(true));
      yield put(actionCreators.getHospitalDetails(response.data.data));

      if (action?.data?.health_heroes) {
        yield* processHealthHeroes(action.data.health_heroes, response.data.data.id);
      }
      
      action.clearData();
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

function* processHealthHeroes(health_heroes, hospitalId) {
  const processedHeroes = [];

  for (const elem of health_heroes) {
    const payload = { ...elem, hospital: hospitalId };
    yield put(actionCreators.addHealthHero(payload));
    processedHeroes.push({ ...elem, hospital: hospitalId });
  }
  yield put(actionCreators.toggleMainLoader(false));
  return processedHeroes;
}

export function* getHospitalData(action) {
  try {
    const response = yield call(serviceCreator.getHospitalData, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.getHospitalDetails(response.data.data));
    }
  } catch (e) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addHealthHeroSagaCall(action) {
  try {
    const response = yield call(serviceCreator.addHealthHero, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getHospitalData(response.data.data.hospital));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* editHealthHeroSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.editHealthHero,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getHospitalData(response.data.data.hospital));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* deleteHealthHeroSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteHealthHero, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getHospitalData(action.hospital_id));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* addClsSagaCall(action) {
  try {
    const response = yield call(serviceCreator.addCls, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getHospitalData(response.data.data.hospital));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* addClinicianSagaCall(action) {
  try {
    const response = yield call(serviceCreator.addClinician, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getHospitalData(response.data.data.hospital));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* updateHospitalSagaCall(action) {
  try {
    const response = yield call(serviceCreator.updateHospital, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.addHospitalSuccess(true));
      yield put(actionCreators.getHospitalData(response.data.data.id));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* deleteHospitalSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteHospital, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* filterHospitalSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.filterHospital,
      action.data,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.allHospitalListResponse(response.data));
    }
  } catch (e) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getFullHospitalSaga(action) {
  try {
    const response = yield call(serviceCreator.getFullHospitalService);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.fullHospitalsResponse(response.data.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (e) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* renewPlanSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.renewPlan,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getHospitalData(response.data.data.id));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* downloadUserSagaCall(action) {
  try {
    const response = yield call(serviceCreator.downloadUser, action.id);
    if (response.status === 200 || response.status === 201) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${action.name}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success("CSV file downloaded successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error("Download Failed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
