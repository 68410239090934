import React from "react";
import EditProcedure from "../Procedures/editProcedure";
import { ROUTES_PATH } from "../../../constant";

const EditChildProcedure = (props) => {
    return (
        <>
            <EditProcedure
                {...props}
                path={ROUTES_PATH.CHILD_PROCEDURE}
                role="CHILD"
            />
        </>
    );
};
export default EditChildProcedure;