import React from "react";
import { Modal } from "react-bootstrap";
import { CROSS_ICON } from "../../../constant";
import "../DailyQuotes/dailyQuotes.css";

const AddModalHoc = (WrappedComponent) => {
  const AddModalHoc = (props) => {
    const { show, title, contentClassName, handleCancelClose } = props;

    return (
      <>
        <Modal
          size="lg"
          show={show}
          centered
          contentClassName={contentClassName}
        >
          <Modal.Body className="modal-content-body">
            <div>
              <img
                className="content-close-icon"
                src={CROSS_ICON}
                alt=""
                onClick={handleCancelClose}
              />
              <p className="content-title-modal">{title}</p>
            </div>
            <hr className="modal-content-header"></hr>
            <WrappedComponent {...props} />
          </Modal.Body>
        </Modal>
      </>
    );
  };
  return AddModalHoc;
};
export default AddModalHoc;
