import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Back from "../../../common/components/back";
import Footer from "../../Footer";
import {
  ROUTES_PATH,
  UPLOAD_PLUS,
  MESSAGE,
  RED_DELETE_ICON,
  USER_GENDER_DROPDOWN,
} from "../../../constant";
import TextField from "../textField";
import { S3Client } from "../../../utils/upload";
import ContentButton from "../contentButton";
import DateField from "../dateField";
import { validateName, validateMeaning } from "../../../utils/validations";
import {rename, status } from "../../../utils/rename";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Spinner } from "react-bootstrap";
import { components } from "react-select";
import MultiSelect from "../../UserManagement/multiSelect";
import { clone } from "lodash";
import imageCompression from 'browser-image-compression';

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const AddPictures = props => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [termInvalid, setTermInvalid] = useState(false);
  const [meaningInvalid, setMeaningInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hospitalInvalid, setHospitalInvalid] = useState(false);

  const [data, setData] = useState({
    term: "",
    meaning: "",
    picture: [],
    scheduled_date: "",
    status: "",
    hospital: [],
    hospitalList: [],
  });

  const clearData = () => {
    setData({
      term: "",
      meaning: "",
      picture: [],
      scheduled_date: "",
      status: "",
      hospital: [],
      hospitalList: [],
    });
  };

  const [options, setOptions] = useState();

  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {
    setData({
      ...data,
      hospital : props.userRole.includes("ADMIN") ? data.hospital : [props.hospitalDetails.id]
    })
  }, []);

  useEffect(() => {
    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ]
    );
  }, [props.fullHospitals.data]);

  //useEffect to navigate screen after add success
  useEffect(() => {
    if (props.responseSuccess) {
      props.history.push(ROUTES_PATH.PICTURES);
    }
    props.response(false);
  }, [props.responseSuccess]);

  const handleBack = () => {
    props.history.push(ROUTES_PATH.PICTURES);
  };

  //function to input images
  const ImageInput = useRef(null);
  const handleUploadImage = e => {
    ImageInput.current.click();
  };

  const resizeFile = (file) => new Promise(async (resolve, reject) => {
    // Options for compression
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
    };

    try {
      // Wait for the compression to complete
      const compressedFile = await imageCompression(file, options);
  
      if (!compressedFile) {
        // If compressedFile is undefined, reject the promise
        return reject(new Error('Failed to compress the image.'));
      }
  
      // Resolve with the compressed file
      resolve(compressedFile);
    } catch (error) {
      console.error('Error during compression:', error);
      reject(error);
    }
  });

  const handleImageUpload = async (e) => {
    if (!e.target.files.length) return;

    const image = await resizeFile(e.target.files[0])
    
    setLoading(true);
    S3Client.uploadFile(image)
      .then(res => {
        ImageInput.current.value = null;
        setData({ ...data, picture: [...data.picture, res.location] });
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleImageDelete = (e, index) => {
    const arr = [...data.picture];
    arr.splice(index, 1);
    setData({ ...data, picture: [...arr] });
  };

  const handleReelDelete = e => {
    e.preventDefault();
    setData({ data, Children_highlight_reel: "" });
  };

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };

  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  //function to add pictures
  const handleAdd = e => {
    e.preventDefault();
    props.toggleMainLoader(true);
    let termValid = validateName(data.term);
    let meaningValid = validateMeaning(data.meaning);
    let validatedHospital = data.hospital.length > 0;
    if (!termValid || !data.term) {
      setTermInvalid(true);
    }
    if (!meaningValid || !data.meaning) {
      setMeaningInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (
      !termValid ||
      !meaningValid ||
      !data.picture.length ||
      !validatedHospital
    ) {
      props.toggleMainLoader(false);
      return;
    }
    if (termValid && meaningValid && data.picture.length) {
      const picture_data = {
        user: props.user.idToken,
        term: data.term,
        meaning: data.meaning,
        picture: data.picture ? data.picture : null,
        scheduled_date: moment(selectedDate).format("YYYY-MM-DD"),
        status: status(selectedDate),
        hospital: data.hospital,
      };
      props.addPicture(picture_data);
      clearData();
    }
  };
  const styles = {
    control: (base, state) => ({
      ...base,
      border: hospitalInvalid ? "1px solid #eb5757" : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: hospitalInvalid ? "1px solid #eb5757" : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  return (
    <>
      <Container className="common-container">
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />
        <Form noValidate></Form>
        <Row style={{ marginTop: "27px" }}>
          <Col>
            <p className="title-add-procedure">Add Pictures</p>
            {props.userRole.includes("ADMIN") ? <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={setHospital}
                value={data.hospitalList}
              />
              {hospitalInvalid ? (
                <p className="clinician-invalid">Please choose a category</p>
              ) : null}
            </Form.Group>:
            
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={[{"label":props.hospitalDetails.name, "value":props.hospitalDetails.name}]}
                disabled = {true}
              />
              {hospitalInvalid ? (
                <p className="clinician-invalid">Please choose a category</p>
              ) : null}
            </Form.Group>}
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="procedure-content-label">
                Picture Title<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                className="input-field-content"
                type="text"
                placeholder="Enter Title Here"
                isInvalid={termInvalid}
                maxLength={100}
                onChange={e => {
                  setData({ ...data, term: e.target.value });
                  setTermInvalid(!validateName(e.target.value));
                }}
              />
              <div style={{ marginBottom: "10px" }}>
                <div className="chracter-remain-title">
                  {100 - data.term.length}/100 Characters{" "}
                </div>
              </div>
              <Form.Control.Feedback
                className="clinician-invalid"
                type="invalid"
              >
                {MESSAGE.TITLE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>

            <TextField
              label={"Description"}
              labelClassName={"textfield-content-label"}
              className={"text-field-content"}
              isInvalid={meaningInvalid}
              max={"200"}
              onChange={e => {
                setData({ ...data, meaning: e.target.value });
                setMeaningInvalid(false);
              }}
              message={MESSAGE.DESCRIPTION_INVALID}
            />
            <div className="chracter-remain">
              {" "}
              {200 - data.meaning.length}/200 Characters
            </div>
          </Col>

          <div className="horizontal-line"></div>

          <Col>
            <div className="textfield-content-label">
              Add pictures<span style={{ color: "red" }}> *</span>
            </div>

            <div className="row">
              {data.picture &&
                data.picture.map((pic, index) => {
                  return (
                    <div key={index}>
                      <img
                        src={pic}
                        alt=""
                        className="procedure-image-wrapper"
                      />
                      <div>
                        <button
                          className="procedure-hospital-icon"
                          onClick={e => {
                            handleImageDelete(e, index);
                          }}
                        >
                          <img src={RED_DELETE_ICON} alt="" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              <div className="procedure-image-wrapper">
                {loading ? (
                  <Spinner
                    animation="border"
                    style={{ margin: "52px 0 0 53px" }}
                  />
                ) : (
                  <div
                    className="upload-hospital-image-icon"
                    onClick={handleUploadImage}
                  >
                    <p>
                      <img
                        src={UPLOAD_PLUS}
                        alt=""
                        style={{ paddingLeft: "61px" }}
                      />
                    </p>
                    <p className="upload-label">Add New</p>
                  </div>
                )}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                  ref={ImageInput}
                />
                  
              </div>
              <Form.Text className="text-muted" style={{ marginTop: "20px", width: "400px" }}>
                **Please Add JPG or PNG. Pictures should be aligned vertically. If posted horizontal, pictures will rotate when exported.
              </Form.Text>
            </div>
            <div style={{ marginTop: "85px" }}>
            <DateField
              show={show}
              selectedDate={selectedDate}
              setShow={e => setShow(e.target.checked)}
              setSelectedDate={date => {
                setSelectedDate(date);
              }}
            />
            </div>
            <ContentButton
              title={"Add Picture"}
              onClick={handleAdd}
              className={"procedure-button"}
            />
          </Col>
        </Row>
      </Container>
      <Footer className={"content-management-footer"} />
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      loader: state.loader.mainLoader,
      responseSuccess: state.responseSuccess.responseSuccess,
      fullHospitals: state.fullHospitals,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleMainLoader: actionCreator.toggleMainLoader,
      addPicture: actionCreator.addPicture,
      response: actionCreator.responseSuccess,
      getFullHospitals: actionCreator.getFullHospitals,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPictures);
