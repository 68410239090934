import React from 'react'
import { Modal } from 'react-bootstrap';
import SaveButton from '../../common/components/saveButton';
import { CROSS_ICON, DELETE_WARNING } from "../../constant";

const DeleteModal = (props) => {
    const { modal,
        handleClose,
        handleDelete,
        loader,
    } = props

    return (
        <Modal show={modal} centered>
            <Modal.Body>
                <img src={CROSS_ICON} alt="close" className="close-icon" onClick={handleClose} />
                <div>
                    <img src={DELETE_WARNING} alt="add-user" className="add-user-logo" />
                    <h2 className="congrats-message">Delete Warning!</h2>
                    <p className="congrats-para">Once you delete this content you won’t be able to reverse this action, please make sure that the content you want to delete is correct.</p>
                    <div style={{ margin: "40px 21px 40px 21px" }}>
                        <button className="form-save-and-add-button" onClick={handleClose}>Go Back</button>
                        <SaveButton
                            className={"modal-delete-button"}
                            submit={handleDelete}
                            buttonName={"Delete"}
                            loader={loader}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteModal;
