import React from "react";
import "./cmDashboard.css";
import Card from "react-bootstrap/Card";
import { LEFT_ARROW } from "../../../constant";

const CmCard = (props) => {
  return (
    <Card className={props.className}>
      <Card.Body style={{ textAlign: "center" }}>
        <Card.Title className={"card-title-content"}>{props.title}</Card.Title>
        <Card.Subtitle>{props.subtitle}</Card.Subtitle>
        <button className={props.viewClassName} onClick={props.onClick}>
          View More
          <img src={LEFT_ARROW} alt="" style={{ marginLeft: "16px" }} />
        </button>
      </Card.Body>
    </Card>
  );
};

export default CmCard;
