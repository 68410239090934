import React, { useEffect, useState, Fragment } from 'react';
import { ROUTES_PATH, EDIT_ICON2 } from "../../constant";
import { Card, Container, Row } from 'react-bootstrap';
import * as actionCreator from "../../modules/actions";
import Back from "../../common/components/back";
import { validatePlantype } from "../../utils/validations";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import MyModal from './MyModal';
import FullPageLoader from "../commonComponent/mainLoaderSpinner";
import "./subscriptions.css";

const Subscriptions = (props) => {

    const handleBack = () => {
        props.history.push(ROUTES_PATH.HOME)
    }

    const { plans,
        getSubscriptionPlans,
        addSubscriptionPlan,
        editSubscriptionPlan,
        toggleMainLoader,
        loader } = props

    //useEffect to get data when page loads
    useEffect(() => {
        toggleMainLoader(true)
        getSubscriptionPlans();
    }, [])

    const [typeInvalid, setTypeInvalid] = useState(false)
    const [durationNoInvalid, setDurationNoInvalid] = useState(false)
    const [durationTypeInvalid, setDurationTypeInvalid] = useState(false)
    const [disabled, setDisabled] = useState(false);
    const [save, setSave] = useState(false)
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [data, setData] = useState({
        id: "",
        type: "",
        duration_no: 0,
        duration_type: ""
    })

    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //function to clear the fields after the data is saved
    const clearData = () => {
        setData({
            id: "",
            type: "",
            duration_no: 0,
            duration_type: ""
        })
    }

    //function to open modal when add button is clicked
    const handleAddClick = () => {
        setShow(true)
        setTitle("Add Plan")
        setSave(true)

    }

    //function to close the modal after data is saved
    const handleClose = () => {
        setShow(false)
        clearData()
        setDurationNoInvalid(false)
        setDurationTypeInvalid(false)
        setTypeInvalid(false)
    }

    //function to add a new subscription plan
    const handleSave = (e) => {
        e.preventDefault()
        toggleMainLoader(true)
        setDisabled(true)
        let validatetype = validatePlantype(data.type)
        if (!data.type) {
            setTypeInvalid(true)
        }
        if (!validatetype) {
            setTypeInvalid(true)
        }
        if (!data.duration_no || data.duration_no === "0") {
            setDurationNoInvalid(true)
        }
        if (!data.duration_type) {
            setDurationTypeInvalid(true)
        }
        if (!data.duration_no || !data.duration_type || !data.type || !validatetype || data.duration_no === "0") {
            setDisabled(false)
            toggleMainLoader(false)
            return
        }
        if (data.duration_no && data.duration_type && data.type && validatetype) {
            let new_data = {
                type: data.type,
                duration: data.duration_no + "-" + data.duration_type,
            }
            clearData()
            addSubscriptionPlan(new_data)
            props.history.push(ROUTES_PATH.SUBSCRIPTION_PLAN)
            setTypeInvalid(false)
            setDurationNoInvalid(false)
            setDurationTypeInvalid(false)
            setDisabled(false)
        }
    }

    //useEffect to close the modal when data is add or edit successfully
    useEffect(() => {
        setShow(false)
    }, [plans,])


    //function to open edit modal and split the duration into 2 parts
    //e.g="2-Months" to "2", "Months"
    const handleEditClick = (index) => {
        setSave(false)
        setTitle("Edit Plan")
        setData({
            id: plans[index].id,
            type: plans[index].type,
            duration_no: plans[index].duration.split("-")[0],
            duration_type: plans[index].duration.split("-")[1]
        })
        setShow(true)
    }

    //function to edit the subscription plans
    const handleEdit = (e) => {
        e.preventDefault()
        toggleMainLoader(true)
        setDisabled(true)
        let validatetype = validatePlantype(data.type)
        if (!data.type) {
            setTypeInvalid(true)
        }
        if (!validatetype) {
            setTypeInvalid(true)
        }
        if (!data.duration_no || data.duration_no === "0") {
            setDurationNoInvalid(true)
        }
        if (!data.duration_type) {
            setDurationTypeInvalid(true)
        }
        if (!data.duration_no || !data.duration_type || !data.type || !validatetype || data.duration_no === "0") {
            setDisabled(false)
            toggleMainLoader(false)
            return
        }
        if (data.duration_no && data.duration_type && data.type && validatetype) {
            let new_data = {
                id: data.id,
                type: data.type,
                duration: data.duration_no + "-" + data.duration_type,
            }
            editSubscriptionPlan(new_data)
            clearData()
            props.history.push(ROUTES_PATH.SUBSCRIPTION_PLAN)
            setTypeInvalid(false)
            setDurationNoInvalid(false)
            setDurationTypeInvalid(false)
            setDisabled(false)
        }

    }

    return (
        <Fragment>
            <Container style={{ minHeight: "100vh", position: "relative", maxWidth: "1046px" }}>

                <Back
                    className={"back-nav"}
                    backNavItemClassName={"back-nav-item"}
                    backIconClassName={"back-icon"}
                    isSearch={false}
                    isDelete={false}
                    isSave={false}
                    Back={handleBack}
                />

                <NavBar
                    title={"Manage Subscription Plans"}
                    id={"SubscriberManagementTitle-css"}
                    className={"main-navbar"}
                    isSearch={false}
                    isFilter={false}
                    isButton={true}
                    buttonName={"Add Plans"}
                    buttonClick={handleAddClick}
                />

                <Row className="plan-row">
                    {plans ? plans && plans.map((plan, index) => {
                        return (
                            <Card className="subscription-plan-body" key={index}>
                                <Card.Body>
                                    <button className="subscription-renew-btn" onClick={() => handleEditClick(index)}>
                                        <img src={EDIT_ICON2} alt="" style={{ marginRight: "12px" }}
                                        />Edit</button>
                                    <p className="subscription-plan-type">{plan.type}</p>
                                    <p className="subscription-plan-duration">{plan.duration}</p>
                                </Card.Body>
                            </Card>
                        );
                    }) : loader ? <FullPageLoader className="spinner-pos" /> : null}

                </Row>

                <MyModal
                    modal={show}
                    title={title}
                    data={data}
                    handleChange={handleChange}
                    handleSubmit={handleSave}
                    handleClose={handleClose}
                    handleEdit={handleEdit}
                    type={save}
                    typeInvalid={typeInvalid}
                    durationNoInvalid={durationNoInvalid}
                    durationTypeInvalid={durationTypeInvalid}
                    disabled={disabled}
                    loader={loader}
                    setTypeInvalid={setTypeInvalid}
                    setDurationNoInvalid={setDurationNoInvalid}
                    setDurationTypeInvalid={setDurationTypeInvalid}
                />

            </Container>
            <Footer className={"my-footer"} />
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        plans: state.plans.data,
        loader: state.loader.mainLoader,
    };
};



const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getSubscriptionPlans: actionCreator.getSubscriptionPlans,
            addSubscriptionPlan: actionCreator.addSubscriptionPlan,
            editSubscriptionPlan: actionCreator.editSubscriptionPlan,
            toggleMainLoader: actionCreator.toggleMainLoader,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);