import Table from 'react-bootstrap/Table';
import '../ContentManagement/ContentTable/contentTable.css';
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import './hospitalSubscription.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from '../../modules/actions';
import Pagination from "react-js-pagination";
import moment from "moment";
import FullPageLoader from '../commonComponent/mainLoaderSpinner';
import { BUTTON_SPINNER } from '../../constant';

function HospitalSubscriptionTable(props) {
  const {
    dataList,
    rowPerPage,
    pageNumber,
    changePage,
    itemCountFromServer,
    sortDropdown,

  } = props;
  return (
    <>
      <div style={{ marginTop: "18px", marginLeft: "13px" }}>
        <Row className="content-main-header">
          <Col className="content-main-header1">Subscriptions</Col>
          <Col className="content-main-header2" style={{marginLeft: "500px"}}>
          {sortDropdown}

          {
            props?.isCsvButton &&
            (
                <button
                className="csv-subscription-button-css"
                onClick={props?.handelDownloadCsv}
              >
                {" "}
                {props?.csvLoader && (
                  <img src={BUTTON_SPINNER} height="30px" alt="" />
                )}{" "}
                {!props?.csvLoader && (
                  <span>
                    {" "}
                    Download CSV
                  </span>
                )}{" "}
              </button>
            )
          }
          
          </Col>
        </Row>
        <Table className="mx-auto" responsive="sm">
          <thead>
            <tr>
              <th className='subscription-heading'>Name</th>
              <th className='subscription-heading'>Subscription Status</th>
              <th className='subscription-heading'>Subscription Type</th>
              <th className='subscription-heading'>Start Date</th>
              <th className='subscription-heading'>End Date</th>
            </tr>
          </thead>
          <tbody>
            {dataList.length ?
              dataList.map(elem => {
                return (
                  <tr>
                    <td>{elem?.user_name}</td>
                    <td>{elem?.status}</td>
                    <td>{elem?.subscription_type}</td>
                    <td>{moment(elem?.start_date).format("MMMM DD , YYYY")}</td>
                    <td>{moment(elem?.expiration_date).format("MMMM DD , YYYY")}</td>
                  </tr>
                )

              })

              : 
              props.loader ? (
                <FullPageLoader className="spinner-pos" />
              ) : (
                <div className="no-results">No results found!!</div>
              )
            }

          </tbody>
        </Table>
      </div>
      {dataList.length ? (
        <Pagination
          totalItemsCount={itemCountFromServer}
          itemsCountPerPage={rowPerPage}
          activePage={pageNumber}
          onChange={changePage}
          prevPageText={"< Prev"}
          nextPageText={"Next >"}
          firstPageText={""}
          lastPageText={""}
          innerClass={"pagination-container"}
          linkClassPrev={"pagination-previous"}
          linkClassNext={"pagination-next"}
          disabledClass={"pagination-link-disabled"}
          activeLinkClass={"pagination-active"}
          linkClass={"page-link-className-css"}
          pageRangeDisplayed={3}
        />
      ) : (
        <div style={{ height: "65px" }}></div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    responseSuccess: state.responseSuccess.responseSuccess,
    loader: state.loader.mainLoader,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,

    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalSubscriptionTable);