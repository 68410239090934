import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { MESSAGE } from "../../../constant";
import "../DailyQuotes/dailyQuotes.css";
import "../../UserManagement/UserManagementClinicianDetails/userManagementClinicianDetails.css";
import AddModalHoc from "../AddModalHoc/addModalHoc";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import moment from "moment";
import { validateChar, validateTextField } from "../../../utils/validations";
import TextField from "../textField";
import { replace, status } from "../../../utils/rename";
import DateField from "../dateField";
import ContentButton from "../contentButton";
import MultiSelect from "../../UserManagement/multiSelect";
import { USER_GENDER_DROPDOWN } from "../../../constant";
import { rename } from "../../../utils/rename";
import { components } from "react-select";
import { clone } from "lodash";

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const AddPodcast = props => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [titleInvalid, setTitleInvalid] = useState(false);
  const [descriptionInvalid, setDescriptionInvalid] = useState(false);
  const [urlInvalid, setUrlInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState();
  const [hospitalInvalid, setHospitalInvalid] = useState(false);

  const [data, setData] = useState({
    title: "",
    description: "",
    audio: "",
    hospital: [],
    hospitalList: [],
  });

  useEffect(() => {
    if (props.isEdit) {

      let hospitalArray = [];
      let hospitalIdArray = [];

      if (
        props.podcast.hospital &&
        props.podcast.hospital.length > 0 &&
        options?.length > 0
      ) {
        props.podcast.hospital &&
          props.podcast.hospital.forEach(hospital => {
            options.map(option => {
              if (option.value === parseInt(hospital)) {
                hospitalArray.push(option);
                hospitalIdArray.push(option.value);
              }
            });
          });
        }

      setData({
        title: props.podcast.cellOne,
        description: props.podcast.description ? props.podcast.description : "",
        audio: props.podcast.audio,
        hospitalList:
          hospitalArray.length === options?.length - 1
            ? [...hospitalArray, { label: "All", value: "all" }]
            : hospitalArray,
        hospital: hospitalIdArray
      });
    }else{

      setData({
        ...data,
        hospital : props.userRole.includes("ADMIN") ? data.hospital : [props.hospitalDetails.id]
      })
    }
  }, [options]);


  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {

    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ]
    );
  }, [props.fullHospitals.data]);

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };
  
  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      data.hospitalList &&
        data.hospitalList.map(hopital => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };


  const handleSubmit = e => {
    e.preventDefault();
    props.toggleMainLoader(true);
    setSubmittedOnce(true);
    let validateTitle = validateChar(data.title);
    let validatedDescription = validateTextField(data.description);
    let validatedHospital = data.hospital.length > 0;
    if (!validateTitle) {
      setTitleInvalid(true);
    }
    if (!validatedDescription) {
      setDescriptionInvalid(true);
    }
    if (!data.audio) {
      setUrlInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (
      !validatedDescription ||
      !validateTitle ||
      !selectedDate ||
      !data.audio || 
      !validatedHospital
    ) {
      props.toggleMainLoader(false);
      return;
    }

    props.addPodcast({
      user: props.user.idToken,
      title: data.title,
      description: data.description,
      audio: data.audio,
      scheduled_date: selectedDate
        ? moment(selectedDate).format("YYYY-MM-DD")
        : "",
      status: status(selectedDate),
      hospital: data.hospital,
    });
  };

  const handleUpdate = e => {
    e.preventDefault();
    props.toggleMainLoader(true);
    setSubmittedOnce(true);
    let validateTitle = validateChar(data.title);
    let validatedDescription = validateTextField(data.description);
    let validatedHospital = data.hospital.length > 0;
    if (!validateTitle) {
      setTitleInvalid(true);
    }
    if (!validatedDescription) {
      setDescriptionInvalid(true);
    }
    if (!data.audio) {
      setUrlInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (
      !validatedDescription ||
      !validateTitle ||
      !selectedDate ||
      !data.audio || 
      !validatedHospital
    ) {
      props.toggleMainLoader(false);
      return;
    }

    props.updatePodcast(props.podcast.id, {
      user: props.podcast.user_id,
      title: data.title,
      description: data.description,
      audio: data.audio,
      hospital: data.hospital,
    });
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce
        ? data.hospital.length === 0
          ? "1px solid #eb5757"
          : 0
        : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: submittedOnce
          ? data.hospital.length === 0
            ? "1px solid #eb5757"
            : 0
          : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };



  return (
    <>
      <Form noValidate>

      {props.userRole.includes("ADMIN") ?
          <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={setHospital}
                value={data.hospitalList}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row> : 
        
        <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={[{"label":props.hospitalDetails.name, "value":props.hospitalDetails.name}]}
                disabled = {true}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        }

        <Row style={{ marginTop: "24px" }}>
          <Col style={{ paddingRight: "10px" }}>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="modal-content-label">
                Podcast Title<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Or Paste Your Podcast Title"
                className="input-field-content"
                value={data.title}
                onChange={e => {
                  setData({ ...data, title: e.target.value });
                  submittedOnce
                    ? setTitleInvalid(!validateChar(e.target.value))
                    : setTitleInvalid(false);
                }}
                isInvalid={titleInvalid}
              />
              <Form.Control.Feedback
                type="invalid"
                className="clinician-invalid"
              >
                {MESSAGE.TITLE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>

        <TextField
          label={"Podcast Description"}
          className={"text-field-content"}
          labelClassName={"textfield-content-label-glossary"}
          placeholder={"Add Podcast Description Here"}
          value={data.description}
          max={"200"}
          isInvalid={descriptionInvalid}
          onChange={e => {
            setData({
              ...data,
              description: e.target.value,
            });
            submittedOnce
              ? setDescriptionInvalid(
                  !validateTextField(replace(e.target.value)) ? true : false
                )
              : setDescriptionInvalid(false);
          }}
          message={MESSAGE.DESCRIPTION_INVALID}
        />
        <div style={{ marginBottom: "10px" }}>
          <div className="chracter-remain">
            {" "}
            {200 - data.description.length}/200 Characters
          </div>
        </div>

        <TextField
          label={"Podcast URL"}
          className={"text-field-content"}
          labelClassName={"textfield-content-label-glossary"}
          placeholder={"Type Or Paste The Podcast URL Here"}
          value={data.audio}
          isInvalid={urlInvalid}
          onChange={e => {
            setData({
              ...data,
              audio: e.target.value,
            });
            setUrlInvalid(false);
          }}
          message={MESSAGE.URL_INVALID}
        />

        <Row>
          <Col>
            {!props.isEdit ? (
              <DateField
                show={show}
                selectedDate={selectedDate}
                setShow={e => setShow(e.target.checked)}
                setSelectedDate={date => {
                  setSelectedDate(date);
                }}
              />
            ) : null}
          </Col>
          <Col className="mt-auto">
            <ContentButton
              onClick={props.isEdit ? handleUpdate : handleSubmit}
              title={props.isEdit ? "Update Podcast" : "Publish Podcast"}
              className={"podcast-button"}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      details: state.userManagementDetails.details,
      loader: state.loader.mainLoader,
      podcast: state.contentDetails,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
      fullHospitals: state.fullHospitals,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      addPodcast: actionCreator.addPodcast,
      toggleMainLoader: actionCreator.toggleMainLoader,
      updatePodcast: actionCreator.updatePodcat,
      getFullHospitals: actionCreator.getFullHospitals,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModalHoc(AddPodcast));
