import React, { useRef, useState, useCallback } from "react";
import { S3Client } from "../../../utils/upload";
import {
  RED_DELETE_ICON,
  UPLOAD_PLUS,
  CROSS_ICON,
  UPLOAD_FILE,
} from "../../../constant";
import { validatePicture, validateVideo } from "../../../utils/validations";
import { Spinner, Form, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreator from "../../../modules/actions";
import UserModal from "./UserModal";
import _ from "lodash";
import * as url from "../../../utils/urls";
import axios from "../../../axios";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { MESSAGE } from "../../../constant";

const HospitalImage = (props) => {

  const { data, setData, media, setMedia, isBack, saveModal } = props;
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [editMediaShow, setEditMediaShow] = useState(false);
  const [editMediaUrl, setEditMediaUrl] = useState(null);
  const [mediaUrlIndex, setMediaUrlIndex] = useState(null);
  const [currentUrl, setCurrentUrl] = useState(null);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [esTitle, setEsTitle] = useState("");
  const [disable, setDisable] = useState(false);

  const ImageInput = useRef(null);
  const handleUploadImage = (e) => {
    ImageInput.current.click();
    setShow(false);
    if (saveModal) {
      isBack(true);
    }
  };
  const handleModalOpen = () => {
    setShow(true);
    setType("");
  };

  const handleClose = () => {
    setEditMediaShow(false);
    setShow(false);
    setType("");
  };

  const fileOnChange = (e) => {
    setType(e.target.value);
  };

  const editMedia = (mediaUrl, index, beforeUrl) => {
    let wholeTitle = beforeUrl?.split("?title=");
    console.log("editMedia", wholeTitle.length);
    if (wholeTitle.length > 1) {
      let title = wholeTitle[1]?.split("&estitle=");
      let titleText = title[0]?.split("?")?.join(" ");
      setTitle(titleText);
    } else {
      setTitle("");
    }

    setCurrentUrl(beforeUrl);
    setMediaUrlIndex(index);
    setEditMediaUrl(mediaUrl);
    setEditMediaShow(true);
  };

  const handleEditMedia = () => {
    const concatedUrl =
      editMediaUrl +
      `?title=${title.split(" ").join("?")}&estitle=${esTitle
        .split(" ")
        .join("?")}`;

    // Determine whether the edited media is an image or video
    const isPicture = validatePicture(editMediaUrl?.split("?title=")[0]);
    const mediaType = isPicture ? "pictures" : "videos";

    // Find the index of the media URL in the corresponding array of the data state
    const dataIndex = data[mediaType].findIndex((url) => url === currentUrl);

    if (dataIndex !== -1) {
      // Update the media URL at the found index
      const updatedMedia = [...media];
      const mediaIndex = updatedMedia.findIndex((url) => url === currentUrl);
      updatedMedia[mediaIndex] = concatedUrl;
      setMedia(updatedMedia);

      // Update the corresponding array in the data state with the new title and esTitle
      setData((prevData) => {
        const newData = { ...prevData };
        newData[mediaType][dataIndex] = concatedUrl;
        return newData;
      });
    }

    // Reset input fields and close the edit modal only if the edit is successful
    setEsTitle("");
    setTitle("");
    setEditMediaShow(false);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      // Check if the file is an image before resizing
      if (file.type.startsWith("image")) {
        Resizer.imageFileResizer(
          file,
          1000,
          900,
          "JPEG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "file"
        );
      } else {
        resolve(file); // If it's not an image, return the file unchanged
      }
    });

    const imageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
    ]

  const handleImageUpload = async (e) => {
    setLoader(true);
    let files = Object.values(e.target.files);
    var uploadedMedia = []; // Array to store newly uploaded media URLs

    // Use for...of loop to properly handle async/await
    for (const elem of files) {
      try {
        if(imageTypes.some(type => type === elem.type)) {
          if(elem.size >= 5000000 ){
            toast.error(MESSAGE.IMAGE_LESS_THAN, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoader(false);
            return;
          }
        }
        const resizedFile = await resizeFile(elem); // Resize the file if it's an image
        const res = await S3Client.uploadFile(resizedFile); // Upload the file
        const concatedUrl =
          res.location +
          `?title=${title.split(" ").join("?")}&estitle=${esTitle
            .split(" ")
            .join("?")}`;
        uploadedMedia.push(concatedUrl); // Add image or video URL to the uploadedMedia array

        // If all files are uploaded, update media array and data state
        if (uploadedMedia.length === files.length) {
          // Concatenate the newly uploaded media with the existing media array
          const updatedMedia = [...media, ...uploadedMedia];

          // Update media array
          setMedia(updatedMedia);

          // Update data state depending on whether the uploaded media is an image or video
          setData((prevData) => ({
            ...prevData,
            pictures: updatedMedia.filter((url) =>
              validatePicture(url.split("?title=")[0])
            ),
            videos: updatedMedia.filter((url) =>
              validateVideo(url.split("?title=")[0])
            ),
          }));
          setEsTitle("");
          setTitle("");
          setLoader(false); // Disable loader
        }
      } catch (err) {
        console.error(err);
        setEsTitle("");
        setTitle("");
        setLoader(false);
      }
    }
  };

  const handleImageDelete = (e, index) => {
    let mediaImage = media[index];
    if (validatePicture(mediaImage.split("?title=")[0])) {
      const myPic = mediaImage;
      const picIndex = data.pictures.indexOf(myPic);
      const pics = [...data.pictures];
      pics.splice(picIndex, 1);
      setData({ ...data, pictures: pics });
    } else {
      const myVid = mediaImage;
      const vidIndex = data.videos.indexOf(myVid);
      const vids = [...data.videos];
      vids.splice(vidIndex, 1);
      setData({ ...data, videos: vids });
    }
    const arr = [...media];
    setMedia(arr.splice(index, 1));
  };

  const delayedQuery = useCallback(
    _.debounce((value) => {
      if (value.length > 0) {
        setDisable(true);
        axios
          .get(`${url.getHospitalPhotoSpansihTitle}?title=${value}`)
          .then((response) => {
            setDisable(false);
            setEsTitle(response.data.data);
          });
      }
    }, 100),
    []
  );

  const handleChange = (e) => {
    setTitle(e.target.value);
    delayedQuery(e.target.value);
  };
  return (
    <div>
      <p className="common-form-label" style={{ paddingLeft: "20px" }}>
        Hospital Videos & Pictures
      </p>

      <div className="row" style={{ paddingLeft: "24px" }}>
        {media &&
          media.map((pic, index) => {
            let beforeUrl = pic;
            let splittedUrl = pic?.split("?title=");
            let photoTitle = splittedUrl[1]?.split("&estitle=");

            return (
              <>
                <div key={index}>
                  {validateVideo(splittedUrl[0]) ? (
                    <>
                      <video
                        className="hospital-iamge-view"
                        width="140"
                        height="140"
                        controls
                        onClick={() =>
                          editMedia(splittedUrl[0], index, beforeUrl)
                        }
                      >
                        <source src={splittedUrl[0]} type="video/mp4" />
                      </video>
                      <div>
                        <button
                          className="hospital-video-delete-button"
                          onClick={(e) => {
                            handleImageDelete(e, index);
                            if (saveModal) {
                              isBack(true);
                            }
                          }}
                        >
                          <img
                            src={RED_DELETE_ICON}
                            alt=""
                            style={{
                              paddingBottom: "3px",
                              paddingRight: "3px",
                            }}
                          />
                        </button>
                        <div style={{ paddingLeft: "10px" }}>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip className="tooltip-top">
                                <div className="mb-1">
                                  Title :{" "}
                                  {photoTitle?.length > 0
                                    ? photoTitle[0].split("?").join(" ")
                                    : ""}
                                </div>
                              </Tooltip>
                            }
                          >
                            <p className="user-name">
                              {photoTitle?.length > 0
                                ? photoTitle[0]
                                    .slice(0, 15)
                                    .split("?")
                                    .join(" ")
                                : ""}
                            </p>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={splittedUrl[0]}
                        alt=""
                        className="hospital-iamge-view"
                        onClick={() =>
                          editMedia(splittedUrl[0], index, beforeUrl)
                        }
                      />
                      <div>
                        <button
                          className="hospital-image-delete-icon"
                          onClick={(e) => {
                            handleImageDelete(e, index);
                            if (saveModal) {
                              isBack(true);
                            }
                          }}
                        >
                          <img
                            src={RED_DELETE_ICON}
                            alt=""
                            style={{
                              paddingBottom: "3px",
                              paddingRight: "3px",
                            }}
                          />
                        </button>
                        <div style={{ paddingLeft: "10px" }}>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip className="tooltip-top">
                                <div className="mb-1">
                                  Title :{" "}
                                  {photoTitle?.length > 0
                                    ? photoTitle[0].split("?").join(" ")
                                    : ""}
                                </div>
                              </Tooltip>
                            }
                          >
                            <p className="user-name">
                              {photoTitle?.length > 0
                                ? photoTitle[0]
                                    .slice(0, 15)
                                    .split("?")
                                    .join(" ")
                                : ""}
                            </p>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            );
          })}

        <div className="hospital-iamge-view">
          {loader ? (
            <Spinner animation="border" style={{ margin: "52px 0 0 53px" }} />
          ) : (
            <div
              className="upload-hospital-image-icon"
              onClick={handleModalOpen}
            >
              <p>
                <img src={UPLOAD_PLUS} alt="" style={{ paddingLeft: "61px" }} />
              </p>
              <p className="upload-label">Add New</p>
            </div>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleImageUpload}
            ref={ImageInput}
            accept={type == "image" ? "image/*" : "video/*"}
            multiple
          />
        </div>
      </div>

      <Modal show={show} centered>
        <Modal.Body>
          <img
            src={CROSS_ICON}
            alt="close"
            className="close-icon"
            onClick={handleClose}
          />
          <div>
            <img
              src={UPLOAD_FILE}
              style={{ height: "50px" }}
              alt="add-user"
              className="add-user-logo"
            />
            <h5 className="modal-note-text">{
              type === "image" &&
              "Image should be in JPG or PNG format. Max 5 MB"  
            }
            {
              type === "video" &&
              "Video should be in MP4 or MOV format. Max 50 MB"
            }
            </h5>
            <h4 className="modal-select-info">
              Please select what you want to upload
            </h4>
            <Form.Group
              controlId="formBasicName"
              className="mt-3"
              style={{ marginLeft: "21px" }}
            >
              <Form.Label className="common-form-label">Media Title</Form.Label>
              <Form.Control
                className="common-form-input"
                type="text"
                name="name"
                placeholder="Enter Media Title"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Form.Group>
            <Form noValidate className="choose-user-form">
              <Form.Check
                type="radio"
                name="type"
                value="image"
                onChange={fileOnChange}
                checked={type === "image"}
                label="Image"
                inline
              />
              <Form.Check
                type="radio"
                name="type"
                label="Video"
                value="video"
                onChange={fileOnChange}
                checked={type === "video"}
                inline
              />
            </Form>
            <div style={{ marginLeft: "21px" }}>
              <button
                className="form-save-and-add-button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className={
                  type == "" ? "form-save-and-add-button1" : "form-save-button"
                }
                onClick={handleUploadImage}
                disabled={type == "" ? true : false}
              >
                Add Now
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={editMediaShow} centered>
        <Modal.Body>
          <img
            src={CROSS_ICON}
            alt="close"
            className="close-icon"
            onClick={handleClose}
          />
          <div>
            <Form.Group
              controlId="formBasicName"
              className="mt-3"
              style={{ marginLeft: "21px" }}
            >
              <Form.Label className="common-form-label">Image</Form.Label>
            </Form.Group>
            {validateVideo(editMediaUrl) ? (
              <video
                className="hospital-iamge-view"
                width="140"
                height="140"
                controls
                onClick={() => editMedia(editMediaUrl)}
              >
                <source src={editMediaUrl} type="video/mp4" />
              </video>
            ) : (
              <img
                src={editMediaUrl}
                alt=""
                className="hospital-edit-media-view"
              />
            )}

            <Form.Group
              controlId="formBasicName"
              className="mt-3"
              style={{ marginLeft: "21px" }}
            >
              <Form.Label className="common-form-label">Media Title</Form.Label>
              <Form.Control
                className="common-form-input"
                type="text"
                name="name"
                placeholder="Enter Media Title"
                value={title}
                maxLength={100}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <div style={{ marginBottom: "10px", marginRight: "23px" }}>
                <div className="chracter-remain-title">
                  {100 - title.length}/100 Characters{" "}
                </div>
              </div>
            </Form.Group>

            <div style={{ marginLeft: "21px", marginTop: "40px" }}>
              <button
                className="form-save-and-add-button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className={
                  disable == true
                    ? "form-save-and-add-button1"
                    : "form-save-button"
                }
                onClick={handleEditMedia}
                disabled={disable == true ? true : false}
              >
                Add Now
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      multiplePicturesUpload: actionCreator.multiplePictures,
    },
    dispatch
  );
};

export default connect(mapDispatchToProps)(HospitalImage);
