import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObjects";

const initialState = {
  forgotPassword: {},
};

const forgotPasswordInformation = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return updateObject(state, { forgotPassword: data });
    default:
      return state;
  }
};
export default forgotPasswordInformation;
