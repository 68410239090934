// Combine all reducers and export root reducers

import { combineReducers } from "redux";
import * as actionTypes from "../actions/actionTypes";
import verifyOtpInformation from "./verifyOtpInformation";
import user from "./user";
import forgotPasswordInformation from "./forgotPasswordInformation";
import hospitals, { fullHospitalReducer, multiplePicturesReducer } from "./hospitalList";
import {
  hospitalDetails,
  addHospitalSuccess,
  currentHealthHero,
  hospitalCopyDetails,
} from "./hospitalDetails";
import loader from "./loader";
import plans from "./planDetails";
import {
  userManagementParentChildListResponse,
  userManagementClinicianListResponse,
  userManagementClsListResponse,
  userManagementDetails,
  searchReducer,
  filterReducer,
  filterValueReducer
} from "./userManagementListResponse";

import {
  proceduresReducer,
  publishedProceduresReducer,
  publishedQuotesReducer,
  quotesReducer,
  responseSuccessReducer,
  scheduledProceduresReducer,
  childProcedureReducer,
  glossaryReducer,
  childQuotesReducer,
  scheduledGlossaryReducer,
  publishedGlossaryReducer,
  scheduledPodcastReducer,
  publishedPodcastReducer,
  healthHeroesReducer,
  picturesReducer,
  publishedHealthHeroesReducer,
  schedulePictureReducer,
  publishedPicturesReducer,
  publishedChildReducer,
  scheduledWhatsGoingOnMeReducer,
  publishedWhatsGoingOnMeReducer,
  postsReducer,
  scheduledPostsReducer,
  publishedPostsReducer,
  getPublishedProcedurePageReducer,
  getAwaitingPageReducer,
  getScheduledPageReducer,
} from "./contentResponse";
import {
  publishedStoryReducer,
  scheduledResourcesReducer,
  scheduledStoryReducer,
  publishedResourcesReducer,
  getResourcesCategoryReducer,
  awaitingResourcesReducer,
  topResourcesReducer,
  publishedParentResourcesReducer,
  getGlobalSearchResultReducer,
  getSearchModalResultReducer,
  hospitalSubscriptionListReducer,
} from "./newContentResponse";
import { contentDetails } from "./contentDetail";

const appReducer = combineReducers({
  user: user,
  verifyOtpInformation: verifyOtpInformation,
  forgotPasswordInformation: forgotPasswordInformation,
  hospitals: hospitals,
  ParentChildList: userManagementParentChildListResponse,
  ClinicianList: userManagementClinicianListResponse,
  ClsList: userManagementClsListResponse,
  userManagementDetails: userManagementDetails,
  hospitalDetails: hospitalDetails,
  addHospitalSuccess: addHospitalSuccess,
  plans: plans,
  loader: loader,
  searchData: searchReducer,
  quotes: quotesReducer,
  publishedQuotes: publishedQuotesReducer,
  responseSuccess: responseSuccessReducer,
  procedures: proceduresReducer,
  scheduledProcedures: scheduledProceduresReducer,
  publishedProcedures: publishedProceduresReducer,
  fullHospitals: fullHospitalReducer,
  filterData: filterReducer,
  childProcedures: childProcedureReducer,
  glossaryList: glossaryReducer,
  scheduledGlossary: scheduledGlossaryReducer,
  publishedGlossary: publishedGlossaryReducer,
  childQuotes: childQuotesReducer,
  publishedChildQuotes: publishedChildReducer,
  scheduledPodcast: scheduledPodcastReducer,
  publishedPodcast: publishedPodcastReducer,
  healthHeroes: healthHeroesReducer,
  pictures: picturesReducer,
  scheduledStory: scheduledStoryReducer,
  publishedStory: publishedStoryReducer,
  scheduledResources: scheduledResourcesReducer,
  publishedResources: publishedResourcesReducer,
  topResources: topResourcesReducer,
  resourcesCategory: getResourcesCategoryReducer,
  publishedHealthHeroes: publishedHealthHeroesReducer,
  scheduledPictures: schedulePictureReducer,
  publishedPictures: publishedPicturesReducer,
  scheduledWhatsGoingOnMe: scheduledWhatsGoingOnMeReducer,
  publishedWhatsGoingOnMe: publishedWhatsGoingOnMeReducer,
  resources: awaitingResourcesReducer,
  contentDetails: contentDetails,
  posts: postsReducer,
  scheduledPosts: scheduledPostsReducer,
  publishedPosts: publishedPostsReducer,
  currentHealthHero: currentHealthHero,
  publishedParentResourcesReducer:publishedParentResourcesReducer,
  globalSearch : getGlobalSearchResultReducer, 
  modalFromSearch : getSearchModalResultReducer,
  PublishedProcedurePage : getPublishedProcedurePageReducer,
  mutplePicturesArray: multiplePicturesReducer,
  AwaitingPageReducer : getAwaitingPageReducer,
  ScheduledPageReducer : getScheduledPageReducer,
  hospitalCopyDetails: hospitalCopyDetails,
  hospitalSubscriptionListReducer : hospitalSubscriptionListReducer,
  filterValueReducer: filterValueReducer
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = undefined;
    localStorage.clear();
  }
  return appReducer(state, action);
};

export default rootReducer;
