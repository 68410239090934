import React, { useRef } from "react";
import { Row, Form, Spinner } from "react-bootstrap";
import { UPLOAD_PLUS, RED_DELETE_ICON } from "../../constant";
import { validateVideo,validatePicture } from "../../utils/validations";
import { S3Client } from "../../utils/upload";
import { toast } from "react-toastify";
import { MESSAGE } from "../../constant";
import imageCompression from 'browser-image-compression';

const UploadMedia = (props) => {
  const {
    handleImageDelete,
    handleReelDelete,
    data,
    setData,
    media,
    setMedia,
    loader,
    setLoader,
    reelLoader,
    setReelLoader,
    editMedia
  } = props;

  const imageInput = useRef(null);

  const handleRef = (e) => {
    imageInput.current.click();
  };

//   const resizeFile = (file) => new Promise(resolve => {
//     Resizer.imageFileResizer(file, 500, 400, 'JPEG', 100, 0,
//     uri => {
//       resolve(uri);
//     }, 'file' );
// });

const resizeFile = (file) => new Promise(async (resolve, reject) => {
  // Options for compression
  const options = {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 2000,
    useWebWorker: true,
  };

  try {
    // Wait for the compression to complete
    const compressedFile = await imageCompression(file, options);

    if (!compressedFile) {
      // If compressedFile is undefined, reject the promise
      return reject(new Error('Failed to compress the image.'));
    }

    // Resolve with the compressed file
    resolve(compressedFile);
  } catch (error) {
    console.error('Error during compression:', error);
    reject(error);
  }
});

const handleImageUpload = async (e) => {

  let files = Object.values(e.target.files);
  var setPicturesArray = []

  setLoader(true);
  // Use Promise.all to await all the upload promises
  await Promise.all(files.map(async (elm) => {

    try {
      if (validatePicture(elm.name)){
        const image = await resizeFile(elm);
        const res = await S3Client.uploadFile(image);
        setPicturesArray.push(res.location)
        setMedia(editMedia ? [...media, ...setPicturesArray] : [...media, ...setPicturesArray] );
      }
      else if (validateVideo(elm.name)){
        const res = await S3Client.uploadFile(elm);
        setPicturesArray.push(res.location)
        setMedia(editMedia? [...media, ...setPicturesArray] : [...media, ...setPicturesArray]);
      }
      else{
        toast.error(MESSAGE.MEDIA_SHOULD_BE, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
      }
      
      
    } catch (err) {
      console.error(err);
    }
  }
  )
  );

  setLoader(false);
};

  const handleReelUpload = (e) => {
    if (!validateVideo(e?.target?.files[0]?.name)){
      toast.error(MESSAGE.MEDIA_SHOULD_BE, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else{
      setReelLoader(true);
      S3Client.uploadFile(e.target.files[0])
      .then((res) => {
        setData({
          ...data,
          Children_highlight_reel: res.location,
        });
        setReelLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setReelLoader(false);
      });
    }
  };

  return (
    <>
      <div className="modal-content-label">
        Add procedure pictures and videos
      </div>

      <Row style={{ marginLeft: "-10px" }}>
        {media &&
          media.map((item, index) => {
            return (
              <div key={index}>
                {validateVideo(item) ? (
                  <>
                    <video
                      className="procedure-image-wrapper"
                      width="140"
                      height="140"
                      controls
                    >
                      <source src={item} type="video/mp4" />
                    </video>
                    <div>
                      <button
                        className="procedure-video-icon"
                        onClick={(e) => {
                          handleImageDelete(e, index);
                          console.log("index", index)
                        }}
                      >
                        <img
                          src={RED_DELETE_ICON}
                          alt=""
                          style={{
                            paddingBottom: "3px",
                            paddingRight: "3px",
                          }}
                        />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={item}
                      alt=""
                      className="procedure-image-wrapper"
                    />
                    <div>
                      <button
                        className="procedure-hospital-icon"
                        onClick={(e) => {
                          handleImageDelete(e, index);
                          console.log("imgindex", index)
                        }}
                      >
                        <img
                          src={RED_DELETE_ICON}
                          alt=""
                          style={{
                            paddingBottom: "3px",
                            paddingRight: "3px",
                          }}
                        />
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        <div className="procedure-image-wrapper">
          <div onClick={handleRef}>
            {loader ? (
              <Spinner className="spinner-class-video" animation="border" />
            ) : (
              <>
                <img
                  className="image-upload-procedure"
                  src={UPLOAD_PLUS}
                  alt=""
                />

                <p style={{ textAlign: "center" }}>Add New</p>
              </>
            )}
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleImageUpload}
            ref={imageInput}
            accept="image/*,video/*"
            multiple
          />
        </div>
        <Form.Text className="text-muted" style={{ marginTop: "-5px", width: "400px" }}>
            **Please Add JPG or PNG. Pictures should be aligned vertically. If posted horizontal, pictures will rotate when exported.
          </Form.Text>
      </Row>
      <div className="procedure-content-label">
        Add Children’s Highlight Reel
      </div>
      <Form.Group>
        <label htmlFor="upload-button">
          <Row style={{ marginLeft: "-10px" }}>
            {data.Children_highlight_reel ? (
              <div>
                <video
                  className="procedure-image-wrapper"
                  width="140"
                  height="140"
                  controls
                >
                  <source src={data.Children_highlight_reel} type="video/mp4" />
                </video>
                <div>
                  <button
                    className="procedure-video-icon"
                    onClick={handleReelDelete}
                  >
                    <img
                      src={RED_DELETE_ICON}
                      alt=""
                      style={{
                        paddingBottom: "3px",
                        paddingRight: "3px",
                      }}
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div className="procedure-image-wrapper">
                <div>
                  {reelLoader ? (
                    <Spinner
                      className="spinner-class-video"
                      animation="border"
                    />
                  ) : (
                    <>
                      <img
                        className="image-upload-procedure"
                        src={UPLOAD_PLUS}
                        alt=""
                      />

                      <p style={{ textAlign: "center" }}>Add New</p>
                    </>
                  )}
                </div>
              </div>
            )}
          </Row>
        </label>
        <input
          type="file"
          id="upload-button"
          style={{ display: "none" }}
          onChange={handleReelUpload}
          accept="video/mp4"
        />
      </Form.Group>
    </>
  );
};

export default UploadMedia;