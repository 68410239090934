import React, { Fragment, useEffect, useState } from 'react';
import * as actionCreator from "../../modules/actions";
import Back from '../../common/components/back';
import { Container } from 'react-bootstrap';
import { ROUTES_PATH } from '../../constant';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TableData from './TableData';
import NavBar from '../NavBar';
import Footer from '../Footer';
import Pagination from "react-js-pagination";
import "./subscribe.css";
import FilterModal from '../../common/components/filterModal';


const Subscriber = (props) => {
  let per_page = 10;
  const [pagenum, setPagenum] = useState(1)
  const [search, setSearch] = useState("")
  const [isSearch, setIsSearch] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [filterModal, setFilterModal] = useState(false)
  const [tier, setTier] = useState([])
  const [dateAdded, setDateAdded] = useState("False")
  const { hospitals, loader } = props


  useEffect(() => {
    props.toggleMainLoader(true)
    props.getAllHospitals(pagenum);
    props.getSubscriptionPlans()

  }, [])


  const changePage = (pagenum) => {
    if (isSearch) {
      setPagenum(pagenum)
      props.resetHospital()
      props.toggleMainLoader(true)
      props.getSearchedHospitals(search, pagenum)
    } else if (isFilter) {
      setPagenum(pagenum)
      props.resetHospital()
      props.toggleMainLoader(true)
      props.filterHospital(filterData.data, pagenum)
    } else {
      setPagenum(pagenum)
      props.resetHospital()
      props.toggleMainLoader(true)
      props.getAllHospitals(pagenum)
    }
  }

  const handleBack = () => {
    props.history.push(ROUTES_PATH.HOME)
  }

  const searchOnChange = (e) => {
    setSearch(e.target.value)
    const value = e.target.value
    if (value.length > 1) {
      setIsSearch(true)
      props.toggleMainLoader(true)
      props.getSearchedHospitals(value, 1)
    } else {
      setIsSearch(false)
      setPagenum(1)
      props.resetHospital()
      props.toggleMainLoader(true)
      props.getAllHospitals(pagenum)
    }
  }

  //Functions for filter

  const handleFilter = () => {
    setFilterModal(true)
  }
  const closeFilter = () => {
    setFilterModal(false)
  }

  const handleRemove = (value) => {
    var index = tier.indexOf(value)
    const arr = [...tier]
    if (index !== -1) {
      arr.splice(index, 1)
      setTier(arr)
    }
  };

  const handleTierFilter = (e) => {
    if (e.target.checked) {
      setTier([...tier, e.target.value]);
    } else {
      handleRemove(e.target.value)
    }
  }


  const handleDateFilter = (e) => {
    if (e.target.checked) {
      setDateAdded("True")
    } else {
      setDateAdded("False")
    }
  }

  const filterClick = (e) => {
    e.preventDefault()
    const data = {
      dateAdded,
      tier,
    }
    setFilterData({ ...filterData, data })
    setPagenum(1)
    setIsFilter(true)
    props.filterHospital(data, 1)
    setTier([])
    setFilterModal(false)
  }
  //End of filter functions

  const buttonClick = () => {
    props.history.push(ROUTES_PATH.ADD_HOSPITAL)
  }

  const handleViewClick = (index) => {
    props.getHospitalDetails(hospitals.data[index])
    props.history.push(ROUTES_PATH.HOSPITAL_VIEW)
  }

  const handleCopyClick = (index) => {
    props.getHospitalCopyDetails(hospitals.data[index])
    props.history.push(ROUTES_PATH.ADD_HOSPITAL);
  }

  return (
    <Fragment>
      <Container style={{ minHeight: "100vh", position: "relative", maxWidth: "1046px" }}>

        <Back
          className={"back-nav"}
          backNavItemClassName={"back-nav-item"}
          backIconClassName={"back-icon"}
          isSearch={false}
          isDelete={false}
          isSave={false}
          Back={handleBack}
        />

        <NavBar
          title={"Subscriber Management"}
          id={"SubscriberManagementTitle-css"}
          className={"main-navbar"}
          isSearch={true}
          isFilter={true}
          isButton={true}
          searchValue={search}
          searchOnChange={searchOnChange}
          buttonName={"Add Hospital"}
          buttonClick={buttonClick}
          handleFilterClick={handleFilter}

        />
        <div className="table-area">
          <p className="table-head">Partner/Organization</p>
          {hospitals && <TableData data={hospitals.data}
            viewClick={handleViewClick}
            copyClick = {handleCopyClick}
            loader={loader} />}
        </div>
        {hospitals.data.length ?
          <Pagination
            totalItemsCount={hospitals.count}
            itemsCountPerPage={per_page}
            activePage={pagenum}
            onChange={changePage}
            prevPageText={"< Prev"}
            nextPageText={"Next >"}
            firstPageText={""}
            lastPageText={""}
            innerClass={"pagination-container"}
            linkClassPrev={"pagination-previous"}
            linkClassNext={"pagination-next"}
            disabledClass={"pagination-link-disabled"}
            activeLinkClass={"pagination-active"}
            linkClass={"page-link-className-css"}
            pageRangeDisplayed={3} /> : null}
      </Container>

      <FilterModal
        modal={filterModal}
        handleClose={closeFilter}
        hospitalFilter={true}
        plans={props.plans}
        tier={tier}
        dateAdded={dateAdded}
        handleTierFilter={handleTierFilter}
        handleDateFilter={handleDateFilter}
        submit={filterClick}
      />

      <Footer className={"my-footer"} />

    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    hospitals: state.hospitals,
    loader: state.loader.mainLoader,
    plans: state.plans.data,
  };
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllHospitals: actionCreator.getAllHospitals,
      allHospitalListResponse: actionCreator.allHospitalListResponse,
      getHospitalDetails: actionCreator.getHospitalDetails,
      toggleMainLoader: actionCreator.toggleMainLoader,
      getSearchedHospitals: actionCreator.getSearchedHospitals,
      getSubscriptionPlans: actionCreator.getSubscriptionPlans,
      filterHospital: actionCreator.filterHospital,
      resetHospital: actionCreator.resetHospitals,
      getHospitalCopyDetails : actionCreator.getHospitalCopyDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriber);