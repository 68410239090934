import React from "react";
import "./card.css";
import Card from "react-bootstrap/Card";
import { LEFT_ARROW } from "../../constant";

const CardItem = (props) => {
  return (
    <Card className={props.className}>
      <Card.Body>
        <Card.Title className="card-title">{props.title}</Card.Title>
        <Card.Subtitle className="card-subtitle">{props.subtitle}</Card.Subtitle>
        <button className="btn" id="view-more" onClick={props.onClick}>
          View More
          <img src={LEFT_ARROW} alt="" style={{ marginLeft: "16px" }} />{" "}
        </button>
      </Card.Body>
    </Card>
  );
};

export default CardItem;
