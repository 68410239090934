import React from "react";
import Select from "react-select";
import "./UserManagementParentChildDetails/userManagementParentChildDetails.css";
import "./userManagementNavbar.css";

const MultiSelect = (props) => {
  const styles = {
    control: (base, state) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      color:  props.isClearable ? "#fff !important" :"#5b616b",
      "&:hover": {
        border: 0,
        color:  props.isClearable ? "#fff !important" :"#5b616b",
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "424px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
  };
  return (
    <>
      <Select
        isMulti={props.multi}
        styles={props.styles ? props.styles : styles}
        value={props.value}
        defaultValue={props.defaultValue}
        name=""
        isDisabled={props.disabled}
        options={props.options}
        className={props.className ? props.className : "parent-name-field"}
        classNamePrefix={props.className}
        onChange={props.onChange}
        placeholder={props.placeholder}
        components={props.components}
        hideSelectedOptions={props.hideSelectedOptions}
        closeMenuOnSelect={props.closeMenuOnSelect}
        isSearchable = {false}
        isClearable={props.isClearable}
      />
    </>
  );
};

export default MultiSelect;
