import axios from "../../axios";
import * as url from "../../utils/urls";

export const getAllHospitalListService = page_no => {
  return axios.get(`${url.getHospitalList}?page=${page_no}`, null);
};

export const getSearchedHospitals = (data, page_no) => {
  return axios.get(
    `${url.searchHospitalList}?search=${data}&page=${page_no}`,
    null
  );
};

export const addHospital = data => {
  return axios.post(url.addHospital, data);
};

export const addHealthHero = data => {
  return axios.post(url.addHealthHero, data);
};

export const editHealthHero = (id, data) => {
  return axios.patch(`${url.editHealthHero}/${id}/`, data);
};

export const deleteHealthHero = id => {
  return axios.delete(`${url.deleteHealthHero}/${id}/`);
};

export const addCls = data => {
  return axios.post(url.addCls, data);
};

export const addClinician = data => {
  return axios.post(url.addClinician, data);
};

export const getHospitalData = data => {
  return axios.get(`${url.getHospitaldata}/${data}/`, null);
};

export const renewPlan = (id, data) => {
  return axios.patch(`${url.renewPlan}/${id}/`, data);
};

export const updateHospital = data => {
  return axios.patch(`${url.updateHospital}/${data.id}/`, data);
};

export const deleteHospital = data => {
  return axios.delete(`${url.deleteHospital}/${data}/`, null);
};

export const filterHospital = (data, page_no) => {
  return axios.post(`${url.filterHospital}?page=${page_no}`, data);
};

export const getAllParentChildListService = (page_no,isCsv) => {
  if(isCsv){
    return axios.get(`${url.parentChildListUrl}?download=csv&page=${page_no}`, null);
  }
  return axios.get(`${url.parentChildListUrl}?page=${page_no}`, null);
};

export const deleteParentService = id => {
  return axios.delete(`${url.deleteParentDetailsUrl}${id}/`);
};

export const deleteChildService = id => {
  return axios.delete(`${url.deleteChildDetailsUrl}${id}/`);
};

export const getAllClsListService = (page_no,isCsv) => {
  if(isCsv){
    return axios.get(`${url.clsListUrl}?download=csv&page=${page_no}`, null);
  }
  return axios.get(`${url.clsListUrl}?page=${page_no}`, null);
};

export const deleteClsService = id => {
  return axios.delete(`${url.deleteClsDetailsUrl}${id}/`);
};
export const getAllClinicianListService = (page_no,isCsv) => {
  if(isCsv){
    return axios.get(`${url.clinicianListUrl}?download=csv&page=${page_no}`, null);
  }
  return axios.get(`${url.clinicianListUrl}?page=${page_no}`, null);
};

export const deleteClinicianService = id => {
  return axios.delete(`${url.deleteClinicianDetailsUrl}${id}/`);
};

export const getSubscriptionPlansService = () => {
  return axios.get(url.subscriptioPlans, null);
};

export const searchService = (page_no, data, role) => {
  return axios.post(
    `${url.userManagementGlobalSearchUrl}?page=${page_no}&search=${data}`,
    role
  );
};
export const addSubscriptionPlan = data => {
  return axios.post(url.addSubscriptionPlan, data);
};

export const editSubscriptionPlan = data => {
  return axios.patch(`${url.updateSubscriptionPlan}/${data.id}/`, data);
};

export const getQuotesService = (data, page_no, sort) => {
  return axios.post(`${url.getQuotesUrl}?page=${page_no}&sort=${sort}`, data);
};

export const publishQuoteService = data => {
  return axios.post(`${url.publishQuoteUrl}`, data);
};

export const getProceduresService = (status, page_no, data, sort, isCsv) => {
  if(isCsv){
    return axios.post(`${url.getProceduresUrl}?page=${page_no}&search=${status}&sort=${sort}&download=csv`, data);
  }
  return axios.post(
    `${url.getProceduresUrl}?page=${page_no}&search=${status}&sort=${sort}`,
    data
  );
};

export const getFullHospitalService = () => {
  return axios.get(url.getHospitalFullUrl);
};

export const filterService = (page_no, data) => {
  return axios.post(
    `${url.userManagementGlobalFilterUrl}?page=${page_no}`,
    data
  );
};

export const approveProcedure = (id, data) => {
  return axios.patch(`${url.approveProcedureUrl}/${id}/`, data);
};

export const addProcedureService = data => {
  return axios.post(`${url.addProcedureUrl}`, data);
};
export const getGlossaryList = (status, page_no, sort, isCsv) => {
  if(isCsv){
    return axios.get(`${url.getGlossaryUrl}?search=${status}&page=${page_no}&download=csv`, null);
  }
  return axios.get(
    `${url.getGlossaryUrl}?search=${status}&page=${page_no}&sort=${sort}`,
    null
  );
};

export const glossaryAction = (id, data) => {
  return axios.patch(`${url.glossaryActionUrl}/${id}/`, data);
};

export const addGlossaryService = data => {
  return axios.post(`${url.addGlossaryUrl}`, data);
};

export const getChildQuotes = (status, page_no, sort) => {
  return axios.get(
    `${url.getChildQuotesUrl}?page=${page_no}&search=${status}&sort=${sort}`,
    null
  );
};

export const childQuotesAction = (id, data) => {
  return axios.patch(`${url.childQuotesActionUrl}/${id}/`, data);
};

export const getPodcastService = (status, page_no, sort) => {
  return axios.get(`${url.getPodcastUrl}?page=${page_no}&search=${status}&sort=${sort}`);
};

export const addPodcastService = data => {
  return axios.post(`${url.addPodcastUrl}`, data);
};

export const getHealthHeroes = (status, page_no, sort, isCsv) => {
  if(isCsv){
    return axios.get(`${url.getHealthheroesUrl}?search=${status}&page=${page_no}&sort=${sort}&download=csv`, null);
  }
  return axios.get(
    `${url.getHealthheroesUrl}?search=${status}&page=${page_no}&sort=${sort}`,
    null
  );
};

export const healthHerosAction = (id, data) => {
  return axios.patch(`${url.healthHeroActionUrl}/${id}/`, data);
};

export const getPictures = (status, page_no, sort) => {
  return axios.get(
    `${url.getPicturesUrl}?search=${status}&page=${page_no}&sort=${sort}`,
    null
  );
};

export const picturesAction = (id, data) => {
  return axios.patch(`${url.picturesAction}/${id}/`, data);
};

export const getStoryService = (status, page_no, sort, isCsv) => {
  if(isCsv){
    return axios.get(`${url.getStoryUrl}?search=${status}&page=${page_no}&sort=${sort}&download=csv`, null);
  }
  return axios.get(`${url.getStoryUrl}?page=${page_no}&search=${status}&sort=${sort}`);
};

export const addStoryService = data => {
  return axios.post(`${url.addStoryUrl}`, data);
};

export const getResourcesService = (status, page_no, sort, hospitalFilterId, isCsv) => {
  if(hospitalFilterId) {
    if(isCsv){
      return axios.get(`${url.getResourcesUrl}?page=${page_no}&sort=${sort}&hospital_id=${hospitalFilterId}&download=csv`);
    }
    return axios.get(`${url.getResourcesUrl}?page=${page_no}&sort=${sort}&hospital_id=${hospitalFilterId}`);
  }
  else{
    if(isCsv){
      return axios.get(`${url.getResourcesUrl}?page=${page_no}&sort=${sort}&download=csv`);
    }
    return axios.get(`${url.getResourcesUrl}?page=${page_no}&sort=${sort}`);
  }

};

export const getOtherResourcesService = (status, page_no) => {
  return axios.get(`${url.getOtherResourcesUrl}?page=${page_no}&search=${status}`);
};

export const getProfessionalResourcesService = (status, page_no, sort, hospitalFilterId, isCsv) => {
  if(hospitalFilterId) {
    if(isCsv){
      return axios.get(`${url.getProfessionalResourcesUrl}?page=${page_no}&sort=${sort}&hospital_id=${hospitalFilterId}&download=csv`);
    }
    return axios.get(`${url.getProfessionalResourcesUrl}?page=${page_no}&sort=${sort}&hospital_id=${hospitalFilterId}`);
  }
  else{
    if(isCsv){
      return axios.get(`${url.getProfessionalResourcesUrl}?page=${page_no}&sort=${sort}&download=csv`);
    }
    return axios.get(`${url.getProfessionalResourcesUrl}?page=${page_no}&sort=${sort}`);
  }

};

export const getParentResourcesService = (status, page_no, sort, hospitalFilterId, isCsv) => {
  if(hospitalFilterId) {
    if(isCsv){
      return axios.get(`${url.getParentResourcesUrl}?page=${page_no}&sort=${sort}&hospital_id=${hospitalFilterId}&download=csv`);
    }
    return axios.get(`${url.getParentResourcesUrl}?page=${page_no}&sort=${sort}&hospital_id=${hospitalFilterId}`);
  }
  else{
    if(isCsv){
      return axios.get(`${url.getParentResourcesUrl}?page=${page_no}&sort=${sort}&download=csv`);
    }
    return axios.get(`${url.getParentResourcesUrl}?page=${page_no}&sort=${sort}`);
  }
};

export const addResourcesService = data => {
  return axios.post(`${url.addResourcesUrl}`, data);
};

export const addParentResourcesService = data => {
  return axios.post(`${url.addParentResourcesUrl}`, data);
};

export const addProfessionalResourcesService = data => {
  return axios.post(`${url.addProfessionalResourcesUrl}`, data);
};

export const resourcesCategoryService = () => {
  return axios.get(`${url.resourcesCategoryUrl}`);
};

export const addPicture = data => {
  return axios.post(url.addPicture, data);
};

export const getWhatsGoingOnMe = (status, page_no) => {
  return axios.get(`${url.getWhatsGoingOnMe}?search=${status}&page=${page_no}`);
};

export const addWhatsGoingOnMe = data => {
  return axios.post(url.addWhatsGoingOnMe, data);
};

export const approveResourcesService = (id, data) => {
  return axios.patch(`${url.approveResourcesUrl}/${id}/`, data);
};

export const activationService = (id, data) => {
  return axios.patch(`${url.activationUrl}${id}/`, data);
};

export const deleteProcedure = id => {
  return axios.delete(`${url.deleteProcedure}/${id}/`);
};

export const deleteDailyQuotes = id => {
  return axios.delete(`${url.deleteDailyQuotes}/${id}/`);
};

export const deleteGlossary = id => {
  return axios.delete(`${url.deleteGlossary}/${id}/`);
};

export const deletePodcast = id => {
  return axios.delete(`${url.deletePodcast}/${id}/`);
};

export const deletePicture = id => {
  return axios.delete(`${url.deletePicture}/${id}/`);
};
export const deleteResource = id => {
  return axios.delete(`${url.deleteResources}/${id}/`);
};
export const deleteParentResource = id => {
  return axios.delete(`${url.deleteParentResources}/${id}/`);
};
export const deleteProfessionalResource = id => {
  return axios.delete(`${url.deleteProfessionalResources}/${id}/`);
};
export const deleteStory = id => {
  return axios.delete(`${url.deleteStory}/${id}/`);
};
export const deleteWhatsGoingonMe = id => {
  return axios.delete(`${url.deleteWhatsGoingonMe}/${id}/`);
};

export const updateDailyQuotes = (id, data) => {
  return axios.patch(`${url.updateDailQuotes}/${id}/`, data);
};
export const updateGlossary = (id, data) => {
  return axios.patch(`${url.updateGlossary}/${id}/`, data);
};
export const updateProcedure = (id, data) => {
  return axios.patch(`${url.updateProcedure}/${id}/`, data);
};
export const updatePodcast = (id, data) => {
  return axios.patch(`${url.updatePodcast}/${id}/`, data);
};
export const updatePicture = (id, data) => {
  return axios.patch(`${url.updatePicture}/${id}/`, data);
};
export const updateResources = (id, data) => {
  return axios.patch(`${url.updateResource}/${id}/`, data);
};
export const updateParentResources = (id, data) => {
  return axios.patch(`${url.updateParentResource}/${id}/`, data);
};
export const updateProfessionalResources = (id, data) => {
  return axios.patch(`${url.updateProfessionalResource}/${id}/`, data);
};
export const updateStory = (id, data) => {
  return axios.patch(`${url.updateStory}/${id}/`, data);
};
export const updateWhatsgoingOnme = (id, data) => {
  return axios.patch(`${url.updateWhatsGoingOnMe}/${id}/`, data);
};

export const getPostsService = (status, page_no) => {
  return axios.get(`${url.getPostsURL}?page=${page_no}&search=${status}`);
};

export const postAction = (id, data) => {
  return axios.patch(`${url.postAction}/${id}/`, data);
};

export const downloadUser = id => {
  return axios.get(`${url.downloadUser}?hospital_id=${id}`);
};

export const getTopResourcesApi = (page_no, sort, isCsv) => {
  if(isCsv){
    return axios.get(`${url.getTopResourcesUrl}?page=${page_no}&sort=${sort}&download=csv`);
  }else{
    return axios.get(`${url.getTopResourcesUrl}?page=${page_no}&sort=${sort}`);
  }
};

export const addTopResourcesApi = payload => {
  return axios.post(`${url.addTopResourcesUrl}`, payload);
};

export const updateTopResourcesApi = (id, payload) => {
  return axios.patch(`${url.updateTopResourcesUrl}/${id}/`, payload);
};

export const deleteTopResourcesApi = id => {
  return axios.delete(`${url.deleteTopResourcesUrl}/${id}/`);
};

export const getHospitalCategoriesApi = (page_no,hospitalFilterId, isCsv) => {
  if(hospitalFilterId) {
    if(isCsv){
      return axios.get(`${url.getHospitalCategoriesUrl}?page=${page_no}&hospital_id=${hospitalFilterId}&download=csv`);
    }
    return axios.get(`${url.getHospitalCategoriesUrl}?page=${page_no}&hospital_id=${hospitalFilterId}`);
  } else{
    if(isCsv){
      return axios.get(`${url.getHospitalCategoriesUrl}?page=${page_no}&download=csv`);
    }
    return axios.get(`${url.getHospitalCategoriesUrl}?page=${page_no}`);
  }
};

export const addHospitalCategoriesApi = payload => {
  return axios.post(`${url.addHospitalCategoriesUrl}`, payload);
};

export const deleteHospitalCategoriesApi = id => {
  return axios.delete(`${url.deleteHospitalCategoriesUrl}/${id}/`);
};

export const getParentCategoriesApi = (page_no, hospitalFilterId, isCsv) => {
  if(hospitalFilterId) {
    if(isCsv){
      return axios.get(`${url.getParentCategoriesUrl}?page=${page_no}&hospital_id=${hospitalFilterId}&download=csv`);
    }
    return axios.get(`${url.getParentCategoriesUrl}?page=${page_no}&hospital_id=${hospitalFilterId}`);
  }else{
    if(isCsv){
      return axios.get(`${url.getParentCategoriesUrl}?page=${page_no}&download=csv`);
    }
    return axios.get(`${url.getParentCategoriesUrl}?page=${page_no}`);
  }
};

export const addParentCategoriesApi = payload => {
  return axios.post(`${url.addParentCategoriesUrl}`, payload);
};

export const deleteParentCategoriesApi = id => {
  return axios.delete(`${url.deleteParentCategoriesUrl}/${id}/`);
};

export const getProfessionalCategoriesApi = (page_no, hospitalFilterId, isCsv) => {
  if(hospitalFilterId) {
    if(isCsv){
      return axios.get(`${url.getProfessionalCategoriesUrl}?page=${page_no}&hospital_id=${hospitalFilterId}&download=csv`);
    }
    return axios.get(`${url.getProfessionalCategoriesUrl}?page=${page_no}&hospital_id=${hospitalFilterId}`);
  }else{
    if(isCsv){
      return axios.get(`${url.getProfessionalCategoriesUrl}?page=${page_no}&download=csv`);
    }
    return axios.get(`${url.getProfessionalCategoriesUrl}?page=${page_no}`);
  }
};

export const addProfessionalCategoriesApi = payload => {
  return axios.post(`${url.addProfessionalCategoriesUrl}`, payload);
};

export const deleteProfessionalCategoriesApi = id => {
  return axios.delete(`${url.deleteProfessionalCategoriesUrl}/${id}/`);
};

export const getAllProfessionalCategoriesApi = () => {
  return axios.get(`${url.getAllProfessionalCategoriesUrl}`);
};

export const getAllParentCategoriesApi = () => {
  return axios.get(`${url.getAllParentCategoriesUrl}`);
};

export const getAllHospitalCategoriesApi = () => {
  return axios.get(`${url.getAllHospitalCategoriesUrl}`);
};

export const updateHospitalCategoryApi = (id, payload) => {
  return axios.patch(`${url.updateHospitalCategory}/${id}/`, payload);
};

export const updateParentCategoryApi = (id, payload) => {
  return axios.patch(`${url.updateParentCategory}/${id}/`, payload);
};

export const updateProfessionalCategoryApi = (id, payload) => {
  return axios.patch(`${url.updateProfessionalCategory}/${id}/`, payload);
};

export const getSearchedGlobal = (data) => {
  return axios.get(
    `${url.searchGlobalList}?search=${data}`,
    null
  );
};

export const getHospitalSubscriptionService = (page_no, sort, isCsv) => {
  if(isCsv){
    return axios.get(`${url.getHospitalSubscriptionList}?page=${page_no}&sort=${sort}&download=csv`);
  }
  return axios.get(`${url.getHospitalSubscriptionList}?page=${page_no}&sort=${sort}`);
};
