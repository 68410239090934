import React from 'react';
import { Col, Form, Modal } from 'react-bootstrap';
import { CROSS_ICON, MESSAGE, BUTTON_SPINNER } from '../../constant';


const MyModal = (props) => {

    return (
        <Modal show={props.modal} centered>
            <Modal.Body>
                <div style={{ margin: "0px 20px 0px 20px" }}>
                    <div className="main-header">
                        <img className="close-icon" src={CROSS_ICON} alt="" onClick={props.handleClose} />
                        <p className="plan-header">{props.title}</p>
                    </div>
                    <hr style={{ marginRight: "16px" }}></hr>

                    <Form noValidate>

                        <Form.Group controlId="formBasicType">

                            <Form.Label className="common-form-label">
                                Type
                            </Form.Label>
                            <Form.Control
                                className="common-form-input"
                                type="text"
                                placeholder="Type"
                                name="type"
                                value={props.data.type}
                                onChange={(e) => {
                                    props.handleChange(e)
                                    props.setTypeInvalid(false)
                                }}
                                isInvalid={props.typeInvalid}
                            />
                            <Form.Text className="text-muted" style={{ marginTop: "4px" }}>
                                Note: Type should be like "Tier-1" or "tier-2", dash and number must be included 
                            </Form.Text>
                            <Form.Control.Feedback
                                type="invalid"
                                className="common-invalid-label"
                            >
                                {MESSAGE.TYPE_INVALID}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Label className="common-form-label">
                            Plan Duration
                        </Form.Label>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formBasicDuration">
                                <Form.Control
                                    className="common-form-input"
                                    type="number"
                                    placeholder="Select Number"
                                    min={0}
                                    max={100}
                                    name="duration_no"
                                    value={props.data.duration_no}
                                    onChange={(e) => {
                                        props.handleChange(e)
                                        props.setDurationNoInvalid(false)
                                    }}
                                    isInvalid={props.durationNoInvalid}
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="common-invalid-label">
                                    {MESSAGE.DURATION_INVALID}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formBasicDuration">
                                <Form.Control as="select"
                                    className="common-form-input img-class"
                                    name="duration_type"
                                    onChange={(e) => {
                                        props.handleChange(e)
                                        props.setDurationTypeInvalid(false)
                                    }}
                                    defaultValue={props.data.duration_type}
                                    isInvalid={props.durationTypeInvalid}
                                >
                                    <option value="">Select Duration</option>
                                    <option value="Months">Months</option>
                                    <option value="Years">Years</option>
                                </Form.Control>
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="common-invalid-label">
                                    {MESSAGE.DURATION_INVALID}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                        <button className="plan-save-button" disabled={props.disabled}
                            onClick={props.type ? props.handleSubmit : props.handleEdit}>
                            {props.loader ? <img src={BUTTON_SPINNER} alt="" height="30px" /> : <span>Save Changes</span>}
                        </button>
                    </Form>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default MyModal;