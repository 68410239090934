import * as ActionTypes from "./actionTypes";

export const getQuotes = (data, page_no, sort) => ({
  type: ActionTypes.GET_QUOTES_REQUEST,
  data,
  page_no,
  sort,
});

export const getQuotesResponse = data => ({
  type: ActionTypes.GET_QUOTES_RESPONSE,
  data,
});

export const getPublishedQuotes = (data, page_no,sort) => ({
  type: ActionTypes.GET_PUBLISHED_QUOTES,
  data,
  page_no,
  sort,
});
export const publishedQuotesResponse = data => ({
  type: ActionTypes.PUBLISHED_QUOTES_RESPONSE,
  data,
});

export const publishQuote = data => ({
  type: ActionTypes.PUBLISH_QUOTE,
  data,
});

export const responseSuccess = data => ({
  type: ActionTypes.RESPONSE_SUCCESS,
  data,
});

export const getProcedures = (status, page_no, data, sort) => ({
  type: ActionTypes.GET_PROCEDURES_REQUEST,
  status,
  page_no,
  data,
  sort,
});

export const getProceduresResponse = data => ({
  type: ActionTypes.GET_PROCEDURES_RESPONSE,
  data,
});

export const getScheduledProcedures = (status, page_no, data, sort) => ({
  type: ActionTypes.GET_SCHEDULED_PROCEDURE,
  status,
  page_no,
  data,
  sort,
});

export const scheduledProceduresResponse = data => ({
  type: ActionTypes.SCHEDULED_PROCEDURE_RESPONSE,
  data,
});
export const getPublishedProcedures = (status, page_no, data, sort) => ({
  type: ActionTypes.GET_PUBLISHED_PROCEDURE,
  status,
  page_no,
  data,
  sort,
});

export const publishedProceduresResponse = data => ({
  type: ActionTypes.PUBLISHED_PROCEDURE_RESPONSE,
  data,
});

export const approveProcedure = (id, data) => ({
  type: ActionTypes.APPROVE_PROCEDURE,
  id,
  data,
});

export const getChildProcedure = (status, page_no, data) => ({
  type: ActionTypes.GET_CHILD_PROCEDURE,
  status,
  page_no,
  data,
});

export const getChildProceduresResponse = data => ({
  type: ActionTypes.CHILD_PROCEDURE_RESPONSE,
  data,
});

export const addProcedure = data => ({
  type: ActionTypes.ADD_PROCEDURE,
  data,
});
export const getGlossaryList = (status, page_no, sort) => ({
  type: ActionTypes.GET_GLOSSARY_LIST,
  status,
  page_no,
  sort,
});

export const glossaryResponse = data => ({
  type: ActionTypes.GLOSSARY_LIST_RESPONSE,
  data,
});
export const getScheduledGlossary = (status, page_no, sort) => ({
  type: ActionTypes.GET_SCHEDULED_GLOSSARY,
  status,
  page_no,
  sort,
});

export const scheduledGlossaryResponse = data => ({
  type: ActionTypes.SCHEDULED_GLOSSARY_RESPONSE,
  data,
});
export const getPublishedGlossary = (status, page_no, sort) => ({
  type: ActionTypes.GET_PUBLISHED_GLOSSARY,
  status,
  page_no,
  sort,
});

export const publishedGlossaryResponse = data => ({
  type: ActionTypes.PUBLISHED_GLOSSARY_RESPONSE,
  data,
});

export const glossaryAction = (id, data) => ({
  type: ActionTypes.GLOSSARY_ACTION,
  id,
  data,
});

export const addGlossary = data => ({
  type: ActionTypes.ADD_GLOSSARY,
  data,
});
export const getChildQuotes = (status, page_no, sort) => ({
  type: ActionTypes.GET_CHILD_QUOTES,
  status,
  page_no,
  sort,
});

export const childQuotesResponse = data => ({
  type: ActionTypes.CHILD_QUOTES_RESPONSE,
  data,
});

export const getPublishedChildQuotes = (status, page_no, sort) => ({
  type: ActionTypes.GET_PUBLISHED_CHILD_QUOTES,
  status,
  page_no,
  sort,
});

export const publishedChildResponse = data => ({
  type: ActionTypes.PUBLISHED_CHILD_RESPONSE,
  data,
});

export const childQuotesAction = (id, data) => ({
  type: ActionTypes.CHILD_QUOTES_ACTION,
  id,
  data,
});

export const getScheduledPodcast = (data, page_no, sort) => ({
  type: ActionTypes.GET_SCHEDULED_PODCAST,
  data,
  page_no,
  sort,
});

export const scheduledPodcastResponse = data => ({
  type: ActionTypes.SCHEDULED_PODCAST_RESPONSE,
  data,
});

export const getPublishedPodcast = (data, page_no, sort) => ({
  type: ActionTypes.GET_PUBLISHED_PODCAST,
  data,
  page_no,
  sort,
});
export const publishedPodcastResponse = data => ({
  type: ActionTypes.PUBLISHED_PODCAST_RESPONSE,
  data,
});

export const addPodcast = data => ({
  type: ActionTypes.ADD_PODCAST,
  data,
});
export const getHealthheroes = (status, page_no, sort) => ({
  type: ActionTypes.GET_HEALTH_HEROES,
  status,
  page_no,
  sort,
});

export const healthHeroesResponse = data => ({
  type: ActionTypes.HEALTH_HEROES_RESPONSE,
  data,
});

export const healthHeroAction = (id, data) => ({
  type: ActionTypes.HEALTH_HERO_ACTIONS,
  id,
  data,
});

export const getPublishedHealthHeroes = (status, page_no, sort) => ({
  type: ActionTypes.GET_PUBLISHED_HEALTH_HEROES,
  status,
  page_no,
  sort,
});

export const publishedHealthHeroesResponse = data => ({
  type: ActionTypes.PUBLISHED_HEALTH_HERO_RES,
  data,
});

export const getPictures = (status, page_no, sort) => ({
  type: ActionTypes.GET_PICTURES,
  status,
  page_no,
  sort,
});

export const pictureResponse = data => ({
  type: ActionTypes.PICTURE_RESPONSE,
  data,
});

export const getScheduledPictures = (status, page_no, sort) => ({
  type: ActionTypes.GET_SCHEDULED_PICTURES,
  status,
  page_no,
  sort,
});

export const schedulePictureResponse = data => ({
  type: ActionTypes.SCHEDULE_PICTURE_RES,
  data,
});

export const getPublishedPictures = (status, page_no, sort) => ({
  type: ActionTypes.GET_PUBLISHED_PICTURES,
  status,
  page_no,
  sort,
});

export const publishPicturesResponse = data => ({
  type: ActionTypes.PUBLISH_PICTURE_RES,
  data,
});

export const pictureAction = (id, data) => ({
  type: ActionTypes.PICTURE_ACTION,
  id,
  data,
});

export const addPicture = data => ({
  type: ActionTypes.ADD_PICTURE,
  data,
});

export const getScheduledWhatsGoingOnMe = (status, page_no) => ({
  type: ActionTypes.GET_SCHEDULED_WHATS_GOING_ON_ME,
  status,
  page_no,
});

export const scheduledWhatsGoingOnMeRes = data => ({
  type: ActionTypes.SCHEDULED_WHATS_GOING_ON_ME_RES,
  data,
});

export const getPublishedWhatsGoingOnMe = (status, page_no) => ({
  type: ActionTypes.GET_PUBLISHED_WHATS_GOING_ON_ME,
  status,
  page_no,
});

export const publishedWhatsGoingOnMeRes = data => ({
  type: ActionTypes.PUBLISHED_WHATS_GOING_ON_ME_RES,
  data,
});

export const addWhatsGoingOnMe = data => ({
  type: ActionTypes.ADD_WHATS_GOING_ON_ME,
  data,
});

export const deleteProcedure = id => ({
  type: ActionTypes.DELETE_PROCEDURE,
  id,
});
export const deleteDailyQuotes = id => ({
  type: ActionTypes.DELETE_DAILYQUOTES,
  id,
});
export const deleteGlossary = id => ({
  type: ActionTypes.DELETE_GLOSSARY,
  id,
});
export const deletePodcast = id => ({
  type: ActionTypes.DELETE_PODCAST,
  id,
});
export const deletePicture = id => ({
  type: ActionTypes.DELETE_PICTURES,
  id,
});
export const deleteResources = id => ({
  type: ActionTypes.DELETE_RESOURCES,
  id,
});
export const deleteParentResources = id => ({
  type: ActionTypes.DELETE_PARENT_RESOURCES,
  id,
});
export const deleteProfessionalResources = id => ({
  type: ActionTypes.DELETE_PROFESSIONAL_RESOURCES,
  id,
});
export const deleteStory = id => ({
  type: ActionTypes.DELETE_STORY,
  id,
});
export const deleteWhatsgoingOnme = id => ({
  type: ActionTypes.DELETE_WHATSGOINGONME,
  id,
});
export const getContentDetails = data => ({
  type: ActionTypes.CONTENT_DETAIL,
  data,
});

export const updateDailyQuotes = (id, data) => ({
  type: ActionTypes.UPDATE_DAILYQUOTES,
  id,
  data,
});
export const updateGlossary = (id, data) => ({
  type: ActionTypes.UPDATE_GLOSSARY,
  id,
  data,
});
export const updateProcedure = (id, data) => ({
  type: ActionTypes.UPDATE_PROCEDURE,
  id,
  data,
});
export const updatePodcat = (id, data) => ({
  type: ActionTypes.UPDATE_PODCAST,
  id,
  data,
});
export const updatePictures = (id, data) => ({
  type: ActionTypes.UPDATE_PICTURE,
  id,
  data,
});
export const updateResource = (id, data) => ({
  type: ActionTypes.UPDATE_RESOURCES,
  id,
  data,
});
export const updateParentResource = (id, data) => ({
  type: ActionTypes.UPDATE_PARENT_RESOURCES,
  id,
  data,
});
export const updateProfessionalResource = (id, data) => ({
  type: ActionTypes.UPDATE_PROFESSIONAL_RESOURCES,
  id,
  data,
});
export const updateStory = (id, data) => ({
  type: ActionTypes.UPDATE_STORY,
  id,
  data,
});
export const updateWhatsgoingOnMe = (id, data) => ({
  type: ActionTypes.UPDATE_WHATSGOINGONME,
  id,
  data,
});

export const getCommunityPosts = (status, page_no) => ({
  type: ActionTypes.GET_COMMUNITY_POSTS,
  status,
  page_no,
});

export const communityPostResponse = data => ({
  type: ActionTypes.COMMUNITY_POST_RESPONSE,
  data,
});
export const getScheduledPosts = (status, page_no) => ({
  type: ActionTypes.GET_SCHEDULED_POSTS,
  status,
  page_no,
});

export const scheduledPostResponse = data => ({
  type: ActionTypes.SCHEDULED_POST_RESPONSE,
  data,
});
export const getPublishedPosts = (status, page_no) => ({
  type: ActionTypes.GET_PUBLISHED_POSTS,
  status,
  page_no,
});

export const publishedPostResponse = data => ({
  type: ActionTypes.PUBLISHED_POST_RESPONSE,
  data,
});

export const postAction = (id, data) => ({
  type: ActionTypes.POST_ACTION,
  id,
  data,
});

export const getPublishProcedurePage = data => ({
  type: ActionTypes.GET_PUBLISH_PAGE,
  data,
});

export const getAwaitingPage = data => ({
  type: ActionTypes.GET_AWAITING_PAGE,
  data,
});

export const getScheduledPage = data => ({
  type: ActionTypes.GET_SCHEDULED_PAGE,
  data,
});

export const updateProcedureHospital = (id, data) => ({
  type: ActionTypes.UPDATE_PROCEDURE_HOSPITAL,
  id,
  data,
});

export const updatePicturesHospital = (id, data) => ({
  type: ActionTypes.UPDATE_PICTURE_HOSPITAL,
  id,
  data,
});