import React, { useEffect, useState } from "react";
import CommonButton from "../commonComponent/commonButton";
import CommonLogoComponent from "../commonComponent/commonLogoComponent";
import "./login.css";
import { ROUTES_PATH, MESSAGE } from "../../constant";
import { Row, Col, Form, Container } from "react-bootstrap";
import { EYE_OFF, EYE_OPEN } from "../../constant/images";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../modules/actions";
import {
  validateEmail,
  validatePasswordNonEmpty,
} from "../../utils/validations";
import { saveToken } from "../../utils/localStorage";
import { toast } from "react-toastify";

import { connect } from "react-redux";
import axios from "axios";

import { Link } from "react-router-dom";
import { getUserLoggedInUrl } from "../../utils/urls";

const Login = props => {
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showPassword, togglePassword] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (props.history.location.pathname === ROUTES_PATH.LOGIN) {
      props.logoutSuccess();
    }
  }, []);
  const toggle = () => togglePassword(prevState => !prevState);

  const handleSignIn = () => {
    setSubmittedOnce(true);
    setDisabled(true);
    setShowLoader(true);
    let validatedEmail = validateEmail(data.email);
    let validatedPassword = validatePasswordNonEmpty(data.password);
    if (!validatedEmail) {
      if (validatedPassword) {
        setPasswordInvalid(false);
      }
      setEmailInvalid(true);
      setDisabled(false);
      setShowLoader(false);
    }
    if (!validatedPassword) {
      if (validatedEmail) {
        setEmailInvalid(false);
      }
      setPasswordInvalid(true);
      setDisabled(false);
      setShowLoader(false);
    }
    if (validatedEmail && validatedPassword) {
      setEmailInvalid(false);
      setPasswordInvalid(false);
      axios
        .post(getUserLoggedInUrl, data)
        .then(response => {
          if (response.data.status_code === 200) {
            if (
              response.data.data.role[0] === "ADMIN" ||
              response.data.data.role[1] === "SUPERUSER"
            ) {
              saveToken(
                response.data.data.token.access,
                response.data.data.token.refresh,
                response.data.data.id
              );

              props.loginSuccess({
                userName: response.data.data.name,
                role: response.data.data.role,
                hospital_id: response.data.data.hospital,
                accessToken: response.data.data.token.access,
                idToken: response.data.data.id,
                refreshToken: response.data.data.token.refresh,
              });

              props.history.push(ROUTES_PATH.HOME);
            } else {
              setDisabled(false);
              setShowLoader(false);
              toast.error(MESSAGE.GROUP_INVALID, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else if (response.data.status_code === 404) {
            setDisabled(false);
            setShowLoader(false);
            try {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } catch (e) {
              console.log(e);
            }
          } else if (response.data.status_code === 400) {
            setDisabled(false);
            setShowLoader(false);
            try {
              toast.error(response.data.message[0], {
                position: toast.POSITION.TOP_RIGHT,
              });
            } catch (e) {
              console.log(e);
            }
          }
        })
        .catch(e => {
          try {
            toast.error(e.response.data.message[0], {
              position: toast.POSITION.TOP_RIGHT,
            });
          } catch (e) {
            console.log(e);
          }
          setDisabled(false);
          setShowLoader(false);
        });
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (!disabled) {
        handleSignIn();
      }
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="px-0 mb-0">
            <CommonLogoComponent />
          </Col>
          <Col className="px-0">
            <Form className="login-main" noValidate>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="login-welcome">
                  {MESSAGE.WELCOME_LABEL}
                </Form.Label>
                <Form.Label className="login-message">
                  {MESSAGE.SIGN_IN_LABEL}
                </Form.Label>
                <Form.Control
                  className="login-email"
                  type="email"
                  onKeyPress={handleKeyPress}
                  placeholder="Enter Email"
                  isInvalid={emailInvalid}
                  onChange={e => {
                    setData({ ...data, email: e.target.value.toLowerCase() });
                    submittedOnce
                      ? setEmailInvalid(!validateEmail(e.target.value))
                      : setEmailInvalid(false);
                  }}
                />
                <Form.Control.Feedback
                  className="login-input-invalid"
                  type="invalid"
                >
                  {MESSAGE.EMAIL_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  className="login-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  isInvalid={passwordInvalid}
                  onKeyPress={handleKeyPress}
                  onChange={e => {
                    setData({ ...data, password: e.target.value });
                    submittedOnce
                      ? setPasswordInvalid(
                          !validatePasswordNonEmpty(e.target.value)
                        )
                      : setPasswordInvalid(false);
                  }}
                />
                <img
                  className={showPassword ? "eye-icon-css" : "eye-icon-off-css"}
                  onClick={toggle}
                  src={showPassword ? EYE_OPEN : EYE_OFF}
                  alt=" "
                />
                <Form.Control.Feedback
                  className="login-password-invalid"
                  type="invalid"
                >
                  {MESSAGE.PASSWORD_NEW_INVALID}
                </Form.Control.Feedback>
                <Link className="linking" to={ROUTES_PATH.FORGOT_PASSWORD}>
                  <Form.Label className="login-forgot">
                    Forgot Password?
                  </Form.Label>
                </Link>
              </Form.Group>
              <CommonButton
                handleButtonAction={handleSignIn}
                buttonValue={"Login"}
                className={"common-button-css"}
                showLoader={showLoader}
                disabled={disabled}
              ></CommonButton>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = state => {
  try {
    if (state.user) {
      return {
        user: state.user.profile,
      };
    }
  } catch (e) {
    console.log(e);
  }
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loginSuccess: actionCreator.loginSuccess,
      logoutSuccess: actionCreator.logoutSuccess,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
