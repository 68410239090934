import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container } from "react-bootstrap";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import Footer from "../../Footer";
import ContentTable from "../ContentTable/contentTable";
import "../Procedures/procedures.css";
import { newRename } from "../../../utils/rename";
import FilterDropdown from '../../../components/commonComponent/FilterDropdown'

const ChildQuote = (props) => {
  const [awaitingPageNumber, setAwaitingPage] = useState(1);
  const [publishedPageNumber, setPublishedPage] = useState(1);
  const [awaitingDataList, setAwaitingList] = useState();
  const [publishedDataList, setPublishedList] = useState();
  const [sort, setSort] = useState();
  const [awaitingSort, setAwaitingSort] = useState();

  useEffect(() => {
    props.reset();
    props.toggleMainLoader(true);
    props.getChildQuotes("AWAITING", awaitingPageNumber, awaitingSort);
    props.getPublishedChildQuotes("PUBLISHED", publishedPageNumber, sort);
    setAwaitingPage(awaitingPageNumber);
    setPublishedPage(publishedPageNumber);
  }, [props.match.params.path, props.responseSuccess, sort, awaitingSort]);

  useEffect(() => {
    setAwaitingList(
      newRename(
        props.childQuotes.results,
        "quote",
        "child_name",
        "updated_at"
      )
    );
  }, [props.childQuotes.results]);

  useEffect(() => {
    setPublishedList(
      newRename(
        props.publishedChildQuotes.results,
        "quote",
        "child_name",
        "updated_at"
      )
    );
  }, [props.publishedChildQuotes.results]);

  const headerAwaiting = {
    headerOne: "Quote",
    headerTwo: "User",
    headerThree: "Actions",
  };
  const headerPublished = {
    headerOne: "Quote",
    headerTwo: "Published By",
    headerThree: "Updated At",
  };

  const changePageAwaiting = (selected) => {
    setAwaitingPage(selected);
    props.toggleMainLoader(true);
    props.getChildQuotes("AWAITING", selected, awaitingSort);
  };

  const changePagePublished = (selected) => {
    setPublishedPage(selected);
    props.toggleMainLoader(true);
    props.getPublishedChildQuotes("PUBLISHED", selected, sort);
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };

  const handleApprove = (index) => {
    props.childQuotesAction(index, { status: "PUBLISHED" });
  };

  const handleReject = (index) => {
    props.childQuotesAction(index, { status: "REJECT" });
  };
  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />
        <UserManagementNavbar
          title={"What Other Kids Say"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          buttonTitle={"Add Glossary"}
        />
        <ContentTable
          mainHeader={"Published"}
          secondHeader = {<FilterDropdown  sort = {setSort} value = {sort}/>}
          header={headerPublished}
          dataList={publishedDataList}
          rowPerPage={10}
          itemCountFromServer={props.publishedChildQuotes.count}
          changePage={changePagePublished}
          pageNumber={publishedPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={false}
        />
        
        <div style={{ marginTop: "39px" }}></div>
        <ContentTable
          mainHeader={"Awaiting"}
          secondHeader = {<FilterDropdown  sort = {setAwaitingSort} value = {awaitingSort}/>}
          header={headerAwaiting}
          dataList={awaitingDataList}
          rowPerPage={10}
          itemCountFromServer={props.childQuotes.count}
          changePage={changePageAwaiting}
          pageNumber={awaitingPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          onApprove={handleApprove}
          onReject={handleReject}
          viewCell={false}
        />
        <Footer className={"content-management-footer"} />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    responseSuccess: state.responseSuccess.responseSuccess,
    childQuotes: state.childQuotes,
    publishedChildQuotes: state.publishedChildQuotes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getChildQuotes: actionCreator.getChildQuotes,
      getPublishedChildQuotes: actionCreator.getPublishedChildQuotes,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      childQuotesAction: actionCreator.childQuotesAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildQuote);
