import Dropdown from 'react-bootstrap/Dropdown';

const FilterDropdown =({
  sort,
  expiry,
  is_priority,
  value
}) => {

  return (
    <Dropdown>
      <Dropdown.Toggle  style={{height: "30px", fontSize : "15px", padding: "3px", width : "150px", background: "#455674", borderRadius : "5px"}}>
        Sort By <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
</svg>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item active={value === "A" ? true : false} onClick={()=> {
          sort("A")}}>A-Z</Dropdown.Item>

        <Dropdown.Item active={value === "B" ? true : false} onClick={()=> {
          sort("B")}}>{expiry ? "Start" : "Created" } Date </Dropdown.Item>

        <Dropdown.Item active={value === "C" ? true : false} onClick={()=> {
          sort("C")}}>{expiry ? "End" : "Updated" } Date</Dropdown.Item>
        
        {
          is_priority && <Dropdown.Item active={value === "D" ? true : false} onClick={()=> {
            sort("D")
          }}>Priority</Dropdown.Item>
        }

      </Dropdown.Menu>
    </Dropdown>
  );
}
export default FilterDropdown;