import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Back from "../../../common/components/back";
import TitleBar from "../../../common/components/titleBar";
import Footer from "../../Footer/index";
import {
    ROUTES_PATH,
    MESSAGE,
} from "../../../constant";
import { validateName, validateSpecialization } from "../../../utils/validations";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreator from "../../../modules/actions";
import SaveButton from "../../../common/components/saveButton";
import UploadImage from "../../../common/components/uploadImage";
import UploadHealthHeroVideo from '../../../common/components/uploadHealthHeroVideo';

const AddHealthHeroes = (props) => {
    const [nameInvalid, setNameInvalid] = useState(false)
    const [specializationInvalid, setSpecializationInvalid] = useState(false)
    const [aboutInvalid, setAboutInvalid] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [data, setData] = useState({
        name: "",
        image: "",
        specialization: "",
        gender: "",
        about_me: "",
        favorite_food: "",
        favorite_movie: "",
        favorite_quote: "",
        favorite_color: "",
        videos: []
    })

    //This function is to clear all the input after submitting value
    const clearData = () => {
        setData({
            name: "",
            image: "",
            specialization: "",
            gender: "",
            about_me: "",
            favorite_food: "",
            favorite_movie: "",
            favorite_quote: "",
            favorite_color: "",
            videos: []
        })
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const handleBack = () => { props.history.push(ROUTES_PATH.EDIT_HOSPITAL) }


    const handleSubmit = (e) => {
        e.preventDefault()
        props.toggleMainLoader(true)
        let validName = validateName(data.name)
        let validSpecialization = validateSpecialization(data.specialization)
        if (!data.specialization || !validSpecialization) {
            setSpecializationInvalid(true);
        }
        if (!data.name) {
            setNameInvalid(true);
        }
        if (!validName) {
            setNameInvalid(true);
        }
        if (!data.about_me) {
            setAboutInvalid(true)
        }
        if (
            !data.specialization ||
            !data.name ||
            !data.about_me ||
            !validName) {
            props.toggleMainLoader(false)
            return;
        }
        if (
            !specializationInvalid &&
            !nameInvalid &&
            !aboutInvalid &&
            validName) {

            const health_hero_data = {
                image: data.image ? data.image : null,
                gender: data.gender ? data.gender : null,
                specialization: data.specialization,
                name: data.name,
                about_me: data.about_me,
                favorite_color: data.favorite_color ? data.favorite_color : null,
                favorite_food: data.favorite_food ? data.favorite_food : null,
                favorite_movie: data.favorite_movie ? data.favorite_movie : null,
                favorite_quote: data.favorite_quote ? data.favorite_quote : null,
                videos: data.videos ? data.videos : [],
                hospital: props.hospitalDetails.id
            }

            props.addHealthHero(health_hero_data)

            props.history.push(ROUTES_PATH.EDIT_HOSPITAL)
            clearData()

        }
    }

    const handleSubmitandAdd = (e) => {
        setSpinner(true)
        e.preventDefault()
        let validName = validateName(data.name)

        if (!data.specialization) {
            setSpecializationInvalid(true);
        }
        if (!data.name) {
            setNameInvalid(true);
        }
        if (!validName) {
            setNameInvalid(true);
        }
        if (!data.about_me) {
            setAboutInvalid(true)
        }
        if (
            !data.specialization ||
            !data.name ||
            !data.about_me ||
            !validName) {
            setSpinner(false)
            return;
        }
        if (
            !specializationInvalid &&
            !nameInvalid &&
            !aboutInvalid &&
            validName) {

            const health_hero_data = {
                image: data.image ? data.image : null,
                gender: data.gender ? data.gender : null,
                specialization: data.specialization,
                name: data.name,
                about_me: data.about_me,
                favorite_color: data.favorite_color ? data.favorite_color : null,
                favorite_food: data.favorite_food ? data.favorite_food : null,
                favorite_movie: data.favorite_movie ? data.favorite_movie : null,
                favorite_quote: data.favorite_quote ? data.favorite_quote : null,
                videos: data.videos ? data.videos : [],
                hospital: props.hospitalDetails.id
            }

            props.addHealthHero(health_hero_data)
            setSpinner(false)
            props.history.push(ROUTES_PATH.ADD_HEALTH_HEROES)
            clearData()

        }
    }


    return (
        <Container className="common-container">
            <Back className={"back-nav"}
                backNavItemClassName={"back-nav-item"}
                backIconClassName={"SubscriberManagement-back-icon-css"}
                isEdit={false}
                isDelete={false}
                isSave={false}
                Back={handleBack}
            />
            <TitleBar
                titlename={"Add Health Hero"}
                headingClass={"title-bar-heading2"} />

            <Form noValidate style={{ paddingLeft: "16px" }}>
                <Row>
                    <Col>
                        <UploadImage
                            data={data}
                            setData={setData} />


                        <Form.Group controlId="formBasicName" className="mt-3">
                            <Form.Label className="common-form-label">
                                HealthHero Name<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                                className="common-form-input"
                                type="text"
                                name="name"
                                isInvalid={nameInvalid}
                                placeholder="Enter Health Hero Name"
                                value={data.name}
                                onChange={(e) => {
                                    setNameInvalid(false)
                                    handleChange(e)
                                }}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="common-invalid-label"
                            >
                                {MESSAGE.NAME_INVALID}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>

                    <Col>
                    <UploadHealthHeroVideo 
                    data={data}
                    setData={setData}
                    />
                        <Form.Group controlId="formBasicSpecialization" className="mt-3">
                            <Form.Label className="common-form-label">
                                Specialization<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                                className="common-form-input"
                                type="text"
                                name="specialization"
                                isInvalid={specializationInvalid}
                                placeholder="Enter Specialization"
                                value={data.specialization}
                                onChange={(e) => {
                                    setSpecializationInvalid(false)
                                    handleChange(e)
                                }}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="common-invalid-label"
                            >
                                {MESSAGE.EMPTY_FIELD}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <TitleBar
                titlename={"About Health Hero"}
                headingClass={"title-bar-heading2"} />


            <Form noValidate style={{ paddingLeft: "16px" }}>
                <Row>
                    <Col>

                        <Form.Group controlId="formBasicAbout">
                            <Form.Label className="common-form-label">
                                About Me
                            </Form.Label>
                            <Form.Control as="textarea" rows={5}
                                className="common-form-input"
                                type="text"
                                placeholder="Write Here"
                                isInvalid={aboutInvalid}
                                name="about_me"
                                value={data.about_me}
                                onChange={(e) => {
                                    setAboutInvalid(false)
                                    handleChange(e)
                                }}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="common-invalid-label"
                            >
                                {MESSAGE.EMPTY_FIELD}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group controlId="formBasicFood">
                            <Form.Label className="common-form-label">
                                Favourite Food
                            </Form.Label>
                            <Form.Control as="textarea" rows={5}
                                className="common-form-input"
                                type="text"
                                placeholder="Write Here"
                                name="favorite_food"
                                value={data.favorite_food}
                                onChange={handleChange}
                            />
                        </Form.Group>


                        <Form.Group controlId="formBasicMovie">
                            <Form.Label className="common-form-label">
                                Favourite Movie
                            </Form.Label>
                            <Form.Control as="textarea" rows={5}
                                className="common-form-input"
                                type="text"
                                placeholder="Write Here"
                                name="favorite_movie"
                                value={data.favorite_movie}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>
                    <Col>

                        <Form.Group controlId="formBasicQuote">
                            <Form.Label className="common-form-label">
                                Favourite Quote
                            </Form.Label>
                            <Form.Control as="textarea" rows={5}
                                className="common-form-input"
                                type="text"
                                placeholder="Write Here"
                                name="favorite_quote"
                                value={data.favorite_quote}
                                onChange={handleChange}
                            />
                        </Form.Group>


                        <Form.Group controlId="formBasicColor">
                            <Form.Label className="common-form-label">
                                Favourite Color
                            </Form.Label>
                            <Form.Control as="textarea" rows={5}
                                className="common-form-input"
                                type="text"
                                placeholder="Write Here"
                                name="favorite_color"
                                value={data.favorite_color}
                                onChange={handleChange}
                            />
                        </Form.Group>


                        <div style={{ marginTop: "60px" }}>
                            <SaveButton className={"form-save-and-add-button"}
                                loader={spinner}
                                submit={handleSubmitandAdd}
                                buttonName={"Save & Add Another"} />
                            <SaveButton
                                className={"form-save-button"}
                                submit={handleSubmit}
                                loader={props.loader}
                                buttonName={"Add Health Hero"} />
                        </div>
                    </Col>
                </Row>
            </Form>

            <Footer className={"my-footer"} />

        </Container>
    )
}

const mapStateToProps = state => {
    return {
        loader: state.loader.mainLoader,
        hospitalDetails: state.hospitalDetails,
        addHospitalSuccess: state.addHospitalSuccess,

    };
};



const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            addHealthHero: actionCreator.addHealthHero,
            toggleMainLoader: actionCreator.toggleMainLoader,
            hospitalSuccess: actionCreator.addHospitalSuccess,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHealthHeroes);
