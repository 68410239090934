import React from 'react'
import { Form, Modal } from 'react-bootstrap';
import { CROSS_ICON } from "../../constant";
import "./common.css";

const FilterModal = (props) => {
    return (
        <Modal show={props.modal} centered>
            <Modal.Body>
                <div style={{ marginLeft: "20px" }}>

                    <div className="main-header">
                        <img className="close-icon" src={CROSS_ICON} alt="" onClick={props.handleClose} />
                        <p className="plan-header">Select Filters</p>
                    </div>
                    <hr style={{ marginRight: "16px" }}></hr>

                    {!props.hospitalFilter ? null :
                        <Form noValidate>
                            <Form.Group controlId="formBasicTier">
                                <Form.Label className="common-form-label">
                                    Membership Tier
                                </Form.Label><br></br>
                                {props.plans && props.plans.map((plan, index) => {
                                    return (
                                        <Form.Check inline
                                            className="filter-check"
                                            key={index}
                                            label={plan.type}
                                            name="tier"
                                            type="checkbox"
                                            id="tiers"
                                            value={plan.id}
                                            onChange={props.handleTierFilter}
                                        />
                                    )
                                })}
                            </Form.Group>

                            <Form.Group controlId="formBasicTier">
                                <Form.Label className="common-form-label">
                                    Date Added
                            </Form.Label><br></br>
                                <Form.Check inline
                                    label="Yes"
                                    name="dateAdded"
                                    type="checkbox"
                                    id="dateAdded"
                                    value={props.dateAdded}
                                    onChange={props.handleDateAdded}
                                />
                            </Form.Group>

                            <div style={{ float: "right", display: "inline-flex" }}>
                                <p className="clear-filter-button" onClick={props.handleClose}>Clear Filters</p>
                                <button className="apply-filter-button" onClick={props.submit}>Apply Filters</button>
                            </div>
                        </Form>

                    }

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FilterModal;
