import React, { useState } from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import "./verifyOTP.css";
import CommonButton from "../commonComponent/commonButton";
import CommonLogoComponent from "../commonComponent/commonLogoComponent";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../modules/actions";
import { getUserOtpVerifiedUrl } from "../../utils/urls";
import { toast } from "react-toastify";
import { ROUTES_PATH, MESSAGE } from "../../constant";
import axios from "axios";

const VerifyOTP = (props) => {
  const [otp, setOtp] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);

  const handleChange = (otp) => {
    if (submittedOnce) {
      if (otp.toString().length < 5) {
        setOtpInvalid(true);
      } else {
        setOtpInvalid(false);
      }
    }
    setOtp(otp);
  };

  const handleVerifyOtp = () => {
    setSubmittedOnce(true);
    if (otp.length < 5) {
      setOtpInvalid(true);
      return;
    }
    setDisabled(true);
    setShowLoader(true);
    axios
      .post(getUserOtpVerifiedUrl, {
        email: props.forgotPasswordInformation.email,
        otp: otp,
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          props.OtpVerified({
            email: props.forgotPasswordInformation.email,
            OtpVerified: true,
          });

          props.history.push(ROUTES_PATH.RESET_PASSWORD);
        } else {
          setDisabled(false);
          setShowLoader(false);
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setDisabled(false);
        setShowLoader(false);
        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="px-0 mb-0">
            <CommonLogoComponent />
          </Col>
          <Col className="px-0">
            <Form className="verifyOTP-form-main-css">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="verifyOTP-welcome-label-css">
                  {MESSAGE.VERIFY_OTP}
                </Form.Label>
                <Form.Label className="verifyOTP-message-label-css">
                  {MESSAGE.ENTER_OTP_LABEL}
                </Form.Label>
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={5}
                  separator={<span className="separator-css"></span>}
                  inputStyle="verifyOTP-input-field"
                  containerStyle="verify-otp-row-container"
                  shouldAutoFocus={true}
                  hasErrored={otpInvalid}
                  errorStyle="verifyOTP-invalid-label"
                  focusStyle="focus-css"
                />
                {otpInvalid ? (
                  <div className="invalid-label">{MESSAGE.OTP_INVALID}</div>
                ) : null}
              </Form.Group>
              <CommonButton
                buttonValue={MESSAGE.VERIFY_OTP}
                className={"common-button-css"}
                showLoader={showLoader}
                disabled={disabled}
                handleButtonAction={handleVerifyOtp}
              ></CommonButton>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  if (state.forgotPasswordInformation) {
    return {
      forgotPasswordInformation: state.forgotPasswordInformation.forgotPassword,
    };
  }
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      OtpVerified: actionCreator.OtpVerified,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTP);
