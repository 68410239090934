import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { USER_GENDER_DROPDOWN, BUTTON_SPINNER } from "../../../constant";
import "../../UserManagement/UserManagementParentChildDetails/userManagementParentChildDetails.css";
import "../../UserManagement/UserManagementClsDetails/userManagementClsDetails.css";
import "./userManagementFilter.css";
import AddModalHoc from "../../ContentManagement/AddModalHoc/addModalHoc";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import MultiSelect from "../multiSelect";
import { components } from "react-select";
import { rename } from "../../../utils/rename";

//custom components for react select options
const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={(e) => null}
        ></input>
      </div>
    </components.Option>
  );
};

const UserFilter = (props) => {
  const { setSuperUser, setHospital, handleFilter, setDateAdded, parentChild } = props;

  const [options, setOptions] = useState();

  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {
    setOptions(
      rename(props.fullHospitals.data, "value", "label", "id", "name")
    );
  }, [props.fullHospitals.data]);

  const styles = {
    control: (base, state) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  return (
    <>
      <Form.Group controlId="formBasicEmail">
        <Form.Label className="parent-name">Hospital</Form.Label>
        <MultiSelect
          multi={true}
          placeholder={"Select Hospital"}
          options={options}
          styles={styles}
          components={{ MenuList, Option }}
          className="select-hospital-class"
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          onChange={setHospital}
        />
      </Form.Group>
      {!parentChild ?
        <>
          <Form inline noValidate style={{ marginTop: "42px" }}>
            <Form.Label className="checkbox-name-super-user">SuperUser</Form.Label>
            <Form.Control
              className="super-user-checkbox"
              type="checkbox"
              onChange={setSuperUser}
            />

            <Form.Label className="checkbox-name-date-added">Date Added</Form.Label>
            <Form.Control
              className="date-added"
              type="checkbox"
              onChange={setDateAdded}
            />
          </Form>
        </> : null}
      <Row style={{ marginTop: "41px" }}>
        <Col>
          <button className="clear-filters" onClick={props.handleCancelClose}>
            Clear Filters
          </button>
        </Col>
        <Col>
          <button className="apply-filters" onClick={handleFilter}>
            {props.loader ? (
              <img src={BUTTON_SPINNER} alt="" height="30px" />
            ) : (
              <span>Apply Filters</span>
            )}
          </button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  try {
    return {
      user: state.user.profile,
      details: state.userManagementDetails.details,
      loader: state.loader.mainLoader,
      fullHospitals: state.fullHospitals,
      filterData: state.filterData,
      responseSuccess: state.responseSuccess.responseSuccess,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      getFullHospitals: actionCreator.getFullHospitals,
      toggleMainLoader: actionCreator.toggleMainLoader,
      filterRequest: actionCreator.filterRequest,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModalHoc(UserFilter));
