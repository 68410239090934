import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObjects";

export const hospitalDetails = (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.GET_HOSPITAL_DETAILS_RESPONSE:
      return data;
    default:
      return state;
  }
};

export const addHospitalSuccess = (
  state = { success: false },
  { type, data }
) => {
  switch (type) {
    case actionTypes.HOSPITAL_ADD_SUCCESS:
      return updateObject(state, {
        success: data,
      });
    default:
      return state;
  }
};

export const currentHealthHero = (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.SET_EDIT_HEALTH_HERO:
      return data;
    default:
      return state;
  }
};

export const hospitalCopyDetails = (state = {data : {}}, { type, data }) => {
  switch (type) {
    case actionTypes.GET_HOSPITAL_COPY_RESPONSE:
      return updateObject(state, {
        data
      });
    default:
      return state;
  }
};