import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Subscriber from "../components/Subscriber";
import { ROUTES_PATH } from "../constant";
import Login from "../components/Login/login";
import ForgotPassword from "../components/ForgotPassword/forgotPassword";
import ResetPassword from "../components/ResetPassword/resetPassword";
import UserManagementDashboard from "../components/UserManagement/UserManagementDashboard/userManagementDashboard";
import VerifyOTP from "../components/VerifyOTP/verifyOTP";
import { connect } from "react-redux";
import UserManagementParentChildDetails from "../components/UserManagement/UserManagementParentChildDetails/userManagementParentChildDetails";
import UserManagementClsDetails from "../components/UserManagement/UserManagementClsDetails/userManagementClsDetails";
import UserManagementClinicianDetails from "../components/UserManagement/UserManagementClinicianDetails/userManagementClinicianDetails";
import UserManagementChildDetails from "../components/UserManagement/UserManagementChildDetails/userManagementChildDetails";
import AddHospital from "../components/Subscriber/Hospital/addhospital";
import EditHospital from "../components/Subscriber/Hospital/editHospital";
import Hospital from "../components/Subscriber/Hospital/hospital";
import AddCls from "../components/Subscriber/Hospital/addCls";
import AddClinician from "../components/Subscriber/Hospital/addClinician";
import AddHealthHeroes from "../components/Subscriber/Hospital/addHealthHeroes";
import Subscription from "../components/Subscriptions";
import CmDashboard from "../components/ContentManagement/CmDashboard/cmDashboard";
import DailyQuotes from "../components/ContentManagement/DailyQuotes/dailyQuotes";
import Procedures from "../components/ContentManagement/Procedures/procedures";
import ContentApproval from "../components/ContentApproval";
import AddProcedures from "../components/ContentManagement/Procedures/addProcedures";
import EditProcedure from "../components/ContentManagement/Procedures/editProcedure";
import ScrollToTop from "../utils/scroll";
import Glossary from "../components/ContentManagement/Glossary/glossary";
import Podcast from "../components/ContentManagement/Podcast/podcast";
import HealthHeroes from "../components/ContentManagement/HealthHeroes/healthHeroes";
import Pictures from "../components/ContentManagement/Pictures/pictures";
import AddPictures from "../components/ContentManagement/Pictures/addPictures";
import EditPictures from "../components/ContentManagement/Pictures/editPictures";
import Story from "../components/ContentManagement/Story/story";
import ChildQuote from "../components/ContentManagement/ChildQuote/childQuote";
import ChildProcedure from "../components/ContentManagement/ChildProcedure/childProcedure";
import AddChildProcedure from "../components/ContentManagement/ChildProcedure/addChildProcedure";
import EditChildProcedure from "../components/ContentManagement/ChildProcedure/editChildProcedure";
import WhatsGoingOnMe from "../components/ContentManagement/WhatsGoingOnMe";
import Resources from "../components/ContentManagement/Resources/resources";
import Community from "../components/ContentManagement/Community/community";
import EditHealthHeroes from "../components/Subscriber/Hospital/editHealthHeroes";
import topresources from "../components/ContentManagement/Resources/topresources";
import ParentResources from "../components/ContentManagement/Resources/parentResources";
import ProfessionalResources from "../components/ContentManagement/Resources/professionalResources";
import ResourcesCategory from "../components/ContentManagement/Resources/resourcesCategory";
import HospitalSubscription  from "../components/HospitalSubscription";

function App(props) {
  let routes = (
    <Switch>
      <Route path={ROUTES_PATH.LOGIN} component={Login} />
      <Route path={ROUTES_PATH.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={ROUTES_PATH.VERIFY_OTP} component={VerifyOTP} />
      <Redirect exact path={"*"} to={ROUTES_PATH.LOGIN} />
    </Switch>
  );
  if (props.OtpVerified) {
    routes = (
      <Switch>
        <Route path={ROUTES_PATH.RESET_PASSWORD} component={ResetPassword} />
        <Route path={ROUTES_PATH.LOGIN} component={Login} />
        <Redirect exact path={"*"} to={ROUTES_PATH.LOGIN} />
      </Switch>
    );
  }
  if (props.isAuthenticated) {
    routes = (
      <>
        <ScrollToTop />
        <Switch>
          <Route path={ROUTES_PATH.HOME} component={Dashboard} />
          <Route path={ROUTES_PATH.SUBSCRIBER} component={Subscriber} />
          <Route
            path={ROUTES_PATH.USER_MANAGEMENT}
            component={UserManagementDashboard}
          />
          <Route
            path={ROUTES_PATH.PARENT_CHILD_DETAILS}
            component={UserManagementParentChildDetails}
          />
          <Route
            path={ROUTES_PATH.CLS_DETAILS}
            component={UserManagementClsDetails}
          />
          <Route
            path={ROUTES_PATH.CLINICIAN_DETAILS}
            component={UserManagementClinicianDetails}
          />
          <Route
            path={ROUTES_PATH.CHILD_DETAILS}
            component={UserManagementChildDetails}
          />
          <Route path={ROUTES_PATH.DAILY_QUOTES} component={DailyQuotes} />
          <Route path={ROUTES_PATH.PROCEDURES} component={Procedures} />
          <Route path={ROUTES_PATH.ADD_PROCEDURES} component={AddProcedures} />
          <Route path={ROUTES_PATH.EDIT_PROCEDURE} component={EditProcedure} />
          <Route path={ROUTES_PATH.GLOSSARY} component={Glossary} />
          <Route path={ROUTES_PATH.PODCAST} component={Podcast} />
          <Route path={ROUTES_PATH.STORY} component={Story} />
          <Route path={ROUTES_PATH.CHILD_QUOTE} component={ChildQuote} />
          <Route
            path={ROUTES_PATH.CHILD_PROCEDURE}
            component={ChildProcedure}
          />
          <Route
            path={ROUTES_PATH.ADD_CHILD_PROCEDURE}
            component={AddChildProcedure}
          />
          <Route
            path={ROUTES_PATH.EDIT_CHILD_PROCEDURE}
            component={EditChildProcedure}
          />
          <Route path={ROUTES_PATH.HOSPITAL_RESOURCES} component={Resources} />
          <Route
            path={ROUTES_PATH.RESOURCES_CATEGORIES}
            component={ResourcesCategory}
          />
          <Route
            path={ROUTES_PATH.PROFESSIONAL_RESOURCES}
            component={ProfessionalResources}
          />
          <Route
            path={ROUTES_PATH.PERSONAL_RESOURCES}
            component={ParentResources}
          />
          <Route path={ROUTES_PATH.TOP_RESOURCES} component={topresources} />
          <Route
            path={ROUTES_PATH.CONTENT_MANAGEMENT}
            component={CmDashboard}
          />
          <Route path={ROUTES_PATH.HEALTH_HEROES} component={HealthHeroes} />
          <Route path={ROUTES_PATH.PICTURES} component={Pictures} />
          <Route path={ROUTES_PATH.ADD_PICTURES} component={AddPictures} />
          <Route path={ROUTES_PATH.EDIT_PICTURE} component={EditPictures} />
          <Route path={ROUTES_PATH.HOSPITAL_VIEW} component={Hospital} />
          <Route path={ROUTES_PATH.ADD_HOSPITAL} component={AddHospital} />
          <Route path={ROUTES_PATH.EDIT_HOSPITAL} component={EditHospital} />
          <Route path={ROUTES_PATH.ADD_CLS} component={AddCls} />
          <Route path={ROUTES_PATH.ADD_CLINICIAN} component={AddClinician} />
          <Route
            path={ROUTES_PATH.ADD_HEALTH_HEROES}
            component={AddHealthHeroes}
          />
          <Route
            path={ROUTES_PATH.EDIT_HEALTH_HEROES}
            component={EditHealthHeroes}
          />
          <Route
            path={ROUTES_PATH.SUBSCRIPTION_PLAN}
            component={Subscription}
          />
          <Route
            path={ROUTES_PATH.CONTENT_AWAITING_APPROVAL}
            component={ContentApproval}
          />
          <Route
            path={ROUTES_PATH.WHATS_GOING_ON_ME}
            component={WhatsGoingOnMe}
          />
          <Route path={ROUTES_PATH.COMMUNITY} component={Community} />
          <Route
            path={ROUTES_PATH.HOSPITAL_SUBSCRIPTION}
            component={HospitalSubscription}
          />
          <Redirect exact path={"*"} to={ROUTES_PATH.HOME} />
        </Switch>
      </>
    );
  }
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>{routes}</Suspense>
    </Router>
  );
}

const mapStateToProps = state => {
  try {
    return {
      isAuthenticated: state.user.profile.accessToken ? true : false,
      user: state.user.profile,
      forgotPasswordRequestSuccess:
        state.forgotPasswordInformation.forgotPassword
          .forgotPasswordRequestSuccess,
      OtpVerified: state.verifyOtpInformation.verifyOtp.OtpVerified,
    };
  } catch (e) {
    console.log(e);
    return {
      isAuthenticated: false,
      user: state.user.profile,
      forgotPasswordRequestSuccess: false,
      OtpVerified: false,
    };
  }
};
export default withRouter(connect(mapStateToProps)(App));
