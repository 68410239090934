import React from 'react';
import { ADD_ICON } from '../../constant';


const MyButton = (props) => {
    return (
        <button id="my-button"
            onClick={props.buttonPath}>
            <img src={ADD_ICON} alt='' style={{ paddingRight: "12px" }} /> {props.name}
        </button>
    )
}

export default MyButton;
