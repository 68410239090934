import React from "react";
import AddProcedures from "../Procedures/addProcedures";
import { ROUTES_PATH } from "../../../constant";

const AddChildProcedure = (props) => {
  return (
    <>
      <AddProcedures
        {...props}
        path={ROUTES_PATH.CHILD_PROCEDURE}
        role="CHILD"
      />
    </>
  );
};
export default AddChildProcedure;
