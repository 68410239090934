import React, { useState, Fragment } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Back from "../../../common/components/back";
import TitleBar from "../../../common/components/titleBar";
import Footer from "../../Footer/index";
import { ROUTES_PATH, MESSAGE } from "../../../constant";
import {
  validateName,
  validateEmail,
  validatePhoneNumber,
  validateCertification,
} from "../../../utils/validations";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreator from "../../../modules/actions";
import SaveButton from "../../../common/components/saveButton";
import UploadImage from "../../../common/components/uploadImage";
import DatePicker from "react-datepicker";
import moment from "moment";

const AddCls = props => {
  const handleBack = () => {
    props.history.push(ROUTES_PATH.EDIT_HOSPITAL);
  };

  const [nameInvalid, setNameInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [genderInvalid, setGenderInvalid] = useState(false);
  const [birthDateInvalid, setBirtDateInvalid] = useState(false);
  const [practiceTypeInvalid, setPracticeTypeInvalid] = useState(false);
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);
  const [certificationInvalid, setCertificationInvalid] = useState(false);
  const [superUser, setSuperuser] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState({
    name: "",
    image: "",
    email: "",
    gender: "",
    birth_date: "",
    practice_type: "",
    phone_number: "",
    certification_id: "",
  });

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };



  //This function is to clear all the input after submitting value
  const clearData = () => {
    setData({
      name: "",
      image: "",
      email: "",
      gender: "",
      birth_date: "",
      practice_type: "",
      phone_number: "",
      certification_id: "",
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.toggleMainLoader(true);
    let emailValid = validateEmail(data.email);
    let PhoneNumberValid = validatePhoneNumber(data.phone_number);
    let nameValid = validateName(data.name);

    if (!data.name) {
      setNameInvalid(true);
    }
    if (!nameValid) {
      setNameInvalid(true);
    }

    if (!emailValid) {
      setEmailInvalid(true);
    }

    if (!data.practice_type) {
      setPracticeTypeInvalid(true);
    }
    if (!data.phone_number) {
      setPhoneNumberInvalid(true);
    }
    if (!PhoneNumberValid) {
      setPhoneNumberInvalid(true);
    }
    if (
      !data.name ||
      !emailValid ||
      !data.practice_type ||
      !PhoneNumberValid ||
      !data.phone_number ||
      !nameValid ||
      certificationInvalid
    ) {
      props.toggleMainLoader(false);
      return;
    }
    if (
      emailValid &&
      !nameInvalid &&
      !practiceTypeInvalid &&
      PhoneNumberValid &&
      nameValid &&
      !certificationInvalid
    ) {
      const clsData = {
        role: superUser ? ["CLS", "SUPERUSER"] : ["CLS"],
        name: data.name,
        image: data.image ? data.image : null,
        email: data.email,
        gender: data.gender ? data.gender : null,
        birth_date: data.birth_date
          ? moment(data.birth_date).format("YYYY-MM-DD")
          : null,
        practice_type: data.practice_type,
        phone_number: data.phone_number,
        certification_id: data.certification_id ? data.certification_id : null,
        hospital: props.hospitalDetails.id,
      };
      props.addClsuser(clsData);
      props.history.push(ROUTES_PATH.EDIT_HOSPITAL);
      clearData();
    }
  };
  const handleSubmitandAdd = e => {
    e.preventDefault();
    setSpinner(true);
    let emailValid = validateEmail(data.email);
    let PhoneNumberValid = validatePhoneNumber(data.phone_number);
    let nameValid = validateName(data.name);

    if (!data.name) {
      setNameInvalid(true);
    }
    if (!nameValid) {
      setNameInvalid(true);
    }

    if (!emailValid) {
      setEmailInvalid(true);
    }

    if (!data.practice_type) {
      setPracticeTypeInvalid(true);
    }

    if (!data.phone_number) {
      setPhoneNumberInvalid(true);
    }
    if (!PhoneNumberValid) {
      setPhoneNumberInvalid(true);
    }

    if (
      !data.name ||
      !emailValid ||
      !data.practice_type ||
      !PhoneNumberValid ||
      !data.phone_number ||
      !nameValid ||
      certificationInvalid
    ) {
      setSpinner(false);
      return;
    }
    if (
      emailValid &&
      !nameInvalid &&
      !practiceTypeInvalid &&
      PhoneNumberValid &&
      nameValid &&
      !certificationInvalid
    ) {
      const clsData = {
        role: superUser ? ["CLS", "SUPERUSER"] : ["CLS"],
        name: data.name,
        image: data.image ? data.image : null,
        email: data.email,
        gender: data.gender ? data.gender : null,
        birth_date: data.birth_date
          ? moment(data.birth_date).format("YYYY-MM-DD")
          : null,
        practice_type: data.practice_type,
        phone_number: data.phone_number,
        certification_id: data.certification_id ? data.certification_id : null,
        hospital: props.hospitalDetails.id,
      };
      props.addClsuser(clsData);
      setSpinner(false);
      props.history.push(ROUTES_PATH.ADD_CLS);
      clearData();
    }
  };

  return (
    <Fragment>
      <Container className="common-container">
        <Back
          className={"back-nav"}
          backNavItemClassName={"back-nav-item"}
          backIconClassName={"back-icon"}
          isEdit={false}
          isDelete={false}
          isSave={false}
          Back={handleBack}
        />
        <TitleBar titlename={"Add CLS"} headingClass={"title-bar-heading2"} />

        <Form noValidate style={{ paddingLeft: "16px" }}>
          <Row>
            <Col>
              <div style={{ marginBottom: "24px" }}>
                <UploadImage data={data} setData={setData} />
              </div>

              <Form.Group controlId="formBasicName">
                <Form.Label className="common-form-label">
                  CLS Name<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="common-form-input"
                  type="text"
                  placeholder="Enter CLS Name"
                  name="name"
                  value={data.name}
                  isInvalid={nameInvalid}
                  onChange={e => {
                    setNameInvalid(false);
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback
                  className="clinician-invalid"
                  type="invalid"
                >
                  {MESSAGE.NAME_INVALID}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicAge">
                <Form.Label className="common-form-label">
                  Birth Date
                </Form.Label>
                <br></br>
                <DatePicker
                  selected={data.birth_date}
                  onChange={date => {
                    setBirtDateInvalid(false);
                    setData({ ...data, birth_date: date });
                  }}
                  className={
                    !birthDateInvalid
                      ? "common-date-input"
                      : "common-date-input-error"
                  }
                  maxDate={new Date()}
                  placeholderText="MM/DD/YYYY"
                  dateFormat="MM/dd/yyyy"
                />
                {birthDateInvalid && (
                  <div className="clinician-invalid">
                    {MESSAGE.DATE_INVALID}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="formBasicGender">
                <Form.Label className="common-form-label">Gender</Form.Label>
                <Form.Control
                  as="select"
                  className="common-form-input img-class"
                  name="gender"
                  value={data.gender}
                  isInvalid={genderInvalid}
                  onChange={e => {
                    setGenderInvalid(false);
                    handleChange(e);
                  }}
                >
                  <option value="">Select</option>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                  <option value="CISGENDER">CISGENDER</option>
                  <option value="NBG">NBG</option>
                  <option value="T_MALE">T_MALE</option>
                  <option value="T_FEMALE">T_FEMALE</option>
                  <option value="OTHER">OTHER</option>
                </Form.Control>
                <Form.Control.Feedback
                  className="clinician-invalid"
                  type="invalid"
                >
                  {MESSAGE.GENDER_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formBasicPractice">
                <Form.Label className="common-form-label">
                  Type of Practice<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  className="common-form-input img-class"
                  name="practice_type"
                  value={data.practice_type}
                  isInvalid={practiceTypeInvalid}
                  onChange={e => {
                    setPracticeTypeInvalid(false);
                    handleChange(e);
                  }}
                >
                  <option value="">Select Type of Practice</option>
                  <option value="HHF">Hospital/Healthcare Facility</option>
                  <option value="CB">Community-Based</option>
                  <option value="CLS">Child Life Student</option>
                  <option value="AC">Academia</option>
                  <option value="OTHER">Other</option>
                </Form.Control>
                <Form.Control.Feedback
                  className="clinician-invalid"
                  type="invalid"
                >
                  {MESSAGE.TYPE_OF_WORK_INVALID}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="formBasicSuperuser"
                style={{ marginBottom: "25px" }}
              >
                <Form.Check
                  type="checkbox"
                  label="This CLS is a Superuser"
                  name="superUser"
                  checked={superUser}
                  onChange={e => {
                    setSuperuser(e.target.checked);
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="common-form-label">
                  Email<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="common-form-input"
                  type="text"
                  placeholder="Enter Email ID"
                  name="email"
                  value={data.email}
                  isInvalid={emailInvalid}
                  onChange={e => {
                    setEmailInvalid(false);
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback
                  className="clinician-invalid"
                  type="invalid"
                >
                  {MESSAGE.EMAIL_INVALID}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicContact">
                <Form.Label className="common-form-label">
                  Contact<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="common-form-input"
                  type="text"
                  placeholder="Enter Phone Number"
                  name="phone_number"
                  value={data.phone_number}
                  isInvalid={phoneNumberInvalid}
                  onChange={e => {
                    setPhoneNumberInvalid(false);
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback
                  className="clinician-invalid"
                  type="invalid"
                >
                  {MESSAGE.PHONE_NUMBER_INVALID}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicCertification">
                <Form.Label className="common-form-label">
                  CLS Certification
                </Form.Label>
                <Form.Control
                  className="common-form-input"
                  type="text"
                  placeholder="Enter Certification Number"
                  name="certification_id"
                  value={data.certification_id}
                  isInvalid={certificationInvalid}
                  onChange={e => {
                    if (e.target.value) {
                      setCertificationInvalid(
                        !validateCertification(e.target.value)
                      );
                    } else {
                      setCertificationInvalid(false);
                    }
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback
                  className="clinician-invalid"
                  type="invalid"
                >
                  {MESSAGE.CERTIFICATION_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
              <div style={{ marginTop: "50px" }}>
                <SaveButton
                  className={"form-save-and-add-button"}
                  submit={handleSubmitandAdd}
                  loader={spinner}
                  buttonName={"Save & Add Another"}
                />
                <SaveButton
                  className={"form-save-button"}
                  submit={handleSubmit}
                  loader={props.loader}
                  buttonName={"Add CLS"}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
      <Footer className={"my-footer"} />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loader: state.loader.mainLoader,
    hospitalDetails: state.hospitalDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleMainLoader: actionCreator.toggleMainLoader,
      addClsuser: actionCreator.addCls,
      getHospitalData: actionCreator.getHospitalData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCls);
