import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { toast } from "react-toastify";



export function* getSubscriptionsSagaCall(action) {
    try {
        const response = yield call(
            serviceCreator.getSubscriptionPlansService,
            action.data
        );
        if (response.status === 200 || response.status === 201) {
            yield put(actionCreators.toggleMainLoader(false))
            yield put(actionCreators.subscriptionPlanResponse(response.data));

        } else {
            yield put(actionCreators.toggleMainLoader(false))
        }
    } catch (error) {
        yield put(actionCreators.toggleMainLoader(false))
    }
}

export function* addSubscriptionPlanSagacall(action) {
    try {
        const response = yield call(
            serviceCreator.addSubscriptionPlan,
            action.data
        );
        if (response.status === 200 || response.status === 201) {
            yield put(actionCreators.getSubscriptionPlans())
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        else {
            yield put(actionCreators.toggleMainLoader(false))
            toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
    }
    catch (error) {
        yield put(actionCreators.toggleMainLoader(false))
        toast.error(error.response.data.message[0], {
            position: toast.POSITION.TOP_RIGHT,
        })
    }
}

export function* editSubscriptionPlanSagacall(action) {
    try {
        const response = yield call(
            serviceCreator.editSubscriptionPlan,
            action.data
        )
        if (response.status === 200 || response.status === 201) {
            yield put(actionCreators.getSubscriptionPlans())
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        else {
            yield put(actionCreators.toggleMainLoader(false))
            toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
    }
    catch (error) {
        yield put(actionCreators.toggleMainLoader(false))
        toast.error(error.response.data.message[0], {
            position: toast.POSITION.TOP_RIGHT,
        })
    }
}
