// UsersActions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const FORGOT_PASSWORD_REQUEST_SUCCESS =
  "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const OTP_VERIFIED = "OTP_VERIFIED";

export const SAVE_USER = "SAVE_USER";

// Hospital Actions

export const GET_ALL_HOSPITALS = "GET_ALL_HOSPITALS";

export const ALL_HOSPITALS_RESPONSE = "ALL_HOSPITALS_RESPONSE";

export const GET_HOSPITAL_DETAILS_RESPONSE = "GET_HOSPITAL_DETAILS_RESPONSE";

export const GET_HOSPITAL_COPY_RESPONSE = "GET_HOSPITAL_COPY_RESPONSE";

export const SEARCH_HOSPITALS = "SEARCH_HOSPITALS";
export const RESET_HOSPITAL = "RESET_HOSPITAL";
export const ADD_HOSPITAL = "ADD_HOSPITAL";
export const HOSPITAL_ADD_SUCCESS = "HOSPITAL_ADD_SUCCESS";
export const ADD_HEALTH_HERO = "ADD_HEALTH_HERO";
export const ADD_CLS = "ADD_CLS";
export const ADD_CLINICIAN = "ADD_CLINICIAN";
export const GET_HOSPITAL_DETAILS = "GET_HOSPITAL_DETAILS";
export const UPDATE_HOSPITAL = "UPDATE_HOSPITAL_DETAILS";
export const DELETE_HOSPITAL = "DELETE_HOSPITAL";
export const FILTER_HOSPITAL = "FILTER_HOSPITAL";
export const GET_FULL_HOSPITALS = "GET_FULL_HOSPITAL";
export const FULL_HOSPITALS_RESPONSE = "FULL_HOSPITALS_RESPONSE";
export const RENEW_PLAN = "RENEW_PLAN";
export const SET_EDIT_HEALTH_HERO = "SET_EDIT_HEALTH_HERO";
export const EDIT_HEALTH_HERO = "EDIT_HEALTH_HERO";
export const DELETE_HEALTH_HERO = "DELETE_HEALTH_HERO";

//User Management Actions
export const GET_ALL_PARENT_CHILD_LIST = "GET_ALL_PARENT_CHILD_LIST";
export const ALL_PARENT_CHILD_LIST_RESPONSE = "ALL_PARENT_CHILD_LIST_RESPONSE";
export const DELETE_PARENT = "DELETE_PARENT";
export const DELETE_CHILD = "DELETE_CHILD";

export const GET_ALL_CLINICIAN_LIST = "GET_ALL_CLINICIAN_LIST";
export const ALL_CLINICAN_LIST_RESPONSE = "ALL_CLINICAN_LIST_RESPONSE";
export const DELETE_CLINICIAN = "DELETE_CLINICIAN";

export const GET_ALL_CLS_LIST = "GET_ALL_CLS_LIST";
export const ALL_CLS_LIST_RESPONSE = "ALL_CLS_LIST_RESPONSE";
export const DELETE_CLS = "DELETE_CLS";

export const STORE_USER_MANAGEMENT_DETAILS = "STORE_USER_MANAGEMENT_DETAILS";
export const RESET = "RESET";

export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_RESPONSE = "SEARCH_RESPONSE";

export const FILTER_REQUEST = "FILTER_REQUEST";
export const FILTER_RESPONSE = "FILTER_RESPONSE";

export const ACTIVATION = "ACTIVATION";

//Subscription Plan Actions

export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const SUBSCRIPTION_PLANS_RESPONSE = "GET_SUBSCRIPTION_PLANS_RESPONSE";
export const ADD_SUBSCRIPTION_PLAN = "ADD_SUBSCRIPTION_PLAN";
export const EDIT_SUBSCRIPTION_PLAN = "EDIT_SUBSCRIPTION_PLAN";

// loader actions
export const SHOW_OR_HIDE_MAIN_LOADER = "SHOW_OR_HIDE_MAIN_LOADER";

//content management actions
export const GET_QUOTES_REQUEST = "GET_QUOTES_REQUEST";
export const GET_QUOTES_RESPONSE = "GET_QUOTES_RESPONSE";
export const GET_PUBLISHED_QUOTES = "GET_PUBLISHED_QUOTES";
export const PUBLISHED_QUOTES_RESPONSE = "PUBLISHED_QUOTES_RESPONSE";
export const PUBLISH_QUOTE = "PUBLISH_QUOTE";
export const GET_PROCEDURES_REQUEST = "GET_PROCEDURES_REQUEST";
export const GET_PROCEDURES_RESPONSE = "GET_PROCEDURES_RESPONSE";
export const GET_SCHEDULED_PROCEDURE = "GET_SCHEDULED_PROCEDURE";
export const SCHEDULED_PROCEDURE_RESPONSE = "SCHEDULED_PROCEDURE_RESPONSE";
export const GET_PUBLISHED_PROCEDURE = "GET_PUBLISHED_PROCEDURE";
export const PUBLISHED_PROCEDURE_RESPONSE = "PUBLISHED_PROCEDURE_RESPONSE";
export const APPROVE_PROCEDURE = "APPROVE_PROCEDURE";
export const GET_CHILD_PROCEDURE = "GET_CHILD_PROCEDURE";
export const CHILD_PROCEDURE_RESPONSE = "CHILD_PROCEDURE_RESPONSE";
export const ADD_PROCEDURE = "ADD_PROCEDURE";
export const GET_GLOSSARY_LIST = "GET_GLOSSARY_LIST";
export const GLOSSARY_LIST_RESPONSE = "GLOSSARY_LIST_RESPONSE";
export const GET_SCHEDULED_GLOSSARY = "GET_SCHEDULED_GLOSSARY";
export const SCHEDULED_GLOSSARY_RESPONSE = "SCHEDULED_GLOSSARY_RESPONSE";
export const GET_PUBLISHED_GLOSSARY = "GET_PUBLISHED_GLOSSARY";
export const PUBLISHED_GLOSSARY_RESPONSE = "PUBLISHED_GLOSSARY_RESPONSE";
export const GLOSSARY_ACTION = "GLOSSARY_ACTION";
export const ADD_GLOSSARY = "ADD_GLOSSARY";
export const GET_CHILD_QUOTES = "GET_CHILD_QUOTES";
export const CHILD_QUOTES_RESPONSE = "CHILD_QUOTES_RESPONSE";
export const GET_PUBLISHED_CHILD_QUOTES = "GET_PUBLISHED_CHILD_QUOTES";
export const PUBLISHED_CHILD_RESPONSE = "PUBLISHED_CHILD_RESPONSE";
export const CHILD_QUOTES_ACTION = "CHILD_QUOTES_ACTION";
export const GET_SCHEDULED_PODCAST = "GET_SCHEDULED_PODCAST";
export const SCHEDULED_PODCAST_RESPONSE = "SCHEDULED_PODCAST_RESPONSE";
export const GET_PUBLISHED_PODCAST = "GET_PUBLISHED_PODCAST";
export const PUBLISHED_PODCAST_RESPONSE = "PUBLISHED_PODCAST_RESPONSE";
export const ADD_PODCAST = "ADD_PODCAST";
export const GET_HEALTH_HEROES = "GET_HEALTH_HEROES";
export const HEALTH_HEROES_RESPONSE = "HEALTH_HEROES_RESPONSE";
export const HEALTH_HERO_ACTIONS = "HEALTH_HERO_ACTIONS";
export const GET_PICTURES = "GET_PICTURES";
export const PICTURE_RESPONSE = "PICTURE_RESPONSE";
export const PICTURE_ACTION = "PICTURE_ACTION";
export const GET_PUBLISHED_HEALTH_HEROES = "GET_PUBLISHED_HEALTH_HEROES";
export const PUBLISHED_HEALTH_HERO_RES = "PUBLISHED_HEALTH_HERO_RES";
export const GET_SCHEDULED_PICTURES = "GET_SCHEDULED_PICTURES";
export const SCHEDULE_PICTURE_RES = "SCHEDULE_PICTURE_RES";
export const GET_PUBLISHED_PICTURES = "GET_PUBLISHED_PICTURES";
export const PUBLISH_PICTURE_RES = "PUBLISH_PICTURE_RES";
export const ADD_PICTURE = "ADD_PICTURE";
export const GET_SCHEDULED_WHATS_GOING_ON_ME =
  "GET_SCHEDULED_WHATS_GOING_ON_ME";
export const SCHEDULED_WHATS_GOING_ON_ME_RES =
  "SCHEDULED_WHATS_GOING_ON_ME_RES";
export const GET_PUBLISHED_WHATS_GOING_ON_ME =
  "GET_PUBLISHED_WHATS_GOING_ON_ME";
export const PUBLISHED_WHATS_GOING_ON_ME_RES =
  "PUBLISHED_WHATS_GOING_ON_ME_RES";
export const ADD_WHATS_GOING_ON_ME = "ADD_WHATS_GOING_ON_ME";

//Response Success flag
export const RESPONSE_SUCCESS = "RESPONSE_SUCCESS";

//New Content Actions
export const GET_SCHEDULED_STORY = "GET_SCHEDULED_STORY";
export const SCHEDULED_STORY_RESPONSE = "SCHEDULED_STORY_RESPONSE";
export const GET_PUBLISHED_STORY = "GET_PUBLISHED_STORY";
export const PUBLISHED_STORY_RESPONSE = "PUBLISHED_STORY_RESPONSE";
export const ADD_STORY = "ADD_STORY";
export const GET_RESOURCES_REQUEST = "GET_RESOURCES_REQUEST";
export const GET_PARENT_RESOURCES = "GET_PARENT_RESOURCES";
export const GET_OTHER_RESOURCES_REQUEST = "GET_OTHER_RESOURCES_REQUEST";
export const GET_RESOURCES_RESPONSE = "GET_RESOURCES_RESPONSE";
export const GET_SCHEDULED_RESOURCES = "GET_SCHEDULED_RESOURCES";
export const SCHEDULED_RESOURCES_RESPONSE = "SCHEDULED_RESOURCES_RESPONSE";
export const GET_PUBLISHED_RESOURCES = "GET_PUBLISHED_RESOURCES";
export const PUBLISHED_RESOURCES_RESPONSE = "PUBLISHED_RESOURCES_RESPONSE";
export const PUBLISHED_PARENT_RESOURCES_RESPONSE = "PUBLISHED_PARENT_RESOURCES_RESPONSE";
export const ADD_RESOURCES = "ADD_RESOURCES";
export const GET_RESOURCES_CATEGORY = "GET_RESOURCES_CATEGORY";
export const RESOURCES_CATEGORY_RESPONSE = "RESOURCES_CATEGORY_RESPONSE";
export const APPROVE_RESOURCES = "APPROVE_RESOURCES";
export const DELETE_PROCEDURE = "DELTE_PROCEDURE";
export const DELETE_DAILYQUOTES = "DELETE_DAILYQUOTES";
export const DELETE_GLOSSARY = "DELETE_GLOSSARY";
export const DELETE_PODCAST = "DELETE_PODCAST";
export const DELETE_PICTURES = "DELETE_PICTURES";
export const DELETE_RESOURCES = "DELETE_RESOURCE";
export const DELETE_STORY = "DELETE_STORY";
export const DELETE_WHATSGOINGONME = "DELETE_WHATSGOINGONME";
export const CONTENT_DETAIL = "CONTENT_DETAIL";
export const UPDATE_DAILYQUOTES = "UPDATE_DAILYQUOTES";
export const UPDATE_GLOSSARY = "UPDATE_GLOSSARY";
export const UPDATE_PROCEDURE = "UPDATE_PROCEDURE";
export const UPDATE_PODCAST = "UPDATE_PODCAST";
export const UPDATE_PICTURE = "UPDATE_PICTURE";
export const UPDATE_RESOURCES = "UPDATE_RESOURCES";
export const UPDATE_STORY = "UPDATE_STORY";
export const UPDATE_WHATSGOINGONME = "UPDATE_WHATSGOINGONME";
export const GET_COMMUNITY_POSTS = "GET_COMMUNITY_POSTS";
export const COMMUNITY_POST_RESPONSE = "COMMUNITY_POST_RESPONSE";
export const GET_SCHEDULED_POSTS = "GET_SCHEDULED_POSTS";
export const SCHEDULED_POST_RESPONSE = "SCHEDULED_POST_RESPONSE";
export const GET_PUBLISHED_POSTS = "GET_PUBLISHED_POSTS";
export const PUBLISHED_POST_RESPONSE = "PUBLISHED_POST_RESPONSE";
export const POST_ACTION = "POST_ACTION";
export const DOWNLOAD_USER = "DOWNLOAD_USER";
export const GET_TOP_RESOURCES = "GET_TOP_RESOURCES";
export const ADD_TOP_RESOURCES = "ADD_TOP_RESOURCES";
export const UPDATE_TOP_RESOURCES = "UPDATE_TOP_RESOURCES";
export const DELETE_TOP_RESOURCES = "DELETE_TOP_RESOURCES";
export const TOP_RESOURCES_RESPONSE = "TOP_RESOURCES_RESPONSE";
export const ADD_PARENT_RESOURCES = "ADD_PARENT_RESOURCES";
export const UPDATE_PARENT_RESOURCES = "UPDATE_PARENT_RESOURCES";
export const DELETE_PARENT_RESOURCES = "DELETE_PARENT_RESOURCES";
export const ADD_PROFESSIONAL_RESOURCES = "ADD_PROFESSIONAL_RESOURCES";
export const UPDATE_PROFESSIONAL_RESOURCES = "UPDATE_PROFESSIONAL_RESOURCES";
export const DELETE_PROFESSIONAL_RESOURCES = "DELETE_PROFESSIONAL_RESOURCES";

export const GET_HOSPITAL_CATEGORIES = "GET_HOSPITAL_CATEGORIES";
export const ADD_HOSPITAL_CATEGORIES = "ADD_HOSPITAL_CATEGORIES";
export const DELETE_HOSPITAL_CATEGORIES = "DELETE_HOSPITAL_CATEGORIES";
export const GET_PARENT_CATEGORIES = "GET_PARENT_CATEGORIES";
export const ADD_PARENT_CATEGORIES = "ADD_PARENT_CATEGORIES";
export const DELETE_PARENT_CATEGORIES = "DELETE_PARENT_CATEGORIES";
export const GET_PROFESSIONAL_CATEGORIES = "GET_PROFESSIONAL_CATEGORIES";
export const ADD_PROFESSIONAL_CATEGORIES = "ADD_PROFESSIONAL_CATEGORIES";
export const DELETE_PROFESSIONAL_CATEGORIES = "DELETE_PROFESSIONAL_CATEGORIES";
export const GET_ALL_PROFESSIONAL_CATEGORIES =
  "GET_ALL_PROFESSIONAL_CATEGORIES";
export const GET_ALL_PERSONAL_CATEGORIES = "GET_ALL_PERSONAL_CATEGORIES";
export const GET_ALL_HOSPITAL_CATEGORIES = "GET_ALL_HOSPITAL_CATEGORIES";
export const UPDATE_HOSPITAL_CATEGORY = "UPDATE_HOSPITAL_CATEGORY";
export const UPDATE_PARENT_CATEGORY = "UPDATE_PARENT_CATEGORY";
export const UPDATE_PROFESSIONAL_CATEGORY = "UPDATE_PROFESSIONAL_CATEGORY";
export const SEARCH_GLOBAL = "SEARCH_GLOBAL";
export const ALL_GLOBAL_RESPONSE = "ALL_GLOBAL_RESPONSE";
export const IS_OPEN_SEARCH_MODAL = "IS_OPEN_SEARCH_MODAL";
export const GET_PUBLISH_PAGE = "GET_PUBLISH_PAGE";
export const MULTIPLE_PICTURES_ARRAY = "MULTIPLE_PICTURES_ARRAY";
export const GET_AWAITING_PAGE = "GET_AWAITING_PAGE";
export const GET_SCHEDULED_PAGE = "GET_SCHEDULED_PAGE";
export const UPDATE_PROCEDURE_HOSPITAL = "UPDATE_PROCEDURE_HOSPITAL";
export const UPDATE_PICTURE_HOSPITAL = "UPDATE_PICTURE_HOSPITAL";
export const GET_HOSPITAL_SUBSCRIPTION_LIST = "GET_HOSPITAL_SUBSCRIPTION_LIST";
export const GET_HOSPITAL_SUBSCRIPTION_LIST_RESPONSE = "GET_HOSPITAL_SUBSCRIPTION_LIST_RESPONSE";
export const GET_USER_NAVBAR_VALUE = "GET_USER_NAVBAR_VALUE";
export const USER_FILTER_VALUE = "USER_FILTER_VALUE";
export const RESET_FILTER = "RESET_FILTER";