import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { toast } from "react-toastify";

export function* getAllParentChildListSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getAllParentChildListService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.getAllParentChildListResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* deleteParentSaga(action) {
  try {
    const response = yield call(
      serviceCreator.deleteParentService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      if (response.data.status_code === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.reset());
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* deleteChildSaga(action) {
  try {
    const response = yield call(serviceCreator.deleteChildService, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      if (response.data.status_code === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.reset());
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getAllClsListSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getAllClsListService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getAllClsListResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* deleteClsSaga(action) {
  try {
    const response = yield call(serviceCreator.deleteClsService, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));

      if (response.data.status_code === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.reset());
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getAllClinicianListSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getAllClinicianListService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.getAllClinicianListResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* deleteClinicianSaga(action) {
  try {
    const response = yield call(
      serviceCreator.deleteClinicianService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      if (response.data.status_code === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.reset());
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* searchSaga(action) {
  try {
    const response = yield call(
      serviceCreator.searchService,
      action.page_no,
      action.data,
      action.role
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.searchResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* filterSaga(action) {
  try {
    const response = yield call(
      serviceCreator.filterService,
      action.page_no,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.filterResponse(response.data.data[0]));
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* activationSaga(action) {
  try {
    const response = yield call(
      serviceCreator.activationService,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      if (response.data.status_code === 200) {
        toast.success(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.reset());
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
