import React from "react";
import { ROUTES_PATH } from "../../../constant";

import Procedure from "../Procedures/procedures";
const ChildProcedure = (props) => {
  return (
    <>
      {" "}
      <Procedure
        {...props}
        role={"CHILD"}
        title={"Procedure(Child)"}
        addPath={ROUTES_PATH.ADD_CHILD_PROCEDURE}
        addEditPath={ROUTES_PATH.EDIT_CHILD_PROCEDURE}
      />
    </>
  );
};

export default ChildProcedure;
