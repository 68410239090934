import React from 'react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { PROFILE_ICON, LOG_OUT } from '../../constant'
import { connect } from "react-redux";
import * as actionCreator from "../../modules/actions";
import { bindActionCreators } from "redux";
import './header.css'

const Header = (props) => {

  return (
    <Navbar className="home-navbar">
      <Navbar.Brand id="nav-brand">Welcome {props.user} !</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <NavDropdown id="collasible-nav-dropdown" alignRight title={<img src={PROFILE_ICON} alt="" />}>
          <NavDropdown.Item className="nav-dropdowns">
            {props.user}<span style={{ marginLeft: "2px" }}>(Admin)</span>
          </NavDropdown.Item>
          <hr></hr>
          <NavDropdown.Item
            className="nav-dropdowns"
            onClick={() => { props.logoutSuccess() }}>
            <span style={{ color: "#455674" }}>Logout</span>
            <img src={LOG_OUT} alt="" style={{ marginLeft: "40px" }} /> </NavDropdown.Item>
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.user.profile.userName,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logoutSuccess: actionCreator.logoutSuccess,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
