import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObjects";

const initialState = {
  data: [],
  count: 0,
  total_page: 0,
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.ALL_HOSPITALS_RESPONSE:
      return updateObject(state, {
        data: [...data.data[0].results],
        count: data.data[0].count,
        total_page: data.data[0].links.total_pages,
      });
    case actionTypes.RESET_HOSPITAL:
      return initialState;
    default:
      return state;
  }
};

export const hospitalState = {
  data: [],
};

export const fullHospitalReducer = (state = hospitalState, { type, data }) => {
  switch (type) {
    case actionTypes.FULL_HOSPITALS_RESPONSE:
      return updateObject(state, {
        data: data,
      });
    default:
      return state;
  }
};

export const multiplePicturesState = {
  data: [],
};

export const multiplePicturesReducer = (state = multiplePicturesState, { type, data }) => {
  switch (type) {
    case actionTypes.MULTIPLE_PICTURES_ARRAY:
      return updateObject(state, {
        data: data,
      });
    default:
      return state;
  }
};
