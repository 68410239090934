import React from "react";
import { Form } from "react-bootstrap";
import "./DailyQuotes/dailyQuotes.css";
import "./Procedures/procedures.css";

const TextField = (props) => {
  const {
    onChange,
    label,
    labelClassName,
    className,
    isInvalid,
    message,
    placeholder,
    max,
    isNotNeccessary
  } = props;
  return (
    <>
      <Form.Group>
        <Form.Label className={labelClassName}>
          {label}
          {
            isNotNeccessary? null : (
              <span style={{ color: "red" }}> *</span>
            )
          }
          
        </Form.Label>
        <Form.Control
          className={className}
          as="textarea"
          maxLength={max}
          placeholder={placeholder ? placeholder : "Type Or Paste Here"}
          onChange={onChange}
          isInvalid={isInvalid}
          value={props.value}
        />
        <Form.Control.Feedback className="clinician-invalid" type="invalid">
          {message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default TextField;
