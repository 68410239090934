export const ROUTES_PATH = {
  HOME: "/home",
  SUBSCRIBER: "/subscriber-management",
  ADD_HOSPITAL: "/add-hospital",
  EDIT_HOSPITAL: "/edit-hospital",
  HOSPITAL_VIEW: "/hospital-details",
  ADD_CLS: "/add-cls",
  ADD_HEALTH_HEROES: "/add-healthheroes",
  ADD_CLINICIAN: "/add-clinician",
  SUBSCRIPTION_PLAN: "/subscription-plans",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY_OTP: "/verify-otp",
  RESET_PASSWORD: "/reset-password",
  USER_MANAGEMENT: "/user-management",
  PARENT_CHILD_DETAILS: "/parent-child-details",
  CLS_DETAILS: "/cls-details",
  CLINICIAN_DETAILS: "/clinician-details",
  CHILD_DETAILS: "/child-details",
  CONTENT_MANAGEMENT: "/content-management",
  DAILY_QUOTES: "/daily-quotes",
  PROCEDURES: "/procedures",
  CONTENT_AWAITING_APPROVAL: "/content-awaiting-approval",
  ADD_PROCEDURES: "/add-procedures",
  GLOSSARY: "/glossary",
  PODCAST: "/podcast",
  STORY: "/inspirational-story",
  HEALTH_HEROES: "/health-heroes",
  PICTURES: "/pictures",
  ADD_PICTURES: "/add-pictures",
  CHILD_QUOTE: "/what-other-kids-say",
  CHILD_PROCEDURE: "/child-procedure",
  ADD_CHILD_PROCEDURE: "/add-child-procedure",
  WHATS_GOING_ON_ME: "/whats-going-on-me",
  HOSPITAL_RESOURCES: "/hospital-resources",
  PROFESSIONAL_RESOURCES: "/professional-resources",
  PERSONAL_RESOURCES: "/parent-resources",
  EDIT_PICTURE: "/edit-picture",
  EDIT_PROCEDURE: "/edit-procedure",
  EDIT_CHILD_PROCEDURE: "/edit-child-procedure",
  COMMUNITY: "/community-posts",
  EDIT_HEALTH_HEROES: "/edit-health-heroes",
  TOP_RESOURCES: "/top-resources",
  RESOURCES_CATEGORIES: "/resources-categories",
  HOSPITAL_SUBSCRIPTION: "/hospital-subscriptions",
};
