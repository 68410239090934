import React from "react";
import { Navbar } from "react-bootstrap";
import { RIGHT_SCROLL_ICON, LEFT_SCROLL_ICON } from "../../constant";

const TitleBar = props => {
  return (
    <Navbar>
      <Navbar.Brand className={props.headingClass}>
        {props.count
          ? props.titlename + "(" + props.count + ")"
          : props.titlename}
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        {props.isScroll ? (
          <>
            <img
              src={LEFT_SCROLL_ICON}
              alt=""
              className="scroll-icon1"
              onClick={() => {
                props.onClick(-400);
              }}
            />

            <img
              src={RIGHT_SCROLL_ICON}
              alt=""
              className="scroll-icon"
              onClick={() => {
                props.onClick(400);
              }}
            />
          </>
        ) : null}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TitleBar;
