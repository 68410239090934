import * as ActionTypes from "./actionTypes";

export const getScheduledStory = (data, page_no, sort) => ({
  type: ActionTypes.GET_SCHEDULED_STORY,
  data,
  page_no,
  sort,
});

export const scheduledStoryResponse = data => ({
  type: ActionTypes.SCHEDULED_STORY_RESPONSE,
  data,
});

export const getPublishedStory = (data, page_no, sort) => ({
  type: ActionTypes.GET_PUBLISHED_STORY,
  data,
  page_no,
  sort,
});
export const publishedStoryResponse = data => ({
  type: ActionTypes.PUBLISHED_STORY_RESPONSE,
  data,
});

export const addStory = data => ({
  type: ActionTypes.ADD_STORY,
  data,
});

export const getResources = (data, page_no, status) => ({
  type: ActionTypes.GET_RESOURCES_REQUEST,
  status,
  page_no,
});

export const getOtherResources = (data, page_no, status) => ({
  type: ActionTypes.GET_OTHER_RESOURCES_REQUEST,
  data,
  page_no,
});

export const getResourcesResponse = data => ({
  type: ActionTypes.GET_RESOURCES_RESPONSE,
  data,
});

export const getScheduledResources = (data, page_no, sort, hospitalFilter) => ({
  type: ActionTypes.GET_SCHEDULED_RESOURCES,
  data,
  page_no,
  sort,
  hospitalFilter
});

export const scheduledResourcesResponse = data => ({
  type: ActionTypes.SCHEDULED_RESOURCES_RESPONSE,
  data,
});

export const getPublishedResources = (data, page_no, sort, hospitalFilter) => ({
  type: ActionTypes.GET_PUBLISHED_RESOURCES,
  data,
  page_no,
  sort,
  hospitalFilter
});

export const getPublishedParentResources = (data, page_no, sort, hospitalFilter) => ({
  type: ActionTypes.GET_PARENT_RESOURCES,
  data,
  page_no,
  sort,
  hospitalFilter
});

export const publishedResourcesResponse = data => ({
  type: ActionTypes.PUBLISHED_RESOURCES_RESPONSE,
  data,
});

export const publishedParentResourcesResponse = data => ({
  type: ActionTypes.PUBLISHED_PARENT_RESOURCES_RESPONSE,
  data,
});
export const getTopResources = (page_no, sort) => ({
  type: ActionTypes.GET_TOP_RESOURCES,
  page_no,
  sort,
});
export const topResourcesResponse = data => ({
  type: ActionTypes.TOP_RESOURCES_RESPONSE,
  data,
});
export const addTopResources = data => ({
  type: ActionTypes.ADD_TOP_RESOURCES,
  data,
});
export const updateTopResource = (id, data) => ({
  type: ActionTypes.UPDATE_TOP_RESOURCES,
  id,
  data,
});
export const deleteTopResources = id => ({
  type: ActionTypes.DELETE_TOP_RESOURCES,
  id,
});
export const addResources = data => ({
  type: ActionTypes.ADD_RESOURCES,
  data,
});
export const addParentResources = data => ({
  type: ActionTypes.ADD_PARENT_RESOURCES,
  data,
});
export const addProfessionalResources = data => ({
  type: ActionTypes.ADD_PROFESSIONAL_RESOURCES,
  data,
});
export const getResourcesCategory = () => ({
  type: ActionTypes.GET_RESOURCES_CATEGORY,
});

export const resourcesCategoryResponse = data => ({
  type: ActionTypes.RESOURCES_CATEGORY_RESPONSE,
  data,
});

export const approveResources = (id, data) => ({
  type: ActionTypes.APPROVE_RESOURCES,
  id,
  data,
});

export const getHospitalCategories = (page_no, hospitalFilter) => ({
  type: ActionTypes.GET_HOSPITAL_CATEGORIES,
  page_no,
  hospitalFilter
});
export const addHospitalCategories = data => ({
  type: ActionTypes.ADD_HOSPITAL_CATEGORIES,
  data,
});
export const deleteHospitalCategories = id => ({
  type: ActionTypes.DELETE_HOSPITAL_CATEGORIES,
  id,
});

export const getParentCategories = (page_no, hospitalFilter) => ({
  type: ActionTypes.GET_PARENT_CATEGORIES,
  page_no,
  hospitalFilter
});
export const addParentCategories = data => ({
  type: ActionTypes.ADD_PARENT_CATEGORIES,
  data,
});
export const deleteParentCategories = id => ({
  type: ActionTypes.DELETE_PARENT_CATEGORIES,
  id,
});

export const getProfessionalCategories = (page_no, hospitalFilter) => ({
  type: ActionTypes.GET_PROFESSIONAL_CATEGORIES,
  page_no,
  hospitalFilter
});
export const addProfessionalCategories = data => ({
  type: ActionTypes.ADD_PROFESSIONAL_CATEGORIES,
  data,
});
export const deleteProfessionalCategories = id => ({
  type: ActionTypes.DELETE_PROFESSIONAL_CATEGORIES,
  id,
});

export const getAllHospitalCategories = () => ({
  type: ActionTypes.GET_ALL_HOSPITAL_CATEGORIES,
});

export const getAllParentCategories = () => ({
  type: ActionTypes.GET_ALL_PERSONAL_CATEGORIES,
});

export const getAllProfessionalCategories = () => ({
  type: ActionTypes.GET_ALL_PROFESSIONAL_CATEGORIES,
});

export const updateHospitalCategory = (id, data) => ({
  type: ActionTypes.UPDATE_HOSPITAL_CATEGORY,
  id,
  data,
});

export const updateParentCategory = (id, data) => ({
  type: ActionTypes.UPDATE_PARENT_CATEGORY,
  id,
  data,
});

export const updateProfessionalCategory = (id, data) => ({
  type: ActionTypes.UPDATE_PROFESSIONAL_CATEGORY,
  id,
  data,
});

export const getSearchedGlobal = (data) => ({
  type: ActionTypes.SEARCH_GLOBAL,
  data,
});

export const allGlobalSearchListResponse = data => ({
  type: ActionTypes.ALL_GLOBAL_RESPONSE,
  data,
});

export const isOpenModalFromSearch = data => ({
  type: ActionTypes.IS_OPEN_SEARCH_MODAL,
  data,
});

export const getHospitalSubscriptionList = (page_no, sort) => ({
  
  type: ActionTypes.GET_HOSPITAL_SUBSCRIPTION_LIST,
  page_no,
  sort,
});

export const getHospitalSubscriptionListResponse = data => ({
  type: ActionTypes.GET_HOSPITAL_SUBSCRIPTION_LIST_RESPONSE,
  data,
});