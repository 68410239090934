import React, { Fragment, useState } from "react";
import "./hospital.css";
import { RED_DELETE_ICON, UPLOAD_PLUS } from "../../../constant";
import TitleBar from "../../../common/components/titleBar";
import UserModal from "./UserModal";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const HospitalUser = props => {
  const {
    user,
    userTitle,
    addUser,
    healthHero,
    healthHeroTitle,
    addHealthhero,
    healthHerodata,
    userdata,
    className,
    modal,
    handleShow,
    handleClose,
    onChange,
    type,
    handleAddButton,
    handleAddHero,
    handleHealthHeroClick,
    handleDeleteHero,
    hospitalId,
    isSuperUser,
  } = props;

  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteClose = () => {
    setIsDelete(false);
  };

  const handleDelete = () => {
    if (deleteId !== null) {
      handleDeleteHero(deleteId, hospitalId);
      setIsDelete(false);
    }
  };

  return (
    <Fragment>
      {user && !isSuperUser ? (
        <div className={className}>
          {userTitle ? (
            <p className="common-form-label">Hospital Users</p>
          ) : (
            <TitleBar
              titlename={"Hospital Users"}
              headingClass={"title-bar-heading"}
              count={userdata.length}
            />
          )}

          <div className="row" style={{ paddingLeft: "5px" }}>
            {userdata &&
              userdata.map((user, index) => {
                return (
                  <div className="user-card" key={index}>
                    {user.image ? (
                      <div className="user-image">
                        <img src={user.image} alt="" className="user-image" />
                      </div>
                    ) : (
                      <div className="blank-user-image">
                        {user.name.toUpperCase()[0]}
                      </div>
                    )}
                    <OverlayTrigger
                   placement="top"
                   delay={{ show: 250, hide: 400 }}
                   overlay = {
                     <Tooltip className="tooltip-top">
                       <div className="mb-1">
                         Name : {user.name}
                       </div>
                     </Tooltip>
                   }
                   >
                    <p className="user-name">{user.name.slice(0,15)}</p>
                   </OverlayTrigger>
                    <p className="user-role">{user.practice_type}</p>
                  </div>
                );
              })}

            {addUser ? (
              <div className="hospital-image-wrapper">
                <div className="upload-hospital-image-icon">
                  <p>
                    <img
                      src={UPLOAD_PLUS}
                      alt=""
                      style={{ paddingLeft: "61px", cursor: "pointer" }}
                      onClick={() => handleShow()}
                    />
                  </p>
                  <p className="upload-label">Add New</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {healthHero ? (
        <div className={className}>
          {healthHeroTitle ? (
            <p className="common-form-label">Health Heroes</p>
          ) : (
            <TitleBar
              titlename={"Health Heroes"}
              headingClass={"title-bar-heading"}
              count={healthHerodata.length}
            />
          )}

          <div className="row" style={{ paddingLeft: "5px" }}>
            {healthHerodata &&
              healthHerodata.map((hero, index) => {
                return (
                  <div className="user-card hero-card" key={index}>
                    {addHealthhero ? (
                      <div>
                        <button
                          className="hospital-hero-delete-icon"
                          onClick={() => {
                            setIsDelete(true);
                            setDeleteId(hero.id);
                          }}
                        >
                          <img
                            src={RED_DELETE_ICON}
                            alt=""
                            style={{
                              paddingBottom: "3px",
                              paddingRight: "3px",
                            }}
                          />
                        </button>
                      </div>
                    ) : null}
                    {hero.image ? (
                      <div
                        onClick={() =>
                          addHealthhero ? handleHealthHeroClick(hero) : {}
                        }
                      >
                        <img src={hero.image} alt="" className="user-image" />
                      </div>
                    ) : (
                      <div
                        className="blank-user-image"
                        onClick={() =>
                          addHealthhero ? handleHealthHeroClick(hero) : {}
                        }
                      >
                        {hero.name.toUpperCase()[0]}
                      </div>
                    )}
                    <p
                      className="user-name"
                      onClick={() =>
                        addHealthhero ? handleHealthHeroClick(hero) : {}
                      }
                    >
                      {hero.name}
                    </p>
                    <p
                      className="user-role"
                      onClick={() =>
                        addHealthhero ? handleHealthHeroClick(hero) : {}
                      }
                    >
                      {hero.specialization}
                    </p>
                  </div>
                );
              })}

            {addHealthhero ? (
              <div className="hospital-image-wrapper">
                <div className="upload-hospital-image-icon">
                  <p>
                    <img
                      src={UPLOAD_PLUS}
                      alt=""
                      style={{ paddingLeft: "61px", cursor: "pointer" }}
                      onClick={() => handleAddHero()}
                    />
                  </p>
                  <p className="upload-label">Add New</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      <UserModal
        modal={isDelete === true ? true : modal}
        handleClose={isDelete === true ? handleDeleteClose : handleClose}
        onChange={onChange}
        type={type}
        isdelete={isDelete}
        handleAddButton={handleAddButton}
        handleDelete={handleDelete}
        addUser={modal}
      />
    </Fragment>
  );
};

export default HospitalUser;