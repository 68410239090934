import * as ActionTypes from "./actionTypes";

export const getSubscriptionPlans = () => ({
    type: ActionTypes.GET_SUBSCRIPTION_PLANS,

});

export const subscriptionPlanResponse = (data) => ({
    type: ActionTypes.SUBSCRIPTION_PLANS_RESPONSE,
    data,
});

export const addSubscriptionPlan = (data) => ({
    type: ActionTypes.ADD_SUBSCRIPTION_PLAN,
    data
})

export const editSubscriptionPlan = (data) => ({
    type: ActionTypes.EDIT_SUBSCRIPTION_PLAN,
    data
})