import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container } from "react-bootstrap";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import "../DailyQuotes/dailyQuotes.css";
import Footer from "../../Footer";
import ContentTable from "../ContentTable/contentTable";
import AddPodcast from "./addPodcast";
import { rename } from "../../../utils/rename";
import DeleteModal from "../deleteModal";
import FilterDropdown from "../../commonComponent/FilterDropdown";

const DailyQuotes = props => {
  const [scheduledPageNumber, setScheduledPage] = useState(1);
  const [publishedPageNumber, setPublishedPage] = useState(1);
  const [show, setShow] = useState(false);
  const [scheduledDataList, setScheduledList] = useState();
  const [publishedDataList, setPublishedList] = useState();
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [sort, setSort] = useState();
  const [scheduledSort, setScheduledSort] = useState();

  useEffect(() => {
    props.reset();
    props.toggleMainLoader(true);
    props.getScheduledPodcast("SCHEDULED", scheduledPageNumber, scheduledSort);
    props.getPublishedPodcast("PUBLISHED", publishedPageNumber, sort);
    props.toggleMainLoader(true);
    setScheduledPage(scheduledPageNumber);
    setPublishedPage(publishedPageNumber);
    if (props.responseSuccess) {
      setDeleteIndex(0);
      setDeleteModal(false);
      setShow(false);
    }
  }, [props.match.params.path, props.responseSuccess, sort, scheduledSort]);

  useEffect(() => {
    setScheduledList(
      rename(
        props.scheduledPodcast.results,
        "cellOne",
        "cellTwo",
        "title",
        "scheduled_date"
      )
    );
  }, [props.scheduledPodcast.results]);

  useEffect(() => {
    setPublishedList(
      rename(
        props.publishedPodcast.results,
        "cellOne",
        "cellTwo",
        "title",
        "updated_at"
      )
    );
  }, [props.publishedPodcast.results]);

  useEffect(() => {
    if(props.openModal){
      setShow(true);
      setEdit(true);
      props.openSearchModal(false)
    }
  }, [])

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };

  const changePageScheduled = selected => {
    setScheduledPage(selected);
    props.toggleMainLoader(true);
    props.getScheduledPodcast("SCHEDULED", selected, scheduledSort);
  };
  const changePagePublished = selected => {
    setPublishedPage(selected);
    props.toggleMainLoader(true);
    props.getPublishedPodcast("PUBLISHED", selected, sort);
  };

  const handleAddButton = () => {
    props.toggleMainLoader(false);
    setShow(true);
    setEdit(false);
  };

  const handleCancelClose = () => {
    setShow(false);
  };
  const headerFirst = {
    headerOne: "Podcast Name",
    headerTwo: "Scheduled Date",
  };
  const headerSecond = {
    headerOne: "Podcast Name",
    headerTwo: "Updated Date",
  };

  //Functions for Edit and delete
  const handleDelete = index => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteIndex(0);
    setDeleteModal(false);
  };
  const handleDeleteQuotes = () => {
    props.toggleMainLoader(true);
    props.deletePodcast(deleteIndex);
  };

  const handleUpdate = data => {
    props.contentDetails(data);
    setEdit(true);
    setShow(true);
  };

  const handleUpdateClose = () => {
    setShow(false);
    setEdit(false);
  };

  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />

        <UserManagementNavbar
          title={"Podcast"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          buttonTitle={"Add Podcast"}
          handleAddButton={handleAddButton}
        />
        <ContentTable
            mainHeader={"Published"}
            secondHeader = {<FilterDropdown  sort = {setSort} value = {sort}/>}
            header={headerSecond}
            dataList={publishedDataList}
            rowPerPage={10}
            itemCountFromServer={props.publishedPodcast.count}
            changePage={changePagePublished}
            pageNumber={publishedPageNumber}
            minHeightClass={"procedures-minHeight"}
            contentHeaderOne={"content-header1"}
            contentHeaderTwo={"content-header2"}
            contentHeaderThree={"content-header3"}
            contentCellOne={"content-cell1"}
            contentCellTwo={"content-cell2"}
            contentCellThree={"content-cell3"}
            viewCellClass={"view-cell-class"}
            viewCell={true}
            onDelete={handleDelete}
            onEdit={handleUpdate}
            isCellTwoDate = {true}
          />

        
        <div style={{ marginTop: "27px" }}>
        <ContentTable
          mainHeader={"Scheduled"}
          secondHeader = {<FilterDropdown  sort = {setScheduledSort} value = {scheduledSort}/>}
          header={headerFirst}
          dataList={scheduledDataList}
          rowPerPage={10}
          itemCountFromServer={props.scheduledPodcast.count}
          changePage={changePageScheduled}
          pageNumber={scheduledPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"content-header2"}
          contentHeaderThree={"content-header3"}
          contentCellOne={"content-cell1"}
          contentCellTwo={"content-cell2"}
          contentCellThree={"content-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
        />
        </div>
        <Footer className={"content-management-footer"} />

        <AddPodcast
          show={show}
          contentClassName={"modal-content-quotes"}
          title={edit ? "Update Podcast" : "Add Podcast"}
          handleCancelClose={edit ? handleUpdateClose : handleCancelClose}
          isEdit={edit}
        />

        <DeleteModal
          modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteQuotes}
          loader={props.loader}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    details: state.userManagementDetails.details,
    scheduledPodcast: state.scheduledPodcast,
    publishedPodcast: state.publishedPodcast,
    responseSuccess: state.responseSuccess.responseSuccess,
    loader: state.loader.mainLoader,
    openModal : state.modalFromSearch.openModal,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getScheduledPodcast: actionCreator.getScheduledPodcast,
      getPublishedPodcast: actionCreator.getPublishedPodcast,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      deletePodcast: actionCreator.deletePodcast,
      contentDetails: actionCreator.getContentDetails,
      openSearchModal : actionCreator.isOpenModalFromSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyQuotes);
