import S3 from "aws-s3";
let AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
let AWS_SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY;

window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: "cloc-app",
  dirName: "myprofilepicture",
  region: "us-east-2",
  accessKeyId: AWS_ACCESS_KEY,
  secretAccessKey: AWS_SECRET_KEY,
  //   s3Url: "https://my-s3-url.com/"
};

export const S3Client = new S3(config);
