import * as actionTypes from "../actions/actionTypes";

export const scheduledStory = {
  storyData: [],
  count: [],
  results: [],
};
export const scheduledStoryReducer = (
  state = scheduledStory,
  { type, data }
) => {
  switch (type) {
    case actionTypes.SCHEDULED_STORY_RESPONSE:
      return {
        ...state,
        ...{ storyData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return scheduledStory;
    default:
      return state;
  }
};

export const publishedStory = {
  storyData: [],
  count: [],
  results: [],
};
export const publishedStoryReducer = (
  state = publishedStory,
  { type, data }
) => {
  switch (type) {
    case actionTypes.PUBLISHED_STORY_RESPONSE:
      return {
        ...state,
        ...{ storyData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return publishedStory;
    default:
      return state;
  }
};

export const awaitingResources = {
  resourcesData: [],
  count: [],
  results: [],
};
export const awaitingResourcesReducer = (
  state = awaitingResources,
  { type, data }
) => {
  switch (type) {
    case actionTypes.GET_RESOURCES_RESPONSE:
      return {
        ...state,
        ...{ resourcesData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return awaitingResources;
    default:
      return state;
  }
};

export const scheduledResources = {
  resourcesData: [],
  count: [],
  results: [],
};
export const scheduledResourcesReducer = (
  state = scheduledResources,
  { type, data }
) => {
  switch (type) {
    case actionTypes.SCHEDULED_RESOURCES_RESPONSE:
      return {
        ...state,
        ...{ resourcesData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return scheduledResources;
    default:
      return state;
  }
};

export const publishedResources = {
  resourcesData: [],
  count: [],
  results: [],
};
export const publishedResourcesReducer = (
  state = publishedResources,
  { type, data }
) => {
  switch (type) {
    case actionTypes.PUBLISHED_RESOURCES_RESPONSE:
      return {
        ...state,
        ...{ resourcesData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return publishedResources;
    default:
      return state;
  }
};

export const publishedParentResources = {
  resourcesData: [],
  count: [],
  results: [],
};

export const publishedParentResourcesReducer = (
  state = publishedParentResources,
  { type, data }
) => {
  switch (type) {
    case actionTypes.PUBLISHED_PARENT_RESOURCES_RESPONSE:
      return {
        ...state,
        ...{ resourcesData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return publishedParentResources;
    default:
      return state;
  }
};

export const topResources = {
  resourcesData: [],
  count: [],
  results: [],
};
export const topResourcesReducer = (state = topResources, { type, data }) => {
  switch (type) {
    case actionTypes.TOP_RESOURCES_RESPONSE:
      return {
        ...state,
        ...{ resourcesData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return topResources;
    default:
      return state;
  }
};

export const resourcesCategory = {
  results: [],
};
export const getResourcesCategoryReducer = (
  state = resourcesCategory,
  { type, data }
) => {
  switch (type) {
    case actionTypes.RESOURCES_CATEGORY_RESPONSE:
      return {
        ...state,
        ...{ results: data },
      };

    case actionTypes.RESET:
      return resourcesCategory;
    default:
      return state;
  }
};

export const globalSearch = {
  results: [],
};
export const getGlobalSearchResultReducer = (
  state = globalSearch,
  { type, data }
) => {
  switch (type) {
    case actionTypes.ALL_GLOBAL_RESPONSE:
      return {
        ...state,
        ...{ results: data },
      };

    case actionTypes.RESET:
      return globalSearch;
    default:
      return state;
  }
};

export const openModalSearch = {
  openModal: false,
};
export const getSearchModalResultReducer = (
  state = openModalSearch,
  { type, data }
) => {
  switch (type) {
    case actionTypes.IS_OPEN_SEARCH_MODAL:
      return {
        openModal: data,
      };

    case actionTypes.RESET:
      return openModalSearch;
    default:
      return state;
  }
};

export const hospitalSubscriptionState = {
  resourcesData: [],
  count: [],
  results: [],
};
export const hospitalSubscriptionListReducer = (
  state = hospitalSubscriptionState,
  { type, data }
) => {
  switch (type) {
    case actionTypes.GET_HOSPITAL_SUBSCRIPTION_LIST_RESPONSE:
      return {
        ...state,
        ...{ resourcesData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return hospitalSubscriptionState;
    default:
      return state;
  }
};