import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Back from "../../../common/components/back";
import { ROUTES_PATH } from "../../../constant";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import ContentTable from "../ContentTable/contentTable";
import {rename, newRename, approveStatus } from "../../../utils/rename";
import Footer from "../../Footer";


const Community = (props) => {
    const handleBack = () => {
        props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
    };

    useEffect(() => {
        props.toggleMainLoader(true)
        props.getPosts("AWAITING", 1);
        props.getScheduledPosts("SCHEDULED", 1);
        props.getPublishedPosts("PUBLISHED", 1);
    }, [])

    const [awaitingPosts, setAwaitingPosts] = useState([])
    const [scheduledPost, setScheduledPosta] = useState([])
    const [publishedPost, setPublishedPost] = useState([])
    const [awaitingPage, setAwaitingPage] = useState(1)
    const [scheduledPage, setScheduledPage] = useState(1)
    const [publishedPage, setPublishedPage] = useState(1)

    //useEffect to refresh the data when an action in called
    useEffect(() => {
        props.toggleMainLoader(true);
        props.getPosts("AWAITING", awaitingPage);
        props.getScheduledPosts("SCHEDULED", scheduledPage);
        props.getPublishedPosts("PUBLISHED", publishedPage);
        props.successResponse(false);
    }, [props.responseSuccess]);


    const headerAwaiting = {
        headerOne: "Post",
        headerTwo: "Uploaded_by",
        headerThree: "Actions",
    };
    const headerScheduled = {
        headerOne: "Post",
        headerTwo: "Uploaded_by",
        headerThree: "Scheduled Date",
    };
    const headerPublished = {
        headerOne: "Post",
        headerTwo: "Uploaded_by",
        headerThree: "Updated Date",
    };

    useEffect(() => {
        setAwaitingPosts(rename(
            props.posts.results,
            "cellOne",
            "cellTwo",
            "post_message",
            "author"
        ))
    }, [props.posts.results]);
    useEffect(() => {
        setScheduledPosta(
            newRename(
                props.scheduledpostsList.results,
                "post_message",
                "author",
                "scheduled_date"
            )
        );
    }, [props.scheduledpostsList.results]);

    useEffect(() => {
        setPublishedPost(
            newRename(
                props.publishedpostsList.results,
                "post_message",
                "author",
                "scheduled_date"
            )
        );
    }, [props.publishedpostsList.results]);


    const awaitingPageChange = (select) => {
        setAwaitingPage(select);
        props.toggleMainLoader(true);
        props.getPosts("AWAITING", select);
    };
    const scheduledPageChange = (select) => {
        setScheduledPage(select);
        props.toggleMainLoader(true);
        props.getScheduledPosts("SCHEDULED", select);
    };
    const publishedPageChange = (select) => {
        setPublishedPage(select);
        props.toggleMainLoader(true);
        props.getPublishedPosts("PUBLISHED", select);
    };

    const PostsApprove = (index) => {
        const status = approveStatus(props.posts.results.filter((item) => item.id === index)[0].scheduled_date)
        props.postAction(index, { status: status });
    };

    const PostsReject = (index) => {
        props.postAction(index, { status: "REJECT" });
    };


    return (
        <>
            <Container className="common-container">
                <Back
                    Back={handleBack}
                    backIconClassName={"parent-back-icon"}
                    backNavItemClassName={"parent-back-nav"}
                    className="parent-back"
                />
                <UserManagementNavbar
                    title={"Community Posts"}
                    isEditButton={false}
                    isDeleteButton={false}
                    id={"parent-nav-title"}
                    className={"parent-navbar"}
                />

                <ContentTable
                    mainHeader={"Published"}
                    header={headerPublished}
                    dataList={publishedPost}
                    itemCountFromServer={props.publishedpostsList.count}
                    rowPerPage={10}
                    pageNumber={publishedPage}
                    changePage={publishedPageChange}
                    minHeightClass={"procedures-minHeight"}
                    contentHeaderOne={"content-header1"}
                    contentHeaderTwo={"procedures-header2"}
                    contentHeaderThree={"procedures-header3"}
                    contentCellOne={"procedures-cell1"}
                    contentCellTwo={"procedures-cell2"}
                    contentCellThree={"procedures-cell3"}
                    viewCellClass={"view-cell-class"}

                />

                <div style={{ marginTop: "39px" }}></div>
                <ContentTable
                    mainHeader={"Content Awaiting Approval"}
                    header={headerAwaiting}
                    dataList={awaitingPosts}
                    onApprove={PostsApprove}
                    onReject={PostsReject}
                    itemCountFromServer={props.posts.count}
                    rowPerPage={10}
                    pageNumber={awaitingPage}
                    changePage={awaitingPageChange}
                    minHeightClass={"procedures-minHeight"}
                    contentHeaderOne={"content-header1"}
                    contentHeaderTwo={"procedures-header2"}
                    contentHeaderThree={"procedures-header3"}
                    contentCellOne={"procedures-cell1"}
                    contentCellTwo={"procedures-cell2"}
                    contentCellThree={"procedures-cell3"}
                    viewCellClass={"view-cell-class"}
                />
                <div style={{ marginTop: "39px" }}></div>

                <ContentTable
                    mainHeader={"Scheduled"}
                    header={headerScheduled}
                    dataList={scheduledPost}
                    itemCountFromServer={props.scheduledpostsList.count}
                    rowPerPage={10}
                    pageNumber={scheduledPage}
                    changePage={scheduledPageChange}
                    minHeightClass={"procedures-minHeight"}
                    contentHeaderOne={"content-header1"}
                    contentHeaderTwo={"procedures-header2"}
                    contentHeaderThree={"procedures-header3"}
                    contentCellOne={"procedures-cell1"}
                    contentCellTwo={"procedures-cell2"}
                    contentCellThree={"procedures-cell3"}
                    viewCellClass={"view-cell-class"}

                />
               

                
            </Container>
            <Footer className={"content-management-footer"} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        posts: state.posts,
        scheduledpostsList: state.scheduledPosts,
        publishedpostsList: state.publishedPosts,
        responseSuccess: state.responseSuccess.responseSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getPosts: actionCreator.getCommunityPosts,
            getScheduledPosts: actionCreator.getScheduledPosts,
            getPublishedPosts: actionCreator.getPublishedPosts,
            postAction: actionCreator.postAction,
            toggleMainLoader: actionCreator.toggleMainLoader,
            successResponse: actionCreator.responseSuccess,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Community);
