import * as actionTypes from "../actions/actionTypes";

export const quotesInitialState = {
  quotesData: [],
  count: [],
  results: [],
  allQuotesData: [],
};

export const quotesReducer = (state = quotesInitialState, { type, data }) => {
  switch (type) {
    case actionTypes.GET_QUOTES_RESPONSE:
      return {
        ...state,
        ...{ quotesData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
        allQuotesData: [...state.allQuotesData, ...data.results],
      };

    case actionTypes.RESET:
      return quotesInitialState;
    default:
      return state;
  }
};

export const publishedQuotesState = {
  publishedQuotes: [],
  count: [],
  results: [],
  allPublishedQuotes: [],
};

export const publishedQuotesReducer = (
  state = publishedQuotesState,
  { type, data }
) => {
  switch (type) {
    case actionTypes.PUBLISHED_QUOTES_RESPONSE:
      return {
        ...state,
        ...{ publishedQuotes: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
        allPublishedQuotes: [...state.allPublishedQuotes, ...data.results],
      };

    case actionTypes.RESET:
      return publishedQuotesState;
    default:
      return state;
  }
};

export const successInitial = {
  responseSuccess: false,
};

export const responseSuccessReducer = (
  state = successInitial,
  { type, data }
) => {
  switch (type) {
    case actionTypes.RESPONSE_SUCCESS:
      return {
        ...state,
        ...{ responseSuccess: data },
      };

    case actionTypes.RESET:
      return successInitial;
    default:
      return state;
  }
};

export const proceduresInitialState = {
  proceduresData: [],
  count: 0,
  results: [],
};

export const proceduresReducer = (
  state = proceduresInitialState,
  { type, data }
) => {
  switch (type) {
    case actionTypes.GET_PROCEDURES_RESPONSE:
      return {
        ...state,
        ...{ proceduresData: data.links },
        count: data.count,
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return proceduresInitialState;
    default:
      return state;
  }
};

export const scheduledProcedures = {
  proceduresData: [],
  count: [],
  results: [],
};

export const scheduledProceduresReducer = (
  state = scheduledProcedures,
  { type, data }
) => {
  switch (type) {
    case actionTypes.SCHEDULED_PROCEDURE_RESPONSE:
      return {
        ...state,
        ...{ proceduresData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return scheduledProcedures;
    default:
      return state;
  }
};
export const publishedProcedures = {
  proceduresData: [],
  count: [],
  results: [],
};

export const publishedProceduresReducer = (
  state = publishedProcedures,
  { type, data }
) => {
  switch (type) {
    case actionTypes.PUBLISHED_PROCEDURE_RESPONSE:
      return {
        ...state,
        ...{ proceduresData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return publishedProcedures;
    default:
      return state;
  }
};

export const childProcedures = {
  proceduresData: [],
  count: 0,
  results: [],
};

export const childProcedureReducer = (
  state = childProcedures,
  { type, data }
) => {
  switch (type) {
    case actionTypes.CHILD_PROCEDURE_RESPONSE:
      return {
        ...state,
        ...{ proceduresData: data.links },
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};

export const glossaryList = {
  glossaryData: [],
  count: 0,
  results: [],
};

export const glossaryReducer = (state = glossaryList, { type, data }) => {
  switch (type) {
    case actionTypes.GLOSSARY_LIST_RESPONSE:
      return {
        ...state,
        ...{ glossaryData: data.links },
        count: data.count,
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return glossaryList;
    default:
      return state;
  }
};

export const scheduledGlossary = {
  glossaryData: [],
  count: 0,
  results: [],
};

export const scheduledGlossaryReducer = (
  state = scheduledGlossary,
  { type, data }
) => {
  switch (type) {
    case actionTypes.SCHEDULED_GLOSSARY_RESPONSE:
      return {
        ...state,
        ...{ glossaryData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return scheduledGlossary;
    default:
      return state;
  }
};
export const publishedGlossary = {
  glossaryData: [],
  count: 0,
  results: [],
};

export const publishedGlossaryReducer = (
  state = publishedGlossary,
  { type, data }
) => {
  switch (type) {
    case actionTypes.PUBLISHED_GLOSSARY_RESPONSE:
      return {
        ...state,
        ...{ glossaryData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return publishedGlossary;
    default:
      return state;
  }
};

export const childQuotes = {
  count: 0,
  results: [],
};

export const childQuotesReducer = (state = childQuotes, { type, data }) => {
  switch (type) {
    case actionTypes.CHILD_QUOTES_RESPONSE:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    case actionTypes.RESET:
      return childQuotes;
    default:
      return state;
  }
};

export const publishedChildQuotes = {
  childquoteData: [],
  count: 0,
  results: [],
};
export const publishedChildReducer = (
  state = publishedChildQuotes,
  { type, data }
) => {
  switch (type) {
    case actionTypes.PUBLISHED_CHILD_RESPONSE:
      return {
        ...state,
        ...{ childquoteData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return publishedChildQuotes;
    default:
      return state;
  }
};

export const scheduledPodcast = {
  podcastData: [],
  count: [],
  results: [],
};
export const scheduledPodcastReducer = (
  state = scheduledPodcast,
  { type, data }
) => {
  switch (type) {
    case actionTypes.SCHEDULED_PODCAST_RESPONSE:
      return {
        ...state,
        ...{ podcastData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return scheduledPodcast;
    default:
      return state;
  }
};

export const healthHeroes = {
  count: 0,
  results: [],
};

export const healthHeroesReducer = (state = healthHeroes, { type, data }) => {
  switch (type) {
    case actionTypes.HEALTH_HEROES_RESPONSE:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};
export const publishedPodcast = {
  podcastData: [],
  count: [],
  results: [],
};

export const publishedPodcastReducer = (
  state = publishedPodcast,
  { type, data }
) => {
  switch (type) {
    case actionTypes.PUBLISHED_PODCAST_RESPONSE:
      return {
        ...state,
        ...{ podcastData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };

    case actionTypes.RESET:
      return publishedPodcast;
    default:
      return state;
  }
};

export const pictures = {
  count: 0,
  results: [],
};

export const picturesReducer = (state = pictures, { type, data }) => {
  switch (type) {
    case actionTypes.PICTURE_RESPONSE:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};

export const publishedHealthHeroes = {
  count: 0,
  results: [],
};

export const publishedHealthHeroesReducer = (state = publishedHealthHeroes, { type, data }) => {
  switch (type) {
    case actionTypes.PUBLISHED_HEALTH_HERO_RES:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};


export const scheduledPictures = {
  count: 0,
  results: [],
};

export const schedulePictureReducer = (state = scheduledPictures, { type, data }) => {
  switch (type) {
    case actionTypes.SCHEDULE_PICTURE_RES:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};


export const publishedPictures = {
  count: 0,
  results: [],
};

export const publishedPicturesReducer = (state = publishedPictures, { type, data }) => {
  switch (type) {
    case actionTypes.PUBLISH_PICTURE_RES:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};

export const scheduledWhatsGoingOnMe = {
  count: 0,
  results: [],
};

export const scheduledWhatsGoingOnMeReducer = (state = scheduledWhatsGoingOnMe, { type, data }) => {
  switch (type) {
    case actionTypes.SCHEDULED_WHATS_GOING_ON_ME_RES:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};

export const publishedWhatsGoingOnMe = {
  count: 0,
  results: [],
};

export const publishedWhatsGoingOnMeReducer = (state = publishedWhatsGoingOnMe, { type, data }) => {
  switch (type) {
    case actionTypes.PUBLISHED_WHATS_GOING_ON_ME_RES:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};
export const posts = {
  count: 0,
  results: [],
};
export const postsReducer = (state = posts, { type, data }) => {
  switch (type) {
    case actionTypes.COMMUNITY_POST_RESPONSE:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};

export const scheduledPosts = {
  count: 0,
  results: [],
};
export const scheduledPostsReducer = (state = scheduledPosts, { type, data }) => {
  switch (type) {
    case actionTypes.SCHEDULED_POST_RESPONSE:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};
export const publishedPosts = {
  count: 0,
  results: [],
};
export const publishedPostsReducer = (state = publishedPosts, { type, data }) => {
  switch (type) {
    case actionTypes.PUBLISHED_POST_RESPONSE:
      return {
        ...state,
        count: data.count,
        ...{ results: data.results },
      };
    default:
      return state;
  }
};


export const publishedProcedurePage = {
  pageNumber: 1,
};
export const getPublishedProcedurePageReducer = (
  state = publishedProcedurePage,
  { type, data }
) => {
  switch (type) {
    case actionTypes.GET_PUBLISH_PAGE:
      return {
        pageNumber: data,
      };

    case actionTypes.RESET:
      return publishedProcedurePage;
    default:
      return state;
  }
};

export const awaitingProcedurePage = {
  awaitingPageNumber: 1,
};
export const getAwaitingPageReducer = (
  state = awaitingProcedurePage,
  { type, data }
) => {
  switch (type) {
    case actionTypes.GET_AWAITING_PAGE:
      return {
        awaitingPageNumber: data,
      };

    case actionTypes.RESET:
      return awaitingProcedurePage;
    default:
      return state;
  }
};

export const scheduledProcedurePage = {
  scheduledPageNumber: 1,
};
export const getScheduledPageReducer = (
  state = scheduledProcedurePage,
  { type, data }
) => {
  switch (type) {
    case actionTypes.GET_SCHEDULED_PAGE:
      return {
        scheduledPageNumber: data,
      };

    case actionTypes.RESET:
      return scheduledProcedurePage;
    default:
      return state;
  }
};