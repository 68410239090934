import * as ActionTypes from "./actionTypes";

export const getAllHospitals = data => ({
  type: ActionTypes.GET_ALL_HOSPITALS,
  data,
});

export const allHospitalListResponse = data => ({
  type: ActionTypes.ALL_HOSPITALS_RESPONSE,
  data,
});

export const getHospitalDetails = data => ({
  type: ActionTypes.GET_HOSPITAL_DETAILS_RESPONSE,
  data,
});

export const getSearchedHospitals = (data, page_no) => ({
  type: ActionTypes.SEARCH_HOSPITALS,
  data,
  page_no,
});

export const addHospital = (data, clearData) => ({
  type: ActionTypes.ADD_HOSPITAL,
  data,
  clearData,
});

export const addHospitalSuccess = data => ({
  type: ActionTypes.HOSPITAL_ADD_SUCCESS,
  data,
});

export const addHealthHero = data => ({
  type: ActionTypes.ADD_HEALTH_HERO,
  data,
});

export const addCls = data => ({
  type: ActionTypes.ADD_CLS,
  data,
});

export const addClinician = data => ({
  type: ActionTypes.ADD_CLINICIAN,
  data,
});

export const getHospitalData = data => ({
  type: ActionTypes.GET_HOSPITAL_DETAILS,
  data,
});

export const updateHospital = data => ({
  type: ActionTypes.UPDATE_HOSPITAL,
  data,
});

export const deleteHospital = data => ({
  type: ActionTypes.DELETE_HOSPITAL,
  data,
});

export const filterHospital = (data, page_no) => ({
  type: ActionTypes.FILTER_HOSPITAL,
  data,
  page_no,
});

export const getFullHospitals = () => ({
  type: ActionTypes.GET_FULL_HOSPITALS,
});

export const fullHospitalsResponse = data => ({
  type: ActionTypes.FULL_HOSPITALS_RESPONSE,
  data,
});

export const resetHospitals = () => ({
  type: ActionTypes.RESET_HOSPITAL,
});

export const renewPlan = (id, data) => ({
  type: ActionTypes.RENEW_PLAN,
  id,
  data,
});

export const downloadUser = (id, name) => ({
  type: ActionTypes.DOWNLOAD_USER,
  id,
  name,
});

export const setEditHealthHero = data => ({
  type: ActionTypes.SET_EDIT_HEALTH_HERO,
  data,
});

export const editHealthHero = (id, data) => ({
  type: ActionTypes.EDIT_HEALTH_HERO,
  id,
  data,
});

export const deleteHealthHero = (id, hospital_id) => ({
  type: ActionTypes.DELETE_HEALTH_HERO,
  id,
  hospital_id,
});

export const multiplePictures = data => ({
  type: ActionTypes.MULTIPLE_PICTURES_ARRAY,
  data,
});

export const getHospitalCopyDetails = data => ({
  type: ActionTypes.GET_HOSPITAL_COPY_RESPONSE,
  data,
});