import React from "react";
import "./footer.css";

const Footer = props => {
  const { className } = props;
  return (
    <div className={className}>
      <p>
        {new Date().getFullYear()} Child Life On Call LLC. All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
