import React from "react";
import { Form, Modal } from "react-bootstrap";
import SaveButton from "../../../common/components/saveButton";
import {
  CROSS_ICON,
  ADD_USER_ICON,
  SUCCESS_ICON,
  DELETE_WARNING,
} from "../../../constant";
import DatePicker from "react-datepicker";
import moment from "moment";

const UserModal = props => {
  const {
    modal,
    handleClose,
    onChange,
    type,
    handleAddButton,
    addUser,
    success,
    handleEdit,
    isdelete,
    handleDelete,
    isRenew,
    planData,
    setPlanData,
    handleRenewPlan,
    copyHealthHero,
  } = props;

  return (
    <Modal show={modal} centered>
      <Modal.Body>
        <img
          src={CROSS_ICON}
          alt="close"
          className="close-icon"
          onClick={handleClose}
        />
        {addUser ? (
          <div>
            <img src={ADD_USER_ICON} alt="add-user" className="add-user-logo" />
            <h4 className="modal-select-info">
              Please select which hospital user do you want to add
            </h4>
            <Form noValidate className="choose-user-form">
              <Form.Check
                type="radio"
                name="type"
                value="cls"
                onChange={onChange}
                checked={type === "cls"}
                label="Cls"
                inline
              />
              <Form.Check
                type="radio"
                name="type"
                label="Clinician"
                value="clinician"
                onChange={onChange}
                checked={type === "clinician"}
                inline
              />
            </Form>
            <div style={{ marginLeft: "21px" }}>
              <button
                className="form-save-and-add-button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button className="form-save-button" onClick={handleAddButton}>
                Add Now
              </button>
            </div>
          </div>
        ) : null}

        {success ? (
          <div>
            <img src={SUCCESS_ICON} alt="add-user" className="add-user-logo" />
            <h2 className="congrats-message">Congratulations!</h2>
            <p className="congrats-para">
              You have successfully added the hospital to your list. Now you can
              add the Health Heroes and Hospital Users from the Hospital Details
              section
            </p>
            <div style={{ margin: "40px 21px 40px 21px" }}>
              <button
                className="form-save-and-add-button"
                onClick={handleClose}
              >
                I’ll Do It Later
              </button>
              <button className="form-save-button" onClick={handleEdit}>
                Add Now
              </button>
            </div>
          </div>
        ) : null
        }

        { copyHealthHero ?
          (<div>
            <img src={SUCCESS_ICON} alt="add-user" className="add-user-logo" />
            <h2 className="congrats-message">Congratulations!</h2>
            <p className="congrats-para">
            The hospital has been added successfully.
            </p>
            <div style={{ margin: "40px 21px 40px 21px" }}>
              <button
                className="form-save-and-copy-button"
                onClick={handleClose}
              >
                ok
              </button>
            </div>
          </div>)
          : null
        }


        {isdelete ? (
          <div>
            <img
              src={DELETE_WARNING}
              alt="add-user"
              className="add-user-logo"
            />
            <h2 className="congrats-message">Delete Warning!</h2>
            <p className="congrats-para">
              Once you delete this hospital you won’t be able to reverse this
              action, please make sure that the hospital you want to delete is
              correct.
            </p>
            <div style={{ margin: "40px 21px 40px 21px" }}>
              <button
                className="form-save-and-add-button"
                onClick={handleClose}
              >
                Go Back
              </button>
              <SaveButton
                className={"modal-delete-button"}
                submit={handleDelete}
                buttonName={"Delete"}
              />
            </div>
          </div>
        ) : null }

        {isRenew ? (
          <div>
            <p className="renew-modal_heading">Renew Plan</p>
            <hr></hr>
            <div>
              <Form noValidate>
                <Form.Group controlId="formBasicSubscriptionPlan">
                  <Form.Label className="common-form-label">
                    Subscription Plan<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="select"
                    className="renew-form-input img-class"
                    defaultValue={planData.tier_plan}
                    onChange={e => {
                      setPlanData({ ...planData, tier_plan: e.target.value });
                    }}
                  >
                    {props.plans &&
                      props.plans.map((plans, index) => {
                        return (
                          <option key={index} value={plans.id}>
                            {plans.type}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="common-form-label">
                    Start Date<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <br></br>
                  <DatePicker
                    selected={moment(planData.plan_start_date).toDate()}
                    onChange={date => {
                      setPlanData({
                        ...planData,
                        plan_start_date: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                    className="input-date-field-content-renew"
                    minDate={moment(planData.plan_start_date).toDate()}
                    dateFormat="MM/dd/yyyy"
                  />
                </Form.Group>
              </Form>

              <div style={{ float: "right" }}>
                <SaveButton
                  className={"form-save-button"}
                  submit={handleRenewPlan}
                  loader={props.loader}
                  buttonName={"Renew Plan"}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
