import React, { useState, useEffect } from "react";
import UserManagementNavbar from "../userManagementNavbar";
import { Container, Form, Row, Col } from "react-bootstrap";
import Back from "../../../common/components/back";

import "./userManagementParentChildDetails.css";
import "../UserManagementClinicianDetails/userManagementClinicianDetails.css";
import { ROUTES_PATH, RED_SPINNER, MESSAGE } from "../../../constant";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import Footer from "../../Footer";
import { Redirect } from "react-router";

import MultiSelect from "../multiSelect";
import { sensoryOptions } from "../../../utils/options";

import DeleteWarning from "../../commonComponent/deleteWarning";
import moment from "moment";
import { deleteParentService, deleteChildService } from "../../../modules/services";
import { toast } from "react-toastify";


const UserManagementParentChildDetails = (props) => {
  const [currentId, setCurrentId] = useState();
  const [show, setShow] = useState(false);
  const [childShow, setChildShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [parentData, setParentData] = useState({
    id: "",
    name: "",
    image: "",
    email: "",
    gender: "",
    birth_date: "",
    identify_as: "",
    hospital_name: "",
    hospital: "",
  });
  const [childData, setChildData] = useState([
    {
      id: "",
      name: "",
      avatar: "",
      avatar_id: "",
      gender: "",
      birth_date: "",
      medical_issues: [],
      sensory_needs: [],
    },
  ]);

  useEffect(() => {
    if (props.details.parent) {
      setParentData({
        ...parentData,
        ...props.details.parent,
        ...props.details,
      });
      setChildData(props.details.parent.children);
    } else {
      setParentData({
        ...parentData,
        ...props.details,
      });
    }
  }, [props.match.params.path]);

  const mapping = (array) => {
    if (array) {
      return array.map((str, index) => ({
        value: str,
        label: str,
      }));
    }
  };


  const handleParentDeleteButton = () => {
    setLoader(true);
    
    deleteParentService(parentData.id).then(() => {
      setLoader(false);
      props.history.push(ROUTES_PATH.USER_MANAGEMENT);
      toast.success(MESSAGE.PARENT_DELETE, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .catch((error) => {
      console.log(error);
      setLoader(false);
    });

  };

  const handleChildDeleteButton =  (currentId) => {

    setLoader(true);
    deleteChildService(currentId).then(() => {
      setLoader(false);
      setChildData(childData.filter((item) => item.id!== currentId));
      toast.success(MESSAGE.CHILD_DELETE, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .catch((error) => {
      console.log(error);
      setLoader(false);
    });

  };

  const handleParentClose = () => {
    setShow(false);
    handleParentDeleteButton();
  };
  const handleParentCancelClose = () => {
    setShow(false);
  };
  const handleParentShow = () => setShow(true);

  const handleChildClose = () => {
    setChildShow(false);
    handleChildDeleteButton(currentId);
  };

  const handleChildShow = (id) => {
    setChildShow(true);
    setCurrentId(id);
  };

  const handleChildCancelClose = () => {
    setChildShow(false);
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.USER_MANAGEMENT);
  };

  const handleActivateButton = () => {
    props.activation(parentData.id, {
      status: props.details.status === "ACTIVE" ? "DELETED" : "ACTIVE",
    });
  };

  let todays_date = new Date();
  todays_date = todays_date.toISOString().split("T")[0];

  return (
    <Container
      style={{ maxWidth: "1046px", minHeight: "100vh", position: "relative" }}
    >
      <Back
        Back={handleBack}
        backIconClassName={"parent-back-icon"}
        backNavItemClassName={"parent-back-nav"}
        className="parent-back"
      />

      <UserManagementNavbar
        title={"Parent Details"}
        isEditButton={true}
        activationLabel={
          props.details.status === "ACTIVE" ? "Deactivate" : "Activate"
        }
        isDeleteButton={true}
        handleDeleteButton={handleParentShow}
        id={"parent-nav-title"}
        className={"parent-navbar"}
        handleEditButton={handleActivateButton}
        loader={loader}
        loaderImg = {RED_SPINNER}
        loaderheight = {"30px"}
      />

      <Form noValidate>
        <Form.Group style={{ paddingTop: "7px" }}>
          <label htmlFor="upload-button">
            {parentData.image ? (
              <img
                src={`${parentData.image}`}
                alt=""
                className="preview-image"
              />
            ) : (
              <div className="preview-image-another">
                {parentData.name.toUpperCase()[0]}
              </div>
            )}
          </label>
          <input
            disabled={true}
            type="file"
            id="upload-button"
            style={{ display: "none" }}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Name</Form.Label>
              <Form.Control
                value={parentData.name}
                readOnly={true}
                className="parent-name-field"
                type="text"
                placeholder="Parent Name"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Email ID</Form.Label>
              <Form.Control
                value={parentData.email}
                readOnly={true}
                className="parent-name-field"
                type="email"
                placeholder="dummy@email.com"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Facility / Hospital Associated With
              </Form.Label>
              <Form.Control
                disabled={true}
                value={parentData.hospital_name}
                className="parent-name-field"
                type="text"
                placeholder="NA"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
                <Form.Label className="parent-name">
                  Phone Number
                </Form.Label>
                <Form.Control
                  readOnly
                  value={parentData.phone_number ? parentData.phone_number : "-"}
                  className="parent-name-field"
                  type="text"
                  placeholder="Parent"
                />
              </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Birth Date</Form.Label>
              <Form.Control
                value={
                  parentData.birth_date
                    ? moment(parentData.birth_date).format("MM/DD/YYYY")
                    : ""
                }
                readOnly={true}
                className="parent-name-field"
                max={todays_date}
                placeholder="MM/DD/YYYY"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                What Describes Me Best?
              </Form.Label>
              <Form.Control
                readOnly
                value={parentData.role ? parentData.role[0] : "Parent"}
                className="parent-name-field"
                type="text"
                placeholder="Parent"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">I Identify as a</Form.Label>
              <Form.Control
                value={parentData.identify_as}
                disabled={true}
                className="parent-name-field"
                type="text"
                placeholder="Female"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Pediatric Hospital</Form.Label>
              <Form.Control
                disabled={true}
                value={parentData.pediatric_hospital ? parentData.pediatric_hospital : "-"}
                className="parent-name-field"
                type="text"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            
            </Col>
          </Row>
      </Form>
      <DeleteWarning
        show={show}
        handleCancelClose={handleParentCancelClose}
        handleClose={handleParentClose}
      />

      {props.details.parent
        ? childData
          ? childData.map((child, index) => {
              let sensoryValue = mapping(child.sensory_needs);
              let medicalValue = mapping(child.medical_issues);

              return (
                <>
                  <div key={child.id}>
                    <UserManagementNavbar
                      title={"Child Details"}
                      isEditButton={false}
                      handleDeleteButton={handleChildShow}
                      deleteId={child.id}
                      isDeleteButton={true}
                      id={"child-navbar-title"}
                      className={"child-navbar-css"}
                      loader={loader}
                      loaderImg = {RED_SPINNER}
                      loaderheight = {"30px"}
                    />

                    <Form noValidate style={{ paddingBottom: "64px" }}>
                      <Form.Group style={{ paddingTop: "7px" }}>
                        <label htmlFor="upload-button">
                          {child.avatar ? (
                            <img
                              src={`${child.avatar}`}
                              alt=""
                              className="preview-image"
                            />
                          ) : (
                            <div className="preview-image-another">
                              {child.name.toUpperCase()[0]}
                            </div>
                          )}
                        </label>
                        <input
                          disabled={true}
                          type="file"
                          id="upload-button"
                          style={{ display: "none" }}
                        />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="parent-name">
                              Name
                            </Form.Label>
                            <Form.Control
                              readOnly={true}
                              value={child.name}
                              className="parent-name-field"
                              type="text"
                              placeholder="Child Name"
                            />
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="parent-name">
                              Gender
                            </Form.Label>
                            <Form.Control
                              disabled={true}
                              className="parent-name-field" //if you need dropdown icon just use img-class
                              value={child.gender}
                              type="text"
                              placeholder="Female"
                            />
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="parent-name">
                              Sensory Needs
                            </Form.Label>

                            <MultiSelect
                              multi={true}
                              disabled={true}
                              value={sensoryValue}
                              placeholder={"None"}
                              options={sensoryOptions}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="parent-name">
                              Birth Date
                            </Form.Label>
                            <Form.Control
                              readOnly={true}
                              value={
                                child.birth_date
                                  ? moment(child.birth_date).format(
                                      "MM/DD/YYYY"
                                    )
                                  : ""
                              }
                              className="parent-name-field"
                              max={todays_date}
                              placeholder="MM/DD/YYYY"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </>
              );
            })
          : null
        : null}
      <DeleteWarning
        show={childShow}
        handleCancelClose={handleChildCancelClose}
        handleClose={handleChildClose}
      />
      <Footer className={"parent-child-footer"} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  try {
    return {
      details: state.userManagementDetails.details,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,

      deleteParent: actionCreator.deleteParent,
      deleteChild: actionCreator.deleteChild,
      activation: actionCreator.activation,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementParentChildDetails);
