import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObjects";

const initialState = {
    data: [],
};

export default (state = initialState, { type, data }) => {

    switch (type) {
        case actionTypes.SUBSCRIPTION_PLANS_RESPONSE:
            return updateObject(state,
                { data: [...data.data] });
        default:
            return state;
    }
};