import * as actionTypes from "../actions/actionTypes";

export const ParentChildinitialState = {
  ParentChildData: [],
  count: [],
  results: [],
  allParentChildData: [],
};

export const userManagementParentChildListResponse = (
  state = ParentChildinitialState,
  { type, data }
) => {
  switch (type) {
    case actionTypes.ALL_PARENT_CHILD_LIST_RESPONSE:
      return {
        ...state,
        ...{ ParentChildData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
        allParentChildData: [...state.allParentChildData, ...data.results],
      };
    case actionTypes.RESET:
      return ParentChildinitialState;
    default:
      return state;
  }
};

const ClsinitialState = {
  ClsData: [],
  count: [],
  results: [],
  allClsData: [],
};

export const userManagementClsListResponse = (
  state = ClsinitialState,
  { type, data }
) => {
  switch (type) {
    case actionTypes.ALL_CLS_LIST_RESPONSE:
      return {
        ...state,
        ...{ ClsData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
        allClsData: [...state.allClsData, ...data.results],
      };
    case actionTypes.RESET:
      return ClsinitialState;
    default:
      return state;
  }
};

const ClinicianinitialState = {
  ClinicianData: [],
  count: [],
  results: [],
  allClinicianData: [],
};

export const userManagementClinicianListResponse = (
  state = ClinicianinitialState,
  { type, data }
) => {
  switch (type) {
    case actionTypes.ALL_CLINICAN_LIST_RESPONSE:
      return {
        ...state,
        ...{ ClinicianData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
        allClinicianData: [...state.allClinicianData, ...data.results],
      };
    case actionTypes.RESET:
      return ClinicianinitialState;
    default:
      return state;
  }
};

const userManagementDetailsInitialState = {
  details: {},
};

export const userManagementDetails = (
  state = userManagementDetailsInitialState,
  { type, data }
) => {
  switch (type) {
    case actionTypes.STORE_USER_MANAGEMENT_DETAILS:
      return {
        ...state,
        ...{ details: data },
      };
    case actionTypes.RESET:
      return userManagementDetailsInitialState;
    default:
      return state;
  }
};

const searchInitial = {
  searchData: {},
  count: [],
  results: [],
};

export const searchReducer = (state = searchInitial, { type, data }) => {
  switch (type) {
    case actionTypes.SEARCH_RESPONSE:
      return {
        ...state,
        ...{ searchData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };
    case actionTypes.RESET:
      return searchInitial;
    default:
      return state;
  }
};

const filterInitial = {
  filterData: {},
  count: [],
  results: [],
};

export const filterReducer = (state = filterInitial, { type, data }) => {
  switch (type) {
    case actionTypes.FILTER_RESPONSE:
      return {
        ...state,
        ...{ filterData: data.links },
        ...{ count: data.count },
        ...{ results: data.results },
      };
    case actionTypes.RESET:
      return filterInitial;
    default:
      return state;
  }
};

const filterValueInitial = {
  filterValue: {},
  userNavData: "",
};

export const filterValueReducer = (state = filterValueInitial, { type, data }) => {
  // console.log('filterValueReducer',data?.navData);
  switch (type) {
    case actionTypes.USER_FILTER_VALUE:
      return {
        ...state,
        ...{ filterValue: data.filterValue },
      };
    case actionTypes.GET_USER_NAVBAR_VALUE:
      return {
        ...state,
        ...{ userNavData: data.navdata },
      };
    case actionTypes.RESET_FILTER:
      return filterValueInitial;
    case actionTypes.RESET:
      return filterValueInitial;
    default:
      return state;
  }
};
