import React, { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { S3Client } from "../../utils/upload";
import {
  PARENT_CHILD_DETAILS_PROFILE_ICON,
  UPLOAD_SMALL_ICON,
} from "../../constant";

const UploadImage = props => {
  const [loader, setLoader] = useState(false);

  const ImageInput = useRef(null);
  const handleUploadImage = e => {
    ImageInput.current.click();
  };

  const profileUpload = e => {
    setLoader(true);
    S3Client.uploadFile(e.target.files[0])
      .then(res => {
        setLoader(false);
        props.setData({ ...props.data, image: res.location });
        e.target.files = null;
      })
      .catch(err => console.error(err));
  };
  return (
    <div>
      <p className="common-form-label">Upload Photo</p>

      <div style={{marginBottom:"41px"}}>
        {!props.data.image ? (
          <>
            <div className="profile-image-view">
              {!loader ? (
                <img
                  src={PARENT_CHILD_DETAILS_PROFILE_ICON}
                  alt=""
                  onClick={handleUploadImage}
                ></img>
              ) : (
                <Spinner
                  animation="border"
                  style={{ margin: "35px 0 0 35px" }}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <img
              src={props.data.image}
              alt=""
              className="preview-image"
              onClick={handleUploadImage}
            />
            <img src={UPLOAD_SMALL_ICON} id="preview-image2" alt="" />
          </>
        )}
      </div>
      <input
        type="file"
        ref={ImageInput}
        style={{ display: "none" }}
        onChange={profileUpload}
      />
    </div>
  );
};

export default UploadImage;
