import React, { useState } from "react";
import { Form, Row, Col, Container, Alert } from "react-bootstrap";
import "./resetPassword.css";
import CommonButton from "../commonComponent/commonButton";
import CommonLogoComponent from "../commonComponent/commonLogoComponent";
import { ROUTES_PATH, MESSAGE } from "../../constant";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../utils/validations";
import { getUserResetPasswordUrl } from "../../utils/urls";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { EYE_OFF, EYE_OPEN } from "../../constant/images";

const ResetPassword = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [showPassword, togglePassword] = useState(false);
  const [showConfirmPassword, toggleConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [data, setData] = useState({
    password: "",
  });

  const handleSignIn = () => {
    props.history.push(ROUTES_PATH.LOGIN);
  };
  const passwordToggle = () => togglePassword((prevState) => !prevState);

  const passwordConfirmToggle = () =>
    toggleConfirmPassword((prevState) => !prevState);

  const handleResetPassword = () => {
    setSubmittedOnce(true);
    setDisabled(true);
    setShowLoader(true);
    let validatedPassword = validatePassword(data.password);
    let validatedConfirmPassword = validateConfirmPassword(
      data.password,
      confirmPassword
    );
    if (!validatedPassword) {
      if (validatedConfirmPassword) {
        setConfirmPasswordInvalid(false);
      }
      setPasswordInvalid(true);
      setDisabled(false);
      setShowLoader(false);
      return;
    }
    if (!validatedConfirmPassword) {
      if (validatedPassword) {
        setPasswordInvalid(false);
      }
      setConfirmPasswordInvalid(true);
      setDisabled(false);
      setShowLoader(false);
      return;
    }
    if (validatedPassword && validatedConfirmPassword) {
      setPasswordInvalid(false);
      setConfirmPasswordInvalid(false);
      setDisabled(true);
      setShowLoader(true);
      axios
        .put(getUserResetPasswordUrl, {
          email: props.verifyOtp.email,
          password: data.password,
        })
        .then((response) => {
          if (response.data.status_code === 200) {
            setResetSuccess(true);
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setDisabled(false);
            setShowLoader(false);
          } else {
            setDisabled(false);
            setShowLoader(false);
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          setDisabled(false);
          setShowLoader(false);
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!disabled) {
        handleResetPassword();
      }
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="px-0 mb-0">
            <CommonLogoComponent />
          </Col>
          <Col className="px-0">
            {!resetSuccess ? (
              <Form className="reset-main" noValidate>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="reset-welcome">
                    {MESSAGE.RESET_PASSWORD}
                  </Form.Label>
                  {/* <Form.Label className="resetPassword-message-label-css">
                    {MESSAGE.RESET_PASSWORD_LABEL}
                  </Form.Label> */}
                  <Form.Control
                    className="reset-input"
                    type={showPassword ? "text" : "password"}
                    placeholder={MESSAGE.ENTER_NEW_PASSWORD}
                    onKeyPress={handleKeyPress}
                    isInvalid={passwordInvalid}
                    onChange={(e) => {
                      setData({ ...data, password: e.target.value });
                      if (submittedOnce) {
                        setPasswordInvalid(!validatePassword(e.target.value));
                        setConfirmPasswordInvalid(
                          !validateConfirmPassword(
                            e.target.value,
                            confirmPassword
                          )
                        );
                      } else {
                        setPasswordInvalid(false);
                      }
                    }}
                  />
                  <img
                    className={
                      showPassword
                        ? "resetPassword-eye-icon-css"
                        : "resetPassword-eye-icon-off-css"
                    }
                    onClick={passwordToggle}
                    src={showPassword ? EYE_OPEN : EYE_OFF}
                    alt=" "
                  />
                  <Form.Control.Feedback
                    className="reset-invalid-password"
                    type="invalid"
                  >
                    {MESSAGE.PASSWORD_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Control
                  className="reset-confirm"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={MESSAGE.ENTER_CONFIRM_NEW_PASSWORD}
                  onKeyPress={handleKeyPress}
                  isInvalid={confirmPasswordInvalid}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (submittedOnce) {
                      setConfirmPasswordInvalid(
                        !validateConfirmPassword(data.password, e.target.value)
                      );
                    } else {
                      setConfirmPasswordInvalid(false);
                    }
                  }}
                />
                <img
                  className={
                    showConfirmPassword
                      ? "resetConfirmPassword-eye-icon-css "
                      : "resetConfirmPassword-eye-icon-off-css"
                  }
                  onClick={passwordConfirmToggle}
                  src={showConfirmPassword ? EYE_OPEN : EYE_OFF}
                  alt=" "
                />
                <Form.Control.Feedback
                  className="reset-confirm-label"
                  type="invalid"
                >
                  {MESSAGE.CONFIRM_PASSWORD_MATCH}
                </Form.Control.Feedback>
                <CommonButton
                  buttonValue={MESSAGE.SAVE_PASSWORD}
                  className={"common-button-css"}
                  showLoader={showLoader}
                  disabled={disabled}
                  handleButtonAction={handleResetPassword}
                ></CommonButton>
              </Form>
            ) : (
              <>
                <Alert className="reset-success" variant="success">
                  <Alert.Heading className="reset-success-welcome">
                    Success!
                  </Alert.Heading>
                  <Alert.Heading className="reset-success-message">
                    {MESSAGE.RESET_PASSWORD_SUCCESS_MESSAGE_LABEL}
                  </Alert.Heading>
                  <p style={{ textAlign: "center" }}>
                    {MESSAGE.RESET_PASSWORD_SUCCESS_LABEL}
                  </p>
                </Alert>
                <CommonButton
                  handleButtonAction={handleSignIn}
                  buttonValue={"Login"}
                  className={"common-button-css"}
                  showLoader={showLoader}
                  disabled={disabled}
                ></CommonButton>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  try {
    if (state.verifyOtpInformation) {
      return {
        verifyOtp: state.verifyOtpInformation.verifyOtp,
      };
    }
  } catch (e) {
    console.log(e);
  }
};
export default connect(mapStateToProps, null)(ResetPassword);
