import React from "react";
import { MESSAGE } from "../../constant";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";

const DateField = (props) => {
  const { show, setShow, setSelectedDate, selectedDate } = props;
  return (
    <>
      <div style={{ marginTop: "40px" }}>
        <label className="switch">
          <p className="scheduled-publish">Scheduled Publish</p>
          <input type="checkbox" onChange={setShow} />
          <span className="slider round"></span>
        </label>

        {show ? (
          <Form.Group controlId="formGroupEmail">
            <Form.Label className="modal-content-label">Date</Form.Label>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              placeholderText="Set Date"
              selected={selectedDate}
              minDate={new Date()}
              onChange={setSelectedDate}
              className={
                selectedDate
                  ? "input-date-field-content"
                  : "input-date-field-content1"
              }
            />
            {!selectedDate && (
              <div className="clinician-invalid">{MESSAGE.DATE_INVALID}</div>
            )}
          </Form.Group>
        ) : null}
      </div>
    </>
  );
};
export default DateField;
