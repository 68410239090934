export const MESSAGE = {
  //validation messages
  WELCOME: "Welcome",
  EMAIL_INVALID: "Please enter valid email address",
  EMPTY_FIELD: "This field cannot be empty",
  PASSWORD_NEW_INVALID: "Please enter valid password",
  PASSWORD_INVALID:
    "Password must contain : 8-15 characters with at least 1 number, 1 Special character and 1 Uppercase letter.",
  CONFIRMPASSWORD_INVALID: "Confirm password should be same as new password.",
  CONTACT_INVALID: "Please enter the valid phone number.",
  NAME_INVALID: "Please enter valid name.",
  GENDER_INVALID: "Please enter valid gender.",
  BIRTH_DATE_INVALID: "Please enter valid birth date",
  AREA_OF_INTEREST_INVALID: "Please enter valid areas of interest.",
  TYPE_OF_WORK_INVALID: "Please enter valid type of work.",
  PHONE_NUMBER_INVALID: "Please enter valid phone number",
  UNIT_INVALID: "Please enter valid unit",
  OTP_INVALID: "Please enter valid OTP",
  CERTIFICATION_INVALID: "Please enter valid certification id.",
  PROVIDER_NAME_INVALID: "Please enter valid provider name.",
  HOSPITAL_INVALID: "Please enter valid hospital.",
  IDENTITY_INVALID: "Please enter valid identity.",
  SENSORY_INVALID: "Please enter valid sensory needs.",
  MEDICAL_ISSUES_INVALID: "Please enter valid medical issues.",
  URL_INVALID: "please enter valid URL",
  IMAGE_LESS_THAN: "Image size must be less than 5MB.",
  MEDIA_SHOULD_BE: "File should be image or video.",

  Fax_INVALID:
    "Fax number format must be +country_code[1-3 digit](area_code)[3-digit]fax_number[7-digit] e.g +1(212)9876543",
  USER_NAME_INVALID: "User name does not allow blank space.",
  CODE_INVALID: "Please enter the code.",
  FILE_VALIDATION: "Only allows CSV file type.",
  GROUP_INVALID: "User not allowed.",
  BLANK_SPACE_INVALID: "Blank sapces at the start and end are not allowed.",
  DEGREE_INVALID: "Please enter the valid degree name.",
  SUBSPECIALITY_INVALID: "Please enter the valid Subspeciality.",
  INSTITUTION_INVALID: "Please enter the valid name of Institution.",
  ADDRESS_INVALID: "Please enter the valid Address.",
  TITLE_INVALID: "Please enter the valid Title.",
  TIER_PLAN_INVALID: "Please select valid tier plan",
  UNIQUE_CODE_INVALID: "Please enter valid unique code",
  LOGO_INVALID: "Logo can not be empty",
  PICTURE_INVALID: "Pictures can not be empty",
  DESCRIPTION_INVALID: "Description can not be empty",
  

  //labels
  SIGN_IN: "Sign In",
  SIGN_IN_LABEL: "Log In to your Account",
  WELCOME_LABEL: "Welcome",
  EMAIL: "Enter Email",
  PASSWORD: "Password",
  ENTER_NEW_PASSWORD: "Enter New Password",
  ENTER_CONFIRM_NEW_PASSWORD: "Enter Confirm Password",
  USER_NAME: "User Name",
  CODE: "Code",
  SEND_OTP: "Send OTP",
  SAVE_PASSWORD: "Save Password",
  VERIFY_OTP: "Verify OTP",
  FORGOT_PASSWORD: "Forgot Password?",
  ENTER_OTP_LABEL: "Enter the OTP received on your email",
  INVALID_OTP: "Invalid OTP",
  FORGOT_PASSWORD_LABEL1: "Forgot Password",
  FORGOT_PASSWORD_LABEL: "Enter your email to receive OTP",
  RETURN_TO_LABEL: "Return to",
  FRGOT_PASSWORD_SUCCESS_LABEL: "Please check your email to get your code.",
  RESET_PASSWORD: "Create New Password",
  RESET_PASSWORD_LABEL: "Enter your new Password",
  RESET_PASSWORD_SUCCESS_MESSAGE_LABEL:
    "Your Password has been Reset Successfully.",
  CONFIRM_PASSWORD_MATCH: "Password and Confirm Password does not match",
  RESET_PASSWORD_SUCCESS_LABEL:
    "You can click the login button below and Sign in with the new Password.",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
  OLD_PASSWORD: "Old Password",
  LOGOUT_MESSAGE: "Are you sure you want to sign out?",
  CHANGE_PASSWORD: "Change Password",
  EDIT_PROVIDER: "Edit Provider",
  EDIT_CLIENT: "Edit Client",
  LOGOUT: "Sign Out",
  NAME: "Name",
  CANCEL: "CANCEL",
  DEGREE: "Degree",
  INSTITUTION: "Institution",
  SUBSPECIALITY: "Subspeciality",
  OFFICE_PHONE: "Office Phone",
  OFFICE_FAX: "Office Fax",
  BIRTH_DATE: "Birth Date",
  ADDRESS: "Address",

  DOB: "Date Of Birth",
  GENDER: "Gender",

  //input placeholders
  EMAIL_PLACEHOLDER: "Enter Email",
  USER_NAME_PLACEHOLDER: "Enter Your Account User Name",
  CODE_PLACEHOLDER: "Enter Your Code Get By Email",
  PASSWORD_PLACEHOLDER: "Enter Password",
  NEW_PASSWORD_PLACEHOLDER: "Enter New Password",
  CONFIRM_PASSWORD_PLACEHOLDER: "Confirm Password",

  //success and failuer messages
  CHANGE_PASS_SUCCESS: "Password changed successfully.",
  CHANGES_SAVED: "Changes saved",

  //User management

  //Subscription plan
  TYPE_INVALID: "Please enter valid  type",
  DURATION_INVALID: "Please enter valid duration",

  AUTHOR_INVALID: "Please enter valid author name",
  QUOTE_INVALID: "Please enter valid quote",
  DATE_INVALID: "Please enter valid date",
  PROCEDURE_INVALID: "Please enter valid procedure",
  SEQUENCE_INVALID: "Please enter valid sequence",
  ADVOCATE_INVALID: "Please enter valid text",
  DESCRIPTION_INVALID: "Please enter valid description",
  TERM_INVALID: "Please enter valid term",
  MEANING_INVALID: "Please enter valid glossary term meaning",
  STORY_INVALID: "Please enter valid story",
  REASON_INVALID: "Please enter valid reason",
  UPLOAD_PROGRESS:
    "Uploading is in progress. Please wait till uploading finishes!",
  DOWNLOAD_CSV : "Download CSV",
  UPLOAD_IMAGE_VIDEO : "Please upload atleast one image or video.",
  MAX_MEDIA_LIMIT : "You cannot upload more than 10 images and videos combined",
  UPLOAD_COVER_IMAGE : "Please upload cover image.",
  CLINICIAN_DELETE:"Clinician deleted successfully.",
  CLS_DELETE: "CLS deleted successfully.",
  CHILD_DELETE: "Child deleted successfully.",
  PARENT_DELETE: "Parent deleted successfully."
};
