import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";
import { ROUTES_PATH } from "../../constant";
import * as actionCreator from "../../modules/actions";
import Footer from "../Footer";
import NavBar from "../NavBar";
import "./contentApproval.css";
import Back from "../../common/components/back";
import { rename, approveStatus, newRename } from "../../utils/rename";
import ContentTable from "../ContentManagement/ContentTable/contentTable";

const ContentApproval = (props) => {
  const handleBack = () => props.history.push(ROUTES_PATH.HOME);
  //headers for procedure
  const procedureHeader = {
    headerOne: "Procedure Name",
    headerTwo: "Uploaded By",
    headerThree: "Actions",
  };
  //header for glossary
  const glossaryHeader = {
    headerOne: "Glossary Name",
    headerTwo: "Uploaded By",
    headerThree: "Actions",
  };
  //headers for child quotes
  const quotesHeader = {
    headerOne: "Quotes",
    headerTwo: "User",
    headerThree: "Actions",
  };
  //headers for health heroes
  const healthHerosHeader = {
    headerOne: "Health Hero Name",
    headerTwo: "Speicalization",
    headerThree: "Actions",
  };
  //headers for pictures
  const picturesHeader = {
    headerOne: "Picture Title",
    headerTwo: "Description",
    headerThree: "Actions",
  };
  //headers for other Resources
  const resourcesHeader = {
    headerOne: "Resource Title",
    headerTwo: "Uploaded By",
    headerThree: "Actions",
  };

  const [toggleState, setToggleState] = useState(1);
  const [parentProcedure, setParentProcedure] = useState([]);
  const [childProcedure, setChildProcedures] = useState([]);
  const [parentGlossary, setParentGlossary] = useState([]);
  const [childQuotes, setChildQuotes] = useState([]);
  const [healthHeroesList, setHealthHeroesList] = useState([]);
  const [picturesList, setPicturesList] = useState([]);
  const [resourcesList, setResourcesList] = useState([])
  const [parentPage, setParentPage] = useState(1);
  const [childPage, setChildPage] = useState(1);
  const [glossaryPage, setGlossaryPage] = useState(1);
  const [quotesPage, setQuotesPage] = useState(1);
  const [healthHerosPage, setHealthHerosPage] = useState(1);
  const [picturesPage, setPicturesPage] = useState(1);
  const [resourcePage, setResourcesPage] = useState(1)

  //Use effect to get the data when the page is load
  useEffect(() => {
    props.toggleMainLoader(true);
    props.getProcedures("AWAITING", 1, { for_role: "PARENT" });
    props.getChildProcedure("AWAITING", 1, { for_role: "CHILD" });
    props.getGlossaryList("AWAITING", 1);
    props.getChildQuotes("AWAITING", 1);
    props.getHealthHeroes("AWAITING", 1);
    props.getPictures("AWAITING", 1);
    props.getResources("AWAITING", 1);
  }, []);

  //useEffect to refresh the data when an action in called
  useEffect(() => {
    props.toggleMainLoader(true);
    props.getProcedures("AWAITING", parentPage, { for_role: "PARENT" });
    props.getChildProcedure("AWAITING", childPage, { for_role: "CHILD" });
    props.getGlossaryList("AWAITING", glossaryPage);
    props.getChildQuotes("AWAITING", quotesPage);
    props.getHealthHeroes("AWAITING", healthHerosPage);
    props.getPictures("AWAITING", picturesPage);
    props.getResources("AWAITING", resourcePage);
    props.successResponse(false);
  }, [props.responseSuccess]);

  useEffect(() => {
    setParentProcedure(
      rename(
        props.procedures.results,
        "cellOne",
        "cellTwo",
        "name",
        "uploaded_by"
      )
    );
  }, [props.procedures.results]);

  useEffect(() => {
    setChildProcedures(
      rename(
        props.childProcedures.results,
        "cellOne",
        "cellTwo",
        "name",
        "uploaded_by"
      )
    );
  }, [props.childProcedures.results]);

  useEffect(() => {
    setParentGlossary(
      rename(
        props.glossaryList.results,
        "cellOne",
        "cellTwo",
        "term",
        "uploaded_by"
      )
    );
  }, [props.glossaryList.results]);

  useEffect(() => {
    setChildQuotes(
      rename(
        props.childQuotes.results,
        "cellOne",
        "cellTwo",
        "quote",
        "child_name"
      )
    );
  }, [props.childQuotes.results]);

  useEffect(() => {
    setHealthHeroesList(
      rename(
        props.healthHeroes.results,
        "cellOne",
        "cellTwo",
        "name",
        "specialization"
      )
    );
  }, [props.healthHeroes.results]);

  useEffect(() => {
    setResourcesList(
      newRename(
        props.resources.results,
        "title",
        "author",
      )
    );
  }, [props.resources.results]);

  useEffect(() => {
    setPicturesList(
      rename(props.pictures.results, "cellOne", "cellTwo", "term", "meaning")
    );
  }, [props.pictures.results]);

  //Parent Functions start
  const parentPageChange = (select) => {
    setParentPage(select);
    props.toggleMainLoader(true);
    props.getProcedures("AWAITING", select, { for_role: "PARENT" });
  };

  const ParentProcedureApprove = (index) => {
    const status = approveStatus(
      props.procedures.results.filter((item) => item.id === index)[0]
        .scheduled_date
    );
    props.approveProcedure(index, { status: status });
  };

  const ParentProcedureReject = (index) => {
    props.approveProcedure(index, { status: "REJECT" });
  };

  //Parent function end

  //Child Function start
  const childPageChange = (select) => {
    setChildPage(select);
    props.toggleMainLoader(true);
    props.getChildProcedure("AWAITING", select, { for_role: "CHILD" });
  };
  const ChildProcedureApprove = (index) => {
    const status = approveStatus(
      props.childProcedures.results.filter((item) => item.id === index)[0]
        .scheduled_date
    );
    props.approveProcedure(index, { status: status });
  };
  const ChildProcedureReject = (index) => {
    props.approveProcedure(index, { status: "REJECT" });
  };
  //Child Function End

  //Parent Glossary Function start
  const glossaryPageChange = (select) => {
    setGlossaryPage(select);
    props.toggleMainLoader(true);
    props.getGlossaryList("AWAITING", select);
  };

  const glossaryApprove = (index) => {
    const status = approveStatus(
      props.glossaryList.results.filter((item) => item.id === index)[0]
        .scheduled_date
    );
    props.glossaryAction(index, { status: status });
  };

  const glossaryReject = (index) => {
    props.glossaryAction(index, { status: "REJECT" });
  };
  //glossary function end

  //ChildQuotes Function Start
  const quotesPageChange = (select) => {
    setQuotesPage(select);
    props.toggleMainLoader(true);
    props.getChildQuotes("AWAITING", select);
  };

  const quotesApprove = (index) => {
    const status = approveStatus(
      props.childQuotes.results.filter((item) => item.id === index)[0]
        .scheduled_date
    );
    props.childQuotesAction(index, { status: status });
  };

  const quotesReject = (index) => {
    props.childQuotesAction(index, { status: "REJECT" });
  };
  //child quotes function end

  //HealthHeroes Function Start
  const healthHeroesPageChange = (select) => {
    setHealthHerosPage(select);
    props.toggleMainLoader(true);
    props.getHealthHeroes("AWAITING", select);
  };

  const healthHeroesApprove = (index) => {
    props.healthHeroesAction(index, { status: "PUBLISHED" });
  };

  const healthHeroesReject = (index) => {
    props.healthHeroesAction(index, { status: "REJECT" });
  };
  //HealthHeroes  function end

  //Pictures Function Start
  const picturesPageChange = (select) => {
    setPicturesPage(select);
    props.toggleMainLoader(true);
    props.getPictures("AWAITING", select);
  };

  const picturesApprove = (index) => {
    const status = approveStatus(
      props.pictures.results.filter((item) => item.id === index)[0]
        .scheduled_date
    );
    props.picturesAction(index, { status: status });
  };

  const picturesReject = (index) => {
    props.picturesAction(index, { status: "REJECT" });
  };
  //pictures  function end

  //resources function start

  const resourcesChangePage = (select) => {
    setResourcesPage(select);
    props.toggleMainLoader(true);
    props.getResources("AWAITING", select);
  };

  const resourceApprove = (index) => {
    props.approveResources(index, { status: "PUBLISHED" });
  };

  const resourceReject = (index) => {
    props.approveResources(index, { status: "REJECT" });
  };
  //resources function end

  return (
    <Fragment>
      <Container className="common-container">
        <Back
          className={"back-nav"}
          backNavItemClassName={"back-nav-item"}
          backIconClassName={"back-icon"}
          isSearch={false}
          isDelete={false}
          isSave={false}
          Back={handleBack}
        />
        <NavBar
          title={"Content Awaiting Approval"}
          id={"SubscriberManagementTitle-css"}
          className={"main-navbar"}
        />
        <div className="head-nav">
          <h3
            className={
              toggleState === 1 ? "nav-content active-content" : "nav-content"
            }
            onClick={() => {
              setToggleState(1);
            }}
          >
            Procedures
          </h3>
          <h3
            className={
              toggleState === 2 ? "nav-content active-content" : "nav-content"
            }
            onClick={() => {
              setToggleState(2);
            }}
          >
            Glossary
          </h3>
          <h3
            className={
              toggleState === 3 ? "nav-content active-content" : "nav-content"
            }
            onClick={() => {
              setToggleState(3);
            }}
          >
            What Other Kids Say
          </h3>
          <h3
            className={
              toggleState === 4 ? "nav-content active-content" : "nav-content"
            }
            onClick={() => {
              setToggleState(4);
            }}
          >
            Health Heroes
          </h3>
          <h3
            className={
              toggleState === 5 ? "nav-content active-content" : "nav-content"
            }
            onClick={() => {
              setToggleState(5);
            }}
          >
            Pictures
          </h3>
          <h3
            className={
              toggleState === 6 ? "nav-content active-content" : "nav-content"
            }
            onClick={() => {
              setToggleState(6);
            }}
          >
            Other Resources
          </h3>
        </div>

        <div
          style={{ margin: "16px" }}
          className={toggleState === 1 ? "content-active" : "content-disable"}
        >
          <ContentTable
            mainHeader={"Parent Content Awaiting Approval"}
            header={procedureHeader}
            dataList={parentProcedure}
            onApprove={ParentProcedureApprove}
            onReject={ParentProcedureReject}
            itemCountFromServer={props.procedures.count}
            rowPerPage={10}
            pageNumber={parentPage}
            changePage={parentPageChange}
            minHeightClass={"procedures-minHeight"}
            contentHeaderOne={"content-header1"}
            contentHeaderTwo={"procedures-header2"}
            contentHeaderThree={"procedures-header3"}
            contentCellOne={"procedures-cell1"}
            contentCellTwo={"procedures-cell2"}
            contentCellThree={"procedures-cell3"}
            viewCellClass={"view-cell-class"}
          />
          <div style={{ marginTop: "40px" }}>
            <ContentTable
              mainHeader={"Child Content Awaiting Approval"}
              header={procedureHeader}
              dataList={childProcedure}
              onApprove={ChildProcedureApprove}
              onReject={ChildProcedureReject}
              itemCountFromServer={props.childProcedures.count}
              rowPerPage={10}
              pageNumber={childPage}
              changePage={childPageChange}
              minHeightClass={"procedures-minHeight"}
              contentHeaderOne={"content-header1"}
              contentHeaderTwo={"procedures-header2"}
              contentHeaderThree={"procedures-header3"}
              contentCellOne={"procedures-cell1"}
              contentCellTwo={"procedures-cell2"}
              contentCellThree={"procedures-cell3"}
              viewCellClass={"view-cell-class"}
            />
          </div>
        </div>

        <div
          style={{ margin: "16px" }}
          className={toggleState === 2 ? "content-active" : "content-disable"}
        >
          <ContentTable
            mainHeader={"Parent Content Awaiting Approval"}
            header={glossaryHeader}
            dataList={parentGlossary}
            onApprove={glossaryApprove}
            onReject={glossaryReject}
            itemCountFromServer={props.glossaryList.count}
            rowPerPage={10}
            pageNumber={glossaryPage}
            changePage={glossaryPageChange}
            minHeightClass={"procedures-minHeight"}
            contentHeaderOne={"content-header1"}
            contentHeaderTwo={"procedures-header2"}
            contentHeaderThree={"procedures-header3"}
            contentCellOne={"procedures-cell1"}
            contentCellTwo={"procedures-cell2"}
            contentCellThree={"procedures-cell3"}
            viewCellClass={"view-cell-class"}
          />
        </div>
        <div
          style={{ margin: "16px" }}
          className={toggleState === 3 ? "content-active" : "content-disable"}
        >
          <ContentTable
            mainHeader={"Child Content Awaiting Approval"}
            header={quotesHeader}
            dataList={childQuotes}
            onApprove={quotesApprove}
            onReject={quotesReject}
            itemCountFromServer={props.childQuotes.count}
            rowPerPage={10}
            pageNumber={quotesPage}
            changePage={quotesPageChange}
            minHeightClass={"procedures-minHeight"}
            contentHeaderOne={"content-header1"}
            contentHeaderTwo={"procedures-header2"}
            contentHeaderThree={"procedures-header3"}
            contentCellOne={"procedures-cell1"}
            contentCellTwo={"procedures-cell2"}
            contentCellThree={"procedures-cell3"}
            viewCellClass={"view-cell-class"}
          />
        </div>

        <div
          style={{ margin: "16px" }}
          className={toggleState === 4 ? "content-active" : "content-disable"}
        >
          <ContentTable
            mainHeader={"Content Awaiting Approval"}
            header={healthHerosHeader}
            dataList={healthHeroesList}
            onApprove={healthHeroesApprove}
            onReject={healthHeroesReject}
            itemCountFromServer={props.healthHeroes.count}
            rowPerPage={10}
            pageNumber={healthHerosPage}
            changePage={healthHeroesPageChange}
            minHeightClass={"procedures-minHeight"}
            contentHeaderOne={"content-header1"}
            contentHeaderTwo={"procedures-header2"}
            contentHeaderThree={"procedures-header3"}
            contentCellOne={"procedures-cell1"}
            contentCellTwo={"procedures-cell2"}
            contentCellThree={"procedures-cell3"}
            viewCellClass={"view-cell-class"}
          />
        </div>

        <div
          style={{ margin: "16px" }}
          className={toggleState === 5 ? "content-active" : "content-disable"}
        >
          <ContentTable
            mainHeader={"Content Awaiting Approval"}
            header={picturesHeader}
            dataList={picturesList}
            onApprove={picturesApprove}
            onReject={picturesReject}
            itemCountFromServer={props.pictures.count}
            rowPerPage={10}
            pageNumber={picturesPage}
            changePage={picturesPageChange}
            minHeightClass={"procedures-minHeight"}
            contentHeaderOne={"content-header1"}
            contentHeaderTwo={"procedures-header2"}
            contentHeaderThree={"procedures-header3"}
            contentCellOne={"procedures-cell1"}
            contentCellTwo={"procedures-cell2"}
            contentCellThree={"procedures-cell3"}
            viewCellClass={"view-cell-class"}
          />
        </div>

        <div
          style={{ margin: "16px" }}
          className={toggleState === 6 ? "content-active" : "content-disable"}
        >
          <ContentTable
            mainHeader={"Content Awaiting Approval"}
            header={resourcesHeader}
            dataList={resourcesList}
            onApprove={resourceApprove}
            onReject={resourceReject}
            itemCountFromServer={props.resources.count}
            rowPerPage={10}
            pageNumber={resourcePage}
            changePage={resourcesChangePage}
            minHeightClass={"procedures-minHeight"}
            contentHeaderOne={"content-header1"}
            contentHeaderTwo={"procedures-header2"}
            contentHeaderThree={"procedures-header3"}
            contentCellOne={"procedures-cell1"}
            contentCellTwo={"procedures-cell2"}
            contentCellThree={"procedures-cell3"}
            viewCellClass={"view-cell-class"}
          />
        </div>

      </Container>
      <Footer className={"my-footer"} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    procedures: state.procedures,
    childProcedures: state.childProcedures,
    glossaryList: state.glossaryList,
    childQuotes: state.childQuotes,
    responseSuccess: state.responseSuccess.responseSuccess,
    healthHeroes: state.healthHeroes,
    pictures: state.pictures,
    resources: state.resources,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProcedures: actionCreator.getProcedures,
      getChildProcedure: actionCreator.getChildProcedure,
      toggleMainLoader: actionCreator.toggleMainLoader,
      approveProcedure: actionCreator.approveProcedure,
      getGlossaryList: actionCreator.getGlossaryList,
      glossaryAction: actionCreator.glossaryAction,
      getChildQuotes: actionCreator.getChildQuotes,
      childQuotesAction: actionCreator.childQuotesAction,
      successResponse: actionCreator.responseSuccess,
      getHealthHeroes: actionCreator.getHealthheroes,
      getPictures: actionCreator.getPictures,
      healthHeroesAction: actionCreator.healthHeroAction,
      picturesAction: actionCreator.pictureAction,
      getResources: actionCreator.getOtherResources,
      approveResources: actionCreator.approveResources,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentApproval);
