import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObjects";

const initialState = {
  verifyOtp: {},
};

const verifyOtpInformation = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.OTP_VERIFIED:
      return updateObject(state, { verifyOtp: data });
    default:
      return state;
  }
};
export default verifyOtpInformation;
