import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { toast } from "react-toastify";

export function* scheduledStorySaga(action) {
  try {
    const response = yield call(
      serviceCreator.getStoryService,
      action.data,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.scheduledStoryResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedStorySaga(action) {
  try {
    const response = yield call(
      serviceCreator.getStoryService,
      action.data,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.publishedStoryResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addStorySaga(action) {
  try {
    const response = yield call(serviceCreator.addStoryService, action.data);
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* awaitingResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getResourcesService,
      action.data,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getResourcesResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* otherResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getOtherResourcesService,
      action.data,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getResourcesResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* scheduledResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getProfessionalResourcesService,
      action.data,
      action.page_no,
      action.sort,
      action.hospitalFilter
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.scheduledResourcesResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getResourcesService,
      action.data,
      action.page_no,
      action.sort,
      action.hospitalFilter
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.publishedResourcesResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedParentResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getParentResourcesService,
      action.data,
      action.page_no,
      action.sort,
      action.hospitalFilter
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.publishedParentResourcesResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addParentResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.addParentResourcesService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addProfessionalResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.addProfessionalResourcesService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.addResourcesService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* resourcesCategorySaga(action) {
  try {
    const response = yield call(
      serviceCreator.resourcesCategoryService,
      action.data,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.resourcesCategoryResponse(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* approveResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.approveResourcesService,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* updateResourcesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateResources,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
export function* updateParentResourcesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateParentResources,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
export function* updateProfessionalResourcesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateProfessionalResources,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* updateStorySagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateStory,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getTopResourcesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getTopResourcesApi,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.topResourcesResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addTopResourcesSaga(action) {
  try {
    const response = yield call(serviceCreator.addTopResourcesApi, action.data);
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* updateTopResourcesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateTopResourcesApi,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* deleteTopResourcesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.deleteTopResourcesApi,
      action.id
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* getHospitalCategoriesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getHospitalCategoriesApi,
      action.page_no,
      action.hospitalFilter
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.publishedResourcesResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addHospitalCategoriesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.addHospitalCategoriesApi,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* deleteHospitalCategoriesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.deleteHospitalCategoriesApi,
      action.id
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* getParentCategoriesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getParentCategoriesApi,
      action.page_no,
      action.hospitalFilter
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getResourcesResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addParentCategoriesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.addParentCategoriesApi,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* deleteParentCategoriesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.deleteParentCategoriesApi,
      action.id
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* getProfessionalCategoriesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getProfessionalCategoriesApi,
      action.page_no,
      action.hospitalFilter
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.scheduledResourcesResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addProfessionalCategoriesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.addProfessionalCategoriesApi,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* deleteProfessionalCategoriesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.deleteProfessionalCategoriesApi,
      action.id
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* getAllParentCategoriesSaga(action) {
  try {
    const response = yield call(serviceCreator.getAllParentCategoriesApi);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.resourcesCategoryResponse(response.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getAllProfessionalCategoriesSaga(action) {
  try {
    const response = yield call(serviceCreator.getAllProfessionalCategoriesApi);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.resourcesCategoryResponse(response.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getAllHospitalCategoriesSaga(action) {
  try {
    const response = yield call(serviceCreator.getAllHospitalCategoriesApi);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.resourcesCategoryResponse(response.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* updateHospitalCategorySagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateHospitalCategoryApi,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* updateParentCategorySagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateParentCategoryApi,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* updateProfessionalCategorySagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateProfessionalCategoryApi,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* searchGlobalSagaCall(action) {
  try {

    const response = yield call(
      serviceCreator.getSearchedGlobal,
      action.data,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.allGlobalSearchListResponse(response.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (e) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* hospitalSubscriptionListSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getHospitalSubscriptionService,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.getHospitalSubscriptionListResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}