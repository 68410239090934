import React, { useState, useEffect } from "react";
import ContentButton from "../contentButton";
import ImageUpload from "../ImageUpload";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Form, Row } from "react-bootstrap";
import { MESSAGE, USER_GENDER_DROPDOWN } from "../../../constant";
import "../DailyQuotes/dailyQuotes.css";
import "../../UserManagement/UserManagementClinicianDetails/userManagementClinicianDetails.css";
import AddModalHoc from "../AddModalHoc/addModalHoc";
import * as actionCreator from "../../../modules/actions";
import { validateChar } from "../../../utils/validations";
import { rename } from "../../../utils/rename";
import MultiSelect from "../../UserManagement/multiSelect";
import { toast } from "react-toastify";
import { components } from "react-select";
import { clone } from "lodash";
import TextField from "../textField";


const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const AddResources = props => {
  const [titleInvalid, setTitleInvalid] = useState(false);
  const [descriptionInvalid, setDescriptionInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [options, setOptions] = useState();
  const [hospitalOptions, setHopitalOptions] = useState();
  const [hospitalInvalid, setHospitalInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({
    value: "",
    label: "",
  });

  let hospital_id = ""
  let option_array = [];

  const [data, setData] = useState({
    title: "",
    description: "",
    url: "",
    resource_category: "",
    website_url: "",
    image: "", // New Function Added For Resources Image
    hospital: [],
    hospitalList: [],
    priority : "",
  });

  const [dropdownData, setDropDownData] = useState({});
  const [priority, setPriority] = useState({});
  const [defaulHideComponent, setDefaulHideComponent] = useState(false);

  useEffect(() => {
    if (props.isEdit) {
      let hospitalArray = [];
      let hospitalIdArray = [];
      if (
        props.resource.hospital &&
        props.resource.hospital?.length > 0 &&
        hospitalOptions?.length > 0
      ) {
        props.resource.hospital &&
          props.resource.hospital.forEach(hospital => {
            hospitalOptions.map(option => {
              if (option.value === Number(hospital)) {
                hospitalArray.push(option);
                hospitalIdArray.push(option.value);
              }
            });
          });
      }
      if (props.isAddResources) {
        setData({
          title: props.resource?.cellOne ? props.resource?.cellOne : "",
          description: props?.resource?.description ? props.resource?.description : "",
          url: props.resource?.pdf_url
            ? props.resource?.pdf_url
            : null,
          resource_category: props.resource?.parent_category
            ? props.resource?.parent_category
            : props.resource?.professional_category
              ? props.resource?.professional_category
              : props.resource?.hospital_category
                ? props.resource?.hospital_category
                : props.resource?.resource_category?.id,
          hospitalList:
            hospitalArray.length === hospitalOptions?.length - 1
              ? [...hospitalArray, { label: "All", value: "all" }]
              : hospitalArray,
          hospital: hospitalIdArray,
          website_url: props.resource?.website_url
            ? props.resource?.website_url
            : props.resource?.url,
          image: props.resource?.image_url
            ? props.resource?.image_url
            : null
        });
      } else {
        setData({
          title: props.resource?.cellOne ? props.resource?.cellOne : "",
          description: props?.resource?.description ? props.resource?.description : "",
          url: props.resource?.url,
          website_url: props.resource?.website_url,
          image: props.resource?.image_url,
          resource_category: props.resource?.resource_category?.id,
          hospitalList:
            hospitalArray.length === hospitalOptions?.length - 1
              ? [...hospitalArray, { label: "All", value: "all" }]
              : hospitalArray,
          hospital: hospitalIdArray,
          priority : props.resource?.cellTwo ? props?.resource?.cellTwo : null,
        });
      }
    }
    else {
      setData({
        ...data,
        hospital: props.userRole.includes("ADMIN") ? data.hospital : [props.hospitalDetails.id]
      })
    }
  }, [hospitalOptions]);

  useEffect(() => {
    props.getFullHospitals();
    if (
      props.title === "Update Hospital Resource" ||
      props.title === "Add Hospital Resources"
    ) {
      props.getResourcesCategory();
    } else if (
      props.title === "Update Parent Resource" ||
      props.title === "Add Parent Resources"
    ) {
      props.getAllParentCategories();
    } else {
      props.getAllProfessionalCategories();
    }
  }, []);

  useEffect(() => {
    setHopitalOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ]
    );
  }, [props.fullHospitals.data]);

  useEffect(() => {
    if (props.isEdit && options?.length > 0) {
      if(props?.userRole[0] === "ADMIN"){
        options.map(option=> {
          if (option.value === data.resource_category) {
            setCategory(option);
          }
        })
      } else {
        options?.filter(obj => obj?.hospital && obj?.hospital?.includes(hospital_id.toString())).map(option => {
          if (option.value === data.resource_category) {
            setCategory(option);
          }
        });
      }
    }
  }, [options]);

  useEffect(() => {
    if (props.resourcesCategory.results?.length > 0) {
      setOptions(
        rename(props.resourcesCategory.results, "value", "label", "id", "title")
      );
    }
    if (props.isEdit) {
      setDefaulHideComponent(true)
      setDropDownData(() => {
        return (
          (data?.website_url === null && data?.image === null) ?
            { "label": "PDF", "value": "PDF" } : 
            (data?.url === null && data?.image === null) ? 
            { "label": "Web URL", "value": "Web URL" } : 
            {"label": "Image", "value" : "Image"}
        )
      })
    }
    else {
      setDropDownData({})
    }
    if (props.isEdit && props.modalOpenFrom === "TopResources") {
      setDefaulHideComponent(false)
      setPriority(() => {
        return (
          (data?.priority === "1") ?
            { "label": "1", "value": "1" } : 
            (data?.priority === "2") ? 
            { "label": "2", "value": "2" } : 
            (data?.priority === "3") ?
            { "label": "3", "value": "3" } :
            (data?.priority === "4") ?
            { "label": "4", "value": "4" }  :
            (data?.priority === "5") ?
            { "label": "5", "value": "5" } :
            {"label": "No Priority", "value" : "No"}
        )
      })
    }else{
      setPriority({})
    }
  }, [props.resourcesCategory.results]);

  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      data.hospitalList &&
        data.hospitalList.map(hopital => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(hospitalOptions);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (hospitalOptions.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === hospitalOptions.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  const handleChange = e => {
    setCategory({
      value: e.value,
      label: e.label,
    });
    setData({ ...data, resource_category: e.value });
  };

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };

  const handleSubmit = e => {
    e.preventDefault();

    setSubmittedOnce(true);
    if (props.isAddResources) {
      let validateTitle = validateChar(data.title);
      let validateDescription = validateChar(data.description);
      let validatedHospital = data.hospital.length > 0;

      if (!validateTitle) {
        setTitleInvalid(true);
      }

      if (!validateDescription) {
        setDescriptionInvalid(true);
      }

      if (!validatedHospital) {
        setHospitalInvalid(true);
      }
      if (
        !validateTitle ||
        !validateDescription ||
        !data.resource_category ||
        !(data.url ||
          data.website_url ||
          data.image) ||
        !validatedHospital
      ) {
        return;
      }

      if (loading) {
        toast.error(MESSAGE.UPLOAD_PROGRESS, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      props.toggleMainLoader(true);

      if (props.title === "Add Hospital Resources") {
        props.addResources({
          pdf_title: data.title,
          description: data.description,
          pdf_url: dropdownData.value === "PDF" ? data.url : null,
          hospital_category: data.resource_category,
          hospital: data.hospital,
          url: dropdownData.value === "Web URL" ? data.website_url : null,
          image_url: dropdownData.value === "Image" ? data.image : null
        });
      } else if (props.title === "Add Parent Resources") {
        props.addParentResources({
          pdf_title: data.title,
          description: data.description,
          pdf_url: dropdownData.value === "PDF" ? data.url : null,
          parent_category: data.resource_category,
          hospital: data.hospital,
          url: dropdownData.value === "Web URL" ? data.website_url : null,
          image_url: dropdownData.value === "Image" ? data.image : null
        });
      } else {
        props.addProfessionalResources({
          pdf_title: data.title,
          description: data.description,
          pdf_url: dropdownData.value === "PDF" ? data.url : null,
          professional_category: data.resource_category,
          hospital: data.hospital,
          url: dropdownData.value === "Web URL" ? data.website_url : null,
          image_url: dropdownData.value === "Image" ? data.image : null
        });
      }
    } else {
      let validateTitle = validateChar(data.title);
      let validatedHospital = data.hospital.length > 0;
      if (!validateTitle) {
        setTitleInvalid(true);
      }
      if (!validatedHospital) {
        setHospitalInvalid(true);
      }
      if (!validateTitle || !data.url || !validatedHospital) {
        return;
      }

      if (loading) {
        toast.error(MESSAGE.UPLOAD_PROGRESS, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      props.toggleMainLoader(true);

      props.addTopResources({
        user: props.user.idToken,
        title: data.title,
        url: data.url,
        hospital: data.hospital,
      });
    }
    setDefaulHideComponent(false);
  };

  const handleUpdate = e => {
    e.preventDefault();
    setSubmittedOnce(true);
    if (props.isAddResources) {
      let validateTitle = validateChar(data.title);
      let validateDescription = validateChar(data.description);
      let validatedHospital = data.hospital.length > 0;

      if (!validateTitle) {
        setTitleInvalid(true);
      }

      if (!validateDescription) {
        setDescriptionInvalid(true);
      }

      if (!validatedHospital) {
        setHospitalInvalid(true);
      }
      if (
        !validateTitle ||
        !validateDescription ||
        !data.resource_category ||
        !(data.url ||
          data.website_url ||
          data.image) ||
        !validatedHospital
      ) {
        return;
      }

      if (loading) {
        toast.error(MESSAGE.UPLOAD_PROGRESS, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      props.toggleMainLoader(true);
      if (props.title === "Update Hospital Resource") {
        props.updateResource(props.resource.id, {
          pdf_title: data.title,
          description: data.description,
          pdf_url: dropdownData.value === "PDF" ? data.url : null,
          hospital_category: data.resource_category,
          hospital: data.hospital,
          url: dropdownData.value === "Web URL" ? data.website_url : null,
          image_url: dropdownData.value === "Image" ? data.image : null
        });
      } else if (props.title === "Update Parent Resource") {
        props.updateParentResource(props.resource.id, {
          pdf_title: data.title,
          description: data.description,
          pdf_url: dropdownData.value === "PDF" ? data.url : null,
          parent_category: data.resource_category,
          hospital: data.hospital,
          url: dropdownData.value === "Web URL" ? data.website_url : null,
          image_url: dropdownData.value === "Image" ? data.image : null
        });
      } else {
        props.updateProfessionalResource(props.resource.id, {
          pdf_title: data.title,
          description: data.description,
          pdf_url: dropdownData.value === "PDF" ? data.url : null,
          professional_category: data.resource_category,
          hospital: data.hospital,
          url: dropdownData.value === "Web URL" ? data.website_url : null,
          image_url: dropdownData.value === "Image" ? data.image : null
        });
      }
    } else {
      let validateTitle = validateChar(data.title);
      let validatedHospital = data.hospital.length > 0;
      if (!validateTitle) {
        setTitleInvalid(true);
      }
      if (!validatedHospital) {
        setHospitalInvalid(true);
      }
      if (!validateTitle || !data.url || !validatedHospital) {
        return;
      }

      if (loading) {
        toast.error(MESSAGE.UPLOAD_PROGRESS, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      props.toggleMainLoader(true);

      if(priority.value === data.priority){
        props.updateTopResource(props.resource.id, {
          user: props.resource.user_id,
          title: data.title,
          url: data.url,
          hospital: data.hospital,
          is_priority: false
        });
      } else{
        props.updateTopResource(props.resource.id, {
          user: props.resource.user_id,
          title: data.title,
          url: data.url,
          hospital: data.hospital,
          priority: priority.value,
          is_priority: true
        });
      }
    }
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce
        ? !data.resource_category
          ? "1px solid #eb5757"
          : 0
        : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: submittedOnce
          ? !data.resource_category
            ? "1px solid #eb5757"
            : 0
          : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
    }),

    placeholder: (base, state) => ({
      ...base,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
      outline: "none",
    }),

    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  if(props.userRole[0] === "ADMIN"){
    hospital_id = ""
  }else{
    hospital_id = props?.user?.hospital_id
    option_array.push(options?.filter(obj => obj?.hospital && obj?.hospital?.includes(hospital_id.toString())))
  }
  return (
    <>
      <Form noValidate>
        {props.userRole.includes("ADMIN") ?
          <Row>
            <Col>
              <Form.Group controlId="formGroupEmail">
                <Form.Label className="parent-name">
                  Hospital<span style={{ color: "red" }}> *</span>
                </Form.Label>
                <MultiSelect
                  multi={true}
                  placeholder={"Select Hospital"}
                  options={hospitalOptions}
                  styles={styles}
                  components={{ MenuList, Option }}
                  className="select-hospital-class"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={setHospital}
                  value={data.hospitalList}
                />
                {submittedOnce ? (
                  hospitalInvalid ? (
                    <p className="clinician-invalid">Please choose a hospital</p>
                  ) : null
                ) : null}
              </Form.Group>
            </Col>
          </Row> :

          <Row>
            <Col>
              <Form.Group controlId="formGroupEmail">
                <Form.Label className="parent-name">
                  Hospital<span style={{ color: "red" }}> *</span>
                </Form.Label>
                <MultiSelect
                  multi={false}
                  placeholder={"Select Hospital"}
                  options={hospitalOptions}
                  styles={styles}
                  className="select-hospital-class"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  value={[{ "label": props.hospitalDetails.name, "value": props.hospitalDetails.name }]}
                  disabled={true}
                />
                {submittedOnce ? (
                  hospitalInvalid ? (
                    <p className="clinician-invalid">Please choose a hospital</p>
                  ) : null
                ) : null}
              </Form.Group>
            </Col>
          </Row>}
        {props.isAddResources && (
          <Row>
            <Col>
              <Form.Group controlId="formGroupEmail">
                <Form.Label className="parent-name">
                  Select Resource Category
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <MultiSelect
                  multi={false}
                  placeholder={"Select Category"}
                  options={props?.userRole[0] === "ADMIN" ? options : options?.filter(obj => obj?.hospital && obj?.hospital?.includes(hospital_id.toString()))}
                  styles={styles}
                  className="select-hospital-class"
                  onChange={handleChange}
                  value={category}
                />
                {submittedOnce ? (
                  !data.resource_category ? (
                    <p className="clinician-invalid">
                      Please choose a category
                    </p>
                  ) : null
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="modal-content-label">
                Resource Title <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Or Paste Your Resource Title"
                className="input-field-content"
                value={data.title}
                maxLength="80"
                onChange={e => {
                  setData({ ...data, title: e.target.value });
                  submittedOnce
                    ? setTitleInvalid(!validateChar(e.target.value))
                    : setTitleInvalid(false);
                }}
                isInvalid={titleInvalid}
              />
              <Form.Control.Feedback
                type="invalid"
                className="clinician-invalid"
              >
                {MESSAGE.TITLE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
            <div style={{ marginBottom: "10px" }}>
              <div className="chracter-remain">
                {" "}
                {80 - data.title.length}/80 Characters
              </div>
            </div>
          </Col>
        </Row>


        {props?.modalOpenFrom !== 'TopResources' && 
        <Row>
          <Col>
        <TextField
                label={"Resource Description"}
                labelClassName={"textfield-content-label"}
                className={"text-field-content"}
                isInvalid={descriptionInvalid}
                max = {180}
                onChange={e => {
                  setData({ ...data, description: e.target.value });
                  submittedOnce
                    ? setDescriptionInvalid(
                        !validateChar(e.target.value)
                          ? true
                          : false
                      )
                    : setDescriptionInvalid(false);
                }}
                message={MESSAGE.DESCRIPTION_INVALID}
                value={data.description}
              />
          <div style={{ marginBottom: "10px" }}>
              <div className="chracter-remain">
                {" "}
                {180 - data?.description?.length}/180 Characters
              </div>
            </div>
            </Col>
        </Row>
        }

        {
          (props?.modalOpenFrom === 'TopResources' && props.isEdit) &&
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="parent-name">
                  Priority
                </Form.Label>
                <MultiSelect
                  multi={false}
                  placeholder={"Select a Priority"}
                  options={[
                    { "label": "1", "value": "1" },
                    { "label": "2", "value": "2" },
                    { "label": "3", "value": "3" },
                    { "label": "4", "value": "4" },
                    { "label": "5", "value": "5" },
                    { "label": "No Priority", "value": "No" }
                  ]}
                  styles={styles}
                  className="select-hospital-class"
                  onChange={(elem) => {
                    setPriority(elem)
                  }}
                  value={priority}
                />
              </Form.Group>
            </Col>
          </Row> 
        }


        {props.modalOpenFrom !== "TopResources" ?
          <Row>
            <Col>
              <Form.Group controlId="formGroupEmail">
                <Form.Label className="parent-name">
                  Website / PDF / Image
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <MultiSelect
                  multi={false}
                  placeholder={"Select Method"}
                  options={[
                    { "label": "Web URL", "value": "Web URL" },
                    { "label": "PDF", "value": "PDF" },
                    { "label": "Image", "value": "Image" }
                  ]}
                  styles={styles}
                  className="select-hospital-class"
                  onChange={(elem) => {
                    setDropDownData(elem)
                    setDefaulHideComponent(true)
                    if(elem.value === 'PDF'){
                      data.image = ""
                    }
                    else{
                      data.url = ""
                    }
                  }}
                  value={dropdownData}
                />
              </Form.Group>
            </Col>
          </Row> :

          <>
            <ImageUpload
              title={"Upload File"}
              optional={false}
              data={data}
              setData={res => {
                setData({ ...data, url: res.location });
              }}
              loading={loading}
              setLoading={setLoading}
            />
            {submittedOnce ? (
              !data.url ? (
                <p className="clinician-invalid">Please upload valid pdf</p>
              ) : null
            ) : (
              <Form.Text className="text-muted" style={{ marginTop: "-10px" }}>
                **Please Upload PDF's Only
              </Form.Text>
            )}</>
        }

        {defaulHideComponent && (dropdownData.value === "Web URL" ? 
          <Row>
          <Col>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="modal-content-label">
                Website Url <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Or Paste Your Website URL"
                className="input-field-content"
                value={data.website_url}
                maxLength="250"
                onChange={e => {
                  setData({ ...data, website_url: e.target.value });
                  submittedOnce
                    ? setTitleInvalid(!validateChar(e.target.value))
                    : setTitleInvalid(false);
                }}
                isInvalid={titleInvalid}
              />
              <Form.Control.Feedback
                type="invalid"
                className="clinician-invalid"
              >
                {MESSAGE.URL_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row> : 
        dropdownData.value === "Image" ?
        <>
        <ImageUpload
        title={"Upload Image"}
        optional={false}
        data={data}
        setData={res => {
          setData({ ...data, image: res.location });
        }}
        handleImageDelete={() => {
          setData({ ...data, image: null });
        }}
        loading={loading}
        setLoading={setLoading}
      />
      {submittedOnce ? (
        !data.image ? (
          <p className="clinician-invalid">Please upload valid image</p>
        ) : null
      ) : (
        <Form.Text className="text-muted" style={{ marginTop: "-10px" }}>
          **Please Add JPG or PNG. Pictures should be aligned vertically. If posted horizontal, pictures will rotate when exported.
        </Form.Text>
      )}
      </>
        : 
        <>
        <ImageUpload
          title={"Upload File"}
          optional={false}
          data={data}
          setData={res => {
            setData({ ...data, url:res.location.split('.').pop() == 'pdf'? res.location : null });
          }}
          loading={loading}
          setLoading={setLoading}
        />
        
        {submittedOnce ? (
          !data.url ? (
            <p className="clinician-invalid">Please upload valid pdf</p>
          ) : null
        ) : (
          <Form.Text className="text-muted" style={{ marginTop: "-10px" }}>
            **Please Upload PDF's Only
          </Form.Text>
        )} </>)
        }

        <Row>
          <Col></Col>
          <Col className="mt-auto">
            <ContentButton
              onClick={props.isEdit ? handleUpdate : handleSubmit}
              title={props.isEdit ? "Update Resource" : "Publish Resource"}
              className={"podcast-button"}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      details: state.userManagementDetails.details,
      loader: state.loader.mainLoader,
      resourcesCategory: state.resourcesCategory,
      resource: state.contentDetails,
      fullHospitals: state.fullHospitals,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      addResources: actionCreator.addResources,
      addParentResources: actionCreator.addParentResources,
      addProfessionalResources: actionCreator.addProfessionalResources,
      getResourcesCategory: actionCreator.getAllHospitalCategories,
      getAllParentCategories: actionCreator.getAllParentCategories,
      getAllProfessionalCategories: actionCreator.getAllProfessionalCategories,
      updateResource: actionCreator.updateResource,
      updateParentResource: actionCreator.updateParentResource,
      updateProfessionalResource: actionCreator.updateProfessionalResource,
      getFullHospitals: actionCreator.getFullHospitals,
      addTopResources: actionCreator.addTopResources,
      updateTopResource: actionCreator.updateTopResource,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModalHoc(AddResources));
