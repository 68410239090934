import React, { useRef, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { S3Client } from "../../utils/upload";
import {
  UPLOAD_PLUS,
  RED_DELETE_ICON
} from "../../constant";

const UploadHealthHeroVideo = (props) => {
  const [loader, setLoader] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState(props.data.videos || []);

  const ImageInput = useRef(null);

  useEffect(() => {
    setSelectedVideos(props.data.videos || []);
  }, [props.data.videos]);

  const handleUploadImage = () => {
    ImageInput.current.click();
  };

  const handleReelUpload = (e) => {
    setLoader(true);
    S3Client.uploadFile(e.target.files[0])
      .then((res) => {
        setSelectedVideos([...selectedVideos, res.location]);
        props.setData({
          ...props.data,
          videos: [...selectedVideos, res.location],
        });
        setLoader(false);
        ImageInput.current.value = ""; // Clear the selected file
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
      });
  };

  const handleImageDelete = (index) => {
    const updatedVideos = [...selectedVideos];
    updatedVideos.splice(index, 1);
    setSelectedVideos(updatedVideos);
    props.setData({
      ...props.data,
      videos: updatedVideos,
    });
  };

  return (
    <div style={{ height: '162px' }}>
      <p className="common-form-label">Upload Media</p>

      {selectedVideos.length > 0 ? (
        <div>
          {selectedVideos.map((mediaUrl, index) => {
            const isImages = /\.(jpg|jpeg|png|gif|bmp)$/i.test(mediaUrl);
            return (
              <div key={index}>
                {isImages ? (
                  <img
                    src={mediaUrl}
                    alt=""
                    style={{ width: "140px", height: "136px" }}
                  />
                ) : (
                  <video width="140" height="136" controls className="procedure-image-wrapper1">
                    <source src={mediaUrl} type="video/mp4" />
                  </video>
                )}
                <div>
                  <button
                    className="health-hero-video-icon"
                    onClick={() => handleImageDelete(index)}
                  >
                    <img
                      src={RED_DELETE_ICON}
                      alt=""
                      style={{
                        paddingBottom: "3px",
                        paddingRight: "3px",
                      }}
                    />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="procedure-image-wrapper1">
          <div onClick={handleUploadImage} style={{ padding: "12px" }}>
            {loader ? (
              <Spinner
                className="spinner-class-video"
                animation="border"
              />
            ) : (
              <>
                <img
                  className="image-upload-procedure1"
                  src={UPLOAD_PLUS}
                  alt=""
                />
                <p style={{ textAlign: "center" }}>Add New</p>
              </>
            )}
          </div>
        </div>
      )}
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleReelUpload}
        ref={ImageInput}
        accept="video/mp4, image/*"
      />
    </div>
  );
};

export default UploadHealthHeroVideo;
