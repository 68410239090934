import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//This function is used to render every page from top.
//Preveiously if we navigate from one pages bottom to another page
//The new page also starts from bottom
const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
};
export default ScrollToTop;