import React, { useEffect, useState } from "react";
import ContentButton from "../ContentManagement/contentButton";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Form, Row } from "react-bootstrap";
import { MESSAGE, USER_GENDER_DROPDOWN } from "../../constant";
import "../ContentManagement/DailyQuotes/dailyQuotes.css";
import "../UserManagement/UserManagementClinicianDetails/userManagementClinicianDetails.css";
import AddModalHoc from "../ContentManagement/AddModalHoc/addModalHoc";
import * as actionCreator from "../../modules/actions";
import MultiSelect from "../UserManagement/multiSelect";
import { rename } from "../../utils/rename";
import { components } from "react-select";
import { clone } from "lodash";

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const EditHospitalModal = props => {
  

  const [submittedOnce, setSubmittedOnce] = useState(false);

  const [options, setOptions] = useState();
  const [hospitalInvalid, setHospitalInvalid] = useState(false);
  const [loader, setLoader] = useState(false);

  const [data, setData] = useState({
    hospital: [],
    hospitalList: [],
  });


  useEffect(() => {
      let hospitalArray = [];
      let hospitalIdArray = [];

      if (
        props.hospitalData.hospital_detail &&
        props.hospitalData.hospital_detail.length > 0 &&
        options?.length > 0
      ) {

        props.hospitalData.hospital_detail &&
          props.hospitalData.hospital_detail.forEach(hospital => {

            options.map(option => {
              
              if (option.value === parseInt(hospital.id)) {

                hospitalArray.push(option);
                hospitalIdArray.push(option.value);
              }
            });
          });
      }
      setData({
        hospitalList:
          hospitalArray.length === options?.length - 1
            ? [...hospitalArray, { label: "All", value: "all" }]
            : hospitalArray,
        hospital: hospitalIdArray,
      });
    
  }, [ options])



  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {  
    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ].sort((a, b) =>
        (a["label"] || "")
          .toString()
          .localeCompare((b["label"] || "").toString())
      )
    );
  }, [props.fullHospitals.data]);

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };
  
  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      
      data.hospitalList &&
        data.hospitalList.map(hopital => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  const handleSubmit = (e) => {
    let validatedHospital = data.hospital.length > 0;
    setSubmittedOnce(true);

    if (!validatedHospital) {
      setHospitalInvalid(true);
      props.toggleMainLoader(false);
    }

    if(validatedHospital){
      if(props.isProcedure){
      props.updateProcedureHospital(props.hospitalData.id, {
        user: props.hospitalData.user_id,
        hospital: data.hospital,
      });
      props.toggleMainLoader(true);
    }

    if(props.isPicture){
      props.updatePicturesHospital(props.hospitalData.id, {
        user: props.hospitalData.user,
        hospital: data.hospital,
      });
      props.toggleMainLoader(true);
    }

    if(props?.isResourceCategory){
      if( props?.contentDetails.category === "Hospital"){
        props.updateHospitalCategory(
          props?.contentDetails?.id,{
          hospital: data.hospital,
        });
      }
      else if(props?.contentDetails.category === "Parent"){
        props.updateParentCategory(
          props?.contentDetails?.id,{
          hospital: data.hospital,
        });
      }
      else {
        props.updateProfessionalCategory(
          props?.contentDetails?.id,{
          hospital: data.hospital,
        });
      }
    }

    if (props?.isParentResource){
      props.updateParentResource(props?.contentDetails?.id, {
        hospital: data.hospital,
      });
    }

    if (props?.isHospitalResource){
      props.updateResource(props?.contentDetails?.id, {
        hospital: data.hospital,
      });
    }

    if (props?.isProfessionalResource){
      props.updateProfessionalResource(props?.contentDetails?.id, {
        hospital: data.hospital,
      });
    }
  }

  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce
        ? !data.category_type
          ? "1px solid #eb5757"
          : 0
        : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: submittedOnce
          ? !data.category_type
            ? "1px solid #eb5757"
            : 0
          : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      marginTop: "2px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
    }),

    placeholder: (base, state) => ({
      ...base,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
      outline: "none",
    }),

    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  return (
    <>
      <Form noValidate onSubmit={(e)=> {
        let validatedHospital = data.hospital.length > 0;
        e.preventDefault();
        if(validatedHospital){
          props.setShow(false);
        }
      }}>

          <Row style={{ marginTop: "24px" }}>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="parent-name">
                  Hospital<span style={{ color: "red" }}> *</span>
                </Form.Label>
                <MultiSelect
                  multi={true}
                  placeholder={"Select Hospital"}
                  options={options}
                  styles={styles}
                  components={{ MenuList, Option }}
                  className="select-hospital-class"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={setHospital}
                  value={data.hospitalList}
                  
                />
                {submittedOnce ? (
                  hospitalInvalid ? (
                    <p className="clinician-invalid">Please choose a hospital</p>
                  ) : null
                ) : null}
              </Form.Group>
            </Col>
          </Row> 




        <Row>
          <Col></Col>
          <Col className="mt-auto">
            <ContentButton
              onClick={()=> handleSubmit()}
              title={"Update Hospital"}
              className={"podcast-button"}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      details: state.userManagementDetails.details,
      loader: state.loader.mainLoader,
      resourcesCategory: state.resourcesCategory,
      contentDetails: state.contentDetails,
      fullHospitals: state.fullHospitals,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      getFullHospitals: actionCreator.getFullHospitals,
      updateProcedure: actionCreator.updateProcedure,
      updatePicture: actionCreator.updatePictures,
      updateProcedureHospital: actionCreator.updateProcedureHospital,
      updatePicturesHospital : actionCreator.updatePicturesHospital,
      updateHospitalCategory : actionCreator.updateHospitalCategory,
      updateParentCategory : actionCreator.updateParentCategory,
      updateProfessionalCategory : actionCreator.updateProfessionalCategory,
      updateResource: actionCreator.updateResource,
      updateParentResource: actionCreator.updateParentResource,
      updateProfessionalResource: actionCreator.updateProfessionalResource,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModalHoc(EditHospitalModal));
