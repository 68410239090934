import { React, useState, useCallback } from "react";
import { Container, CardColumns } from "react-bootstrap";
import Back from "../../../common/components/back";
import { ROUTES_PATH } from "../../../constant";
import Footer from "../../Footer";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import CmCard from "./cmCard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from '../../../modules/actions';
import _ from "lodash";
import { useHistory } from "react-router-dom";

const CmDashboard = props => {

  const history = useHistory()
  const [search, setSearch] = useState("");

  const handleBack = () => {
    props.history.push(ROUTES_PATH.HOME);
  };

  const delayedQuery = useCallback(
    _.debounce((value) => {
      props.getSearchedGlobal(value);
    }, 100), []
  );

  const handleOnChange = (e) => {
    setSearch(e.target.value)
    const value = e.target.value
    delayedQuery(value)
  };

  
  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />

        <UserManagementNavbar
          title={"Content Management"}
          isEditButton={false}
          isDeleteButton={false}
          isSearch={true}
          searchValue={search}
          searchOnChange={handleOnChange}
          id={"parent-nav-title"}
          className={"parent-navbar"}
        />

        {search ?
          <div className="search-div">

            {
              props.search_data !== undefined ?
                  
                (props.search_data?.procedure?.length || 
                props.search_data?.picture?.length ||
                props.search_data?.glossary?.length ||
                props.search_data?.podcast?.length ||
                props.search_data?.child_quotes?.length ||
                props.search_data?.whats_going?.length ||
                props.search_data?.inspirational_story?.length ||
                props.search_data?.community_post?.length ||
                props.search_data?.daily_quotes?.length ||
                props.search_data?.health_hero?.length ||
                props.search_data?.hospital_resources?.length ||
                props.search_data?.parent_resources?.length ||
                props.search_data?.professional_resources?.length ||
                props.search_data?.top_resources?.length ||
                props.search_data?.parent_category?.length ||
                props.search_data?.professional_category?.length ||
                props.search_data?.hospital_category?.length
                ) ?
                (
                  <>
                    <>
                      {props.search_data?.procedure
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {

                                if (element.for_role === "PARENT"){
                                  history.push(ROUTES_PATH.EDIT_PROCEDURE);
                                  props.contentDetails({...element, cellOne: element.name, for_role : "PARENT"});
                                }
                                else{
                                  history.push(ROUTES_PATH.EDIT_CHILD_PROCEDURE);
                                  props.contentDetails({...element, cellOne: element.name, for_role : "CHILD"});
                                }
                                 
                                
                              }} >{element.name} <span style={{color: "grey"}}>({element.for_role === "PARENT" ? "Parent Procedure" : "Child Prodcedure"})</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.picture
                        .map(element => {
                          
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.EDIT_PICTURE);
                                props.contentDetails({...element, cellOne : element.term, cellTwo : element.meaning});
                              }} >{element.term} <span style={{color: "grey"}}>(Pictures)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.glossary
                        .map(element => {
                          
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                
                                history.push(ROUTES_PATH.GLOSSARY);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.term});
                              }} >{element.term} <span style={{color: "grey"}}>(Glossary)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.podcast
                        .map(element => {
                          
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.PODCAST)
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne : element.title});
                              }} >{element.title} <span style={{color: "grey"}}>(Podcast)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.child_quotes
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={()=> {
                                history.push(ROUTES_PATH.CHILD_QUOTE);
                              }} >{element.quote} <span style={{color: "grey"}}>(Child Quotes)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.whats_going
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.WHATS_GOING_ON_ME);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.term});
                              }} >{element.term} <span style={{color: "grey"}}>(What Is Going On With (Child) )</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.inspirational_story
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.STORY)
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.title});
                              }} >{element.title} <span style={{color: "grey"}}>(Community Story)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.community_post
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.COMMUNITY)
                              }} >{element.post_message} <span style={{color: "grey"}}>(Community Posts)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.daily_quotes
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.DAILY_QUOTES);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.quote});
                              }} >{element.quote} <span style={{color: "grey"}}>(Daily Quotes)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.health_hero
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.HEALTH_HEROES)
                              }} >{element.name} <span style={{color: "grey"}}>(Health Heroes)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.hospital_resources
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.HOSPITAL_RESOURCES);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.pdf_title});
                              }} >{element.pdf_title} <span style={{color: "grey"}}>(Hospital Resources)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.parent_resources
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.PERSONAL_RESOURCES);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.pdf_title});
                              }} >{element.pdf_title} <span style={{color: "grey"}}>(Parent Resources)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.professional_resources
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.PROFESSIONAL_RESOURCES);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.pdf_title});
                              }} >{element.pdf_title} <span style={{color: "grey"}}>(Hospital Resources)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.top_resources
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.TOP_RESOURCES);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.title});
                              }} >{element.title} <span style={{color: "grey"}}>(Top Resources)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.parent_category
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.RESOURCES_CATEGORIES);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.title, "category": "Parent"});
                              }} >{element.title} <span style={{color: "grey"}}>(Parent Category)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.professional_category
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.RESOURCES_CATEGORIES);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.title, "category": "Professional"});
                              }} >{element.title} <span style={{color: "grey"}}>(Professional Category)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                    <>
                      {props.search_data?.hospital_category
                        .map(element => {
                          return (
                            <>
                              <p className="search-data" onClick={() => {
                                history.push(ROUTES_PATH.RESOURCES_CATEGORIES);
                                props.openSearchModal(true);
                                props.contentDetails({...element, cellOne: element.title, "category": "Hospital"});
                              }} >{element.title} <span style={{color: "grey"}}>(Hospital Category)</span></p>
                              <hr></hr>
                            </>
                          )
                        })}
                    </>

                  </>
                ): (<h1 className="no-data">No Data Found</h1>) : <div class="loader"></div>

            }
          </div> : <>
        {props.role.includes("ADMIN") ?
          <>
            <CardColumns style={{ paddingTop: "16px" }}>
              <CmCard
                viewClassName="view-more-card"
                className={"my-card-cm"}
                title={"Comfort Positions"}
                onClick={() => props.history.push(ROUTES_PATH.STORY)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Community Posts"}
                onClick={() => props.history.push(ROUTES_PATH.COMMUNITY)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                onClick={() => props.history.push(ROUTES_PATH.DAILY_QUOTES)}
                title={"Daily Quotes"}
              />
            </CardColumns>

            <CardColumns style={{ paddingTop: "20px" }}>
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Glossary (Parent)"}
                onClick={() => props.history.push(ROUTES_PATH.GLOSSARY)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Health Heroes"}
                onClick={() => props.history.push(ROUTES_PATH.HEALTH_HEROES)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Hospital Resources"}
                onClick={() => props.history.push(ROUTES_PATH.HOSPITAL_RESOURCES)}
              />
            </CardColumns>

            <CardColumns style={{ paddingTop: "20px" }}>
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Procedure (Parent)"}
                onClick={() => props.history.push(ROUTES_PATH.PROCEDURES)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Procedure (Child)"}
                onClick={() => props.history.push(ROUTES_PATH.CHILD_PROCEDURE)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Pictures"}
                onClick={() => props.history.push(ROUTES_PATH.PICTURES)}
              />
            </CardColumns>


            <CardColumns style={{ paddingTop: "20px" }}>
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Parent Resources"}
                onClick={() => props.history.push(ROUTES_PATH.PERSONAL_RESOURCES)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Podcast"}
                onClick={() => props.history.push(ROUTES_PATH.PODCAST)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Professional Resources"}
                onClick={() =>
                  props.history.push(ROUTES_PATH.PROFESSIONAL_RESOURCES)
                }
              />
            </CardColumns>

            <CardColumns style={{ paddingTop: "20px" }}>
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Resources Categories"}
                onClick={() => props.history.push(ROUTES_PATH.RESOURCES_CATEGORIES)}
              />

              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Top Resources"}
                onClick={() => props.history.push(ROUTES_PATH.TOP_RESOURCES)}
              />

              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"What Other Kids Say"}
                onClick={() => props.history.push(ROUTES_PATH.CHILD_QUOTE)}
              />
            </CardColumns>
            <CardColumns style={{ paddingTop: "20px" }}>
              <div className={"my-card-empty"}></div>

              <CmCard
                viewClassName="view-more-card1"
                className={"my-card-cm"}
                title={"What’s going on with me? (Child)"}
                onClick={() => props.history.push(ROUTES_PATH.WHATS_GOING_ON_ME)}
              />
              <div className={"my-card-empty"}></div>
            </CardColumns> </> :

          <>

            <CardColumns style={{ paddingTop: "20px" }}>
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Health Heroes"}
                onClick={() => props.history.push(ROUTES_PATH.HEALTH_HEROES)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Hospital Resources"}
                onClick={() => props.history.push(ROUTES_PATH.HOSPITAL_RESOURCES)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Procedure (Parent)"}
                onClick={() => props.history.push(ROUTES_PATH.PROCEDURES)}
              />
            </CardColumns>

            <CardColumns style={{ paddingTop: "20px" }}>

              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Pictures"}
                onClick={() => props.history.push(ROUTES_PATH.PICTURES)}
              />
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Parent Resources"}
                onClick={() => props.history.push(ROUTES_PATH.PERSONAL_RESOURCES)}
              />

              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Resources Categories"}
                onClick={() => props.history.push(ROUTES_PATH.RESOURCES_CATEGORIES)}
              />

            </CardColumns>

            <CardColumns style={{ paddingTop: "20px" }}> 
              <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Top Resources"}
                onClick={() => props.history.push(ROUTES_PATH.TOP_RESOURCES)}
              />

            <CmCard
                className={"my-card-cm"}
                viewClassName="view-more-card"
                title={"Professional Resources"}
                onClick={() =>
                  props.history.push(ROUTES_PATH.PROFESSIONAL_RESOURCES)
                }
              />
              
              

            <div className={"my-card-empty"}></div>
            </CardColumns>

          </>} </>}
        <Footer className={"content-management-footer"} />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    role: state.user.profile.role,
    hospital_id: state.user.profile.hospital_id,
    loader: state.loader.mainLoader,
    search_data: state.globalSearch.results.data
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSearchedGlobal: actionCreator.getSearchedGlobal,
      openSearchModal : actionCreator.isOpenModalFromSearch,
      contentDetails: actionCreator.getContentDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CmDashboard);