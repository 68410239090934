import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../utils/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from "../components/commonComponent/errorBoundary";
import { Offline, Online } from "react-detect-offline";
import NetworkPopOver from "../components/commonComponent/networkPopover";
import { useNetworkState } from 'react-use';

const Root = () => {
  const state = useNetworkState();
  return (
    <>
    {
      state.online === false ?
      <NetworkPopOver />
      :
      <Provider store={store}>
          <div>
            <BrowserRouter>
              <ErrorBoundary>
                <App />
                <ToastContainer hideProgressBar={true} newestOnTop={false} />
              </ErrorBoundary>
            </BrowserRouter>
          </div>
        </Provider>
    }
      {/* <Online>
        <Provider store={store}>
          <div>
            <BrowserRouter>
              <ErrorBoundary>
                <App />
                <ToastContainer hideProgressBar={true} newestOnTop={false} />
              </ErrorBoundary>
            </BrowserRouter>
          </div>
        </Provider>
      </Online>
      <Offline>
        <NetworkPopOver />
      </Offline> */}
    </>
  );
};
export default Root;
