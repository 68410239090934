import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../modules/actions";
import { BUTTON_SPINNER } from "../../constant";

const ContentButton = (props) => {
  const { title, onClick, className } = props;
  return (
    <>
      <button className={className} onClick={onClick}>
        {props.loader ? (
          <img src={BUTTON_SPINNER} alt="" height="30px" />
        ) : (
          <span>{title}</span>
        )}
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
  try {
    return {
      loader: state.loader.mainLoader,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: actionCreator.toggleMainLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentButton);
