import axios from "axios";
import {
  retrieveRefreshToken,
  retrieveToken,
  saveToken,
} from "../src/utils/localStorage";
import _ from "lodash";
import { getAccessTokenUrl } from "../src/utils/urls";

axios.defaults.headers.post["Content-Type"] = "application/json";

const axiosInstance = axios.create({
  baseURL: `${window.location.protocol}//`,
});

const instance = axios.create({
  baseURL: `${window.location.protocol}//`,
});

axiosInstance.all = axios.all;
axiosInstance.spread = axios.spread;

axiosInstance.interceptors.request.use(request => {
  request.headers["Authorization"] = `Bearer ${retrieveToken()}`;
  return request;
});

instance.all = axios.all;
instance.spread = axios.spread;
instance.interceptors.request.use(request => {
  request.headers["Authorization"] = `Bearer ${retrieveRefreshToken()}`;
  return request;
});

const handleClear = () => {
  localStorage.clear();
  window.location.pathname = "/login";
};

const handleResponse = response => {
  if (response.status === 200 || response.status === 201) {
    let resp = response.data.data;
    saveToken(
      resp.token?.access
      // refresh: resp.token?.refresh,
    );
    axiosInstance.defaults.headers.common["Authorization"] =
      "Bearer " + retrieveToken();
    // window.location.reload();
  } else {
    localStorage.clear();
    window.location.reload();
    window.location.pathname = "/login";
  }
};

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const userDetails = JSON.parse(localStorage.getItem("state"));
    return new Promise((resolve, reject) => {
      if (error?.response?.status === 401) {
        originalRequest._retry = true;
        // eslint-disable-next-line
        const refreshToken = retrieveRefreshToken();
        if (!_.isEmpty(userDetails?.user)) {
          instance
            .post(getAccessTokenUrl)
            .then(response => {
              handleResponse(response);
              return resolve(axiosInstance(originalRequest));
            })
            .catch(error => {
              if (error?.response?.status >= 400) {
                handleClear();
              }
            });
        }
      } else if (error?.response?.status === 403) {
        handleClear();
        return reject(error);
      } else {
        return reject(error);
      }
    });
  }
);

export default axiosInstance;
