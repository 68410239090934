import * as ActionTypes from "./actionTypes";

export const getAllParentChild = (data) => ({
  type: ActionTypes.GET_ALL_PARENT_CHILD_LIST,
  data,
});

export const getAllParentChildListResponse = (data) => ({
  type: ActionTypes.ALL_PARENT_CHILD_LIST_RESPONSE,
  data,
});

export const deleteParent = (data) => ({
  type: ActionTypes.DELETE_PARENT,
  data,
});

export const deleteChild = (data) => ({
  type: ActionTypes.DELETE_CHILD,
  data,
});

export const deleteCls = (data) => ({
  type: ActionTypes.DELETE_CLS,
  data,
});

export const getAllCls = (data) => ({
  type: ActionTypes.GET_ALL_CLS_LIST,
  data,
});

export const getAllClsListResponse = (data) => ({
  type: ActionTypes.ALL_CLS_LIST_RESPONSE,
  data,
});

export const getAllClinician = (data) => ({
  type: ActionTypes.GET_ALL_CLINICIAN_LIST,
  data,
});
export const getAllClinicianListResponse = (data) => ({
  type: ActionTypes.ALL_CLINICAN_LIST_RESPONSE,
  data,
});

export const deleteClinician = (data) => ({
  type: ActionTypes.DELETE_CLINICIAN,
  data,
});

export const storeUserManagementDetails = (data) => ({
  type: ActionTypes.STORE_USER_MANAGEMENT_DETAILS,
  data,
});

export const searchRequest = (page_no, data, role) => ({
  type: ActionTypes.SEARCH_REQUEST,
  page_no,
  data,
  role,
});

export const searchResponse = (data) => ({
  type: ActionTypes.SEARCH_RESPONSE,
  data,
});

export const filterRequest = (page_no, data) => ({
  type: ActionTypes.FILTER_REQUEST,
  page_no,
  data,
});

export const filterResponse = (data) => ({
  type: ActionTypes.FILTER_RESPONSE,
  data,
});

export const reset = () => ({
  type: ActionTypes.RESET,
});

export const activation = (id, data) => ({
  type: ActionTypes.ACTIVATION,
  id,
  data,
});

export const filterValue = (data) => ({
  type: ActionTypes.USER_FILTER_VALUE,
  data,
});

export const navbarValue = (data) => ({
  type: ActionTypes.GET_USER_NAVBAR_VALUE,
  data,
});

export const resetFilter = () => ({
  type: ActionTypes.RESET_FILTER,
});