import React from "react";
import PropTypes from "prop-types";
import { CLOC_CHILD_IMAGE, CLOC_LOGO } from "../../constant";
import { Container } from "react-bootstrap";
import "./commonComponent.css";

// added common  component for logo part which is used on login,forgot password page.
const CommonLogoComponent = (props) => {
  return (
    <>
      <Container fluid className="px-0 common-logo-container">
        <img
          className="common-image-component"
          src={CLOC_CHILD_IMAGE}
          alt=""
        ></img>
        <img className="common-logo-css" src={CLOC_LOGO} alt=""></img>
      </Container>
    </>
  );
};

CommonLogoComponent.propTypes = {
  className: PropTypes.string,
};

CommonLogoComponent.defaultProps = {};

export default CommonLogoComponent;
