import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { toast } from "react-toastify";

export function* quotesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getQuotesService,
      action.data,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getQuotesResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedQuotesSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getQuotesService,
      action.data,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.publishedQuotesResponse(response.data.data[0]));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishQuoteSaga(action) {
  try {
    const response = yield call(
      serviceCreator.publishQuoteService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* proceduresSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getProceduresService,
      action.status,
      action.page_no,
      action.data,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.getProceduresResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
export function* scheduledProceduresSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getProceduresService,
      action.status,
      action.page_no,
      action.data,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.scheduledProceduresResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
export function* publishedProceduresSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getProceduresService,
      action.status,
      action.page_no,
      action.data,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.publishedProceduresResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* approveProcedureSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.approveProcedure,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* ChildProceduresSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getProceduresService,
      action.status,
      action.page_no,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.getChildProceduresResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* addProcedureSaga(action) {
  try {
    const response = yield call(
      serviceCreator.addProcedureService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* getGlossarySagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getGlossaryList,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.glossaryResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* scheduledGlossarySaga(action) {
  try {
    const response = yield call(
      serviceCreator.getGlossaryList,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.scheduledGlossaryResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
export function* publishedGlossarySaga(action) {
  try {
    const response = yield call(
      serviceCreator.getGlossaryList,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.publishedGlossaryResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* glossaryActionSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.glossaryAction,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* addGlossarySaga(action) {
  try {
    const response = yield call(serviceCreator.addGlossaryService, action.data);
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getChildQuotesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getChildQuotes,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.childQuotesResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* childQuotesActionSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.childQuotesAction,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* scheduledPodcastSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getPodcastService,
      action.data,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.scheduledPodcastResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getHealthHeroesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getHealthHeroes,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.healthHeroesResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedPodcastSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getPodcastService,
      action.data,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.publishedPodcastResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* healthHerosActionSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.healthHerosAction,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getPicturesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getPictures,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.pictureResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addPodcastSaga(action) {
  try {
    const response = yield call(serviceCreator.addPodcastService, action.data);
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* picturesActionSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.picturesAction,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedHealthHeroesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getHealthHeroes,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.publishedHealthHeroesResponse(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* schedulePicturesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getPictures,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.schedulePictureResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedChildSaga(action) {
  try {
    const response = yield call(
      serviceCreator.getChildQuotes,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.publishedChildResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedPicturesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getPictures,
      action.status,
      action.page_no,
      action.sort,
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.publishPicturesResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addPictureSagaCall(action) {
  try {
    const response = yield call(serviceCreator.addPicture, action.data);
    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* scheduledWhatsGoingOnMeSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getWhatsGoingOnMe,
      action.status,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.scheduledWhatsGoingOnMeRes(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* publishedWhatsGoingOnMeSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getWhatsGoingOnMe,
      action.status,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.publishedWhatsGoingOnMeRes(response.data.data[0])
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* addWhatsGoingOnMeSagaCall(action) {
  try {
    const response = yield call(serviceCreator.addWhatsGoingOnMe, action.data);
    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deleteProcedureSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteProcedure, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deleteDailyQuotesSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteDailyQuotes, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deleteGlossarySagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteGlossary, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deletePodcastSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deletePodcast, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deletePictureSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deletePicture, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deleteResourcesSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteResource, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deleteParentResourcesSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteParentResource, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deleteProfessionalResourcesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.deleteProfessionalResource,
      action.id
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deleteStorySagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteStory, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* deleteWhatsGoingonMeSagaCall(action) {
  try {
    const response = yield call(serviceCreator.deleteWhatsGoingonMe, action.id);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* updateDailyQuoteSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateDailyQuotes,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* updateGlossarySagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateGlossary,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* updatePodcastSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updatePodcast,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (response.data.status_code === 200) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(true));
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.responseSuccess(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));

    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* updateWhatsGoingOnMeSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updateWhatsgoingOnme,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* updatePictureSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updatePicture,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* updateProcedureSaga(action) {
  try {
    const response = yield call(
      serviceCreator.updateProcedure,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.responseSuccess(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* getPostsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getPostsService,
      action.status,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.communityPostResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getScheduledPostsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getPostsService,
      action.status,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.scheduledPostResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* getPublishedPostsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getPostsService,
      action.status,
      action.page_no
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.publishedPostResponse(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export function* postActionSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.postAction,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.responseSuccess(true));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(false));
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (e) {
    toast.error(e.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.responseSuccess(false));
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* updatePictureHospitalSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.updatePicture,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } 
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));
  }
}

export function* updateProcedureHospitalSaga(action) {
  try {
    const response = yield call(
      serviceCreator.updateProcedure,
      action.id,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(actionCreators.responseSuccess(true));
      yield put(actionCreators.toggleMainLoader(false));
    } 
  } catch (error) {
    toast.error(error.response.data.message[0], {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.responseSuccess(false));
  }
}