import React, { useState, useEffect } from "react";
import UserManagementNavbar from "../userManagementNavbar";
import { Container, Form, Row, Col } from "react-bootstrap";
import Back from "../../../common/components/back";

import "../UserManagementParentChildDetails/userManagementParentChildDetails.css";
import { ROUTES_PATH, RED_SPINNER } from "../../../constant";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import Footer from "../../Footer";
import { Redirect } from "react-router";
import moment from "moment";
import MultiSelect from "../multiSelect";
import { sensoryOptions } from "../../../utils/options";
import DeleteWarning from "../../commonComponent/deleteWarning";

const UserManagementChildDetails = (props) => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sensoryValue, setSensoryValue] = useState();
  const [medicalValue, setMedicalValue] = useState();

  const [data, setData] = useState({
    id: "",
    name: "",
    email: "",
    avatar: "",
    avatar_id: "",
    gender: "",
    birth_date: "",
    medical_issues: [],
    sensory_needs: [],
  });

  useEffect(() => {
    try {
      if (props.details.child) {
        setData({ ...data, ...props.details.child, ...props.details });
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (medicalValue) {
      var names = medicalValue.map(function (item) {
        return item["value"];
      });

      setData({ ...data, medical_issues: names });
    }
    if (sensoryValue) {
      var sensoryNames = sensoryValue.map(function (item) {
        return item["value"];
      });

      setData({ ...data, sensory_needs: sensoryNames });
    }
  }, [medicalValue, sensoryValue]);

  useEffect(() => {
    try {
      if (props.details.child) {
        setSensoryValue(
          props.details.child.sensory_needs.map((str, index) => ({
            value: str,
            label: str,
          }))
        );
      }
      if (props.details.child) {
        setMedicalValue(
          props.details.child.medical_issues.map((str, index) => ({
            value: str,
            label: str,
          }))
        );
      }
    } catch (e) {}
  }, [props.match.params.path]);

  try {
    if (Object.keys(props.details).length === 0) {
      return <Redirect to={ROUTES_PATH.USER_MANAGEMENT} />;
    }
  } catch (e) {}

  const handleCancelClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleDeleteButton = async () => {
    setLoader(true); // Show loader when deletion starts
    await props.deleteParent(data.id); // Await the delete action
    setLoader(false); // Hide loader once delete action is done
  };

  const handleClose = () => {
    setShow(false);
    handleDeleteButton();
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.USER_MANAGEMENT);
  };

  const handleActivateButton = () => {
    props.activation(data.id, {
      status: props.details.status === "ACTIVE" ? "DELETED" : "ACTIVE",
    });
  };

  let todays_date = new Date();
  todays_date = todays_date.toISOString().split("T")[0];
  return (
    <Container
      style={{ maxWidth: "1046px", minHeight: "100vh", position: "relative" }}
    >
      <Back
        Back={handleBack}
        backIconClassName={"parent-back-icon"}
        backNavItemClassName={"parent-back-nav"}
        className="parent-back"
      />

      <UserManagementNavbar
        title={"Child Details"}
        isEditButton={true}
        isDeleteButton={true}
        handleDeleteButton={handleShow}
        id={"parent-nav-title"}
        className={"parent-navbar"}
        activationLabel={
          props.details.status === "ACTIVE" ? "Deactivate" : "Activate"
        }
        handleEditButton={handleActivateButton}
        loader={loader}
        loaderImg = {RED_SPINNER}
        loaderheight = {"30px"}
      />

      <Form noValidate>
        <Form.Group style={{ paddingTop: "7px" }}>
          <label htmlFor="upload-button">
            {data.avatar ? (
              <img src={`${data.avatar}`} alt="" className="preview-image" />
            ) : (
              <div className="preview-image-another">
                {data.name.toUpperCase()[0]}
              </div>
            )}
          </label>
          <input
            disabled={true}
            type="file"
            id="upload-button"
            style={{ display: "none" }}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Name</Form.Label>
              <Form.Control
                value={data.name}
                readOnly={true}
                className="parent-name-field"
                type="text"
                placeholder="Child Name"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Email ID</Form.Label>
              <Form.Control
                value={data.email}
                readOnly={true}
                className="parent-name-field"
                type="email"
                placeholder="dummy@email.com"
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Gender</Form.Label>
              <Form.Control
                value={data.gender}
                disabled={true}
                className="parent-name-field"
                type="text"
                placeholder="Female"
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Birth Date</Form.Label>
              <Form.Control
                value={
                  data.birth_date
                    ? moment(data.birth_date).format("MM/DD/YYYY")
                    : ""
                }
                readOnly={true}
                className="parent-name-field"
                max={todays_date}
                placeholder="MM/DD/YYYY"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                What Describes Me Best?
              </Form.Label>
              <Form.Control
                readOnly
                value={data.role ? data.role[0] : "Child"}
                className="parent-name-field"
                type="text"
                placeholder="Parent"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Sensory Needs</Form.Label>

              <MultiSelect
                multi={true}
                disabled={true}
                value={sensoryValue}
                placeholder={"None"}
                options={sensoryOptions}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <DeleteWarning
        show={show}
        handleCancelClose={handleCancelClose}
        handleClose={handleClose}
      />
      <Footer className={"parent-child-footer"} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  try {
    return {
      details: state.userManagementDetails.details,
      hospitals: state.hospitals.data[0],
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      deleteParent: actionCreator.deleteParent,
      activation: actionCreator.activation,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementChildDetails);
