import React from "react";
import { Table } from "react-bootstrap";
import { VIEW_ICON } from "../../constant";
import "./commonComponent.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../modules/actions";
import FullPageLoader from "./mainLoaderSpinner";
import Pagination from "react-js-pagination";

const UserManagementCommonTable = (props) => {
  const {
    dataList,
    header,
    rowPerPage,
    handleClickParentChild,
    handleClickClinician,
    handleClickCls,
    parentChild,
    CLS,
    clinician,
    pageNumber,
    changePage,
    itemCountFromServer,
    handleViewClick,
  } = props;

  return (
    <>
      <div
        style={{
          minHeight: "440px",
          backgroundColor: "#e5e5e5",
        }}
      >
        <Table className="table-main mx-auto" responsive="sm">
          <thead className="tableHead-main">
            <tr>
              <th
                onClick={handleClickParentChild}
                className={parentChild ? "tableHead" : "tableHead-noBorder"}
              >
                Parent/Child
              </th>
              <th
                onClick={handleClickCls}
                className={CLS ? "cls-tablehead" : "cls-tablehead-noBorder"}
              >
                CLS
              </th>
              <th
                onClick={handleClickClinician}
                className={
                  clinician
                    ? "clinician-tablehead"
                    : "clinician-tablehead-noBorder"
                }
              >
                Clinician
              </th>
            </tr>
          </thead>
          <tr className="table-row">
            {header.header1 ? (
              <th className="table-header1">{header.header1}</th>
            ) : null}
            {header.header2 ? (
              <th className="table-header2">{header.header2}</th>
            ) : null}
            <th className="table-header3"></th>
          </tr>

          <tbody className="tbody5">
            {dataList.length ? (
              dataList.map((user, index) => {
                return (
                  <tr className="table-row" key={user.id}>
                    <td className="table-cell">{user.name}</td>
                    <td className="table-cell2">{user.email}</td>
                    <td className="table-cell-view">
                      <button
                        id="view"
                        onClick={() => handleViewClick(user.id)}
                      >
                        View{" "}
                        <img
                          src={VIEW_ICON}
                          alt=""
                          style={{ marginLeft: "8px" }}
                        />{" "}
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : props.loader.mainLoader ? (
              <FullPageLoader className="spinner-pos" />
            ) : (
              <div className="no-results">No results found!!</div>
            )}
            {props.loader.mainLoader ? (
              <FullPageLoader className="spinner-pos" />
            ) : null}
          </tbody>
        </Table>
      </div>
      {dataList.length ? (
        <Pagination
          totalItemsCount={itemCountFromServer}
          itemsCountPerPage={rowPerPage}
          activePage={pageNumber}
          onChange={changePage}
          prevPageText={"< Prev"}
          nextPageText={"Next >"}
          firstPageText={""}
          lastPageText={""}
          innerClass={"pagination-container"}
          linkClassPrev={"pagination-previous"}
          linkClassNext={"pagination-next"}
          disabledClass={"pagination-link-disabled"}
          activeLinkClass={"pagination-active"}
          linkClass={"page-link-className-css"}
          pageRangeDisplayed={3}
        />
      ) : (
        <div style={{ height: "60px" }}></div>
      )}
    </>
  );
};

UserManagementCommonTable.defaultProps = {
  header: {},
  dataList: [],
};
const mapStateToProps = (state) => {
  return {
    loader: state.loader,
    ClsList: state.ClsList.ClsData.data,
    ClinicianList: state.ClinicianList,
    ParentChildList: state.ParentChildList.ParentChildData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: actionCreator.toggleMainLoader,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementCommonTable);
