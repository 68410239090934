import React from 'react'
import { Modal } from 'react-bootstrap';
import SaveButton from '../../common/components/saveButton';
import { CROSS_ICON, DELETE_WARNING, SAVE_HOSPITAL_DATA } from "../../constant";

const AlertDialogSlide = (props) => {
    const { open,
      title,
      message,
      handleClose,
      onSaveModal,
        loader,
    } = props

    return (
        <Modal show={open} centered>
            <Modal.Body>
                <div>
                    <p className="congrats-message" style={{paddingTop: "50px"}}>{message}</p>
                    <div style={{ margin: "40px 21px 40px 21px" }}>
                        <button className="form-save-and-add-button" onClick={handleClose}>Don't Save</button>
                        <SaveButton
                            className={"modal-save-hospital-button"}
                            submit={onSaveModal}
                            buttonName={"Save"}
                            loader={loader}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AlertDialogSlide;