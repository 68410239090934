import React from "react";
import { NETWORK_ERROR_IMAGE } from "../../constant";
import { Container } from "react-bootstrap";

const NetworkPopOver = () => {
  const handleClick = () => {
    window.location.reload();
  };

  return (
    <Container>
      <img className="error-boundary-css" src={NETWORK_ERROR_IMAGE}></img>
      <div className="error-boundary-text">No Internet Connection</div>
      <div className="error-boundary-text1">
        We are not able to fetch any data because, we can not find an active
        internet connection
      </div>
      <button className="error-boundary-button" onClick={handleClick}>
        Try Again
      </button>
    </Container>
  );
};
export default NetworkPopOver;
