import React, { useEffect, useState, useRef, Fragment } from "react";
import { UPLOAD_PLUS, ROUTES_PATH, MESSAGE } from "../../../constant";
import {
  validateEmail,
  validateUniqueCode,
  validateAddress,
  validateName,
} from "../../../utils/validations";
import { Col, Container, Form, Row } from "react-bootstrap";
import TitleBar from "../../../common/components/titleBar";
import * as actionCreator from "../../../modules/actions";
import Back from "../../../common/components/back";
import { S3Client } from "../../../utils/upload";
import { bindActionCreators } from "redux";
import HospitalUser from "./hospitalUser";
import { connect } from "react-redux";
import Footer from "../../Footer";
import "./hospital.css";
import HospitalImage from "./hospitalImage";
import moment from "moment";
import AlertDialogSlide from "../../commonComponent/AlertDialogSlide";

const EditHospital = props => {
  const handleBack = () => {
    if(isBack){
      setShowSaveModal(true)
    }else{
      props.history.push(ROUTES_PATH.HOSPITAL_VIEW);
    }
    
  };

  const addHealthHero = () => {
    props.history.push(ROUTES_PATH.ADD_HEALTH_HEROES);
  };
  const [modal, setModal] = useState(false);
  const [media, setMedia] = useState([]);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [tierplanInvalid, setTierplanInvalid] = useState(false);
  const [startDateInvalid, setStartDateInvalid] = useState(false);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [type, setType] = useState("");
  const [isBack, setIsBack] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  let users = [];
  if (props.hospitalDetails.cls && props.hospitalDetails.clinician) {
    const { cls, clinician } = props.hospitalDetails;
    users = [...users, ...cls, ...clinician];
  }

  //functions to open modal and choose beetween Cls or Clinician
  const handleShow = () => {
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
    setType("");
  };

  const handleSaveModal = () =>{
    props.toggleMainLoader(true)
    setShowSaveModal(false);
    props.history.push(ROUTES_PATH.HOSPITAL_VIEW);
  }

  const userOnChange = e => {
    setType(e.target.value)
    setIsBack(true);
  };

  const handleEditHealthHero = data => {
    props.history.push(ROUTES_PATH.EDIT_HEALTH_HEROES);
    props.setEditHealthHero(data);
  };

  const handleAddButton = () => {
    if (type === "cls") {
      props.history.push(ROUTES_PATH.ADD_CLS);
      setModal(false);
      setType(false);
    } else if (type === "clinician") {
      props.history.push(ROUTES_PATH.ADD_CLINICIAN);
      setModal(false);
      setType(false);
    }
  };
  //modal functions end

  const [hospitaData, setHospitalData] = useState({
    id: props.hospitalDetails.id,
    name: props.hospitalDetails.name,
    email: props.hospitalDetails.email,
    address: props.hospitalDetails.address,
    logo: props.hospitalDetails.logo,
    pictures: props.hospitalDetails.pictures
      ? props.hospitalDetails.pictures
      : [],
    videos: props.hospitalDetails.videos ? props.hospitalDetails.videos : [],
    plan_start_date: props.hospitalDetails.plan_start_date,
    plan_end_date: props.hospitalDetails.plan_end_date,
    tier_plan: props.hospitalDetails.tier_plan?.id
      ? props.hospitalDetails.tier_plan?.id
      : props.hospitalDetails.tier_plan,
    code: props.hospitalDetails.code,
  });
  
  useEffect(() => {
    setMedia([...hospitaData.pictures, ...hospitaData.videos]);
  }, [hospitaData.pictures, hospitaData.videos]);

  const handleChange = e => {
    setHospitalData({
      ...hospitaData,
      [e.target.name]: e.target.value,
    });setIsBack(true)
  };

  const logoInput = useRef(null);
  const handleUploadClick = e => {
    setIsBack(true);
    logoInput.current.click();
  };
  const handleLogoUpload = e => {
    setIsBack(true);
    S3Client.uploadFile(e.target.files[0])
      .then(res => {
        setHospitalData({ ...hospitaData, logo: res.location });
        e.target.files = null;
      })
      .catch(err => console.error(err));
  };

  //useEffect to get hospital details when page refreshed
  useEffect(() => {
    props.getHospitalData(props.hospitalDetails.id);
  }, []);

  //useeffect to navigate page to subscriber after updating the hospital data
  useEffect(() => {
    if (props.addHospitalSuccess.success) {
      props.addSuccess(false);
      props.history.push(ROUTES_PATH.HOSPITAL_VIEW);
    }
  }, [props.addHospitalSuccess.success]);

  //function to update the hospital details
  const handleSubmit = e => {
    props.toggleMainLoader(true);
    let nameValid = validateName(hospitaData.name);
    let addressValid = validateAddress(hospitaData.address);
    let emailValid = validateEmail(hospitaData.email);
    let codeValid = validateUniqueCode(hospitaData.code);
    if (!hospitaData.name || !nameValid) {
      setNameInvalid(true);
    }
    if (!hospitaData.address || !addressValid) {
      setAddressInvalid(true);
    }
    if (!hospitaData.email || !emailValid) {
      setEmailInvalid(true);
    }
    if (!hospitaData.plan_start_date) {
      setStartDateInvalid(true);
    }
    if (!hospitaData.tier_plan) {
      setTierplanInvalid(true);
    }
    if (!hospitaData.code || !codeValid) {
      setCodeInvalid(true);
    }

    if (
      !hospitaData.name ||
      !nameValid ||
      !hospitaData.address ||
      !addressValid ||
      !hospitaData.email ||
      !emailValid ||
      !hospitaData.plan_start_date ||
      !hospitaData.tier_plan ||
      !hospitaData.code ||
      !codeValid
    ) {
      props.toggleMainLoader(false);
      return;
    }
    if (
      !nameInvalid &&
      nameValid &&
      !addressInvalid &&
      addressValid &&
      !emailInvalid &&
      emailValid &&
      !tierplanInvalid &&
      !startDateInvalid &&
      !codeInvalid &&
      codeValid
    ) {
      const hospital_data = {
        id: hospitaData.id,
        name: hospitaData.name,
        address: hospitaData.address,
        logo: hospitaData.logo ? hospitaData.logo : null,
        email: hospitaData.email,
        tier_plan: hospitaData.tier_plan,
        plan_start_date: hospitaData.plan_start_date,
        plan_end_date: hospitaData.plan_end_date,
        code: hospitaData.code,
        pictures: hospitaData.pictures.length ? hospitaData.pictures : null,
        videos: hospitaData.videos.length ? hospitaData.videos : null,
      };
      props.updateHospital(hospital_data);
    }
  };

  return (
    <Fragment>
      <Container className="common-container">
        <Back
          className={"back-nav"}
          backNavItemClassName={"back-nav-item"}
          backIconClassName={"back-icon"}
          isEdit={false}
          isDelete={false}
          isSave={true}
          Back={handleBack}
          onSave={handleSubmit}
          loader={props.loader}
        />
        <TitleBar
          titlename={"Edit Hospital Details"}
          headingClass={"title-bar-heading"}
        />

        <Form noValidate style={{ paddingLeft: "16px" }}>
          <Row>
            <Col>
              <Form.Group controlId="formBasicName">
                <Form.Label className="common-form-label">
                  Hospital Name<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="common-form-input"
                  type="text"
                  placeholder="Hospital Name"
                  name="name"
                  maxLength="150"
                  isInvalid={nameInvalid}
                  value={hospitaData.name}
                  onChange={e => {
                    setNameInvalid(false);
                    handleChange(e);
                    setIsBack(true);
                  }}
                  disabled={props.user.profile.role.includes("SUPERUSER")}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="common-invalid-label"
                >
                  {MESSAGE.NAME_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="chracter-remain" style={{ marginRight: "60px" }}>
                {" "}
                {150 - hospitaData.name?.length}/150 Characters
              </div>

              <Form.Group controlId="formBasicAddress">
                <Form.Label className="common-form-label">
                  Hospital Address<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="common-form-input"
                  type="text"
                  placeholder="Hospital Address"
                  name="address"
                  maxLength="250"
                  isInvalid={addressInvalid}
                  value={hospitaData.address}
                  onChange={e => {
                    setAddressInvalid(false);
                    handleChange(e);
                    setIsBack(true);
                  }}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="common-invalid-label"
                >
                  {MESSAGE.ADDRESS_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="chracter-remain" style={{ marginRight: "60px" }}>
                {" "}
                {250 - hospitaData.address?.length}/250 Characters
              </div>

              {props.user.profile.role.includes("SUPERUSER") ? null : (
                <>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="common-form-label">
                      Email<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      className="common-form-input"
                      type="email"
                      placeholder="Hospital Email"
                      name="email"
                      isInvalid={emailInvalid}
                      value={hospitaData.email}
                      onChange={e => {
                        setEmailInvalid(false);
                        handleChange(e);
                        setIsBack(true);
                      }}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="common-invalid-label"
                    >
                      {MESSAGE.EMAIL_INVALID}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicSubscriptionPlan">
                    <Form.Label className="common-form-label">
                      Subscription Plan<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      as="select"
                      className="common-form-input img-class"
                      defaultValue={hospitaData.tier_plan}
                      name="tier_plan"
                      isInvalid={tierplanInvalid}
                      disabled="true"
                      onChange={e => {
                        setTierplanInvalid(false);
                        handleChange(e);
                        setIsBack(true);
                      }}
                    >
                      {props.plans &&
                        props.plans.map((plans, index) => {
                          return (
                            <option key={index} value={plans?.id}>
                              {plans?.type}
                            </option>
                          );
                        })}
                    </Form.Control>
                    <Form.Control.Feedback
                      type="invalid"
                      className="common-invalid-label"
                    >
                      {MESSAGE.TIER_PLAN_INVALID}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="common-form-label">
                      Subscription Start Date
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      className="common-form-input"
                      name="plan_start_date"
                      disabled="true"
                      value={moment(hospitaData.plan_start_date).format(
                        "MM/DD/YYYY"
                      )}
                      isInvalid={startDateInvalid}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicCode">
                    <Form.Label className="common-form-label">
                      Unique Code<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      className="common-form-input"
                      type="text"
                      placeholder="Enter 6 Digit Unique Code"
                      name="code"
                      maxLength="6"
                      value={hospitaData.code}
                      isInvalid={codeInvalid}
                      onChange={e => {
                        setCodeInvalid(false);
                        handleChange(e);
                        setIsBack(true);
                      }}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="common-invalid-label"
                    >
                      {MESSAGE.UNIQUE_CODE_INVALID}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </Col>
            <Col>
              {hospitaData.logo === null ? (
                <div className="upload-logo">
                  <p className="upload-label-logo" onClick={handleUploadClick}>
                    <img src={UPLOAD_PLUS} alt="" /> Add Hospital Logo
                  </p>
                </div>
              ) : (
                <div className="after-upload-logo">
                  <img
                    src={hospitaData.logo}
                    alt="image"
                    onClick={handleUploadClick}
                  />{" "}
                </div>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleLogoUpload}
                ref={logoInput}
              />
            </Col>
          </Row>
        </Form>

        <HospitalImage
          data={hospitaData}
          setData={setHospitalData}
          media={media}
          setMedia={setMedia}
          isBack = {setIsBack}
          saveModal ={true}
        />

        <HospitalUser
          className={"edit-hospital-user"}
          user={true}
          userTitle={true}
          addUser={true}
          healthHero={true}
          healthHeroTitle={true}
          addHealthhero={true}
          modal={modal}
          handleShow={handleShow}
          onChange={userOnChange}
          handleClose={handleClose}
          type={type}
          handleAddButton={handleAddButton}
          handleAddHero={addHealthHero}
          handleHealthHeroClick={data => handleEditHealthHero(data)}
          userdata={users}
          healthHerodata={props.hospitalDetails.health_heroes}
          handleDeleteHero={props.deleteHealthHero}
          hospitalId={props.hospitalDetails.id}
          isSuperUser={
            props.user.profile.role.includes("SUPERUSER") ? true : false
          }
        />
        <AlertDialogSlide
          open={showSaveModal}
          title={"Save changes"}
          message={"Do you want to save changes ?"}
          handleClose = {handleSaveModal}
          onSaveModal ={handleSubmit}
          loader={props.loader}
        />
      </Container>
      <Footer className={"my-footer"} />
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    hospitalDetails: state.hospitalDetails,
    plans: state.plans.data,
    loader: state.loader.mainLoader,
    addHospitalSuccess: state.addHospitalSuccess,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getHospitalDetails: actionCreator.getHospitalDetails,
      getHospitalData: actionCreator.getHospitalData,
      getSubscriptionPlans: actionCreator.getSubscriptionPlans,
      toggleMainLoader: actionCreator.toggleMainLoader,
      updateHospital: actionCreator.updateHospital,
      addSuccess: actionCreator.addHospitalSuccess,
      setEditHealthHero: actionCreator.setEditHealthHero,
      deleteHealthHero: actionCreator.deleteHealthHero,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHospital);
