import React from "react";
import { Navbar } from "react-bootstrap";
import Search from "./search";
import MyButton from "./myButton";
import { FILTER_ICON, BUTTON_SPINNER } from "../../constant";
import "./navbar.css";

const NavBar = (props) => {
  const { id, className, title, handleFilterClick } = props;
  return (
    <>
      <Navbar className={className}>
        <Navbar.Brand id={id}>{title}</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">

          {
            props?.isCsvButton &&
            (
                <button
                className="csv-user-button-css"
                onClick={props?.handelDownloadCsv}
              >
                {" "}
                {props?.csvLoader && (
                  <img src={BUTTON_SPINNER} height="30px" alt="" />
                )}{" "}
                {!props?.csvLoader && (
                  <span>
                    {" "}
                    Download CSV
                  </span>
                )}{" "}
              </button>
            )
          }

          {props.isSearch ? <Search
            value={props.searchValue}
            onChange={props.searchOnChange}
          /> : null
          }


          {props.isFilter ? <img src={FILTER_ICON}
            alt=""
            id="navbar-filter"
            onClick={handleFilterClick} /> : null}


          {props.isButton ? <MyButton
            name={props.buttonName}
            buttonPath={props.buttonClick}
          />
            : null}

        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
