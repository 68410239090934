import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Back from '../../common/components/back';
import { ROUTES_PATH } from '../../constant';
import NavBar from '../NavBar';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreator from "../../modules/actions";
import "../ContentManagement/Procedures/procedures.css";
import HospitalSubscriptionTable from './hospitalSubscriptionTable';
import FilterDropdown from '../../components/commonComponent/FilterDropdown';
import { getHospitalSubscriptionService } from '../../modules/services';
import { toast } from "react-toastify";


const HospitalSubscription = (props) => {

    const [subscriptionPageNumber, setSubscriptionPage] = useState(1);
    const [sort, setSort] = useState();
    const [csvLoader, setCsvLoader] = useState(false);


    useEffect(()=>{
        props.toggleMainLoader(true);
        props.getHospitalSubscriptionList(subscriptionPageNumber, sort)
        
    },[sort,subscriptionPageNumber])

    const handleBack = () => {
        props.history.push(ROUTES_PATH.HOME)
    }

    const changePagePublished = selected => {
        setSubscriptionPage(selected);
        props.toggleMainLoader(true);
        props.getHospitalSubscriptionList(selected)
      };

      const handleDownloadCsv = () => {
        setCsvLoader(true);
        getHospitalSubscriptionService(subscriptionPageNumber, sort, true)
          .then(response =>{
            if (response.status === 200 || response.status === 201) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `hospital_subscription.csv`);
              document.body.appendChild(link);
              link.click();
              link.remove();
              setCsvLoader(false)
              toast.success("CSV file downloaded successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(err=> {
            console.error(err)
            setCsvLoader(false)
            toast.error("Download Failed", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })

    }

    return (
        <>
            <Container style={{ minHeight: "100vh", position: "relative", maxWidth: "1046px" }}>
                <Back
                    className={"back-nav"}
                    backNavItemClassName={"back-nav-item"}
                    backIconClassName={"back-icon"}
                    isSearch={false}
                    isDelete={false}
                    isSave={false}
                    Back={handleBack}
                />
                <NavBar
                    title={"Hospital Subscriptions"}
                    id={"SubscriberManagementTitle-css"}
                    className={"main-navbar"}
                    isSearch={false}
                    isFilter={false}
                    isButton={false}
                />
                <HospitalSubscriptionTable 
                dataList={props?.hospitalSubscriptionList?.results}
                rowPerPage={10}
                itemCountFromServer={props?.hospitalSubscriptionList?.count}
                changePage={changePagePublished}
                pageNumber={subscriptionPageNumber}
                sortDropdown = {<FilterDropdown  sort = {setSort} expiry={true} value = {sort}/>}
                isCsvButton = {true}
                handelDownloadCsv = {handleDownloadCsv}
                csvLoader = {csvLoader}                
                />
                
            </Container>

        </>
    )
};
const mapStateToProps = state => {
    return {
        responseSuccess: state.responseSuccess.responseSuccess,
        loader: state.loader.mainLoader,
        hospitalSubscriptionList: state.hospitalSubscriptionListReducer
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            reset: actionCreator.reset,
            toggleMainLoader: actionCreator.toggleMainLoader,
            getHospitalSubscriptionList: actionCreator.getHospitalSubscriptionList
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalSubscription);
