import React, { useState } from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import "./forgotPassword.css";
import CommonButton from "../commonComponent/commonButton";
import CommonLogoComponent from "../commonComponent/commonLogoComponent";
import { ROUTES_PATH, MESSAGE } from "../../constant";
import { validateEmail } from "../../utils/validations";
import { getUserForgotPasswordRequestUrl } from "../../utils/urls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../modules/actions";
import axios from "axios";
import { toast } from "react-toastify";

const ForgotPassword = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [data, setData] = useState({
    email: "",
    request_status: "UU",
  });

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setSubmittedOnce(true);
    setDisabled(true);
    setShowLoader(true);
    let validatedEmail = validateEmail(data.email);
    if (!validatedEmail) {
      setEmailInvalid(true);
      setDisabled(false);
      setShowLoader(false);
      return;
    }
    if (validatedEmail) {
      setEmailInvalid(false);
      setDisabled(true);
      setShowLoader(true);
      axios
        .post(getUserForgotPasswordRequestUrl, data)
        .then((response) => {
          if (response.data.status_code === 200) {
            props.forgotPasswordRequestSuccess({
              email: data.email,
              forgotPasswordRequestSuccess: true,
            });

            setDisabled(false);
            setShowLoader(false);
            props.history.push(ROUTES_PATH.VERIFY_OTP);
          } else {
            setDisabled(false);
            setShowLoader(false);
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          setDisabled(false);
          setShowLoader(false);
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!disabled) {
        handleForgotPassword(e);
      }
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="px-0 mb-0">
            <CommonLogoComponent />
          </Col>
          <Col className="px-0">
            <Form className="password-main" noValidate>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="password-label">
                  {MESSAGE.FORGOT_PASSWORD_LABEL1}
                </Form.Label>
                <Form.Label className="password-label1">
                  {MESSAGE.FORGOT_PASSWORD_LABEL}
                </Form.Label>
                <Form.Control
                  className="password-email"
                  type="email"
                  placeholder="Enter Email"
                  onKeyPress={handleKeyPress}
                  isInvalid={emailInvalid}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                    submittedOnce
                      ? setEmailInvalid(!validateEmail(e.target.value))
                      : setEmailInvalid(false);
                  }}
                />
                <Form.Control.Feedback
                  className="password-invalid-email"
                  type="invalid"
                >
                  {MESSAGE.EMAIL_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
              <CommonButton
                buttonValue={MESSAGE.SEND_OTP}
                className={"common-button-css"}
                showLoader={showLoader}
                disabled={disabled}
                handleButtonAction={handleForgotPassword}
              ></CommonButton>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  try {
    if (state.forgotPasswordInformation) {
      return {
        forgotPasswordInformation:
          state.forgotPasswordInformation.forgotPasswordRequest,
      };
    }
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgotPasswordRequestSuccess: actionCreator.forgotPasswordRequestSuccess,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
