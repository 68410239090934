import { REGEX } from "../constant";

export const validateEmail = (email) => {
  return REGEX.EMAIL.test(email);
};
export const validatePassword = (password) => {
  return REGEX.PASSWORD.test(password);
};

export const validatePasswordNonEmpty = (password) => {
  if (password !== "") {
    return true;
  }
  return false;
};

export const validateConfirmPassword = (password, confirmPassword) => {
  if ((password && confirmPassword) !== "") {
    if (password === confirmPassword) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const validateGender = (gender) => {
  if (
    gender.toLocaleUpperCase() !== "MALE" &&
    gender.toLocaleUpperCase() !== "FEMALE"
  ) {
    return false;
  }
  return true;
};

export const validatePhoneNumber = (str) => {
  return REGEX.CONTACT.test(str.toString());
};

export const validateAreasOfInterest = (str) => {
  return REGEX.COMMA_AND_ALPHABET.test(str);
};

export const validateCertification = (str) => {
  return REGEX.CERTIFICATE_NUMBER.test(str);
};
export const validatePlantype = (str) => {
  return REGEX.CHARACTER_DASH_NUMBER.test(str);
};

export const validatePlanDuration = (str) => {
  return REGEX.NUMBER_DASH_CHARACTER.test(str);
};

export const validateUniqueCode = (str) => {
  if (str.length === 6) {
    return REGEX.ONLY_NUMBER.test(str.toString());
  } else {
    return false;
  }
};

export const validateName = (str) => {
  return REGEX.VALID_NAME.test(str);
};

export const validateAddress = (str) => {
  return REGEX.ADDRESS.test(str);
};

export const validatePicture = (str) => {
  return REGEX.ONLY_PICTURES.test(str);
};

export const validateVideo = (str) => {
  return REGEX.ONLY_VIDEOS.test(str);
};

export const validateTextField = (str) => {
  return REGEX.TEXTFIELD.test(str);
};

export const validateSpecialization = (str) => {
  return REGEX.ONLY_CHARACTER.test(str);
};

export const validateChar = (str) => {
  if (REGEX.TEXTFIELD.test(str)) {
    if (!isNaN(str)) {
      return false;
    } else {
      return !REGEX.ONLY_SPECIAL_CHAR.test(str);
    }
  }
  return false;
};
export const validateMeaning = (str) => {
  return REGEX.NO_SPECIAL_CHARACTER.test(str);
};
