import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { BUTTON_SPINNER } from "../../constant";
import "./commonComponent.css";

const CommonButton = (props) => {
  let {
    className,
    buttonValue,
    handleButtonAction,
    disabled,
    showLoader,
    loaderheight,
    loaderImg,
  } = props;
  return (
    <>
      <Button
        id={className}
        disabled={disabled || showLoader}
        onClick={handleButtonAction}
      >
        {" "}
        {showLoader && (
          <img src={loaderImg} height={loaderheight} alt="" />
        )}{" "}
        {!showLoader && (
          <span id={"common-button-value-css"}>{buttonValue}</span>
        )}
      </Button>
    </>
  );
};

CommonButton.propTypes = {
  buttonValue: PropTypes.string,
  handleButtonAction: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showLoader: PropTypes.bool,
  loaderheight: PropTypes.string,
  loaderImg: PropTypes.string,
};

CommonButton.defaultProps = {
  buttonValue: "",
  className: undefined,
  disabled: false,
  showLoader: false,
  loaderheight: "30px",
  loaderImg: BUTTON_SPINNER,
};

export default CommonButton;
