import React from 'react';
import { Table } from 'react-bootstrap';
import { VIEW_ICON, COPY_HOSPITAL } from '../../constant';
import './subscribe.css';
import FullPageLoader from "../commonComponent/mainLoaderSpinner";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const TableData = (props) => {
  const { data, viewClick, loader, copyClick } = props

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Hospital Name</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.length ? data.map((data, index) => {
          return (
            <tr key={index}>
               <OverlayTrigger
                   placement="top"
                   delay={{ show: 250, hide: 400 }}
                   overlay = {
                     <Tooltip className="tooltip-top">
                       <div className="mb-1">
                         Hospital Name : {data.name}
                       </div>
                     </Tooltip>
                   }
                   >
                    <td style={{ padding: "24px 10px" }}>{data.name.slice(0,29)}</td>
                   </OverlayTrigger>
              
              <td style={{ padding: "24px 10px" }}>{data.email}</td>
              <td style={{ padding: "16px 0px 16px 40px" }}>
                <button id="detail-view" onClick={() => { viewClick(index) }}>
                  View <img src={VIEW_ICON} alt='' style={{ marginLeft: "8px" }} /> </button></td>
                  <td style={{ padding: "16px 0px 16px 40px" }}>
                <button id="copy-hospital" onClick={() => { copyClick(index) }}>
                  Copy<img src={COPY_HOSPITAL} alt='' style={{ marginLeft: "8px" }} /> </button></td>
            </tr>
          )
        }) : loader ? <FullPageLoader className="spinner-position" /> :
          (
            <div className="no-results-found">No results found!!</div>
          )}
      </tbody>
    </Table>
  );
}

export default TableData;
