import { takeEvery, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as hospitalMiddleware from "./hospitalSagas";
import * as userManagementMiddleWare from "./userManagementSagas";
import * as planMiddleware from "./planSaga";
import * as contentMiddleware from "./contentSagas";
import * as newContentMiddleware from "./newContentSagas";

export default function* mySaga() {
  yield takeLatest(
    actionTypes.GET_ALL_HOSPITALS,
    hospitalMiddleware.getAllHospitalSagaCall
  );
  yield takeEvery(
    actionTypes.SEARCH_HOSPITALS,
    hospitalMiddleware.searchHospitalSagaCall
  );
  yield takeEvery(
    actionTypes.ADD_HOSPITAL,
    hospitalMiddleware.addHospitalSagaCall
  );
  yield takeEvery(
    actionTypes.ADD_HEALTH_HERO,
    hospitalMiddleware.addHealthHeroSagaCall
  );
  yield takeEvery(
    actionTypes.EDIT_HEALTH_HERO,
    hospitalMiddleware.editHealthHeroSagaCall
  );
  yield takeEvery(
    actionTypes.DELETE_HEALTH_HERO,
    hospitalMiddleware.deleteHealthHeroSagaCall
  );
  yield takeEvery(actionTypes.ADD_CLS, hospitalMiddleware.addClsSagaCall);
  yield takeEvery(
    actionTypes.ADD_CLINICIAN,
    hospitalMiddleware.addClinicianSagaCall
  );
  yield takeEvery(
    actionTypes.GET_HOSPITAL_DETAILS,
    hospitalMiddleware.getHospitalData
  );

  yield takeEvery(actionTypes.RENEW_PLAN, hospitalMiddleware.renewPlanSagaCall);

  yield takeEvery(
    actionTypes.UPDATE_HOSPITAL,
    hospitalMiddleware.updateHospitalSagaCall
  );
  yield takeEvery(
    actionTypes.DELETE_HOSPITAL,
    hospitalMiddleware.deleteHospitalSagaCall
  );
  yield takeEvery(
    actionTypes.FILTER_HOSPITAL,
    hospitalMiddleware.filterHospitalSagaCall
  );
  yield takeEvery(
    actionTypes.GET_ALL_PARENT_CHILD_LIST,
    userManagementMiddleWare.getAllParentChildListSagaCall
  );

  yield takeLatest(
    actionTypes.DELETE_PARENT,
    userManagementMiddleWare.deleteParentSaga
  );

  yield takeLatest(
    actionTypes.DELETE_CHILD,
    userManagementMiddleWare.deleteChildSaga
  );

  yield takeEvery(
    actionTypes.GET_ALL_CLS_LIST,
    userManagementMiddleWare.getAllClsListSagaCall
  );

  yield takeLatest(
    actionTypes.DELETE_CLS,
    userManagementMiddleWare.deleteClsSaga
  );
  yield takeEvery(
    actionTypes.GET_ALL_CLINICIAN_LIST,
    userManagementMiddleWare.getAllClinicianListSagaCall
  );

  yield takeLatest(
    actionTypes.DELETE_CLINICIAN,
    userManagementMiddleWare.deleteClinicianSaga
  );
  yield takeLatest(
    actionTypes.GET_SUBSCRIPTION_PLANS,
    planMiddleware.getSubscriptionsSagaCall
  );
  yield takeLatest(
    actionTypes.SEARCH_REQUEST,
    userManagementMiddleWare.searchSaga
  );
  yield takeLatest(
    actionTypes.FILTER_REQUEST,
    userManagementMiddleWare.filterSaga
  );

  yield takeEvery(
    actionTypes.ADD_SUBSCRIPTION_PLAN,
    planMiddleware.addSubscriptionPlanSagacall
  );
  yield takeEvery(
    actionTypes.EDIT_SUBSCRIPTION_PLAN,
    planMiddleware.editSubscriptionPlanSagacall
  );

  yield takeLatest(
    actionTypes.GET_QUOTES_REQUEST,
    contentMiddleware.quotesSaga
  );

  yield takeLatest(
    actionTypes.GET_PUBLISHED_QUOTES,
    contentMiddleware.publishedQuotesSaga
  );

  yield takeLatest(
    actionTypes.PUBLISH_QUOTE,
    contentMiddleware.publishQuoteSaga
  );

  yield takeLatest(
    actionTypes.GET_PROCEDURES_REQUEST,
    contentMiddleware.proceduresSaga
  );
  yield takeLatest(
    actionTypes.GET_SCHEDULED_PROCEDURE,
    contentMiddleware.scheduledProceduresSaga
  );
  yield takeLatest(
    actionTypes.GET_PUBLISHED_PROCEDURE,
    contentMiddleware.publishedProceduresSaga
  );

  yield takeLatest(
    actionTypes.GET_FULL_HOSPITALS,
    hospitalMiddleware.getFullHospitalSaga
  );

  yield takeEvery(
    actionTypes.APPROVE_PROCEDURE,
    contentMiddleware.approveProcedureSagaCall
  );

  yield takeLatest(
    actionTypes.GET_CHILD_PROCEDURE,
    contentMiddleware.ChildProceduresSagaCall
  );

  yield takeLatest(
    actionTypes.ADD_PROCEDURE,
    contentMiddleware.addProcedureSaga
  );
  yield takeLatest(
    actionTypes.GET_GLOSSARY_LIST,
    contentMiddleware.getGlossarySagaCall
  );

  yield takeLatest(
    actionTypes.GET_SCHEDULED_GLOSSARY,
    contentMiddleware.scheduledGlossarySaga
  );
  yield takeLatest(
    actionTypes.GET_PUBLISHED_GLOSSARY,
    contentMiddleware.publishedGlossarySaga
  );

  yield takeLatest(actionTypes.ADD_GLOSSARY, contentMiddleware.addGlossarySaga);

  yield takeEvery(
    actionTypes.GLOSSARY_ACTION,
    contentMiddleware.glossaryActionSagaCall
  );
  yield takeLatest(
    actionTypes.GET_CHILD_QUOTES,
    contentMiddleware.getChildQuotesSagaCall
  );
  yield takeEvery(
    actionTypes.CHILD_QUOTES_ACTION,
    contentMiddleware.childQuotesActionSagaCall
  );

  yield takeLatest(
    actionTypes.GET_SCHEDULED_PODCAST,
    contentMiddleware.scheduledPodcastSaga
  );

  yield takeLatest(
    actionTypes.GET_PUBLISHED_PODCAST,
    contentMiddleware.publishedPodcastSaga
  );

  yield takeLatest(actionTypes.ADD_PODCAST, contentMiddleware.addPodcastSaga);

  yield takeLatest(
    actionTypes.GET_HEALTH_HEROES,
    contentMiddleware.getHealthHeroesSagaCall
  );
  yield takeEvery(
    actionTypes.HEALTH_HERO_ACTIONS,
    contentMiddleware.healthHerosActionSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PICTURES,
    contentMiddleware.getPicturesSagaCall
  );
  yield takeEvery(
    actionTypes.PICTURE_ACTION,
    contentMiddleware.picturesActionSagaCall
  );

  yield takeLatest(
    actionTypes.GET_SCHEDULED_STORY,
    newContentMiddleware.scheduledStorySaga
  );
  yield takeLatest(
    actionTypes.GET_PUBLISHED_STORY,
    newContentMiddleware.publishedStorySaga
  );
  yield takeLatest(
    actionTypes.GET_PUBLISHED_HEALTH_HEROES,
    contentMiddleware.publishedHealthHeroesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_SCHEDULED_PICTURES,
    contentMiddleware.schedulePicturesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PUBLISHED_PICTURES,
    contentMiddleware.publishedPicturesSagaCall
  );
  yield takeEvery(
    actionTypes.ADD_PICTURE,
    contentMiddleware.addPictureSagaCall
  );

  yield takeLatest(actionTypes.ADD_STORY, newContentMiddleware.addStorySaga);

  yield takeLatest(
    actionTypes.GET_PUBLISHED_CHILD_QUOTES,
    contentMiddleware.publishedChildSaga
  );

  yield takeLatest(
    actionTypes.GET_SCHEDULED_WHATS_GOING_ON_ME,
    contentMiddleware.scheduledWhatsGoingOnMeSagaCall
  );

  yield takeLatest(
    actionTypes.GET_PUBLISHED_WHATS_GOING_ON_ME,
    contentMiddleware.publishedWhatsGoingOnMeSagaCall
  );

  yield takeEvery(
    actionTypes.ADD_WHATS_GOING_ON_ME,
    contentMiddleware.addWhatsGoingOnMeSagaCall
  );

  yield takeEvery(
    actionTypes.GET_SCHEDULED_RESOURCES,
    newContentMiddleware.scheduledResourcesSaga
  );

  yield takeLatest(
    actionTypes.GET_PUBLISHED_RESOURCES,
    newContentMiddleware.publishedResourcesSaga
  );
  
  yield takeLatest(
    actionTypes.GET_PARENT_RESOURCES,
    newContentMiddleware.publishedParentResourcesSaga
  );

  yield takeLatest(
    actionTypes.ADD_RESOURCES,
    newContentMiddleware.addResourcesSaga
  );

  yield takeLatest(
    actionTypes.ADD_PARENT_RESOURCES,
    newContentMiddleware.addParentResourcesSaga
  );

  yield takeLatest(
    actionTypes.ADD_PROFESSIONAL_RESOURCES,
    newContentMiddleware.addProfessionalResourcesSaga
  );

  yield takeLatest(
    actionTypes.GET_RESOURCES_CATEGORY,
    newContentMiddleware.resourcesCategorySaga
  );

  yield takeLatest(
    actionTypes.GET_RESOURCES_REQUEST,
    newContentMiddleware.awaitingResourcesSaga
  );

  yield takeLatest(
    actionTypes.GET_OTHER_RESOURCES_REQUEST,
    newContentMiddleware.otherResourcesSaga
  );
  yield takeEvery(
    actionTypes.APPROVE_RESOURCES,
    newContentMiddleware.approveResourcesSaga
  );

  yield takeLatest(
    actionTypes.ACTIVATION,
    userManagementMiddleWare.activationSaga
  );
  yield takeLatest(
    actionTypes.DELETE_PROCEDURE,
    contentMiddleware.deleteProcedureSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_DAILYQUOTES,
    contentMiddleware.deleteDailyQuotesSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_GLOSSARY,
    contentMiddleware.deleteGlossarySagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_PODCAST,
    contentMiddleware.deletePodcastSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_PICTURES,
    contentMiddleware.deletePictureSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_RESOURCES,
    contentMiddleware.deleteResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_PARENT_RESOURCES,
    contentMiddleware.deleteParentResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_PROFESSIONAL_RESOURCES,
    contentMiddleware.deleteProfessionalResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_STORY,
    contentMiddleware.deleteStorySagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_WHATSGOINGONME,
    contentMiddleware.deleteWhatsGoingonMeSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_DAILYQUOTES,
    contentMiddleware.updateDailyQuoteSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_GLOSSARY,
    contentMiddleware.updateGlossarySagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_PODCAST,
    contentMiddleware.updatePodcastSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_RESOURCES,
    newContentMiddleware.updateResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_PARENT_RESOURCES,
    newContentMiddleware.updateParentResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_PROFESSIONAL_RESOURCES,
    newContentMiddleware.updateProfessionalResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_STORY,
    newContentMiddleware.updateStorySagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_WHATSGOINGONME,
    contentMiddleware.updateWhatsGoingOnMeSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_PICTURE,
    contentMiddleware.updatePictureSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_PROCEDURE,
    contentMiddleware.updateProcedureSaga
  );
  yield takeLatest(
    actionTypes.GET_COMMUNITY_POSTS,
    contentMiddleware.getPostsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_SCHEDULED_POSTS,
    contentMiddleware.getScheduledPostsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PUBLISHED_POSTS,
    contentMiddleware.getPublishedPostsSagaCall
  );
  yield takeEvery(
    actionTypes.POST_ACTION,
    contentMiddleware.postActionSagaCall
  );
  yield takeLatest(
    actionTypes.DOWNLOAD_USER,
    hospitalMiddleware.downloadUserSagaCall
  );
  yield takeLatest(
    actionTypes.GET_TOP_RESOURCES,
    newContentMiddleware.getTopResourcesSaga
  );
  yield takeLatest(
    actionTypes.ADD_TOP_RESOURCES,
    newContentMiddleware.addTopResourcesSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_TOP_RESOURCES,
    newContentMiddleware.updateTopResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_TOP_RESOURCES,
    newContentMiddleware.deleteTopResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_HOSPITAL_CATEGORIES,
    newContentMiddleware.getHospitalCategoriesSaga
  );
  yield takeLatest(
    actionTypes.ADD_HOSPITAL_CATEGORIES,
    newContentMiddleware.addHospitalCategoriesSaga
  );
  yield takeLatest(
    actionTypes.DELETE_HOSPITAL_CATEGORIES,
    newContentMiddleware.deleteHospitalCategoriesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PARENT_CATEGORIES,
    newContentMiddleware.getParentCategoriesSaga
  );
  yield takeLatest(
    actionTypes.ADD_PARENT_CATEGORIES,
    newContentMiddleware.addParentCategoriesSaga
  );
  yield takeLatest(
    actionTypes.DELETE_PARENT_CATEGORIES,
    newContentMiddleware.deleteParentCategoriesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PROFESSIONAL_CATEGORIES,
    newContentMiddleware.getProfessionalCategoriesSaga
  );
  yield takeLatest(
    actionTypes.ADD_PROFESSIONAL_CATEGORIES,
    newContentMiddleware.addProfessionalCategoriesSaga
  );
  yield takeLatest(
    actionTypes.DELETE_PROFESSIONAL_CATEGORIES,
    newContentMiddleware.deleteProfessionalCategoriesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_ALL_PERSONAL_CATEGORIES,
    newContentMiddleware.getAllParentCategoriesSaga
  );
  yield takeLatest(
    actionTypes.GET_ALL_PROFESSIONAL_CATEGORIES,
    newContentMiddleware.getAllProfessionalCategoriesSaga
  );
  yield takeLatest(
    actionTypes.GET_ALL_HOSPITAL_CATEGORIES,
    newContentMiddleware.getAllHospitalCategoriesSaga
  );

  yield takeLatest(
    actionTypes.UPDATE_HOSPITAL_CATEGORY,
    newContentMiddleware.updateHospitalCategorySagaCall
  );

  yield takeLatest(
    actionTypes.UPDATE_PARENT_CATEGORY,
    newContentMiddleware.updateParentCategorySagaCall
  );

  yield takeLatest(
    actionTypes.UPDATE_PROFESSIONAL_CATEGORY,
    newContentMiddleware.updateProfessionalCategorySagaCall
  );
  yield takeLatest(
    actionTypes.SEARCH_GLOBAL,
    newContentMiddleware.searchGlobalSagaCall
  );

  yield takeLatest(
    actionTypes.UPDATE_PICTURE_HOSPITAL,
    contentMiddleware.updatePictureHospitalSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_PROCEDURE_HOSPITAL,
    contentMiddleware.updateProcedureHospitalSaga
  );
  
  yield takeLatest(
    actionTypes.GET_HOSPITAL_SUBSCRIPTION_LIST,
    newContentMiddleware.hospitalSubscriptionListSaga
  );
  
}

