import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import CardItem from "../Card";
import { ROUTES_PATH } from "../../constant";
import { connect } from "react-redux";
import * as actionCreator from "../../modules/actions";
import { bindActionCreators } from "redux";
import "./dashboard.css"

const Dashboard = props => {
  useEffect(() => {
    if (props.role.includes("SUPERUSER")) {
      props.getHospitalData(props.hospital_id);
    }
  }, []);
  return (
    <>
      <div className="container" style={{ minHeight: "100vh" }}>
        <Header />
        <div className="row">
          {props.role[0] === "ADMIN" ? (
            <>
            <div className="col-sx-12 col-sm-6 col-md-6 col-lg-4">
            <CardItem
                className={"my-card"}
                title={"Subscriber management"}
                subtitle={"Manage hospitals and subscriptions"}
                onClick={() => props.history.push(ROUTES_PATH.SUBSCRIBER)}
              />
            </div>
              <div className="col-sx-12 col-sm-6 col-md-6 col-lg-4">
              <CardItem
                className={"my-card"}
                title={"Manage subscription plans"}
                subtitle={"Add or edit subscription plans"}
                onClick={() =>
                  props.history.push(ROUTES_PATH.SUBSCRIPTION_PLAN)
                }
              />
              </div>
              
            </>
          ) : null}
          {props.role.includes("SUPERUSER") ? (
            <>
            <div className="col-sx-12 col-sm-6 col-md-6 col-lg-4">
              <CardItem
                className={"my-card"}
                title={"My Hospital"}
                subtitle={"View and manage my hospital"}
                onClick={() => props.history.push(ROUTES_PATH.HOSPITAL_VIEW)}
              /></div>
            </>
          ) : null}
          <div className="col-sx-12 col-sm-6 col-md-6 col-lg-4">
          <CardItem
            className={"my-card"}
            title={"User management"}
            subtitle={"Manage CLOC app users"}
            onClick={() => {
              props.history.push(ROUTES_PATH.USER_MANAGEMENT);
            }}
          />
          </div>
          <div className="col-sx-12 col-sm-6 col-md-6 col-lg-4">
          <CardItem
            className={"my-card"}
            title={"Content management"}
            subtitle={"Add and update content on the app"}
            onClick={() => {
              props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
            }}
          />
          </div>
          <div className="col-sx-12 col-sm-6 col-md-6 col-lg-4">
          <CardItem
            className={"my-card"}
            title={"Content awaiting approval"}
            subtitle={"Approve submissions by users"}
            onClick={() => {
              props.history.push(ROUTES_PATH.CONTENT_AWAITING_APPROVAL);
            }}
          />
          </div>

          {props.role[0] === "ADMIN" ? (
            <div className="col-sx-12 col-sm-6 col-md-6 col-lg-4">
          <CardItem
            className={"my-card"}
            title={"Hospital Subscriptions"}
            subtitle={"Hospital subscription by users"}
            onClick={() => {
              props.history.push(ROUTES_PATH.HOSPITAL_SUBSCRIPTION);
            }}
          />
          </div>
          ) : null  
        }

        </div>
      </div>
      <Footer className={"dashboard-footer"} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    role: state.user.profile.role,
    hospital_id: state.user.profile.hospital_id,
    loader: state.loader.mainLoader,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getHospitalData: actionCreator.getHospitalData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);