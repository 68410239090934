import React from "react";
import { DELETE_WARNING, CROSS_ICON } from "../../constant/images";
import "../../components/UserManagement/UserManagementParentChildDetails/userManagementParentChildDetails.css";
import { Modal } from "react-bootstrap";

const DeleteWarning = (props) => {
  const { handleCancelClose, handleClose, show, id } = props;
  return (
    <>
      <Modal
        size="lg"
        centered
        className={"parent-delete-modal"}
        show={show}
        onHide={handleCancelClose}
        contentClassName="modal-content-delete"
      >
        <Modal.Header className={"parent-delete-header"}>
          <img
            className="close-icon-delete"
            src={CROSS_ICON}
            alt=""
            onClick={props.handleCancelClose}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
          }}
        >
          <img src={DELETE_WARNING} alt=""></img>
          <Modal.Title className="parent-modal-title">
            Delete Warning!
          </Modal.Title>
          <Modal.Title className="modal-parent-text">
            Once you delete this profile you won’t be able to reverse this
            action, please make sure that the profile you want to delete is
            correct.
          </Modal.Title>

          <button
            className="back-button-modal"
            onClick={() => handleCancelClose(id)}
          >
            Go Back
          </button>
          <button
            className="delete-button-modal"
            onClick={() => handleClose(id)}
          >
            Delete
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DeleteWarning;
