import Footer from "../../Footer";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HospitalUser from "./hospitalUser";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import { Col, Container, Row,Tooltip, OverlayTrigger } from "react-bootstrap";
import TitleBar from "../../../common/components/titleBar";
import * as actionCreator from "../../../modules/actions";
import UserModal from "./UserModal";
import moment from "moment";
import { validateVideo } from "../../../utils/validations";
import { downloadUser } from "../../../utils/urls";
import axios from "axios";

const Hospital = props => {
  useEffect(() => {
    setModal(false);
    setRenew(false);
  }, [props.hospitalDetails]);

  const [modal, setModal] = useState(false);
  const [remove, setRemove] = useState(false);
  const [renew, setRenew] = useState(false);
  const [media, setMedia] = useState([]);
  const {
    id,
    name,
    logo,
    address,
    pictures,
    videos,
    tier_plan,
    health_heroes,
    plan_end_date,
  } = props.hospitalDetails;

  const [planData, setPlanData] = useState({
    tier_plan: tier_plan?.id ? tier_plan?.id : tier_plan,
    plan_start_date: plan_end_date,
  });

  //formatting time e.g:may 22, 2022
  const time = moment(plan_end_date).format("MMMM Do YYYY");

  let users = [];
  if (props.hospitalDetails.cls && props.hospitalDetails.clinician) {
    const { cls, clinician } = props.hospitalDetails;
    users = [...users, ...cls, ...clinician];
  }

  //useEffect to concatenate pictures and videos in one array
  useEffect(() => {
    if (pictures && videos) {
      setMedia([...pictures, ...videos]);
    } else if (!pictures && videos) {
      setMedia([...videos]);
    } else if (pictures && !videos) {
      setMedia([...pictures]);
    } else {
      setMedia([]);
    }
  }, [pictures, videos]);

  const headingclass = "title-bar-heading";

  const handleEdit = () => {
    props.history.push(ROUTES_PATH.EDIT_HOSPITAL);
  };

  const scroller = useRef(null);
  const scroll = scrollOffset => {
    scroller.current.scrollLeft += scrollOffset;
  };

  const handleBack = () => {
    if (props.user.profile.role.includes("SUPERUSER")) {
      props.history.push(ROUTES_PATH.HOME);
    } else {
      props.history.push(ROUTES_PATH.SUBSCRIBER);
    }
  };

  const deleteHospital = () => {
    setModal(true);
    setRemove(true);
  };

  const handleClose = () => {
    setModal(false);
    setRemove(false);
    setRenew(false);
  };

  //function to open renew modal
  const renewPlan = () => {
    setModal(true);
    setRenew(true);
  };

  //function to renew the plan
  const handleRenewPlan = e => {
    e.preventDefault();
    props.toggleMainLoader(true);
    props.renewPlan(id, planData);
  };

  const handleDelete = () => {
    props.toggleMainLoader(true);
    props.deleteHospital(id);
    props.history.push(ROUTES_PATH.SUBSCRIBER);
  };

  const downloadUser = e => {
    e.preventDefault();
    props.download(id, name);
  };

  return (
    <Container className="common-container">
      <Back
        className={"back-nav"}
        backNavItemClassName={"back-nav-item"}
        backIconClassName={"back-icon"}
        isEdit={true}
        isDelete={props.user.profile.role.includes("SUPERUSER") ? false : true}
        isSave={false}
        isDownload={true}
        handleEdit={handleEdit}
        Back={handleBack}
        onDelete={deleteHospital}
        onDownload={downloadUser}
      />

      <TitleBar
        titlename={"Hospital Information"}
        headingClass={headingclass}
      />

      <Row>
        <Col style={{ marginLeft: "16px" }}>
          <p className="hospital-name">{name}</p>
          <p className="hospital-address">{address}</p>
        </Col>
        <Col>
          {logo ? (
            <img src={logo} alt="image" className="hospital-logo" />
          ) : null}
        </Col>
      </Row>

      {props.user.profile.role.includes("SUPERUSER") ? null : (
        <>
          <TitleBar titlename={"Plan Details"} headingClass={headingclass} />

          <Row>
            <Col xs={6} style={{ marginLeft: "16px", marginBottom: "40px" }}>
              <div className="plan-card">
                <button className="renew-btn" onClick={renewPlan}>
                  Renew Plan
                </button>
                <p className="plan-duration">{tier_plan?.type}</p>
                <p className="plan-date">Expiring On {time}</p>
              </div>
            </Col>
          </Row>
        </>
      )}

      <TitleBar
        titlename={"Hospital Videos & Pictures"}
        headingClass={headingclass}
        isScroll={true}
        onClick={scroll}
      />

      <div className="row image-row" ref={scroller}>
        {media &&
          media.map((item, index) => {
            let splittedUrl = item?.split("?title=")
            let photoTitle = splittedUrl[1]?.split("&estitle=")

            return (
              <>
              <div key={index}>
                {validateVideo(splittedUrl[0]) ? (
                  <>
                  <video
                    className="hospital-image-video"
                    width="140"
                    height="140"
                    controls
                  >
                    <source src={splittedUrl[0]} type="video/mp4" />
                  </video>
                  <div style={{paddingLeft: "10px"}}>
                  <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay = {
                      <Tooltip className="tooltip-top">
                      <div className="mb-1">
                          Title : {photoTitle?.length > 0 ? photoTitle[0].split("?").join(" ") : ""}
                      </div>
                      </Tooltip>
                  }
                  >
                      <p className="user-name">{photoTitle?.length > 0 ? photoTitle[0].slice(0,15).split("?").join(" ") : ""}</p>
                  </OverlayTrigger>
                  </div>
                  </>
                  
                ) : (
                  <>
                  <img
                    key={index}
                    src={splittedUrl[0]}
                    alt=""
                    className="hospital-image-video "
                  />
                  <div style={{paddingLeft: "10px"}}>
                      <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay = {
                          <Tooltip className="tooltip-top">
                          <div className="mb-1">
                              Title : {photoTitle?.length > 0 ? photoTitle[0].split("?").join(" ") : ""}
                          </div>
                          </Tooltip>
                      }
                      >
                          <p className="user-name">{photoTitle?.length > 0 ? photoTitle[0].slice(0,15).split("?").join(" ") : "" }</p>
                      </OverlayTrigger>
                      </div>
                  </>
                )}
              </div>
              
              </>
            );
          })}
      </div>

      <HospitalUser
        user={true}
        userTitle={false}
        healthHero={true}
        healthHeroTitle={false}
        addUser={false}
        addHealthhero={false}
        healthHerodata={health_heroes}
        userdata={users}
        isSuperUser={
          props.user.profile.role.includes("SUPERUSER") ? true : false
        }
      />
      <UserModal
        modal={modal}
        handleClose={handleClose}
        loader={props.loader}
        isRenew={renew}
        plans={props.plans}
        planData={planData}
        setPlanData={setPlanData}
        handleRenewPlan={handleRenewPlan}
        isdelete={remove}
        handleDelete={handleDelete}
      />

      <Footer className={"my-footer"} />
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    hospitalDetails: state.hospitalDetails,
    loader: state.loader.mainLoader,
    plans: state.plans.data,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getHospitalDetails: actionCreator.getHospitalDetails,
      toggleMainLoader: actionCreator.toggleMainLoader,
      renewPlan: actionCreator.renewPlan,
      download: actionCreator.downloadUser,
      getHospitalData: actionCreator.getHospitalData,
      deleteHospital: actionCreator.deleteHospital,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Hospital);
