import React, { useEffect, useState, useRef } from "react";
import {
  UPLOAD_PLUS,
  ROUTES_PATH,
  MESSAGE,
  BUTTON_SPINNER,
} from "../../../constant";
import { Col, Container, Form, Row } from "react-bootstrap";
import TitleBar from "../../../common/components/titleBar";
import Back from "../../../common/components/back";
import Footer from "../../Footer";
import { S3Client } from "../../../utils/upload";
import "./hospital.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreator from "../../../modules/actions";
import {
  validateName,
  validateEmail,
  validateUniqueCode,
  validateAddress,
} from "../../../utils/validations";
import UserModal from "./UserModal";
import HospitalImage from "./hospitalImage";
import DatePicker from "react-datepicker";
import moment from "moment";
import HospitalUser from "./hospitalUser";
import { toast } from "react-toastify";

const AddHospital = props => {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [tierplanInvalid, setTierplanInvalid] = useState(false);
  const [startDateInvalid, setStartDateInvalid] = useState(false);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [media, setMedia] = useState([]);
  const [healthHero, setHealthHero] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    address: "",
    tier_plan: "",
    plan_start_date: "",
    plan_end_date: "2021-04-28",
    logo: "",
    pictures: [],
    videos: [],
    code: "",
    health_heroes : [],
  });

  //function to clear input field after submit
  const clearData = () => {
    setData({
      name: "",
      email: "",
      address: "",
      tier_plan: "",
      plan_start_date: "",
      plan_end_date: "2021-04-28",
      logo: "",
      pictures: [],
      videos: [],
      code: "",
      health_heroes : [],
    });
  };


  useEffect(() => {
    props.getSubscriptionPlans();
  }, []);

  useEffect(() => {
    if (props.addHospitalSuccess.success) {
      setData({});
      setShow(true);
    }
  }, [props.addHospitalSuccess.success]);

  useEffect(() => {
      if(Object.keys(props.hospitalCopyDetails).length > 0){
      setData({
          name: props?.hospitalCopyDetails?.data?.name,
          email: props?.hospitalCopyDetails?.data?.email,
          address: props?.hospitalCopyDetails?.data?.address,
          logo: props?.hospitalCopyDetails?.data?.logo,
          pictures: props?.hospitalCopyDetails?.data?.pictures
            ? props?.hospitalCopyDetails?.data?.pictures
            : [],
          videos: props?.hospitalCopyDetails?.data?.videos ? props?.hospitalCopyDetails?.data?.videos : [],
          plan_start_date: "",
          plan_end_date: "2021-04-28",
          tier_plan: props?.hospitalCopyDetails?.data?.tier_plan?.id
            ? props?.hospitalCopyDetails?.data?.tier_plan?.id
            : props?.hospitalCopyDetails?.data?.tier_plan,
          code: props?.hospitalCopyDetails?.data?.code,
          health_heroes: props?.hospitalCopyDetails?.data?.health_heroes ? props?.hospitalCopyDetails?.data?.health_heroes : []
      });
      }
  },[props.hospitalCopyDetails]);

  

  useEffect(() => {
    if(data.pictures && data.videos){
      setMedia([...data.pictures, ...data.videos]);
    }
    
  }, [data.pictures, data.videos]);


  const handleLater = () => {
    props.hospitalSuccess(false);
    props.history.push(ROUTES_PATH.SUBSCRIBER);
    window.location.reload();
    props.getHospitalCopyDetails({})
    setHealthHero(false);
    clearData();
    setShow(false);
  };

  const handleEdit = () => {
    props.hospitalSuccess(false);
    props.history.push(ROUTES_PATH.EDIT_HOSPITAL);
    clearData();
    setShow(false);
  };

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.SUBSCRIBER);
    props.getHospitalCopyDetails({})
    clearData()
  };

  const logoInput = useRef(null);
  const handleUploadClick = e => {
    logoInput.current.click();
  };
  const handleLogoUpload = e => {
    setLoader(true);
    S3Client.uploadFile(e.target.files[0], data.name.split(" ")[0])
      .then(res => {
        setLoader(false);
        setData({ ...data, logo: res.location });
      })
      .catch(err => console.error(err));
  };

  //functions to automatic scroll the cursor to error input
  const scrolltoRef = ref => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const executeScroll = () => scrolltoRef(myRef);

  const handleSubmit = e => {
    props.toggleMainLoader(true);
    let nameValid = validateName(data.name);
    let emailValid = validateEmail(data.email);
    let codeValid = validateUniqueCode(data.code);
    let addressValid = validateAddress(data.address);
    if (!data.name || !nameValid) {
      setNameInvalid(true);
    }
    if (!data.address || !addressValid) {
      setAddressInvalid(true);
    }
    if (!data.email || !emailValid) {
      setEmailInvalid(true);
    }
    if (!data.plan_start_date) {
      setStartDateInvalid(true);
    }
    if (!data.tier_plan) {
      setTierplanInvalid(true);
    }
    if (!data.code || !codeValid) {
      setCodeInvalid(true);
      executeScroll();
    }
    if (
      !data.name ||
      !nameValid ||
      !addressValid ||
      !data.address ||
      !data.email ||
      !emailValid ||
      !data.plan_start_date ||
      !data.tier_plan ||
      !data.code ||
      !codeValid
    ) {
      props.toggleMainLoader(false);
      return;
    }
    if (
      !nameInvalid &&
      nameValid &&
      !addressInvalid &&
      addressValid &&
      !emailInvalid &&
      emailValid &&
      !tierplanInvalid &&
      !startDateInvalid &&
      !codeInvalid &&
      codeValid
    ) {
      const hospital_data = {
        name: data.name,
        address: data.address,
        logo: data.logo ? data.logo : null,
        email: data.email,
        tier_plan: data.tier_plan,
        plan_start_date: moment(data.plan_start_date).format("YYYY-MM-DD"),
        plan_end_date: data.plan_end_date,
        code: data.code,
        pictures: data.pictures.length ? data.pictures : null,
        videos: data.videos.length ? data.videos : null,
      };
      props.addHospital(hospital_data, clearData);
      setSuccess(true);
    }
  };

  const handleCopySubmit = () =>{
    props.toggleMainLoader(true);
    let nameValid = validateName(data.name);
    let emailValid = validateEmail(data.email);
    let codeValid = validateUniqueCode(data.code);
    let addressValid = validateAddress(data.address);
    if (!data.name || !nameValid) {
      setNameInvalid(true);
    }
    if (!data.address || !addressValid) {
      setAddressInvalid(true);
    }
    if (!data.email || !emailValid) {
      setEmailInvalid(true);
    }
    if (!data.plan_start_date) {
      setStartDateInvalid(true);
    }
    if (!data.tier_plan) {
      setTierplanInvalid(true);
    }
    if (!data.code || !codeValid) {
      setCodeInvalid(true);
      executeScroll();
    }
    if(nameChanged === false){
      toast.error('Hopital with this name already exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (
      !data.name ||
      !nameValid ||
      !addressValid ||
      !data.address ||
      !data.email ||
      !emailValid ||
      !data.plan_start_date ||
      !data.tier_plan ||
      !data.code ||
      !codeValid ||
      nameChanged === false
    ) {
      props.toggleMainLoader(false);
      return;
    }
    if (
      !nameInvalid &&
      nameValid &&
      !addressInvalid &&
      addressValid &&
      !emailInvalid &&
      emailValid &&
      !tierplanInvalid &&
      !startDateInvalid &&
      !codeInvalid &&
      codeValid &&
      nameChanged === true
    ) {

    const hospital_data = {
      name: data.name,
      address: data.address,
      logo: data.logo && data.logo,
      email: data.email,
      tier_plan: data.tier_plan,
      plan_start_date: moment(data.plan_start_date).format("YYYY-MM-DD"),
      plan_end_date: data.plan_end_date,
      code: data.code,
      pictures: data.pictures.length ? data.pictures : null,
      videos: data.videos.length ? data.videos : null,
      health_heroes : data.health_heroes ? data.health_heroes : null,
    };

    props.addHospital(hospital_data);
    setHealthHero(true);
  }
    
  }

  return (
    <Container className="common-container">
      <Back
        className={"back-nav"}
        backNavItemClassName={"back-nav-item"}
        backIconClassName={"back-icon"}
        isEdit={false}
        isDelete={false}
        isSave={true}
        Back={handleBack}
        onSave={Object.keys(props.hospitalCopyDetails.data).length > 0 ? handleCopySubmit : handleSubmit}
        loader={props.loader}
      />
      <TitleBar
        titlename={"Add Hospital Details"}
        headingClass={"title-bar-heading"}
      />

      <Form noValidate style={{ paddingLeft: "16px" }}>
        <Row>
          <Col>
            <Form.Group controlId="formBasicName">
              <Form.Label className="common-form-label">
                Hospital Name<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="common-form-input"
                type="text"
                placeholder="Hospital Name"
                name="name"
                maxLength="150"
                value={data.name}
                isInvalid={nameInvalid}
                onChange={e => {
                  setNameInvalid(false);
                  handleChange(e);
                  if(Object.keys(props.hospitalCopyDetails.data).length > 0){
                  setNameChanged(true)
                  }
                }}
              />
              <Form.Control.Feedback
                type="invalid"
                className="common-invalid-label"
              >
                {MESSAGE.NAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicAddress">
              <Form.Label className="common-form-label">
                Hospital Address<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                className="common-form-input"
                type="text"
                placeholder="Hospital Address"
                name="address"
                maxLength="250"
                value={data.address}
                isInvalid={addressInvalid}
                onChange={e => {
                  setAddressInvalid(false);
                  handleChange(e);
                }}
              />
              <Form.Control.Feedback
                type="invalid"
                className="common-invalid-label"
              >
                {MESSAGE.ADDRESS_INVALID}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label className="common-form-label">
                Email<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="common-form-input"
                type="email"
                placeholder="Hospital Email"
                name="email"
                value={data.email}
                isInvalid={emailInvalid}
                onChange={e => {
                  setEmailInvalid(false);
                  handleChange(e);
                }}
              />
              <Form.Control.Feedback
                type="invalid"
                className="common-invalid-label"
              >
                {MESSAGE.EMAIL_INVALID}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicSubscriptionPlan">
              <Form.Label className="common-form-label">
                Subscription Plan<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                as="select"
                className="common-form-input img-class"
                name="tier_plan"
                isInvalid={tierplanInvalid}
                onChange={e => {
                  setTierplanInvalid(false);
                  handleChange(e);
                }}
                value={data.tier_plan}
              >
                <option>Select Tier Plan</option>
                {props.plans &&
                  props.plans.map((plans, index) => {
                    return (
                      <option key={index} value={plans.id}>
                        {plans.type}
                      </option>
                    );
                  })}
              </Form.Control>
              <Form.Control.Feedback
                type="invalid"
                className="common-invalid-label"
              >
                {MESSAGE.TIER_PLAN_INVALID}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label className="common-form-label">
                Subscription Start Date<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <br></br>
              <DatePicker
                selected={data.plan_start_date}
                onChange={date => {
                  setStartDateInvalid(false);
                  setData({ ...data, plan_start_date: date });
                }}
                className={
                  !startDateInvalid
                    ? "common-date-input"
                    : "common-date-input-error"
                }
                minDate={new Date()}
                placeholderText="MM/DD/YYYY"
                dateFormat="MM/dd/yyyy"
              />
              {startDateInvalid && (
                <div className="clinician-invalid">{MESSAGE.DATE_INVALID}</div>
              )}
            </Form.Group>

            <Form.Group controlId="formBasicCode" ref={myRef}>
              <Form.Label className="common-form-label">
                Unique Code<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="common-form-input"
                type="text"
                placeholder="Enter 6 Digit Unique Code"
                name="code"
                maxLength="6"
                value={data.code}
                isInvalid={codeInvalid}
                onChange={e => {
                  setCodeInvalid(false);
                  handleChange(e);
                }}
              />
              <Form.Control.Feedback
                type="invalid"
                className="common-invalid-label"
              >
                {MESSAGE.UNIQUE_CODE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col>
            {data.logo === "" || data.logo === undefined ? (
              <div className="upload-logo">
                <p className="upload-label-logo" onClick={handleUploadClick}>
                  {!loader ? (
                    <>
                      <img src={UPLOAD_PLUS} alt="" />
                      <span> Add Hospital Logo</span>
                    </>
                  ) : (
                    <img
                      src={BUTTON_SPINNER}
                      style={{ height: "30px" }}
                      alt=""
                    />
                  )}
                </p>
              </div>
            ) : (
              <div className="after-upload-logo">
                <img src={data.logo} alt="image" onClick={handleUploadClick} />{" "}
              </div>
            )}

            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleLogoUpload}
              ref={logoInput}
            />
          </Col>
        </Row>
      </Form>

      <HospitalImage
        data={data}
        setData={setData}
        media={media}
        setMedia={setMedia}
        saveModal ={false}
      />

      
      <UserModal
      modal={show}
      success={success}
      handleClose={handleLater}
      handleEdit={handleEdit}
      copyHealthHero = {healthHero}
      /> 

      {
      Object.keys(props.hospitalCopyDetails.data).length > 0 ?
      <HospitalUser 
      healthHero={true}
      healthHerodata={Object.keys(props.hospitalCopyDetails.data).length > 0 && props?.hospitalCopyDetails?.data?.health_heroes}
      /> 

       :
       null}

      <Footer className={"my-footer"} />
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    plans: state.plans.data,
    loader: state.loader.mainLoader,
    hospitalDetails: state.hospitalDetails,
    addHospitalSuccess: state.addHospitalSuccess,
    hospitalCopyDetails : state.hospitalCopyDetails
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSubscriptionPlans: actionCreator.getSubscriptionPlans,
      addHospital: actionCreator.addHospital,
      toggleMainLoader: actionCreator.toggleMainLoader,
      hospitalSuccess: actionCreator.addHospitalSuccess,
      getHospitalCopyDetails : actionCreator.getHospitalCopyDetails,
      deleteHealthHero: actionCreator.deleteHealthHero,
      addHealthHero: actionCreator.addHealthHero,
      getHospitalDetails: actionCreator.getHospitalDetails,
      getAllHospitals: actionCreator.getAllHospitals,
      getSubscriptionPlans: actionCreator.getSubscriptionPlans,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHospital);
