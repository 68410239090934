import React from "react";
import { Form, FormControl } from "react-bootstrap";
import "./navbar.css";

const Search = (props) => {
  return (
    <Form inline>
      <FormControl
        type="text"
        placeholder="Search"
        className="mr-sm-0 search-classname-css"
        value={props.value}
        onChange={props.onChange}
        bsPrefix={"search-classname-css"}
      />
    </Form>
  );
};
export default Search;
