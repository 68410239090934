export const areasOfInterestOptions = [
  { value: "Child care", label: "Child care" },
  { value: "Child physchology", label: "Child physchology" },
  { value: "Child behaviour", label: "Child behaviour" },
];

export const medicalIssuesOptions = [
  { value: "Diabetes", label: "Diabetes" },
  { value: "Cancer", label: "Cancer" },
  { value: "Blood pressure", label: "Blood pressure" },
  { value: "Child is in Emergency Room", label: "Child is in Emergency Room" },
  { value: "Tay-Sachs disease", label: "Tay-Sachs disease" },
  { value: "Autism", label: "Autism" },
  { value: "Sickle cell disease", label: "Sickle cell disease" },
  { value: "Other", label: "Other" },
  { value: "Unknown", label: "Unknown" },
];

export const sensoryOptions = [
  { value: "Bright lights", label: "Bright lights" },
  { value: "Loud noises", label: "Loud noises" },
  { value: "Being touched", label: "Being touched" },
  { value: "Specific textures", label: "Specific textures" },
  { value: "Crowded Places", label: "Crowded Places" },
  { value: "Other", label: "Other" },
];
