import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ERROR_IMAGE, ROUTES_PATH } from "../../constant";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }
  render() {
    const handleClick = () => {
      this.props.history.push(ROUTES_PATH.HOME);
      window.location.reload();
    };

    if (this.state.hasError) {
      return (
        <>
          <Container>
            <img className="error-boundary-css" src={ERROR_IMAGE}></img>
            <div className="error-boundary-text">Oops! Page Not Found</div>
            <div className="error-boundary-text1">
              We’re sorry, the page you requested could not be found.
            </div>
            <div className="error-boundary-text1">
              Please go back to the previous page to continue
            </div>
            <button className="error-boundary-button" onClick={handleClick}>
              Go Back
            </button>
          </Container>{" "}
        </>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
