import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import Back from "../../../common/components/back";
import { ROUTES_PATH } from "../../../constant";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import { newRename } from "../../../utils/rename";
import ContentTable from "../ContentTable/contentTable";
import Footer from "../../Footer";
import AddWhatsGoingOn from "./addWhatsGoingOn";
import DeleteModal from "../deleteModal";

const WhatsGoingOnMe = props => {
  const [scheduledData, setScheduledData] = useState([]);
  const [scheduledPage, setScheduledPage] = useState(1);
  const [publishedData, setPublishedData] = useState([]);
  const [publishedPage, setPublishedPage] = useState(1);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };

  //useEffect to get data when page loads
  useEffect(() => {
    props.toggleMainLoader(true);
    props.getScheduledWhatsGoingOnMe("SCHEDULED", 1);
    props.getPublishedWhatsGoingOnMe("PUBLISHED", 1);
  }, [props.match.params.path]);

  //useEffect to close the modal after adding the data
  useEffect(() => {
    setScheduledPage(scheduledPage);
    setPublishedPage(publishedPage);
    props.toggleMainLoader(true);
    props.getScheduledWhatsGoingOnMe("SCHEDULED", scheduledPage);
    props.getPublishedWhatsGoingOnMe("PUBLISHED", publishedPage);
    if (props.responseSuccess) {
      setDeleteIndex(0);
      setDeleteModal(false);
      setShow(false);
      props.successResponse(false);
    }
  }, [props.responseSuccess]);

  const headerScheduled = {
    headerOne: "Term",
    headerTwo: "Uploaded By",
    headerThree: "Scheduled Date",
  };
  const headerPublished = {
    headerOne: "Picture Title",
    headerTwo: "Uploaded By",
    headerThree: "Uploaded On",
  };

  useEffect(() => {
    setScheduledData(
      newRename(
        props.scheduledWhatsGoingOnMe.results,
        "term",
        "uploaded_by",
        "scheduled_date"
      )
    );
  }, [props.scheduledWhatsGoingOnMe.results]);

  useEffect(() => {
    setPublishedData(
      newRename(
        props.publishedWhatsGoingOnMe.results,
        "term",
        "uploaded_by",
        "scheduled_date"
      )
    );
  }, [props.publishedWhatsGoingOnMe.results]);

  useEffect(() => {
    if(props.openModal){
      setShow(true);
      setEdit(true);
      props.openSearchModal(false)
    }
  }, [])

  //pageChange function for scheduled data
  const scheduledPageChange = select => {
    props.toggleMainLoader(true);
    props.getScheduledWhatsGoingOnMe("SCHEDULED", select);
    setScheduledPage(select);
  };

  //pageChange function for published data
  const publishedPageChange = select => {
    props.toggleMainLoader(true);
    props.getPublishedWhatsGoingOnMe("PUBLISHED", select);
    setPublishedPage(select);
  };

  const handleAddButton = () => {
    setShow(true);
    setEdit(false);
  };

  const handleCancelClose = () => {
    setShow(false);
  };

  //Update and Delete functions
  const handleDelete = index => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteIndex(0);
    setDeleteModal(false);
  };
  const handleDeleteQuotes = () => {
    props.toggleMainLoader(true);
    props.deleteWhatsGoingonMe(deleteIndex);
  };

  const handleUpdate = data => {
    props.contentDetails(data);
    setEdit(true);
    setShow(true);
  };

  const handleUpdateClose = () => {
    setShow(false);
    setEdit(false);
  };

  return (
    <>
      <Container className="common-container">
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />

        <UserManagementNavbar
          title={"What’s going on with me (Child)"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          buttonTitle={"Add Term"}
          handleAddButton={handleAddButton}
        />

        <ContentTable
          mainHeader={"Published"}
          header={headerPublished}
          dataList={publishedData}
          itemCountFromServer={props.publishedWhatsGoingOnMe.count}
          rowPerPage={10}
          pageNumber={publishedPage}
          changePage={publishedPageChange}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
        />

        <div style={{ marginTop: "39px" }}></div>

        <ContentTable
          mainHeader={"Scheduled"}
          header={headerScheduled}
          dataList={scheduledData}
          itemCountFromServer={props.scheduledWhatsGoingOnMe.count}
          rowPerPage={10}
          pageNumber={scheduledPage}
          changePage={scheduledPageChange}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
        />
        

        
        <AddWhatsGoingOn
          show={show}
          title={"What’s going on with me (Term)"}
          contentClassName={"modal-content-quotes"}
          handleCancelClose={edit ? handleUpdateClose : handleCancelClose}
          isEdit={edit}
        />

        <DeleteModal
          modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteQuotes}
          loader={props.loader}
        />
      </Container>
      <Footer className={"content-management-footer"} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    responseSuccess: state.responseSuccess.responseSuccess,
    scheduledWhatsGoingOnMe: state.scheduledWhatsGoingOnMe,
    publishedWhatsGoingOnMe: state.publishedWhatsGoingOnMe,
    loader: state.loader.mainLoader,
    openModal : state.modalFromSearch.openModal,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleMainLoader: actionCreator.toggleMainLoader,
      successResponse: actionCreator.responseSuccess,
      getScheduledWhatsGoingOnMe: actionCreator.getScheduledWhatsGoingOnMe,
      getPublishedWhatsGoingOnMe: actionCreator.getPublishedWhatsGoingOnMe,
      deleteWhatsGoingonMe: actionCreator.deleteWhatsgoingOnme,
      contentDetails: actionCreator.getContentDetails,
      openSearchModal : actionCreator.isOpenModalFromSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsGoingOnMe);
